/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import { User, Organization, TranslationGroup, trans, TranslationKey } from 'lib/types'
import { DetailSliderContent } from 'components/partials'
import { DetailRow } from './utils'

import { getNameFromUser, formatDistanceToNow, formatTime } from 'lib/utils'
import { LOCAL_DATE } from 'lib/constants'
import { Tooltip } from '@material-ui/core'
import { detailStyles } from 'styles'
const pluralize = require('pluralize')

export const UserDetailDrawer = ({ user, close }: { user: User; close: Function }) => {
  const translation: TranslationGroup = trans.merge(TranslationKey.USER_TABLE_VIEW)
  const { membership = [] } = user.attributes
  const properties = membership.filter(org => org.orgType === 'property')
  const groups = membership.filter(org => org.orgType === 'group')
  const lastCheckedIn = user.attributes.lastCheckedInAt ? (
    formatDistanceToNow(user.attributes.lastCheckedInAt)
  ) : (
    <Tooltip enterDelay={1000} enterNextDelay={1000} placement={'left'} title={translation.no_checkin}>
      <span>–</span>
    </Tooltip>
  )

  const renderOrgs = (orgDisplayType: string, orgs: Organization[], showName?: boolean) =>
    orgs.length > 0 && (
      <DetailRow
        title={pluralize(orgDisplayType, orgs.length)}
        content={
          <div css={{ display: 'inline' }}>
            {orgs.map((org, i) => (
              <div key={i}>{showName ? org.name : org.id}</div>
            ))}
          </div>
        }
      />
    )

  return (
    <>
      <DetailSliderContent title="User Details" close={close}>
        <ul css={detailStyles}>
          <DetailRow title={translation.Name} content={getNameFromUser(user)} />
          {renderOrgs(translation.Property, properties, true)}
          {renderOrgs(translation.Property_ID, properties, false)}
          {renderOrgs(translation.Group, groups, true)}
          {renderOrgs(translation.Group_ID, groups, false)}
          <DetailRow title={translation.Last_Check_In} content={lastCheckedIn} />
          <DetailRow title={translation.Contact} content={user.attributes.email} />
          <DetailRow
            title={translation.User_Ceated}
            content={formatTime(user.attributes.insertedAt, LOCAL_DATE) as string}
          />
          {user.attributes.superAdmin && <DetailRow title={translation.super_admin} content="true" />}
          {user.attributes.provider && (
            <DetailRow title={translation.login_provider} capitalize={true} content={user.attributes.provider} />
          )}
          <DetailRow title={translation.User_ID} content={user.id} />
        </ul>
      </DetailSliderContent>
    </>
  )
}
