/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import {
  ButtonProps,
  DeviceProps,
  FirmwareVersionProps,
  trans,
  TranslationGroup,
  TranslationKey,
  UpdateFirmwareProps,
} from 'lib/types'
import { getAppColors } from 'lib/constants'
import { Firmware } from 'components/partials'
import { SignalStrengthIcon } from './SignalStrengthIcon'
import { textTransform } from 'styles'
import { formatDistance } from 'lib/utils'

export const DetailRow = ({
  title,
  capitalize,
  content,
}: {
  title: string
  capitalize?: boolean
  content: string | number | React.ReactElement
}) => {
  let contentStyles: Record<string, string | number> = {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 15px',
    fontWeight: 500,
    lineHeight: '1.9rem',
    textAlign: 'end',
    overflowWrap: 'anywhere',
  }
  if (capitalize) {
    contentStyles = { ...contentStyles, ...textTransform('capitalize') }
  }
  return (
    <li
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        margin: '5px 0',
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontSize: '1.4rem',
      }}
    >
      <h3
        css={{
          display: 'flex',
          fontSize: 'inherit',
          color: getAppColors('--color-primary'),
          fontWeight: 600,
          lineHeight: '1.554',
          margin: 0,
          padding: 0,
          textTransform: 'capitalize',
        }}
      >
        {`${title}:`}
      </h3>
      <div css={contentStyles}>{content}</div>
    </li>
  )
}

export function WanDetail({ data }: { data: DeviceProps }) {
  const { carrier, currentUptime, hardwareType, iccid, ipAddress, rssi, temperature, wirelessBand, wirelessMode } = data
  const translation: TranslationGroup = trans.merge(TranslationKey.DEVICES_DRAWER)
  let temp = ''
  if (temperature) {
    const c = !isNaN(Number(temperature)) ? Number(temperature) : null
    if (c) {
      const f = Math.round((c * 9) / 5) + 32
      temp = `${c}°C / ${f}°F`
    }
  }
  let duration = ''
  if (!isNaN(Number(currentUptime))) {
    const t = Number(currentUptime) * 1000 // convert seconds to milliseconds
    duration = formatDistance(t)
  }
  return (
    <>
      {hardwareType !== undefined && <DetailRow title={translation.device_type} content={hardwareType} />}
      {temp && <DetailRow title={translation.temperature} content={temp} />}
      {wirelessBand !== undefined && <DetailRow title={translation.wireless_band} content={wirelessBand} />}
      {wirelessMode !== undefined && <DetailRow title={translation.wireless_mode} content={wirelessMode} />}
      {iccid !== undefined && <DetailRow title={translation.iccid} content={iccid} />}
      {ipAddress !== undefined && <DetailRow title={translation.ip_address} content={ipAddress} />}
      {carrier !== undefined && <DetailRow title={translation.carrier} content={carrier} />}
      {rssi !== undefined && (
        <DetailRow title={translation.rssi.toUpperCase()} content={<SignalStrengthIcon rssi={rssi} />} />
      )}
      {duration && <DetailRow title={translation.uptime} content={duration} />}
    </>
  )
}

export function ButtonDetail({ data, update }: { data: ButtonProps; update?: UpdateFirmwareProps }) {
  const { batteryPercent, connected, id, version } = data
  const translation: TranslationGroup = trans.merge(TranslationKey.DEVICES_DRAWER)

  return (
    <>
      <DetailRow title={translation.button_paired} content={`${!!connected ? translation.yes : ''}`} />
      <DetailRow title={translation.button_id} content={`${id}`} />
      {Number.isInteger(batteryPercent) && (
        <DetailRow title={translation.button_battery} content={`${batteryPercent}%`} />
      )}
      {version && <FirmwareDetail title={translation.button_firmware} version={version} update={update} />}
    </>
  )
}

export function FirmwareDetail({
  fallback,
  title,
  update,
  version = {},
}: {
  fallback?: string
  title: string
  update?: UpdateFirmwareProps
  version?: FirmwareVersionProps
}) {
  return <DetailRow content={<Firmware update={update} fallback={fallback} version={version} />} title={`${title}`} />
}
