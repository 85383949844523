import { GeneralSettingsContent } from './GeneralSettingsContent'
import AdminRequired from 'components/partials/AdminRequired'

const GeneralSettingsPage = () => (
  <AdminRequired>
    <GeneralSettingsContent />
  </AdminRequired>
)

export default GeneralSettingsPage
