import { FlattenedOrg } from 'models/Orgs'
import { isEmpty } from 'lodash'
import { trans, TranslationGroup } from 'lib/types'

export const validateOrgData = (org: FlattenedOrg) => {
  const translation: TranslationGroup = trans.admin()
  if (org.name.length >= 255) return translation.org_name_valid_length
  const proximityUuId = org.proximityUuidValue?.replace(/_/g, '') || ''
  if (proximityUuId.length < 36 && !isEmpty(org.proximityUuidValue)) return translation.proximity_id_valid_length

  return
}
