/** @jsxImportSource @emotion/react */
import {
  IconButton,
  useTheme,
} from '@material-ui/core'
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions'
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, Sort } from '@material-ui/icons'

import { trans, TranslationGroup } from 'lib/types'

const PaginatedTableActions = (props: TablePaginationActionsProps) => {
  const { page: pageProp, count, rowsPerPage, onPageChange } = props
  const theme = useTheme()
  const numPages = Math.ceil(count / rowsPerPage)
  const page = Math.max(Math.min(pageProp, numPages), 0)

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 1)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(1, numPages))
  }
  const common: TranslationGroup = trans.common()

  return (
    <div
      css={{
        flexShrink: 0,
      }}
    >
      <IconButton onClick={handleFirstPageButtonClick} disabled={page <= 1} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page <= 1} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <span>
        {common.page} {page} {common.of} {numPages}
      </span>
      <IconButton onClick={handleNextButtonClick} disabled={page >= numPages} aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= numPages} aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  )
}

export default PaginatedTableActions