/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'

import { NumberBubble } from 'components/widgets'
import { Organization } from 'lib/types'
import { Grants } from 'models'
import { ViewUsers } from './ViewUsersButton'
import uniq from 'lodash/uniq'

export const UsersCount = ({ org }: { org: Organization }) => {
  const orgUsers = uniq(Grants.useOrgUserMap()[org.id] || [])

  return (
    <div
      css={{
        whiteSpace: 'nowrap',
        display: 'flex',
      }}
    >
      <NumberBubble value={orgUsers?.length} />
      <ViewUsers userIds={orgUsers} />
    </div>
  )
}
