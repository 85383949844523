/** @jsxImportSource @emotion/react */
import { shallowEqual } from 'react-redux'
import { Button, Chip, FormControl, MenuItem, Select } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { StyledSubHeader } from 'components/widgets'
import { getAppColors, GRAY3_COLOR } from 'lib/constants'
import { Organization, trans, TranslationGroup, TranslationKey } from 'lib/types'
import _ from 'lodash'
import { ChildOrgMap, Orgs } from 'models'
import { useState } from 'react'
import { BORDER1_COLOR } from 'lib/constants'

const manageGroupsStyles = {
  borderRadius: '10px',
  backgroundColor: GRAY3_COLOR,
}
const tagsContainerStyles = {
  padding: '15px',
  borderBottom: `1px solid ${BORDER1_COLOR}`,
  display: 'flex',
  flexFlow: 'row wrap',
}
const addGroupContainerStyles = {
  padding: '15px',
  display: 'flex',
}

export const PropertyGroupAssignment = ({ selectedProperty }: { selectedProperty: Organization }) => {
  const translation: TranslationGroup = trans.group(TranslationKey.ADMIN)

  const parentOrgMap = ChildOrgMap.use(({ parentOrgMap }) => parentOrgMap)
  const { orgs, orgsById } = Orgs.use(({ orgs, orgsById }) => {
    return { orgs, orgsById }
  }, shallowEqual)
  const [dropdownSelectedGroupId, setDropdownSelectedGroupId] = useState<string | null>(null)
  const allGroups = orgs.filter(Orgs.isGroup)
  const assignedGroupIds = parentOrgMap[selectedProperty.id]
  const assignedGroups = !!assignedGroupIds ? assignedGroupIds?.map(id => orgsById[id]).filter(Orgs.isGroup) : []

  const assignableGroups = _.xorBy(allGroups, assignedGroups, org => org.id)

  function handleAddGroup() {
    !!dropdownSelectedGroupId
      ? ChildOrgMap.create(dropdownSelectedGroupId, selectedProperty.id).then(Orgs.reload)
      : console.log('no id selected')
  }

  function removeGroup(groupId: string) {
    ChildOrgMap.deleteRelationship(groupId, selectedProperty.id).then(Orgs.reload)
  }

  return (
    <>
      <StyledSubHeader css={{ label: 'PropertyGroupAssignment-title' }}>
        {translation.associated_groups}
      </StyledSubHeader>
      <div css={manageGroupsStyles}>
        <div css={tagsContainerStyles}>
          {assignedGroups?.length > 0 ? (
            assignedGroups.map(group => (
              <Chip
                key={group.id}
                label={group.name}
                onDelete={() => removeGroup(group.id)}
                deleteIcon={<Delete />}
                variant="outlined"
                css={{ fontFamily: 'Montserrat' }}
              />
            ))
          ) : (
            <div css={{ margin: 'auto' }}>{translation.no_groups_assigned}</div>
          )}
        </div>
        <div css={addGroupContainerStyles}>
          <FormControl css={{ width: '200px' }}>
            <Select label="Select group" onChange={e => setDropdownSelectedGroupId(`${e.target.value}`)}>
              {assignableGroups.map(group => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            css={{
              color: 'white',
              backgroundColor: getAppColors('--color-brand'),
              marginLeft: '12px',
              fontFamily: 'Montserrat',
            }}
            onClick={handleAddGroup} // TODO: add group to property
          >
            {translation.add_this_property_to_group}
          </Button>
        </div>
      </div>
    </>
  )
}
