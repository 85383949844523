import { getAppColors } from 'lib/constants'

export const formControlStyles = {
  margin: '0 15px',
  alignSelf: 'center',
  background: getAppColors('--color-gray-3'),
  borderRadius: '15px',
  color: getAppColors('--color-secondary'),
  fontFamily: 'Montserrat',
  '&:first-of-type': {
    marginLeft: 0,
    marginRight: '15px',
  },
}
