import jwtDecode, { JwtPayload } from 'jwt-decode'
import { JWT_TOKEN, GRANTS } from 'lib/constants'
import { JWTResponse, UserGrant } from 'lib/types'
import { Users } from 'models'

function token_decodes(): boolean {
  const token = localStorage.getItem(JWT_TOKEN)
  if (!token) return false
  try {
    jwtDecode(token)
  } catch (e) {
    return false
  }
  return true
}

export function isSuperAdmin(): boolean {
  if (!token_decodes()) return false
  return Users.store.getState().currentUser?.attributes.superAdmin === true
}

export function hasOrgGrant(grant_name: string, orgId?: string): boolean {
  if (!token_decodes()) return false
  const userGrants: UserGrant[] = Users.store.getState().currentUser?.attributes.grants || []
  const orgUserGrants = userGrants.filter(g => g.org_id === orgId)
  return orgUserGrants.filter(g => g.name === grant_name).length > 0
}

export function hasGrantAnywhere(grant_name: string): boolean {
  if (!token_decodes()) return false
  const userGrants: UserGrant[] = Users.store.getState().currentUser?.attributes.grants || []
  return userGrants.filter(g => g.name === grant_name).length > 0
}

export function isAdmin(org?: string): boolean {
  if (isSuperAdmin()) return true
  return hasOrgGrant(GRANTS.ADMIN, org)
}

export function isAdminAnywhere(): boolean {
  if (isSuperAdmin()) return true
  return hasGrantAnywhere(GRANTS.ADMIN)
}

export function isPortalUser(org?: string): boolean {
  if (isSuperAdmin()) return true
  return hasOrgGrant(GRANTS.PORTAL_USER, org)
}

export function isInstaller(org?: string): boolean {
  if (isSuperAdmin()) return true
  return hasOrgGrant(GRANTS.INSTALLER, org)
}

export function isResponder(org?: string): boolean {
  if (isSuperAdmin()) return true
  return hasOrgGrant(GRANTS.RESPONDER, org)
}

export function isMe(user_id: string): boolean {
  const token = getUserJWT()
  if (!token) return false
  try {
    const decoded = jwtDecode(token) as any
    const sub = JSON.parse(decoded.sub)
    return user_id === sub.user_id
  } catch (e) {
    return false
  }
}

export function isMyEmail(email: string): boolean {
  const token = getUserJWT()
  if (!token) return false
  try {
    const decoded = jwtDecode(token) as any
    const sub = JSON.parse(decoded.sub)
    return email === sub.email
  } catch (e) {
    return false
  }
}

export function isDispatcher(org?: string): boolean {
  return isResponder(org) && isPortalUser(org)
}

export function getUserJWT() {
  return localStorage.getItem(JWT_TOKEN)
}

export function getDataFromJWT() {
  const token = localStorage.getItem(JWT_TOKEN)
  if (token) {
    const decoded = jwtDecode<JwtPayload>(token)
    if (decoded.sub) {
      let JWT_resp: JWTResponse = JSON.parse(decoded.sub)
      return JWT_resp
    }
    return null
  }
  return null
}
