import { deleteRequest, getRequest, postRequest } from './rest'
import { ALL_ORGS_SELECTED, API_CHILD_ORGS } from 'lib/constants'
import { ChildOrg } from 'lib/types'

export const getChildOrgIdsByParentId = (parentId: string): Promise<string[]> =>
  parentId === ALL_ORGS_SELECTED
    ? Promise.resolve([])
    : getAllChildOrgs().then(childOrgs =>
        childOrgs
          .filter(({ parentOrgId }) => parentOrgId === parentId)
          ?.map(({ childOrgId }) => childOrgId),
      )

export const getAllChildOrgs = (): Promise<ChildOrg[]> =>
  getRequest({ endpoint: API_CHILD_ORGS }).then(({ data }) =>
    data.data.map(({ attributes }: { attributes: ChildOrg }) => attributes),
  )

export const createChildOrgRelationship = (
  parentOrgId: string,
  childOrgId: string,
) =>
  postRequest({
    endpoint: `${API_CHILD_ORGS}/`,
    data: { child_org: { childOrgId: childOrgId } },
    header: { org_id: parentOrgId },
  })

export const deleteChildOrgRelationship = (
  parentOrgId: string,
  childOrgId: string,
) =>
  deleteRequest({
    endpoint: `${API_CHILD_ORGS}/${childOrgId}`,
    header: { org_id: parentOrgId },
  })
