import { AlertEvent, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { compact } from 'lodash'
import { INTEGRATIONS, INTEGRATION_MAP, UPDATED_BY_DISPATCHER } from 'lib/constants'
import { ACTION_TYPE } from 'lib/constants/keys/actionTypes'

export const eventsAreFromTheSameSource = (e1: AlertEvent, e2: AlertEvent) =>
  (e1.attributes.alertSourceId && e1.attributes.alertSourceId === e2.attributes.alertSourceId) ||
  (e1.attributes.userId && e1.attributes.userId === e2.attributes.userId)

export const eventsAreFromTheSameLocation = (e1: AlertEvent, e2: AlertEvent) => {
  const l1 = e1.attributes.location,
    l2 = e2.attributes.location
  return (
    (!l1 && !l2) ||
    (l1 &&
      l2 &&
      l1.floor == l2.floor &&
      l1.integration == l2.integration &&
      // TODO: Re-add these when they are being accessed.
      // l1.lat == l2.lat &&
      // l1.long == l2.long &&
      l1.room == l2.room &&
      l1.property == l2.property)
  )
}

// export const isBlankMapEvent = (event: any) => {

//   if (
//     event.attributes.action === null &&
//     event.attributes?.map !== null &&
//     event.attributes?.beacons.length === 0 &&
//     event.attributes?.integration === null
//   ) {
//     console.log('bae:', event)
//     return true
//   } else return false
// }

export const alertEventsAreDuplicates = (e1?: AlertEvent, e2?: AlertEvent) =>
  (e1 &&
    e2 &&
    e1.attributes.action === e2.attributes.action &&
    !e1.attributes.message &&
    !e2.attributes.message &&
    e1.attributes?.map === e2.attributes?.map &&
    eventsAreFromTheSameSource(e1, e2) &&
    eventsAreFromTheSameLocation(e1, e2)) ||
  false

export const getAlertDisplayMessage = (alert: AlertEvent) => {
  const { attributes } = alert
  const { action, location, message } = attributes

  const translation: TranslationGroup = trans.group(TranslationKey.ACTION_MESSAGES)
  const common: TranslationGroup = trans.common()

  // @ts-ignore
  let actionMessage =
    action === ACTION_TYPE.NO_ACTION || action === null || action === undefined ? '' : translation[action]
  actionMessage =
    action === ACTION_TYPE.ARRIVED && message === UPDATED_BY_DISPATCHER ? translation.dispatcher : actionMessage

  const locationIntegration = location?.integration
    ? `(${INTEGRATION_MAP[location.integration as INTEGRATIONS] || location.integration})`
    : undefined
  const loc = []
  if (location && location.room) loc.push(`${common.room}: ${location.room}`)
  if (location && location.floor) loc.push(`${common.floor}: ${location.floor}`)
  const locationMessage = loc.join(', ')
  let userMessage = message && (actionMessage || locationMessage) ? `${translation.message}: ${message}` : message
  userMessage = message === UPDATED_BY_DISPATCHER ? '' : userMessage

  return compact([actionMessage, locationMessage, locationIntegration, userMessage]).join('\n')
}
