export default {
  actionmessages: {
    arrived: 'arrived at the incident.',
    close: 'stopped the alert.',
    depart: 'quit responding.',
    dispatcher: 'indicated that someone has arrived at the alert.',
    enroll: 'Has joined the alert.',
    message: 'Message',
    nearby: 'is near the alert.',
    open: 'I need help.',
    integration: 'integration',
  },
  admin: {
    add_a_user: 'Add a User',
    add_property_to_this_group: 'Add Property to this Group',
    add_this_property_to_group: 'Add this Property to Group',
    add_user: 'Add User',
    address_line_1: 'Address Line 1',
    address_line_2: 'Address Line 2',
    admin_required: 'Please select an organization in which you have admin permissions',
    associated_groups: 'Associated Groups',
    associated_properties: 'Associated Properties',
    associated_users: 'Associated Users',
    back_to_all: 'Back to All',
    city: 'City',
    country: 'Country',
    image: 'Image',
    image_url: 'Image URL',
    no_groups: 'No Groups',
    no_groups_assigned: 'No Groups Assigned',
    no_properties_assigned: 'No properties assigned',
    no_users: 'No Users',
    org_name_valid_length: 'Name should be 255 characters or less.',
    proximity: 'Proximity',
    proximity_id: 'Proximity ID',
    proximity_id_valid_length: 'Proximity UUID should be 32 characters',
    proximity_uuid: 'Proximity UUID',
    proximity_uuid_friendly_name: 'Proximity UUID Friendly Name',
    state: 'State',
    street_one: 'Street 1',
    street_two: 'Street 2',
    updated: 'updated',
    zipcode: 'Zip Code',
  },
  adminfirmware: {
    add_new: 'Add a new firmware build',
    confirm_delete_firmware: 'Are you sure you want to delete the selected firmware?',
    delete_failed: 'There was a problem deleting the selected firmware.',
    delete_firmware: 'Delete Firmware',
    delete_successful: 'Firmware deleted successfully',
    delete_version: 'Delete Version',
    enviroment: 'Environment (Optional)',
    file: 'File',
    firmware_added: 'Firmware was added successfully',
    firmware_failure: 'Firmware upload failed',
    firmware_file: 'firmware file. Selected file appears to be for',
    hardware: 'Hardware',
    manage: 'Manage firmware versions',
    nofile: 'No file chosen',
    samantic: 'Must be in semantic versioning format',
    select_env: '-- Select Environment options --',
    select_file: 'Select File',
    semantic_version: 'Version must be in semantic version format',
    upload: 'Upload new firmware',
    upload_new: 'Upload New',
    version: 'Version',
    versions: 'Versions',
    was_expecting: 'was expecting a',
  },
  alertdrawer: {
    add_comment: 'Add comment...',
    alert_detail: 'Alert Details',
    alert_event_count: 'Alert event count',
    alert_history: 'Alert History',
    alert_id: 'Alert ID',
    alert_source: 'Alert source',
    end: 'End',
    participants: 'Participants',
    resolved_in: 'Resolved in',
    resolved_info: 'RESOLVED INFO',
    send: 'Send',
    start: 'Start',
    status: 'Status',
    view_newest: 'View Newest Messages',
  },
  alerthistory: {
    ago: 'ago',
    arrived_close_error: 'There was a problem setting the status of the alert to "Arrived".',
    arrived_close_warning:
      'Please note: you will be associated with the remote marking of this active alert with status "Arrived".',
    certified_by: 'Certified by',
    close_error: 'There was a problem closing the alert.',
    close_warning: 'Warning: you will be associated with the remote closing of this active alert.',
    describe_incident: 'Describe incident',
    end_loc: 'Ending location',
    enter_reason: 'Enter your reason here',
    false_alarm: 'False alarm',
    guest_incident: 'Guest incident',
    help_has_arrived: 'Help has arrived',
    in_process: 'in process',
    just_testing: 'Just testing',
    resolution_reason: 'Reason for resolution',
    resolve_this_alert: 'Resolve this alert',
    staff_incident: 'Staff incident',
    start_loc: 'Starting location',
    network_error: 'Could not close alert: Network error.',
  },
  alertview: {
    csv_end_date: 'End date',
    csv_first_loc: 'First location',
    csv_initiator_name: 'Initiator name',
    csv_last_loc: 'Last location',
    csv_org_id: 'org Id',
    csv_property_name: 'Property name',
    csv_resolver_name: 'Resolver name',
    csv_start_date: 'Start date',
    date: 'Date',
    end_loc: 'End Location',
    start_loc: 'Start Location',
    time: 'Time',
  },
  auth: {
    check_email: 'Check your email for details on creating a login password.',
    choose_provider: 'Choose your provider',
    confirm_password: 'Confirm Password',
    contact_org_admin: 'Please contact your organization admin',
    create_account_password: 'Create a password for your account.',
    create_password: 'Create password',
    email_resent: 'An email has been sent',
    enter_email: 'Enter your email below to sign in.',
    enter_password: 'Enter your password below to sign in.',
    first_name: 'First Name',
    forgot_password: 'Forgot password?',
    invalid_alert_source: 'Invalid alert source. Please login again',
    invalid_login: 'Invalid login',
    invalid_super_admin: 'You are not a super admin, please contact support.',
    invalid_user: 'User invalid. Please login again',
    last_name: 'Last Name',
    login_again: 'For security reasons, please login again.',
    login_title: 'Welcome back!',
    login_unsuccessful: 'Login unsuccessful',
    no_grants: 'No permissions in this org.',
    org_access_denied: 'You are not allowed to access that organization, please contact support',
    password_error:
      'Password should contain at least 12 characters, one uppercase letter, one lowercase letter, and one number or special character: @$!%*?&',
    password_validation_length: 'Must be at least 12 characters',
    password_validation_match: 'Passwords must match',
    password_validation_number: 'Contains at least 1 number',
    password_validation_number_symbol: 'Contains at least 1 number or symbol and no spaces',
    password_validation_special_char: 'Contains at least 1 symbol',
    password_validation_upper_lower: 'Contains both lower and uppercase letters',
    registration_sent: 'Registration sent, please check your email for the next step.',
    resend_email: 'Resend email',
    reset_password: 'Reset password',
    reset_password_title: 'Update account details',
    reset_provider: 'Reset SSO provider',
    reset_provider_success: 'SSO provider has been reset',
    reset_sent: 'An email was sent to help you reset your password',
    reset_your_password: 'Reset your password.',
    sign_in: 'Sign in',
    sign_in_with: 'Sign in with',
    toggle_password: 'toggle password visibility',
    token_expired: 'Token expired. Please login again',
    unauthenticated: 'Your user does not have access permissions, please contact your organization admin.',
    unauthorized: "You don't have permissions to access the customer portal",
    unexpected_error: 'There was an unexpected error.',
    update_successful: 'Update successful. Please sign in.',
    update_unsuccessful: "That didn't work",
  },
  beacondrawer: {
    beacon_details: 'Beacon Details',
    beacon_id: 'Beacon ID',
    beacon_updated: 'Beacon updated',
    confirm_disable: 'Confirm disabling Beacon?',
    confirm_unregister: 'Are you sure you want to unregister the selected Beacon?',
    edit_beacon: 'Edit Beacon',
    edit_beacon_details: 'Edit Beacon Details',
    edit_beacon_name: 'Edit Beacon Name',
    enter_beacon_floor: 'Enter beacon floor',
    enter_beacon_room: 'Enter beacon room',
    manufacturer: 'Manufacturer',
    please_confirm: 'Please confirm',
    remove_beacon_from_loc: 'Remove Beacon from Location',
    remove_loc_from_property: 'Remove Location from Property',
    unregister_beacon: 'Unregister Beacon',
  },
  beaconstableview: {
    all_beacons: 'All Beacons',
    beacon_stable: 'Beacons Table',
  },
  common: {
    active_in_progress: 'Active (in progress)',
    active_no_response: 'Active (no response)',
    add: 'Add',
    admin: 'Admin',
    alert: 'Alert',
    alerts: 'Alerts',
    all: 'All',
    all_alerts: 'All Alerts',
    all_properties: 'All Properties',
    and: 'and',
    apply: 'Apply',
    apply_changes: 'Apply Changes',
    back: 'back',
    battery: 'Battery',
    beacon: 'Beacon',
    beacons: 'Beacons',
    button_paired: 'Button Paired',
    cancel: 'Cancel',
    canceled: 'canceled',
    close: 'Close',
    confirm: 'Confirm',
    create: 'Create',
    delete: 'delete',
    details: 'Details',
    device: 'Device',
    devices: 'Devices',
    download_csv: 'Download CSV',
    edit: 'Edit',
    email: 'Email',
    email_alerts_enabled: 'Email alerts enabled',
    email_alerts_not_enabled: 'Email alerts not enabled',
    email_alerts_pending: 'Verification required for email alerts',
    email_error: 'Please enter a valid email.',
    enter: 'Enter',
    false: 'false',
    filter: 'Filter',
    firmware: 'Firmware',
    first_page: 'First page',
    floor: 'Floor',
    group: 'Group',
    groups: 'Groups',
    hardware_type: 'Hardware Type',
    health: 'Health',
    healthy: 'Healthy',
    here: 'here',
    id: 'id',
    in_progress: 'in progress',
    key_green: 'healthy',
    key_red: 'critical',
    key_yellow: 'moderate',
    last_check_in: 'Last check in',
    last_days: 'Last 7 days',
    last_page: 'Last page',
    last_seen: 'Last Seen',
    loading: 'Loading...',
    location: 'Location',
    mark_arrived: 'Mark Arrived',
    membership: 'Membership',
    message_green: 'healthy',
    moderate: 'Moderate',
    na: 'N/A',
    name: 'Name',
    next_page: 'Next page',
    no: 'no',
    no_records_returned: 'No records returned',
    no_response: 'No Response',
    of: 'of',
    offline: 'offline',
    ok: 'OK',
    ongoing: 'ongoing',
    open: 'open',
    or: 'or',
    organization: 'organization',
    organizations: 'organizations',
    page: 'Page',
    password: 'password',
    pending: 'pending',
    phone: 'Phone',
    previous_page: 'Previous page',
    properties: 'Properties',
    property: 'Property',
    property_id: 'Property ID',
    push_alerts_disabled: 'Push alerts not enabled',
    push_alerts_enabled: 'Push alerts enabled',
    register: 'Register',
    register_qr: 'Register Device with QR Code for:',
    registered: 'registered',
    registration_status: 'Registration Status',
    remove: 'Remove',
    request_failed: 'Request did not succeed.',
    resolution: 'Resolution',
    resolve_alert: 'Resolve Alert',
    resolved: 'Resolved',
    resolved_by: 'Resolved by',
    responder: 'Responder',
    responder_alerts_enabled: 'alerts are enabled',
    responder_alerts_not_enabled: 'alerts are not enabled',
    responder_devices: 'Responder Devices',
    responder_offline: 'is offline',
    responder_online: 'is online',
    responder_s: 'Responder(s):',
    responders: 'Responders',
    roles: 'Roles',
    room: 'Room',
    rssi: 'RSSI',
    search: 'Search',
    sms_alerts_enabled: 'SMS alerts enabled',
    sms_alerts_not_enabled: 'SMS alerts not enabled',
    sms_alerts_pending: 'Verification required for SMS alerts',
    sub_type: 'Subtype',
    submit: 'Submit',
    success: 'Success',
    super_admin: 'Super Admin',
    total: 'Total',
    true: 'true',
    type: 'Type',
    unavailable: 'Unavailable',
    unhandled_sort_column: 'Unhandled sort column',
    unknown: 'Unknown',
    user: 'User',
    users: 'Users',
    view: 'View',
    was_not_found: 'was not found',
    yes: 'Yes',
  },
  dashboard: {
    best_health: 'Best health',
    sort_options: '["Alphabetical","Address","Health (ascending)","Health (descending)"]',
  },
  devicesdrawer: {
    button_battery: 'Button Battery',
    button_firmware: 'Button Firmware',
    button_id: 'Button ID',
    carrier: 'Carrier',
    confirm_delete: 'Are you sure you want to delete the selected Device?',
    confirm_unregister: 'Are you sure you want to unregister the selected Device?',
    delete_device: 'Delete Device',
    device_id: 'Device ID',
    device_type: 'Device type',
    edit_device: 'Edit Device',
    edit_device_name: 'Edit Device Name',
    iccid: 'ICCID',
    imei: 'IMEI',
    ip_address: 'IP address',
    push_enabled: 'Push Enabled',
    temperature: 'Temperature',
    unregister_device: 'Unregister device',
    uptime: 'Uptime',
    wireless_band: 'Wireless band',
    wireless_mode: 'Wireless mode',
  },
  devicestableview: {
    all_devices: 'All buttons & devices',
    csv_devices: 'Devices',
    csv_table: 'Table',
    integration_id: 'Integration id',
    register_select_org: 'You have all properties selected. Please select a property to register a device.',
  },
  errors: {
    grants_delete_failed: 'There was a problem removing grants.',
    grants_update_failed: 'There was a problem updating grants.',
  },
  header: {
    admin_tooltip: 'Administer Properties, Groups and Users',
    header_slogan: 'Protect your people',
    logout: 'Logout',
    notifications: 'Notifications',
    open_notifications: 'Open Notifications',
    open_user_options: 'Open user options',
    settings: 'Settings',
    settings_tooltip: 'List of tools and controls',
    user_avatar: 'User Avatar',
  },
  healthlabels: {
    critical: 'Critical',
    low_battery: 'Low-battery',
    no_response_critical: 'Critical - No response',
    offline: 'Critical',
  },
  healthtooltips: {
    alerts_active_in_progress: 'Alert is open but has responders working at the moment',
    alerts_active_no_response: 'Alert is open and has no responders',
    alerts_resolved: 'Alert is resolved',
    beacons_critical: 'Beacon needs urgent battery charge or has been offline for at least a week',
    beacons_healthy: 'Beacon is healthy',
    beacons_moderate: 'Beacon either needs an update or is close to needing a battery charge',
    devices_healthy: 'Device is healthy',
    devices_moderate: 'Device needs battery charge and/or firmware update',
    devices_offline: 'Device is offline',
  },
  healthtypes: {
    critical: 'critical',
    low_battery: 'low-battery',
    no_response_critical: 'no response - critical',
  },
  healthwarnings: {
    health_critical_alert_targets: 'is not configured to receive alerts',
    health_critical_battery: 'needs urgent battery charge',
    health_critical_offline: 'is offline',
    health_critical_unknown: 'status is unknown',
    health_moderate_battery: 'needs battery charge',
    health_moderate_firmware: 'needs a firmware update',
    health_moderate_offline: 'has not checked in in the past two hours',
  },
  humangrantsname: {
    associate: 'Associate',
    dispatcher: 'Dispatcher',
    installer: 'Installer',
    portal_user: 'Portal User',
  },
  landing: {
    management_console: 'Management Console',
    slogan: 'Protect your most important asset, your people.',
  },
  mainsidebar: {
    alerts_tooltip: 'List of current and past alerts',
    beacons_tooltip: 'List of installed beacons and their status',
    dashboard: 'Dashboard',
    dashboard_tooltip: 'Overview of your properties',
    devices: 'Devices',
    devices_tooltip: 'List of devices',
    live_responders_tooltip: 'Responders Online | Responder Devices',
    responders_tooltip: 'List of responder users and devices',
    users_tooltip: 'List of users and their info',
  },
  mobileapp: {
    apple_app_store: 'ReactMobile on Apple App Store',
    google_play_store: 'ReactMobile on Google Play Store',
  },
  modalqr: {
    device_alert_source: 'Device as Alert Source',
    expires: 'QR Code expires',
  },
  notifications: {
    alert_resolved: 'Alert resolved',
    new_alert_started: 'New alert started',
  },
  orgglobalcard: {
    alerts_message_red: 'active - no response',
    alerts_message_yellow: 'active',
    beacons_message_red: 'low battery & offline',
    beacons_message_yellow: 'need update',
    days: 'days',
    devices_devices: 'Devices',
    devices_message_yellow: 'need charge or update',
    min: '1 min',
    mins: 'mins',
    nodata: 'No data yet',
    orgavatar: 'Organization Avatar',
    responders_message_green: 'online & ready',
    responders_message_red: 'offline or off duty',
    response_time: 'Response Time',
    test_interval: 'Test Interval',
    view_details: 'View Details',
  },
  orgs: {
    add_property_to: 'Add Property to',
    confirm_delete: 'Are you sure you want to delete',
    confirm_type_delete: 'Confirm deletion by typing DELETE',
    duplicate: 'Duplicate',
    image_error: 'There was a problem updating the image link.',
    select_group: 'Select group',
    show_more_options: 'Show More Options',
  },
  respondertableview: {
    no_online_responders: 'No online responders',
    responders_online: 'Responders Online',
  },
  settings: {
    activate: 'Activate',
    additional_options: 'Additional Options',
    all_options: 'All Options',
    apikey: 'API Key',
    apiurl: 'API URL',
    auto_update: 'Auto-update to latest stable build',
    beacon_type: 'Beacon Type',
    beacons_bulk_installation: 'Beacons bulk installation',
    beacons_create_fail: 'There was a problem creating the beacons.',
    beacons_created: 'Beacons created.',
    browse_file: 'BROWSE FILE',
    confirm_delete_integration: 'Are you sure you want to delete this integration?',
    confirm_update: 'Are you sure you want to make this update?',
    csv_file_format: 'Files should have the following format:',
    csv_parse_error: 'There was a problem parsing the file. Please make sure is a valid csv',
    delete_integration: 'Delete Integration',
    demo_mode: 'Demo Mode',
    determine_update: 'Determine how hardware is updated over-the-air.',
    device_password: 'Device Password',
    disabled: 'Disabled',
    do_not_update: 'Do not update',
    enabled: 'Enabled',
    enter_apikey: 'Enter API Key',
    enter_device_password: 'Enter device password',
    enter_hotel_code: 'Enter hotel code',
    enter_password: 'Enter Password',
    enter_property_code: 'Enter property code',
    enter_url: 'Enter URL',
    enter_username: 'Enter Username',
    ex_bathroom_hallway: 'ex: Bathroom Hallway',
    ex1: 'ex: Bathroom Hallway',
    ex10: 'ex: af4b3211-491c-4ae3-bbb1-3c8b2ab4f7dc (estimote)',
    ex11: 'ex: 72e35d00-f8fe-4bec-8027-fcc1c86b0806:1000:514 (iBeacon)',
    ex12: ' iBeacon,Bathroom Hallway,3,312,72e35d00-f8fe-4bec-8027-fcc1c86b0806:1000:514,',
    ex13: ' Estimote,Minibar,P14,1421,af4b3211-491c-4ae3-bbb1-3c8b2ab4f7dc,',
    ex14: ' React2,Linen Closet,Regence 12,12 linen closet,35:90:66:33:71:f3,',
    ex2: 'ex: Minibar',
    ex3: 'ex: 3',
    ex4: 'ex: P14',
    ex5: 'ex: Regence 12',
    ex6: 'ex: 2147',
    ex7: 'ex: 21 linen closet',
    ex8: 'ex: (empty)',
    ex9: 'ex: 35:90:66:33:71:f3 (carfit, react1, react2)',
    example_file: 'Example file:',
    external_integration: 'External Integration',
    firmware_settings: 'Firmware Settings',
    firmware_settings_change: 'Firmware Settings Change',
    firmware_settings_updated: 'Firmware settings updated successfully',
    hotel_code: 'Hotel Code',
    integration: 'Integration',
    leave_empty: 'leave empty',
    may_be_empty: 'may be empty',
    more_details: 'More details',
    new: 'New',
    no_existing: 'No existing version',
    not_provisioned: 'Not Provisioned',
    old: 'Old',
    property_code: 'Property Code',
    provisioned: 'Provisioned',
    register: 'Register hardware to this organization before setting firmware options.',
    remove_integration: 'Remove integration',
    required: 'required',
    required_values: 'required and must be one of the following values:',
    select_org_beacons: 'Please select an organization before uploading beacons:',
    select_org_firmware: 'Please select an organization before updating firmware settings.',
    select_org_integrations: 'Please select an organization before updating external integrations.',
    settings_to_apply: 'SETTINGS TO APPLY',
    target_version: 'Target Version',
    there_was_a_problem: 'There was a problem updating the settings info.',
    upload_csv: 'Upload a CSV file in order to do a bulk installation of beacons.',
    username: 'Username',
    version_code: 'version Code:  empty',
  },
  tableview: {
    sort: 'Sort',
  },
  usertableview: {
    add_user: 'ADD USER',
    all_properties_selected: 'You have all properties selected. Please select a property to add a user.',
    all_users: 'All Users',
    assign_roles: 'Assign Roles',
    confirm_remove_user: 'Are you sure you want to remove User',
    contact: 'Contact',
    enable_app_login: 'Enable App Login',
    enable_sso: 'Enable Single Sign-On (SSO) with the React Mobile app',
    enter_email: 'Enter email here',
    enter_valid_email: 'Please enter a valid email',
    group_id: 'Group ID',
    invalid_phone: 'Please enter a valid phone number',
    login_provider: 'Login Provider',
    no_checkin: 'User has never checked in',
    no_login: 'user has not logged in yet',
    no_membership: 'No Membership',
    online_now: 'Online now',
    provider: 'Provider',
    remove_user: 'REMOVE USER',
    remove_user_from: 'Remove User From',
    subscribe_email: 'Invite to receive email alerts',
    subscribe_sms: 'Invite to receive sms alerts',
    subscription_accepted: 'User is subscribed to alerts',
    subscription_pending: 'Invitation sent. Awaiting user response',
    subscription_refused: 'User has unsubscribed from alerts',
    unsubscribe_email: 'Unsubscribe from email alerts',
    unsubscribe_sms: 'Unsubscribe from sms alerts',
    user_added: 'User added',
    user_ceated: 'User created',
    user_id: 'User ID',
  },
}
