import { BRAND_COLOR, DISABLED_COLOR, GRANTS } from 'lib/constants'
import { GrantCheckedMap } from 'lib/types'

export function getCheckboxColor(grants: GrantCheckedMap, grantName: string) {
  return isDisabled(grants, grantName) ? DISABLED_COLOR : BRAND_COLOR
}

export function getCheckedStatus(grants: GrantCheckedMap, grantName: string) {
  if (isDisabled(grants, grantName)) return true

  if (grantName == GRANTS.DISPATCHER && grants[GRANTS.PORTAL_USER] && grants[GRANTS.RESPONDER]) {
    return true
  }

  return !!grants[grantName]
}

export function isDisabled(grants: GrantCheckedMap, grantName: string) {
  switch (grantName) {
    case GRANTS.PORTAL_USER:
    case GRANTS.RESPONDER:
      return adminOrDispatcherSelected(grants)
    default:
      return !!(grants[GRANTS.ADMIN] && grantName !== GRANTS.ADMIN)
  }
}

function adminOrDispatcherSelected(grants: GrantCheckedMap) {
  return !!(grants[GRANTS.ADMIN] || (grants[GRANTS.PORTAL_USER] && grants[GRANTS.RESPONDER]))
}
