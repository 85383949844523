import Papa from 'papaparse'

export function generateCSV(data: any[], fields: string[], name: string) {
    const csv = Papa.unparse({
        data,
        fields,
    })
    const blob = new Blob([csv], { type: 'text/csv' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}