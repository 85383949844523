/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import { getAppColors } from 'lib/constants'
import { availableLanguages } from 'i18n'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { position } from 'styles'

const formControlStyles = {
  margin: '0 10px 0 10px',
  alignSelf: 'center',
  background: getAppColors('--color-gray-3'),
  borderRadius: '15px',
  color: getAppColors('--color-secondary'),
  fontFamily: 'Montserrat',
  '&:first-of-type': {
    marginLeft: 0,
    marginRight: '15px',
  },
}

const prefixStyles = {
  label: 'prefix',
  ...position('relative'),
  top: '17px',
  marginLeft: '10px',
  height: '2px',
}

const selectStyles = {
  label: 'select',
  fontFamily: 'Montserrat',
  fontSize: '1.2rem',
  'div[id="dropdown-widget"]': {
    padding: '8px 30px 8px 8px',
    color: getAppColors('--color-secondary'),
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
}

export const CountrySelect = () => {
  const { i18n } = useTranslation()
  const [selectedCountry, setSelectedCountry] = useState<string>(i18n.language || i18n.languages[0])
  const handleChange = (e: any) => {
    setSelectedCountry(e.target.value)
    i18n.changeLanguage(e.target.value)
  }
  const opts = availableLanguages.map(lang => {
    return { id: lang, name: lang.slice(0, 2) }
  })
  return (
    <FormControl css={formControlStyles}>
      <span css={prefixStyles}> </span>
      <Select
        id="dropdown-widget"
        value={selectedCountry}
        onChange={handleChange}
        IconComponent={KeyboardArrowDownIcon}
        disableUnderline
        autoWidth={false}
        css={selectStyles}
      >
        {opts.map(lang => {
          return (
            <MenuItem value={lang.id} key={lang.id}>
              {lang.name.toUpperCase()}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
