import { ButtonProps, FirmwareVersionProps, HealthCode, TimeStamps, UpdateFirmwareProps } from '.'

interface LegacyProps {
  accessToken: string
  client: string
  uid: string
}

export interface DeviceProps {
  button?: ButtonProps
  carrier: string
  currentUptime: string | number
  hardwareType: string
  iccid: string
  ipAddress: string
  legacy?: LegacyProps
  rssi: string | number
  temperature: string | number
  version?: FirmwareVersionProps
  wirelessBand: string
  wirelessMode: string
}

export interface Device extends TimeStamps {
  batteryPercent: number
  desired_firmware_version?: FirmwareVersionProps
  deviceType: DeviceType
  diagnostic: string
  disabled: boolean
  health?: HealthCode
  healthWarnings?: string[]
  id: string
  name: string
  orgId: string
  props: DeviceProps
  pushEnabled: boolean
  registration: string
  responder: boolean
  token: string
  updateFirmware?: UpdateFirmwareProps
  versionCode: number
  lastFetched?: number
}

export enum DeviceType {
  Android = 'android',
  IOS = 'ios',
  Portal = 'portal',
  WAN = 'wan_button',
}
export interface QRCodeData {
  token: string
  orgId: string
  responder: boolean
}

export enum RegisterStatus {
  PENDING = 'pending',
  REGISTERED = 'registered',
  CANCELED = 'canceled',
}
