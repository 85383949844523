import React from 'react'

// export type SortOrder = 'ascending' | 'descending' | null
export enum SortOrder {
  Ascending,
  Descending,
}

/** Specify sort order of a field of type S */
export interface SortBy<S> {
  field: S
  order: SortOrder
}

/** Sort option for a field of type S */
export interface SortOption<S> {
  sortBy: SortBy<S>
  display: string
}

/** Table state manipulated by a filter of type F and a sort of a field of type S */
export interface TableState<F, S> {
  filterBy: F
  sortBy: SortBy<S>
  searchBy: string
}

export type onTableStateChange = <F, S>(state: TableState<F, S>) => void

/** Render definition for a header of type H and render data of type C */
export interface TableColumnConfig<H, C extends unknown> {
  header: H
  renderFn: (cell: C) => React.ReactNode
  label?: string
  sortable?: boolean
  requiresAdmin?: boolean
  requiresOrg?: boolean
}

/** Table tool event with filter of type F and sort by field of type S */
export interface TableToolEvent<F, S> {
  filterByRequest?: F
  sortByRequest?: SortBy<S>
  searchByRequest?: string
}

export type onToolEventCallback = <F, S>(toolEvent: TableToolEvent<F, S>) => void
