/** @jsxImportSource @emotion/react */
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled/macro'
import { Button } from '@material-ui/core'
import { SignInHeader, SignInSubtitle } from 'components/partials'
import { pusher } from 'models/api'
import { authButtonWrapperStyles, authWrapperStyles, authButtonStyles } from 'styles'
import { trans, TranslationGroup, TranslationKey } from 'lib/types'

function NotAuthorizedPage() {
  const translation: TranslationGroup = trans.merge(TranslationKey.AUTH)
  const history = useHistory()
  const logout = () => {
    localStorage.clear()
    pusher?.disconnect()
    history.push('/')
  }
  return (
    <div css={authWrapperStyles}>
      <SignInHeader>{translation.unauthorized}</SignInHeader>
      <SignInSubtitle>{translation.contact_org_admin}</SignInSubtitle>
      <div css={authButtonWrapperStyles}>
        <Button variant={`contained`} style={authButtonStyles} onClick={logout}>
          {translation.back}
        </Button>
      </div>
    </div>
  )
}

export default NotAuthorizedPage
