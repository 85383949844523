/** @jsxImportSource @emotion/react */
import React from 'react'

/** Returns React children if the condition is truthy. Returns null otherwise. */
export const ConditionalRender = ({
  condition = true,
  children,
}: {
  condition?: any
  children: React.ReactElement[] | React.ReactElement
}) => <>{!!condition && children}</>
