import { getRequest, putRequest, postRequest, deleteRequest } from './rest'
import { Organization } from 'lib/types'
import { API_ORGANIZATIONS } from 'lib/constants'
import { getChildOrgIdsByParentId } from './childOrgs'
import { decodeJsonApiObject } from './ApiLib'

export const getOrganization = (id: string): Promise<Organization> =>
  getRequest({ endpoint: `${API_ORGANIZATIONS}/${id}?summary=true` }).then(({ data }) => decodeJsonApiObject(data?.data || []))

export const getAllOrganizationsRaw = (): Promise<any[]> =>
  getRequest({ endpoint: `${API_ORGANIZATIONS}?summary=true` }).then(({ data }) => data?.data || [])

export const getAllOrganizations = (): Promise<Organization[]> =>
  getAllOrganizationsRaw().then(rawOrgs => rawOrgs.map(a => decodeJsonApiObject<Organization>(a)))

export const createOrUpdateOrg = (orgWithId: Organization): Promise<Organization> => {
  const { id, ...org } = orgWithId
  return (
    id
      ? putRequest({
          endpoint: `${API_ORGANIZATIONS}/${id}`,
          data: { org },
        })
      : postRequest({
          endpoint: `${API_ORGANIZATIONS}`,
          data: { org },
        })
  ).then(({ data }) => decodeJsonApiObject<Organization>(data?.data || []))
}

export const getChildOrgsByParentId = (parentId: string): Promise<Organization[]> =>
  getChildOrgIdsByParentId(parentId).then(childIds => Promise.all(childIds.map(getOrganization)))

export const deleteOrg = (orgId: string) =>
  deleteRequest({
    endpoint: `${API_ORGANIZATIONS}/${orgId}`,
  })
