/** @jsxImportSource @emotion/react */
import { Tab, Tabs } from '@material-ui/core'
import { AdminAnywhereRequired } from 'components/partials'
import { ConditionalRender, PageWrapper, StyledHeader, ViewSpacerWrapper, ViewWrapper } from 'components/widgets'
import { Orgs, NavState } from 'models'
import { useEffect, useState } from 'react'
import { AdminOrgsTable } from './Partials'
import { EditOrgView } from './EditOrgView'
import { ManageFirmwareVersions } from '../GeneralSettingsPage/ManageFirmwareVersions'
import { Link, useParams } from 'react-router-dom'
import { PATH_ADMIN } from 'lib/constants'
import { trans, TranslationGroup } from 'lib/types'
import { RenderCounter } from 'components/widgets/RenderCounter'
import { isSuperAdmin } from 'lib/utils/auth'

const tabCss = { fontFamily: 'Montserrat' }

interface AdminParams {
  section: string
}

export const AdminView = () => {
  const common: TranslationGroup = trans.common()
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  // const [groupsMode, setGroupsMode] = useState(false)
  const orgsById = Orgs.use(({ orgsById }) => orgsById)
  const selectedAdminOrgId = NavState.use(({ selectedAdminOrgId }) => selectedAdminOrgId)

  const selectedOrg = orgsById[selectedAdminOrgId || '']
  const { section } = useParams<AdminParams>()

  useEffect(() => {
    switch (section) {
      case 'groups':
        return setSelectedTabIndex(1)
      case 'firmware':
        return setSelectedTabIndex(2)
      default:
        return setSelectedTabIndex(0)
    }
  }, [section])

  return (
    <AdminAnywhereRequired>
      <PageWrapper>
        <ViewWrapper>
          <ViewSpacerWrapper>
            <RenderCounter name={'AdminView'} />
            <ConditionalRender condition={!selectedOrg}>
              <StyledHeader>Admin</StyledHeader>
              <div>
                <Tabs value={selectedTabIndex} onChange={(e, newValue) => setSelectedTabIndex(newValue)}>
                  <Tab label={common.properties} css={tabCss} component={Link} to={PATH_ADMIN.PROPERTIES} />
                  <Tab label={common.groups} css={tabCss} component={Link} to={PATH_ADMIN.GROUPS} />
                  <>
                    {isSuperAdmin() ? (
                      <Tab label={common.firmware} css={tabCss} component={Link} to={PATH_ADMIN.FIRMWARE} />
                    ) : (
                      <></>
                    )}
                  </>
                </Tabs>
              </div>
              {selectedTabIndex < 2 ? (
                <AdminOrgsTable groupsMode={selectedTabIndex === 1} />
              ) : (
                <ManageFirmwareVersions />
              )}
            </ConditionalRender>
            {selectedOrg && <EditOrgView org={selectedOrg} />}
          </ViewSpacerWrapper>
        </ViewWrapper>
      </PageWrapper>
    </AdminAnywhereRequired>
  )
}
