/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Icon } from '@material-ui/core'
import GoogleLogo from 'static/images/auth/Google_Logo.svg'
import GithubLogo from 'static/images/auth/Github_Logo.svg'
import MicrosoftLogo from 'static/images/auth/Microsoft_Logo.svg'
import AppleLogo from 'static/images/auth/Apple_Logo.svg'
import { PopUpNotifications } from 'models'
import { resendAuthEmail } from 'models/api'
import { LoginForm, ResetPasswordForm, SignInHeader, SignInSubtitle } from 'components/partials'
import {
  AUTH_OAUTH,
  AuthProvider,
  AuthTokenParam,
  DASHBOARD_MAIN_PATH,
  EMAIL_PARAM,
  JWT_TOKEN,
  LOGIN_MESSAGES,
  REDIRECT_URL_PARAM,
  RELOGIN_PARAM,
  UNAUTHENTICATED_PARAM,
} from 'lib/constants'
import {
  authButtonStyles,
  authCheckEmailStyles,
  authDividerTextStyles,
  authWrapperStyles,
  oauthButtonStyles,
  oauthButtonLabelStyles,
  authButtonWrapperStyles,
  textTransform,
} from 'styles'
import { getUserByEmail } from 'models/api'
import { ConditionalRender } from 'components/widgets'
import { trans, TranslationGroup, TranslationKey } from 'lib/types'
import { LensTwoTone } from '@material-ui/icons'

export function getIcon(iconUrl: string, alt: string) {
  return (
    <Icon>
      <img alt={alt} src={iconUrl} />
    </Icon>
  )
}

function getWindowLocationSearch() {
  // Function to check if the standard portal url is wrapped by a security app like urlisolation.com. If so, unwrap
  const host = window.location.host
  let search = ''
  switch (host) {
    case 'urlisolation.com': {
      // urlisolation has a 'url' param in the query string that contains what we want.
      let urlIsoParams = new URLSearchParams(window.location.search)
      search = urlIsoParams.get('url')?.split('?')[1] || '' // splits off just the inner params within the url param. Necessary for the consumeers of this func to use the URLSearchParams object.
      break
    }
    default:
      search = window.location.search
  }
  return search
}

function getEmailAddress() {
  const search = getWindowLocationSearch()

  const params = new URLSearchParams(search)
  return params.get(EMAIL_PARAM) || ''
}

function getReloginMessage(code: string | null) {
  if (!code) return ''
  const translation: TranslationGroup = trans.group(TranslationKey.AUTH)
  return translation[LOGIN_MESSAGES[code]] || ''
}

function getReloginCode() {
  const search = getWindowLocationSearch()

  const params = new URLSearchParams(search)
  return params.get(RELOGIN_PARAM)
}

function getResetToken() {
  const search = getWindowLocationSearch()
  const params = new URLSearchParams(search)
  return params.get(AuthTokenParam.RESET_TOKEN) || undefined
}

function getConfirmToken() {
  const search = getWindowLocationSearch()
  const params = new URLSearchParams(search)
  return params.get(AuthTokenParam.CONFIRM_TOKEN) || undefined
}

export default function LoginPage() {
  const translation: TranslationGroup = trans.merge(TranslationKey.AUTH)
  const history = useHistory()
  const [email, setEmail] = useState(getEmailAddress())
  const REDIRECT_URL = `${window.location.protocol}//${window.location.host}`
  const reloginMessage = getReloginMessage(getReloginCode())
  const resetToken = getResetToken()
  const confirmToken = getConfirmToken()
  const hasToken = !!resetToken || !!confirmToken
  const [provider, setProvider] = useState<AuthProvider | null>(AuthProvider.UNKNOWN)
  const [resendDisabled, setResendDisabled] = useState(false)
  const [updateAccount, setUpdateAccount] = useState(false)
  const [step, setStep] = useState(1)

  useEffect(() => {
    const queryString = window.location.search
    if (!!queryString) {
      const urlParams = new URLSearchParams(queryString)
      const rm_token = urlParams.get(JWT_TOKEN) || ''
      !!rm_token && localStorage.setItem(JWT_TOKEN, rm_token)
    }

    if (localStorage.getItem(JWT_TOKEN)) history.push(DASHBOARD_MAIN_PATH)

    if (queryString.includes(UNAUTHENTICATED_PARAM)) {
      PopUpNotifications.fireError({
        content: translation[LOGIN_MESSAGES[UNAUTHENTICATED_PARAM]],
      })
    }

    if (reloginMessage) {
      PopUpNotifications.fireError({ content: reloginMessage })
    }

    // eslint-disable-next-line
  }, [reloginMessage])

  useEffect(() => {
    if (!email) {
      return setStep(1)
    }
    if (!updateAccount && provider === null) {
      return setStep(2)
    }
    if (updateAccount && hasToken) return setStep(3)
    // eslint-disable-next-line
  }, [email, hasToken, provider, updateAccount])

  function handleEmailLookup(email: string) {
    setEmail(email)
    getUserByEmail(email).then(res => {
      const provider = res.provider || null
      switch (provider) {
        case AuthProvider.GITHUB:
        case AuthProvider.GOOGLE:
        case AuthProvider.MICROSOFT:
          setProvider(provider)
          return handleOauthLoginButton(provider)
        case AuthProvider.IDENTITY:
          if (resetToken) setUpdateAccount(true)
          setProvider(provider)
          break
        default:
          setProvider(provider)
      }
    })
  }

  function onResetComplete() {
    setEmail('')
    setStep(1)
    setProvider(AuthProvider.UNKNOWN)
    setUpdateAccount(false)
    history.replace('/')
  }

  function handleForgotPassword() {
    setProvider(AuthProvider.UNKNOWN)
    handleResendEmail(true)
  }

  function handleResendConfirm() {
    setProvider(AuthProvider.UNKNOWN)
    handleResendEmail(false)
  }

  function handleOauthLoginButton(provider: AuthProvider) {
    window.location.assign(`${AUTH_OAUTH}${provider}?${REDIRECT_URL_PARAM}=${REDIRECT_URL}`)
  }

  function handleResendEmail(forgotPassword?: boolean) {
    resendAuthEmail(email, REDIRECT_URL, forgotPassword).then(message => {
      if (typeof message === 'number' && message > 399 && message < 500) {
        PopUpNotifications.fireError({ content: translation.unauthorized })
        setResendDisabled(true)
      } else if (typeof message !== 'number') {
        PopUpNotifications.fireInfo({ content: message })
        setResendDisabled(true)
      } else {
        PopUpNotifications.fireInfo({ content: translation.unexpected_error })
      }
    })
  }
  const pageTitle = step === 3 ? translation.reset_password_title : translation.login_title
  return (
    <div css={authWrapperStyles}>
      <SignInHeader>{pageTitle}</SignInHeader>
      <ConditionalRender condition={step !== 3}>
        <SignInSubtitle provider={provider} />
      </ConditionalRender>
      <div css={authButtonWrapperStyles}>
        <ConditionalRender condition={step === 1}>
          <LoginForm
            sentEmail={email}
            lookupCallback={handleEmailLookup}
            handleForgotPassword={handleForgotPassword}
            provider={provider}
          />
        </ConditionalRender>
        <ConditionalRender condition={step === 2}>
          <ConditionalRender condition={provider === AuthProvider.GOOGLE || provider === null}>
            <Button
              data-testid={`signInGoogleButton`}
              variant={`contained`}
              style={oauthButtonStyles}
              startIcon={getIcon(GoogleLogo, `${translation.SIGN_IN_WITH} Google`)}
              onClick={() => handleOauthLoginButton(AuthProvider.GOOGLE)}
            >
              <span
                css={oauthButtonLabelStyles}
                data-testid={`signInGoogle`}
              >{`${translation.SIGN_IN_WITH} Google`}</span>
            </Button>
          </ConditionalRender>
          <ConditionalRender condition={provider === AuthProvider.GITHUB || provider === null}>
            <Button
              data-testid={`signInGithubButton`}
              variant={`contained`}
              style={oauthButtonStyles}
              startIcon={getIcon(GithubLogo, `${translation.SIGN_IN_WITH} Github`)}
              onClick={() => handleOauthLoginButton(AuthProvider.GITHUB)}
            >
              <span
                css={oauthButtonLabelStyles}
                data-testid={`signInGithub`}
              >{`${translation.SIGN_IN_WITH} Github`}</span>
            </Button>
          </ConditionalRender>
          <ConditionalRender condition={provider === AuthProvider.MICROSOFT || provider === null}>
            <Button
              data-testid={`signInMicrosoftButton`}
              variant={`contained`}
              style={oauthButtonStyles}
              startIcon={getIcon(MicrosoftLogo, `${translation.SIGN_IN_WITH} Microsoft`)}
              onClick={() => handleOauthLoginButton(AuthProvider.MICROSOFT)}
            >
              <span
                css={oauthButtonLabelStyles}
                data-testid={`signInMicrosoft`}
              >{`${translation.SIGN_IN_WITH} Microsoft`}</span>
            </Button>
          </ConditionalRender>
          <ConditionalRender condition={provider === AuthProvider.APPLE || provider === null}>
            <Button
              data-testid={`signInAppleButton`}
              variant={`contained`}
              style={oauthButtonStyles}
              startIcon={getIcon(AppleLogo, `${translation.SIGN_IN_WITH} Apple`)}
              onClick={() => handleOauthLoginButton(AuthProvider.APPLE)}
            >
              <span
                css={oauthButtonLabelStyles}
                data-testid={`signInApple`}
              >{`${translation.SIGN_IN_WITH} Apple`}</span>
            </Button>
          </ConditionalRender>
          <ConditionalRender condition={!hasToken && provider === null}>
            <span css={authDividerTextStyles}>{translation.or}</span>
            <p css={authCheckEmailStyles}>{translation.check_email}</p>
            <Button
              disabled={resendDisabled}
              variant={`contained`}
              style={{ ...authButtonStyles, ...textTransform('capitalize') }}
              onClick={() => handleResendEmail(provider === AuthProvider.IDENTITY)}
            >
              {translation.resend_email}
            </Button>
          </ConditionalRender>
          <ConditionalRender condition={!!hasToken && provider === null}>
            <span css={authDividerTextStyles}>{translation.or}</span>
            <p css={authCheckEmailStyles}>{translation.create_account_password}</p>
            <Button
              disabled={resendDisabled}
              variant={`contained`}
              style={{ ...authButtonStyles, ...textTransform('capitalize') }}
              onClick={() => setUpdateAccount(true)}
            >
              {translation.create_password}
            </Button>
          </ConditionalRender>
        </ConditionalRender>
        <ConditionalRender condition={step === 3}>
          <ResetPasswordForm
            onResetComplete={onResetComplete}
            provider={provider}
            token={provider === AuthProvider.IDENTITY ? resetToken : confirmToken}
          />
        </ConditionalRender>
      </div>
    </div>
  )
}
