/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled/macro'
import { InputLabel, Input, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { ActionButton, StyledDialogActions } from 'components/widgets'
import { Beacon, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { isSuperAdmin } from 'lib/utils/auth'
import { PopUpNotifications } from 'models'
import { useState } from 'react'
import { getAppColors, GRAY3_COLOR, TEXTFIELD_LABEL_COLOR } from 'lib/constants'

const UserWrapper = styled.div({
  width: '100%',
  alignContent: 'center',
})

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '25px',
})

const StyledFormControl = styled.div({
  width: '100%',
  marginBottom: '15px',
})
const RadioButtonLabel = styled.div({
  fontSize: '1.2rem',
  lineHeight: '0.8em',
  fontFamily: 'Montserrat',
  fontWeight: 'bold',
  color: getAppColors('--color-textfield-label'),
})

const StyledRadio = styled(Radio)({
  padding: '4px',
})

const StyledRadioGroup = styled(RadioGroup)({
  padding: '4px',
})

const StyledInputLabel = styled(InputLabel)({
  color: TEXTFIELD_LABEL_COLOR,
  fontFamily: 'Montserrat',
  fontSize: '1.4rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.5rem',
  letterSpacing: '0.1rem',
  textAlign: 'left',
  marginBottom: '10px',
})

const StyledGroupLabel = styled(InputLabel)({
  color: TEXTFIELD_LABEL_COLOR,
  fontFamily: 'Montserrat',
  fontSize: '1.4rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.5rem',
  letterSpacing: '0.1rem',
  textAlign: 'left',
  marginBottom: '1px',
})

const StyledInput = styled(Input)({
  background: GRAY3_COLOR,
  borderRadius: '10px',
  padding: '5px 10px',
  fontFamily: 'Montserrat',
})

function isBeaconTypeIncluded(type: string): boolean {
  const types = ['CARFIT', 'REACT', 'REACT2', 'UNKNOWN']
  return types.includes(type.toUpperCase())
}

export const EditBeaconDetailsModal = ({
  beacon,
  close,
  action,
}: {
  beacon: Beacon
  close: Function
  action: Function
}) => {
  const translation: TranslationGroup = trans.group(TranslationKey.BEACON_DRAWER)
  const common: TranslationGroup = trans.common()

  const [name, setName] = useState<string>(beacon.name)
  const [floor, setFloor] = useState<string>(beacon.floor)
  const [room, setRoom] = useState<string>(beacon.room)
  const [beaconType, setBeaconType] = useState<string>(beacon.beaconType)
  const handleSubmit = () => {
    const element = {
      id: beacon.id,
      name,
      floor,
      room,
      beaconType,
      registration: beacon.registration,
      orgId: beacon.orgId,
    }
    close()
    return PopUpNotifications.tryWithErrorNotifications(action(element), translation.beacon_updated)
  }
  return (
    <UserWrapper>
      <StyledContainer>
        <div>
          <form>
            <StyledFormControl>
              <StyledInputLabel htmlFor="name">{common.name}</StyledInputLabel>
              <StyledInput
                id="name"
                disableUnderline
                fullWidth
                placeholder={translation.edit_beacon_name}
                value={name}
                onChange={e => setName(e.target.value)}
                inputProps={{ maxLength: 255 }}
              />
            </StyledFormControl>
            <StyledFormControl>
              <StyledInputLabel htmlFor="floor">{common.floor}</StyledInputLabel>
              <StyledInput
                id="floor"
                disableUnderline
                fullWidth
                placeholder={translation.enter_beacon_floor}
                value={floor}
                onChange={e => setFloor(e.target.value)}
                inputProps={{ maxLength: 255 }}
              />
            </StyledFormControl>
            <StyledFormControl>
              <StyledInputLabel htmlFor="room">{common.room}</StyledInputLabel>
              <StyledInput
                id="room"
                disableUnderline
                fullWidth
                placeholder={translation.enter_beacon_room}
                value={room}
                onChange={e => setRoom(e.target.value)}
                inputProps={{ maxLength: 255 }}
              />
            </StyledFormControl>
            {isSuperAdmin() && isBeaconTypeIncluded(beaconType) && (
              <StyledFormControl>
                <StyledGroupLabel htmlFor="room">{translation.manufacturer}</StyledGroupLabel>
                <StyledRadioGroup name="beacon-type" value={beaconType} onChange={e => setBeaconType(e.target.value)}>
                  {[
                    ['CARFIT', 'CARFIT'],
                    ['REACT', 'REACT'],
                    ['REACT2', 'REACT2'],
                  ].map(option => (
                    <FormControlLabel
                      key={option[0]}
                      value={option[0]}
                      control={<StyledRadio />}
                      label={<RadioButtonLabel>{option[1]}</RadioButtonLabel>}
                    />
                  ))}
                </StyledRadioGroup>
              </StyledFormControl>
            )}
          </form>
        </div>
      </StyledContainer>
      <StyledDialogActions>
        <ActionButton width="110px" height="28px" onClick={() => close()}>
          {common.cancel}
        </ActionButton>
        <ActionButton width="110px" height="28px" onClick={handleSubmit}>
          {common.confirm}
        </ActionButton>
      </StyledDialogActions>
    </UserWrapper>
  )
}
