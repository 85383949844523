/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react/macro'
import { IconWithLabel } from 'components/widgets'
import { getAppColors, HealthLabelsKeys } from 'lib/constants'

/** Quick legend for pie charts with one green, one yellow, and one red section.
 * By default renders from top-to-bottom green, yellow, red, respectively.
 *
 * @param green the label for green section
 * @param yellow the label for yellow section
 * @param red you guessed it, the label for the red section
 * @param reverse Reverses the display order
 */
const StyledPieChartLegend = ({
  green,
  yellow,
  red,
  reverse = false,
}: {
  green: string
  yellow: string
  red: string
  reverse?: boolean
}) => {
  const content = [
    <IconWithLabel key={'dot1'} flag={reverse ? HealthLabelsKeys.CRITICAL : HealthLabelsKeys.HEALTHY} label={green} />,
    <IconWithLabel key={'dot2'} flag={HealthLabelsKeys.MODERATE} label={yellow} />,
    <IconWithLabel key={'dot3'} flag={reverse ? HealthLabelsKeys.HEALTHY : HealthLabelsKeys.CRITICAL} label={red} />,
  ]

  if (reverse) content.reverse()

  return (
    <div
      css={{
        fontSize: '1.2rem',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '2.5rem',
        alignItems: 'center',
        margin: 'auto',
        color: getAppColors('--color-primary'),
      }}
    >
      {content}
    </div>
  )
}

export default StyledPieChartLegend
