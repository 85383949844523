//# Health => 0: critical, 1: moderate, 2: healthy, 3: disabled
export enum HealthCode {
  CRITICAL = 0,
  MODERATE = 1,
  HEALTHY = 2,
  DISABLED = 3,
}
export interface RouteParams {
  id: string
}
export interface Meta {
  'total-count': number
  'total-pages': number
}

export interface StringIndexedObject extends Object {
  [key: string]: any
}

export interface NameValuePair {
  name: string
  value: any
}

export interface NameNumberValuePair {
  name: string
  value: number
}

export type Error = {
  code?: number
  detail?: string
  source?: any
  status: string
  title: string
}

export interface IdTypePair {
  id: string
  type: string
}

export interface Links {
  first: string
  last: string
  next: string
  prev: string
  self: string
}

export type TimeStamps = {
  insertedAt?: Date
  updatedAt?: Date
  deletedAt?: Date
}

export type onChangeValueCallback = (value: string) => void

export type Primitive = number | string | boolean | null | undefined

export type IdToIdList = { [key: string]: string[] }

export type IdPair = [string, string]

export interface ButtonProps {
  batteryPercent: number
  connected: boolean
  id: string
  version: FirmwareVersionProps
}

export interface FirmwareVersionProps {
  [key: string]: string
}

export interface UpdateFirmwareProps {
  firmwareType: string
  id: string
  sha256: string
  version: string
}
