/** @jsxImportSource @emotion/react */
import { HoverBox } from 'components/widgets'
import { Orgs } from 'models'
import { trans, TranslationGroup } from 'lib/types'

export const ViewGroupsButton = ({ groupIds }: { groupIds: string[] }) => {
  const orgsById = Orgs.use(({ orgsById }) => orgsById)
  const groupNames = groupIds.map(id => (orgsById[id] ? orgsById[id].name : '')).filter(str => str !== '')

  const common: TranslationGroup = trans.common()
  return <HoverBox content={groupNames}>{groupNames.length ? common.view : ''}</HoverBox>
}
