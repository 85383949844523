import { shallowEqual } from 'react-redux'
import { memo, useEffect, useState } from 'react'
import _ from 'lodash'
import { ALL_ORGS_SELECTED, ResponderColumnKeys } from 'lib/constants'
import {
  ActionButton,
  AddDeviceQRC,
  ViewWrapper,
  PageWrapper,
  ViewSpacerWrapper,
  StyledHeader,
} from 'components/widgets'
import {
  Responders,
  NavState,
  DetailsDrawer,
  Orgs,
  Modal,
  PopUpNotifications,
  LiveResponders,
  Users,
  Devices,
} from 'models'
import { isAdmin, isInstaller, isSuperAdmin } from 'lib/utils/auth'
import * as utils from './utils'
import { PreConfiguredTableView } from 'components/partials/TableView'
import {
  SortOrder,
  TableState,
  TableToolEvent,
  TranslationGroup,
  trans,
  TranslationKey,
  Responder,
  ResponderType,
} from 'lib/types'
import { invertBinaryEnum } from 'lib/utils/common'
import { DeviceDetailDrawer } from 'components/partials/DrawerDetails/DeviceDetailDrawer'
import { useHistory, useParams } from 'react-router-dom'
import { RESPONDERS_PATH } from 'lib/constants'
import { useQuery } from 'models/modelUtils'
import { RenderCounter } from 'components/widgets/RenderCounter'
import { UserDetailDrawer } from 'components/partials/DrawerDetails'

interface RouteParams {
  id: string
}

export const RespondersPage = memo(() => {
  const history = useHistory()
  const { id } = useParams<RouteParams>()
  const query = useQuery()
  const selectedOrgId = NavState.use(({ selectedOrgId }) => selectedOrgId)
  const selectedOrg = Orgs.useSelectedOrg()
  const { devicesLoading, devicesById } = Devices.use(({ loading, devicesById }) => {
    return { devicesLoading: loading, devicesById }
  }, shallowEqual)
  const { usersLoading, usersById } = Users.use(({ loading, usersById }) => {
    return { usersLoading: loading, usersById }
  }, shallowEqual)
  const translation: TranslationGroup = trans.group(TranslationKey.DEVICES_TABLE_VIEW)
  const common: TranslationGroup = trans.common()

  const {
    setModalContent,
    openModal: openQrcModal,
    closeModal,
  } = Modal.useStaticModal(`${common.register_qr} ${selectedOrg && selectedOrg.name}`)
  const baseRoute = RESPONDERS_PATH

  const liveRespondersLoaded = LiveResponders.use(({ loading }) => loading)

  const { responders, respondersById, respondersByOrgId, initialLoading, loading } = Responders.use(
    ({ responders, respondersById, respondersByOrgId, initialLoading, loading }) => {
      return { responders, respondersById, respondersByOrgId, initialLoading, loading }
    },
    shallowEqual,
  )
  const [filteredItems, setfilteredItems] = useState<Responder[]>(responders)
  const [tableState, setTableState] = useState<TableState<string, ResponderColumnKeys>>({
    filterBy: utils.getFilterOptions()[0],
    sortBy: utils.getSortOptions()[0].sortBy,
    searchBy: '',
  })

  useEffect(() => {
    if (id && (!loading || !initialLoading)) {
      const item = respondersById[id]
      const u = item?.attributes.responderType === ResponderType.USER
      const loaded = u ? !usersLoading : !devicesLoading
      if (item && loaded) {
        showDetailsDraw(item)
      } else if (!loading && loaded) {
        PopUpNotifications.fireWarning({
          content: `${common.responder} ${id} ${common.was_not_found}`,
        })
        history.replace(baseRoute)
      }
    }
  }, [id, initialLoading, loading, devicesLoading, usersLoading])

  useEffect(() => {
    const page = query.get('page')
    if (page === '1' || loading) return
    query.set('page', '1')
    history.push({
      search: query.toString(),
    })
  }, [tableState])

  useEffect(() => {
    if (!loading || !initialLoading) {
      processItems()
    }
  }, [initialLoading, loading, responders, tableState, query, liveRespondersLoaded])

  useEffect(() => {
    setModalContent(<AddDeviceQRC responders={true} closeModal={closeModal} />)
  }, [responders, closeModal, setModalContent])

  function processItems() {
    const selectedItems = selectedOrgId === ALL_ORGS_SELECTED ? responders : respondersByOrgId[selectedOrgId] || []
    const opts = utils.getFilterOptions()
    const idx = opts.indexOf(tableState.filterBy)
    const filtered = utils
      .searchResponders(selectedItems, tableState.searchBy)
      .filter(responder => (idx === 0 ? true : utils.getFilterResponderComparison(responder, tableState.filterBy)))
      .sort(utils.getSortResponderCompareFn(tableState.sortBy))
    setfilteredItems(filtered)
  }

  function handleDrawerClose() {
    history.push({
      pathname: `${baseRoute}`,
      search: query.toString(),
    })
    DetailsDrawer.close()
  }

  function handleRowClick(row: Responder) {
    if (row.id != id) {
      history.push({
        pathname: `${baseRoute}/${row.id}`,
        search: query.toString(),
      })
    }
  }

  function showDetailsDraw(row: Responder) {
    const u = row.attributes.responderType === ResponderType.USER
    const Component = u ? UserDetailDrawer : DeviceDetailDrawer
    const d = u ? usersById[row.id] : devicesById[row.id]
    // console.log('data', row.id, d, devicesById, Object.keys(devicesById).length)
    DetailsDrawer.show({
      drawerComponent: Component,
      drawerProps: {
        user: d,
        device: d,
        close: handleDrawerClose,
      },
    })
  }

  function handleHeaderClick(header: string) {
    setTableState(state => {
      return Object.assign({}, state, {
        sortBy: {
          field: header,
          order: state.sortBy.field === header ? invertBinaryEnum(state.sortBy.order) : SortOrder.Ascending,
        },
      })
    })
  }

  function handleToolEvent(toolEvent: TableToolEvent<string, ResponderColumnKeys>) {
    setTableState(state => ({
      filterBy: toolEvent.filterByRequest || state.filterBy,
      sortBy: toolEvent.sortByRequest || state.sortBy,
      searchBy: _.isNil(toolEvent.searchByRequest) ? state.searchBy : toolEvent.searchByRequest,
    }))
  }

  const isAdminOrInstaller = isAdmin(selectedOrgId) || isInstaller(selectedOrgId) || isSuperAdmin()

  return (
    <>
      <PageWrapper>
        <ViewWrapper>
          <RenderCounter name={'RespondersPage'} />
          <ViewSpacerWrapper>
            <StyledHeader>{common.responders}</StyledHeader>
            <PreConfiguredTableView
              sortedData={filteredItems}
              tableState={tableState}
              columnConfigs={utils.getColumnConfigs()}
              filterOptions={utils.getFilterOptions()}
              sortOptions={utils.getSortOptions()}
              getRowToolTip={() => ''}
              onHeaderClick={handleHeaderClick}
              onRowClick={handleRowClick}
              onToolEvent={handleToolEvent}
              selectedRowId={id}
              loading={loading}
            >
              <ActionButton onClick={() => utils.downloadCsv(filteredItems)}>{common.download_csv}</ActionButton>
              {isAdminOrInstaller && (
                <ActionButton
                  onClick={() => {
                    if (selectedOrgId === ALL_ORGS_SELECTED) alert(translation.register_select_org)
                    else openQrcModal()
                  }}
                >
                  {common.register} {common.responders}
                </ActionButton>
              )}
            </PreConfiguredTableView>
          </ViewSpacerWrapper>
        </ViewWrapper>
      </PageWrapper>
    </>
  )
})

RespondersPage.displayName = 'RespondersPage'
