/** @jsxImportSource @emotion/react */
import { HealthLabelsKeys, ResponderColumnKeys } from 'lib/constants'
import _ from 'lodash'
import { fuzzySearch } from '@art-suite/art-fuzzy-search'
import {
  SortBy,
  SortOption,
  SortOrder,
  TableColumnConfig,
  Device,
  TranslationGroup,
  trans,
  TranslationKey,
  ucfirst,
  ResponderType,
  HealthCode,
} from 'lib/types'
import { createSortOption, formatDistanceToNow, generateCSV, getHealth, parseTime } from 'lib/utils'
import {
  HighlightOff as HighlightOffIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@material-ui/icons'
import { Devices, LiveResponders, Orgs, Responders } from 'models'
import { getBatteryIcon } from 'styles'
import { Responder, AlertTargetStatus, AlertTargetForwardType } from 'lib/types'
import { TargetStatusValue } from 'models/Responders'
import { differenceInMilliseconds } from 'date-fns'
import { Tooltip } from '@material-ui/core'
import { getDeviceTooltip } from 'lib/utils/common'
import { isMyEmail } from 'lib/utils/auth'

export function getFilterOptions() {
  const translation: TranslationGroup = trans.merge(TranslationKey.USER_TABLE_VIEW)
  return [
    ucfirst(translation.all),
    ucfirst(translation.users),
    ucfirst(translation.devices),
    ucfirst(translation.offline),
    ucfirst(translation.moderate),
    ucfirst(translation.online_now),
  ]
}

export function getMembershipFromResponder(responder: Responder, joinChar?: string) {
  const { orgsById } = Orgs.store.getState()
  return responder.attributes.orgIds.map(oid => orgsById[oid]?.name).join(joinChar || ', ')
}

export function getFilterOptionKeys() {
  return ['all', 'users', 'devices', HealthLabelsKeys.CRITICAL, HealthLabelsKeys.MODERATE, HealthLabelsKeys.HEALTHY]
}

export function getResponderName(responder: Responder): string {
  return (responder.attributes.name.trim() ? responder.attributes.name : responder.attributes.email) || ' '
}

export function getResponderHealth(responder: Responder) {
  // Show responder healthy without tooltip if the responder is logged in as self
  if (responder.attributes?.email) {
    if (isMyEmail(responder?.attributes?.email)) return HealthLabelsKeys.HEALTHY
  }

  switch (responder.attributes.health) {
    case HealthCode.CRITICAL:
      return HealthLabelsKeys.CRITICAL
    case HealthCode.MODERATE:
      return HealthLabelsKeys.MODERATE
    case HealthCode.HEALTHY:
      return HealthLabelsKeys.HEALTHY
    default:
      return HealthLabelsKeys.UNKNOWN
  }
}

function getResponderHealthTooltip(responder: Responder): string {
  // Show responder healthy without tooltip if the responder is logged in as self
  if (responder.attributes?.email) {
    if (isMyEmail(responder?.attributes?.email)) return ''
  }

  if (responder.attributes.responderType === ResponderType.ALERT_SOURCE) {
    const { devicesById } = Devices.store.getState()
    const device = devicesById[responder.id]
    return device ? getDeviceTooltip(device) : ''
  }
  const isOnline = LiveResponders.isResponderOnline(responder.id)
  const translation: TranslationGroup = trans.common()
  const warnings: TranslationGroup = trans.group(TranslationKey.HEALTH_WARNINGS)
  const tmp = (responder.attributes.healthWarnings?.map(warning => warnings[warning]) || []).concat()

  if (isOnline) tmp.unshift(translation.responder_online)
  let last = ''
  if (tmp.length > 1) {
    last = `, ${translation.and} ${tmp.pop()}`
  }
  return tmp.length ? `${translation.responder} ${tmp.join(', ')}${last}` : ''
}

export function hasValidTarget(responder: Responder) {
  return responder.attributes.targets.some(
    t =>
      t.status === AlertTargetStatus.ACCEPTED ||
      [AlertTargetForwardType.PUSH_ANDROID, AlertTargetForwardType.PUSH_IOS].includes(t.forwardType),
  )
}

export function getTargetStatus(responder: Responder, forwardType: AlertTargetForwardType) {
  return responder.attributes.targets.find(t => t.forwardType === forwardType)?.status
}

function invertIfDescending(diff: number, sortOrder: SortOrder) {
  return sortOrder === SortOrder.Descending ? diff * -1 : diff
}

export const getSortOptions: () => SortOption<ResponderColumnKeys>[] = () => {
  const translation: TranslationGroup = trans.merge(TranslationKey.DEVICES_TABLE_VIEW)
  return _.flatten(
    _.map(ResponderColumnKeys, c => {
      let desc = `${translation[c]} ↓`
      let asc = `${translation[c]} ↑`
      if (['Push', 'SMS'].includes(c)) {
        desc = `${c} ↓`
        asc = `${c} ↑`
      }
      return [createSortOption(c, SortOrder.Descending, desc), createSortOption(c, SortOrder.Ascending, asc)]
    }),
  )
}

export function searchResponders(responders: Responder[], searchBy: string): Responder[] {
  if (searchBy.length < 2) return responders
  const translation: TranslationGroup = trans.merge(TranslationKey.HEALTH_LABELS)
  const searchArray = _.flatten(
    responders.map(responder => {
      const arr = [
        [responder.id, responder.id],
        [translation[getResponderHealth(responder)], responder.id],
        [getResponderName(responder), responder.id],
        [responder.attributes.responderType === 'user' ? translation.user : translation.device, responder.id],
      ]
      return arr
    }),
  )
  const searchResults = fuzzySearch(searchBy, searchArray)
  const searchResultsIds = searchResults.map(result => result[1] || null).filter(result => result !== null)
  return responders.filter(responder => searchResultsIds.includes(responder.id))
}

export function getFilterResponderComparison(responder: Responder, filterBy: string) {
  const opts = getFilterOptions()
  const keys = getFilterOptionKeys()
  if (!opts.includes(filterBy)) throw new Error()
  const idx = opts.indexOf(filterBy)
  switch (keys[idx]) {
    case 'all':
      return true
    case 'devices':
      return responder.attributes.responderType === ResponderType.ALERT_SOURCE
    case 'users':
      return responder.attributes.responderType === ResponderType.USER
    default:
      return getResponderHealth(responder) === keys[idx]
  }
}

export function filterResponderByHealthLabel(responders: Responder[], healthLabel: string) {
  return responders.filter(a => getResponderHealth(a) === healthLabel)
}

export const getHealthIcon = (health: string) => {
  switch (health) {
    case HealthLabelsKeys.HEALTHY:
    case HealthLabelsKeys.ONLINE:
      return <CheckCircleOutlineIcon style={{ color: '#5FD078' }} />

    case HealthLabelsKeys.MODERATE:
      return <RemoveCircleOutlineIcon style={{ color: '#E89F0B' }} />

    case HealthLabelsKeys.OFFLINE:
    case HealthLabelsKeys.CRITICAL:
      return <HighlightOffIcon style={{ color: '#F44336' }} />

    default:
      return <></>
  }
}

export const getAlertTargetStatusIcon = (status: AlertTargetStatus | null) => {
  switch (status) {
    case AlertTargetStatus.ACCEPTED:
      return <CheckCircleOutlineIcon style={{ color: '#5FD078' }} />

    case AlertTargetStatus.PENDING:
      return <RemoveCircleOutlineIcon style={{ color: '#E89F0B' }} />

    case AlertTargetStatus.REFUSED:
      return <HighlightOffIcon style={{ color: '#F44336' }} />

    default:
      return <></>
  }
}
export interface AlertTargetStatusMap {
  email?: AlertTargetStatus | null
  push?: AlertTargetStatus | null
  sms?: AlertTargetStatus | null
}

const getAlertTargetStatusMap = (responder: Responder): AlertTargetStatusMap => {
  const targets = responder.attributes.targets
  const map: AlertTargetStatusMap = {}
  targets.forEach(t => {
    if (t.forwardType === AlertTargetForwardType.PUSH_IOS || t.forwardType === AlertTargetForwardType.PUSH_ANDROID) {
      map.push = t.status
    } else if (t.forwardType === AlertTargetForwardType.EMAIL) {
      map.email = t.status
    } else if (t.forwardType === AlertTargetForwardType.SMS) {
      map.sms = t.status
    }
  })
  return map
}

export const isConnected = (device: Device) => {
  return device.props.button?.connected ? true : false
}

export const getBatteryDisplay = (device: Device) => {
  return (
    <>
      {getBatteryIcon(device.batteryPercent)}
      {!_.isNil(device.batteryPercent) && `${device.batteryPercent}%`}
    </>
  )
}

export const getColumnConfigs: () => TableColumnConfig<ResponderColumnKeys, Responder>[] = () => {
  const translation: TranslationGroup = trans.merge(TranslationKey.HEALTH_LABELS)
  const userTrans: TranslationGroup = trans.merge(TranslationKey.USER_TABLE_VIEW)

  return [
    {
      header: ResponderColumnKeys.Health,
      label: translation[ResponderColumnKeys.Health],
      renderFn: (responder: Responder) => {
        const health = getResponderHealth(responder)
        const icon = getHealthIcon(health)
        return (
          <Tooltip title={getResponderHealthTooltip(responder)}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  paddingRight: '10px',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                {icon}
              </div>

              <div style={{ lineHeight: '10px' }}>{ucfirst(translation[health])}</div>
            </div>
          </Tooltip>
        )
      },
    },
    {
      header: ResponderColumnKeys.Type,
      label: translation[ResponderColumnKeys.Type],
      renderFn: (responder: Responder) =>
        translation[responder.attributes.responderType === 'user' ? 'user' : 'device'],
    },
    {
      header: ResponderColumnKeys.Name,
      label: translation[ResponderColumnKeys.Name],
      renderFn: (responder: Responder) => getResponderName(responder),
    },
    // {
    //   header: ResponderColumnKeys.Push,
    //   label: ResponderColumnKeys.Push,
    //   renderFn: (responder: Responder) => {
    //     const status = getAlertTargetStatusMap(responder)['push'] || null
    //     const icon = getAlertTargetStatusIcon(status)
    //     return <Tooltip title={getNotificationTooltip('push', status)}>{icon}</Tooltip>
    //   },
    // },
    {
      header: ResponderColumnKeys.SMS,
      label: ResponderColumnKeys.SMS,
      renderFn: (responder: Responder) => {
        const status = getAlertTargetStatusMap(responder)['sms'] || null
        const icon = getAlertTargetStatusIcon(status)
        return <Tooltip title={getNotificationTooltip(AlertTargetForwardType.SMS, status)}>{icon}</Tooltip>
      },
    },
    {
      header: ResponderColumnKeys.Email,
      label: ResponderColumnKeys.Email,
      renderFn: (responder: Responder) => {
        const status = getAlertTargetStatusMap(responder)['email'] || null
        const icon = getAlertTargetStatusIcon(status)
        return <Tooltip title={getNotificationTooltip(AlertTargetForwardType.EMAIL, status)}>{icon}</Tooltip>
      },
    },
    {
      header: ResponderColumnKeys.Membership,
      label: translation[ResponderColumnKeys.Membership],
      renderFn: (responder: Responder) => {
        return getMembershipFromResponder(responder)
      },
    },
    {
      header: ResponderColumnKeys.LastSeen,
      label: translation[ResponderColumnKeys.LastSeen],
      renderFn: (responder: Responder) => {
        const { respondersById } = LiveResponders.store.getState()
        return !!respondersById[responder.id]
          ? userTrans.online_now
          : responder.attributes.lastCheckedInAt
          ? formatDistanceToNow(responder.attributes.lastCheckedInAt)
          : ''
      },
    },
  ]
}

export function getSortResponderCompareFn(sortBy: SortBy<ResponderColumnKeys>): (a: Responder, b: Responder) => number {
  switch (sortBy.field) {
    case ResponderColumnKeys.Health:
      return (a: Responder, b: Responder) => {
        const keys = getFilterOptionKeys()
        const diff = keys.findIndex(h => getResponderHealth(a) === h) - keys.findIndex(h => getResponderHealth(b) === h)
        return normalizeCompareResult(invertIfDescending(diff, sortBy.order))
      }

    case ResponderColumnKeys.Type:
      return (a: Responder, b: Responder) =>
        normalizeCompareResult(
          invertIfDescending(simpleSort(a.attributes.responderType, b.attributes.responderType), sortBy.order),
        )

    case ResponderColumnKeys.Name:
      return (a: Responder, b: Responder) => {
        const aname = getResponderName(a)?.toLowerCase()
        const bname = getResponderName(b)?.toLowerCase()
        return normalizeCompareResult(invertIfDescending(simpleSort(aname, bname), sortBy.order))
      }

    case ResponderColumnKeys.Push:
      return (a: Responder, b: Responder) => {
        const { targetsByResponderId } = Responders.store.getState()
        const atarget = targetsByResponderId[a.id]?.PUSH || TargetStatusValue.UNKNOWN
        const btarget = targetsByResponderId[b.id]?.PUSH || TargetStatusValue.UNKNOWN
        return normalizeCompareResult(invertIfDescending(simpleSort(atarget, btarget), sortBy.order))
      }

    case ResponderColumnKeys.Email:
      return (a: Responder, b: Responder) => {
        const { targetsByResponderId } = Responders.store.getState()
        const atarget = targetsByResponderId[a.id]?.EMAIL || TargetStatusValue.UNKNOWN
        const btarget = targetsByResponderId[b.id]?.EMAIL || TargetStatusValue.UNKNOWN
        return normalizeCompareResult(invertIfDescending(simpleSort(atarget, btarget), sortBy.order))
      }

    case ResponderColumnKeys.SMS:
      return (a: Responder, b: Responder) => {
        const { targetsByResponderId } = Responders.store.getState()
        const atarget = targetsByResponderId[a.id]?.SMS || TargetStatusValue.UNKNOWN
        const btarget = targetsByResponderId[b.id]?.SMS || TargetStatusValue.UNKNOWN
        return normalizeCompareResult(invertIfDescending(simpleSort(atarget, btarget), sortBy.order))
      }

    case ResponderColumnKeys.Membership:
      return (a: Responder, b: Responder) => {
        return normalizeCompareResult(
          invertIfDescending(simpleSort(getMembershipFromResponder(a), getMembershipFromResponder(b)), sortBy.order),
        )
      }

    case ResponderColumnKeys.LastSeen:
      return (a: Responder, b: Responder) =>
        normalizeCompareResult(
          invertIfDescending(lastUpdatedSort(a.attributes.lastCheckedInAt, b.attributes.lastCheckedInAt), sortBy.order),
        )

    default:
      console.warn(`Unhandled sort column ${sortBy.field}`)
      return () => 0
  }
}

function getNotificationTooltip(ntype: string, status: AlertTargetStatus | null) {
  const translation: TranslationGroup = trans.common()
  switch (status) {
    case AlertTargetStatus.ACCEPTED:
      return translation[`${ntype}_alerts_enabled`]
    case AlertTargetStatus.PENDING:
      return translation[`${ntype}_alerts_pending`]
    case AlertTargetStatus.REFUSED:
      return translation[`${ntype}_alerts_not_enabled`]
    default:
      return ''
  }
}

function simpleSort(a: any, b: any) {
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

function lastUpdatedSort(a: any, b: any) {
  if (!a) return -1
  if (!b) return 1
  return differenceInMilliseconds(parseTime(a), parseTime(b))
}

function normalizeCompareResult(r: number) {
  return simpleSort(r, 0)
}

export function downloadCsv(responders: Responder[]) {
  const translation: TranslationGroup = trans.merge(TranslationKey.USER_TABLE_VIEW)

  const fields = [translation.id, translation.health, translation.type, translation.name, 'Push', translation.email]

  const data = responders.map(responder => {
    const emailStatus = getTargetStatus(responder, AlertTargetForwardType.EMAIL)
    const emailStatusString = (status: AlertTargetStatus | undefined) => {
      switch (status) {
        case AlertTargetStatus.ACCEPTED:
          return translation.yes
        case AlertTargetStatus.PENDING:
        case AlertTargetStatus.REFUSED:
          return ucfirst(translation[status])
        default:
          return ''
      }
    }

    // const emailStatusString = emailStatus ? translation[emailStatus] : ''
    return [
      responder.id,
      ucfirst(translation[getResponderHealth(responder)]),
      responder.attributes.responderType === ResponderType.USER ? translation.user : translation.device,
      responder.attributes.name,
      getTargetStatus(responder, AlertTargetForwardType.PUSH_ANDROID) ||
      getTargetStatus(responder, AlertTargetForwardType.PUSH_IOS)
        ? translation.yes
        : '',
      emailStatusString(emailStatus),
    ]
  })
  const name = `${Orgs.getSelectedOrgName()} ${translation.responders}`.trim()

  generateCSV(data, fields, name)
  return
}
