import { UNAUTHENTICATED_PARAM } from './'

export const LOGIN_MESSAGES: Record<string, string> = {
  40100: `invalid_login`,
  40101: `token_expired`,
  40102: `invalid_user`,
  40103: `invalid_alert_source`,
  40104: `login_again`,
  40300: `no_grants`,
  40301: `invalid_super_admin`,
  [UNAUTHENTICATED_PARAM]: `unauthenticated`,
}

export const LOGIN_TITLE = `Welcome back!`
export const EMAIL_TO_SIGN_IN = `Enter your email below to sign in.`
export const SIGN_IN_WITH = `Sign in with`
