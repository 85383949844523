/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import { ChartsWrapper } from 'components/widgets'
import { Beacon, NameNumberValuePair } from 'lib/types'
import { HealthLabelsKeys } from 'lib/constants'
import * as utils from '../utils'
import { chartPageContainer } from 'styles'
import StyledPieChartLegend from 'components/partials/StyledPieChartLegend'
import StyledPieChart from 'components/partials/StyledPieChart'
import { HealthKey, LegendLabel } from 'components/partials/MainSidebar/ChartsSection'

export const Charts = ({
  beacons,
  legendLabel,
}: {
  beacons: Beacon[]
  width?: number
  height?: number
  legendLabel: LegendLabel
}) => {
  const green = legendLabel[HealthKey.GREEN]
  const yellow = legendLabel[HealthKey.YELLOW]
  const red = legendLabel[HealthKey.RED]
  const beaconsData: NameNumberValuePair[] = [
    {
      name: red,
      value: utils.filterBeaconByHealthLabel(beacons, HealthLabelsKeys.CRITICAL).length,
    },
    {
      name: yellow,
      value: utils.filterBeaconByHealthLabel(beacons, HealthLabelsKeys.MODERATE).length,
    },
    {
      name: green,
      value: utils.filterBeaconByHealthLabel(beacons, HealthLabelsKeys.HEALTHY).length,
    },
  ]

  return (
    <ChartsWrapper>
      <div css={chartPageContainer}>
        <StyledPieChart data={beaconsData} legendLabel={legendLabel} />

        <StyledPieChartLegend
          green={`${beaconsData[2].value} ${green}`}
          yellow={`${beaconsData[1].value} ${yellow}`}
          red={`${beaconsData[0].value} ${red}`}
          reverse
        />
      </div>
    </ChartsWrapper>
  )
}
