declare global {
  interface Window {
    _env_: any
  }
}

export function getEnv() {
  if (window._env_) {
    return window._env_.REACT_APP_ENV || 'prod'
  }
  if (process.env) {
    return process.env.REACT_APP_ENV || 'prod'
  }
  return 'prod'
}

export const ROWS_PER_PAGE = 50

export const PUSHER_APP_KEY = window._env_?.PUSHER_APP_KEY || process.env?.PUSHER_APP_KEY || ''
export const PUSHER_APP_CLUSTER = window._env_?.PUSHER_APP_CLUSTER || process.env?.PUSHER_APP_CLUSTER || ''

export const USE_PUSHER_PRIVATE = true
export const PUSHER_PRESENCE_PREFIX = USE_PUSHER_PRIVATE ? 'presence-' : ''
export const PUSHER_PRIVATE_PREFIX = USE_PUSHER_PRIVATE ? 'private-' : ''

export * from './api'
export * from './auth'
export * from './colors'
export * from './domain'
export * from './authMessages'
export * from './routing'
export * from './ui'
export * from './dateFormat'
export * from './keys'
export * from './grants'
