import { ROWS_PER_PAGE } from 'lib/constants'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import semver from 'semver'
const { object } = require('art-comprehensions')

interface RecordObject<T> {
  [key: string]: T
}
interface RecordListObject<T> {
  [key: string]: T[]
}

type RecordTypeWithId = {
  id?: string
}

type RecordWithOrgId = {
  id?: string
  orgId?: string
  attributes?: { orgId?: string }
}

export function getRecords<RecordType extends RecordTypeWithId>(
  records: RecordType[],
  acc: RecordType[] = [],
): RecordType[] {
  if (acc.length === 0) return records
  records.forEach(record => {
    const index = acc.findIndex((o: RecordType) => o.id === record.id)
    index > -1 ? (acc[index] = record) : acc.push(record)
  })
  // console.log('getRecords', acc)
  return acc
}

export function getRecordsById<RecordType extends RecordTypeWithId>(
  records: RecordType[],
  obj: RecordObject<RecordType> = {},
): RecordObject<RecordType> {
  // @ts-ignore
  return object(records, obj, { withKey: ({ id }) => id })
}

export function getRecordsByOrgId<RecordType extends RecordWithOrgId>(
  records: RecordType[],
  obj: RecordListObject<RecordType> = {},
): RecordListObject<RecordType> {
  records.forEach(record => {
    const org = (obj[(record.orgId ?? record.attributes?.orgId) || 'noOrgId'] ??= [])
    const index = org.findIndex((o: RecordType) => o.id === record.id)
    index > -1 ? (org[index] = record) : org.push(record)
  })
  return obj
}

export const isValidVersion = (version: string | number | null | undefined) => {
  return normalizeVersion(version) !== ''
}

export const normalizeVersion = (version: string | number | null | undefined, empty: string = '') => {
  if (!version) return empty
  const ver = version.toString().split('.')
  while (ver.length < 3) {
    ver.push('0')
  }
  const mmp = ver.splice(0, 3)
  const final = mmp.join('.')
  if (!semver.valid(final)) return empty
  return final
}

type RecordPageDetails = {
  id: string
  found: boolean
  index?: number
  indexOnPage?: number
  page?: number
}

export function getRecordPageDetails<RecordType extends RecordTypeWithId>(
  id: string,
  records: RecordType[],
  perPage: number = ROWS_PER_PAGE,
): RecordPageDetails {
  let index = -1
  records.find((ele, ind) => {
    if (ele.id === id) {
      index = ind
      return ele
    }
  })
  if (index === -1)
    return {
      id,
      found: false,
    }
  return {
    id,
    found: true,
    index,
    indexOnPage: index % perPage,
    page: Math.floor(index / perPage) + 1,
  }
}

export function usePath(): string {
  const { pathname } = useLocation()
  return useMemo(() => {
    return pathname
  }, [pathname])
}

export function useQuery(defaultPage: string | number = 1): URLSearchParams {
  const { search } = useLocation()
  return useMemo(() => {
    return search.length ? new URLSearchParams(search) : new URLSearchParams(`page=${defaultPage}`)
  }, [search])
}

export function useSearchParams(key?: string): URLSearchParams | string | null {
  const { search } = useLocation()
  const s = search.length ? new URLSearchParams(search) : new URLSearchParams()
  return key ? s.get(key) : s
}

export function isTruthy(val: string): boolean {
  if (val === undefined || val === null) return false
  return ['true', '1', 'yes'].includes(val.toLowerCase())
}

export function isValidEmail(email: string) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
}
