/** @jsxImportSource @emotion/react */
import { PieChart, Pie, Cell, Tooltip, Label } from 'recharts'
import { CHART_COLORS, CHART_COLOR_PLACEHOLDER } from 'lib/constants'
import { NameNumberValuePair } from 'lib/types'
import { sumValuesReducer } from 'lib/utils/common'
import { HealthKey, LegendLabel } from './MainSidebar/ChartsSection'

const StyledPieChart = ({
  data,
  width = 200,
  height = 200,
  legendLabel,
}: {
  data: NameNumberValuePair[]
  width?: number
  height?: number
  legendLabel: LegendLabel
}) => {
  const total = data.reduce(sumValuesReducer).value
  const empty = total === 0
  const pieChartCells = empty ? (
    <Cell key={`cell-placeholder`} fill={CHART_COLOR_PLACEHOLDER} />
  ) : (
    data.map((_, colorIndex) => <Cell key={`cell-${colorIndex}`} fill={CHART_COLORS[colorIndex]} />)
  )
  const pieChartData = empty ? [{ name: '', value: 1 }] : data

  return (
    <PieChart width={width} height={height} style={{ margin: 'auto' }}>
      <Pie
        data={pieChartData}
        dataKey="value"
        cx="50%"
        cy="50%"
        innerRadius={65}
        outerRadius={80}
        fill="#8884d8"
        paddingAngle={5}
        cornerRadius={10}
        isAnimationActive={true}
      >
        {pieChartCells}
        <Label value={`${total} ${legendLabel[HealthKey.TOTAL]}`} position="center" />
      </Pie>

      {!empty && <Tooltip />}
    </PieChart>
  )
}

export default StyledPieChart
