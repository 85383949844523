/** @jsxImportSource @emotion/react */
import { css, jsx } from '@emotion/react/macro'
import { getAppColors } from 'lib/constants'

export const roundedTextFieldStyles = {
  label: 'roundedTextField',
  '.MuiInputBase-root': {
    borderRadius: '15px',
    fontFamily: 'Montserrat',
    '&:.Mui-focused': {
      fieldset: {
        borderColor: getAppColors('--color-textfield-focus'),
      },
    },
  },
  input: {
    padding: '15px',
  },
}
