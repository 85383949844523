/** @jsxImportSource @emotion/react */
import AlertInfoCard from 'components/partial-pages/AlertsView/Partials/AlertInfoCard'
import { HealthLabelsKeys } from 'lib/constants'
import { AlertSession } from 'lib/types'
import * as controller from '../utils'

export const OpenAlertCards = ({ alertSessions }: { alertSessions: AlertSession[] }) => {
  const sortedAlertsByNewFirst = alertSessions.sort((a, b) => b.attributes.firstTime - a.attributes.firstTime)

  return (
    <div
      css={{
        display: 'flex',
        overflowY: 'auto',
        '> div': {
          marginRight: '10px',
        },
      }}
    >
      {[
        ...controller.filterAlertsByHealthLabel(sortedAlertsByNewFirst, HealthLabelsKeys.ACTIVE_NO_RESPONSE),
        ...controller.filterAlertsByHealthLabel(sortedAlertsByNewFirst, HealthLabelsKeys.ACTIVE_IN_PROGRESS),
      ].map((alertSession, index) => (
        <AlertInfoCard alertSession={alertSession} key={index} />
      ))}
    </div>
  )
}
