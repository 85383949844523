/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react'
import { Button, IconButton, InputAdornment } from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { ConditionalRender, StyledInput } from 'components/widgets'
import { PopUpNotifications } from 'models'
import { updatePassword } from 'models/api'
import { AuthProvider, PASSWORD_REGEX } from 'lib/constants'
import { authButtonStyles, authInputFieldStyles } from 'styles'
import { PasswordValidation, PasswordValidationList } from './PasswordValidationList'
import { trans, TranslationGroup, TranslationKey } from 'lib/types'

interface Props {
  onResetComplete: Function
  provider: AuthProvider | null
  token: string | undefined
}

export function ResetPasswordForm({ onResetComplete, provider, token }: Props) {
  const translation: TranslationGroup = trans.merge(TranslationKey.AUTH)
  const defaultValidation = {
    VALID: false,
    LENGTH: false,
    LOWER: false,
    UPPER: false,
    NUMBER_SYMBOL: false,
    MATCH: false,
  }
  const [isValidPassword, setIsValidPassword] = useState(false)
  const [validation, setValidation] = useState<PasswordValidation>(defaultValidation)
  const [firstName, setFirstName] = useState<string>()
  const [lastName, setLastName] = useState<string>()
  const [passwordsMatchError, setPasswordsMatchError] = useState(true)
  const [password, setPassword] = useState('')
  const [confirmation, setConfirmation] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  // const [submitted, setSubmitted] = useState(false)
  const inputRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (inputRef.current !== null) {
      const ele = inputRef.current.children[0] as HTMLInputElement
      ele.focus()
    }
  }, [])

  function handleClickShowConfirmation() {
    setShowConfirmation(!showConfirmation)
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword)
  }

  function handlePasswordChange(input: string) {
    const len = input.length >= 12
    const match = input === confirmation
    const upper = PASSWORD_REGEX.UPPER.test(input)
    const lower = PASSWORD_REGEX.LOWER.test(input)
    const number_symbol = PASSWORD_REGEX.NUMBER_SYMBOL.test(input)
    const has_space = input.indexOf(' ') >= 0
    const valid = upper && lower && number_symbol && len && !has_space
    setValidation({
      VALID: valid,
      LENGTH: len,
      UPPER: PASSWORD_REGEX.UPPER.test(input),
      LOWER: PASSWORD_REGEX.LOWER.test(input),
      NUMBER_SYMBOL: PASSWORD_REGEX.NUMBER_SYMBOL.test(input) && !has_space,
      MATCH: match,
    })
    setIsValidPassword(valid)
    setPasswordsMatchError(!match)
    setPassword(input)
  }

  function handleConfirmationChange(input: string) {
    const match = password === input
    setPasswordsMatchError(!match)
    setValidation({ ...validation, MATCH: match })
    setConfirmation(input)
  }

  function handleResetPassword() {
    updatePassword(password, confirmation, token, firstName, lastName, provider === AuthProvider.IDENTITY)
      .then(response => {
        if (response) {
          PopUpNotifications.fireSuccess({ content: translation.update_successful })
          // setSubmitted(true)
          onResetComplete()
        } else {
          throw new Error(translation.update_unsuccessful)
        }
      })
      .catch(() => {
        // setSubmitted(false)
        PopUpNotifications.fireError({ content: translation.update_unsuccessful })
      })
  }

  return (
    <>
      <ConditionalRender condition={!!token}>
        <ConditionalRender condition={provider === null}>
          <StyledInput
            css={authInputFieldStyles}
            id={`firstName`}
            disableUnderline
            fullWidth
            type={`text`}
            placeholder={translation.first_name}
            value={firstName}
            onChange={e => setFirstName(e.target.value.trim())}
            inputProps={{ maxLength: 255 }}
          />
          <StyledInput
            css={authInputFieldStyles}
            id={`lastName`}
            disableUnderline
            fullWidth
            type={`text`}
            placeholder={translation.last_name}
            value={lastName}
            onChange={e => setLastName(e.target.value.trim())}
            inputProps={{ maxLength: 255 }}
          />
        </ConditionalRender>
        <StyledInput
          css={authInputFieldStyles}
          id={`password`}
          disableUnderline
          fullWidth
          required
          type={showPassword ? `text` : `password`}
          placeholder={translation.password}
          value={password}
          onChange={e => handlePasswordChange(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label={translation.toggle_password}
                onClick={handleClickShowPassword}
                onMouseDown={event => event.preventDefault()}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          inputProps={{ maxLength: 72, minLength: 12 }}
        />
        <PasswordValidationList validation={validation} />
        <StyledInput
          css={authInputFieldStyles}
          id={`passwordConfirmation`}
          disableUnderline
          fullWidth
          required
          type={showConfirmation ? `text` : `password`}
          placeholder={translation.confirm_password}
          value={confirmation}
          onChange={e => handleConfirmationChange(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label={translation.toggle_password}
                onClick={handleClickShowConfirmation}
                onMouseDown={event => event.preventDefault()}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          inputProps={{ maxLength: 72, minLength: 12 }}
        />
        <PasswordValidationList validation={validation} isConfirm={true} />
        <Button
          css={authButtonStyles}
          disabled={!isValidPassword || passwordsMatchError}
          variant={`contained`}
          onClick={handleResetPassword}
        >
          {translation.submit}
        </Button>
      </ConditionalRender>
    </>
  )
}
