import { shallowEqual } from 'react-redux'
import React, { useState } from 'react'
import { ReactComponent as BestLocationIcon } from 'static/images/dashboard/Best_Location.svg'
import { ReactComponent as HotelIcon } from 'static/images/dashboard/Hotel.svg'
import { TableFilterHeader } from 'components/partials/TableFilterHeader'
import OrgGlobalCard from './OrgGlobalCard/OrgGlobalCard'
import {
  getBestHealthLocation,
  sortOrganizations,
} from 'components/partials/_legacy/MainDashboardPage/components/utils'
import styles from './GlobalDashboard.module.css'
import { ChildOrgMap, NavState, Orgs } from 'models'
import { trans, TranslationGroup, TranslationKey } from 'lib/types'
import { ALL_ORGS_SELECTED } from 'lib/constants'
import { RenderCounter } from 'components/widgets/RenderCounter'

function GlobalDashboard() {
  const { orgs, orgsById } = Orgs.use(({ orgs, orgsById }) => {
    return { orgs, orgsById }
  }, shallowEqual)
  const childOrgMap = ChildOrgMap.use(({ childOrgMap }) => childOrgMap)
  const { orgSortOrder, selectedOrgId } = NavState.use(({ orgSortOrder, selectedOrgId }) => {
    return { orgSortOrder, selectedOrgId }
  }, shallowEqual)
  const [searchTerm, setSearchTerm] = useState('')

  let filteredOrgs =
    selectedOrgId === ALL_ORGS_SELECTED ? orgs : orgsById[selectedOrgId] ? [orgsById[selectedOrgId]] : orgs

  // if selected orgType is group render it's children
  if (selectedOrgId !== ALL_ORGS_SELECTED && filteredOrgs[0].orgType === 'group') {
    // filteredOrgs = orgs.filter(o => childOrgMap[selectedOrgId].includes(o.id))
    filteredOrgs = childOrgMap[selectedOrgId] ? childOrgMap[selectedOrgId].map(id => orgsById[id]) : []
  }
  // remove groups
  filteredOrgs = filteredOrgs.filter(o => o.orgType !== 'group')
  filteredOrgs = searchTerm ? filteredOrgs.filter(o => o.name.toLowerCase().includes(searchTerm)) : filteredOrgs

  const translation: TranslationGroup = trans.merge(TranslationKey.DASHBOARD)
  const options: Array<string> = JSON.parse(translation.sort_options)

  return (
    <>
      <RenderCounter name={'GlobalDashboard'} />
      <div className={styles.summaryContainer}>
        <div className={styles.summaryWrapper}>
          <HotelIcon className={styles.iconStyle} />
          {filteredOrgs.length} {translation.properties}
        </div>
        {filteredOrgs.length > 0 && (
          <div className={styles.summaryWrapper}>
            <BestLocationIcon className={styles.iconStyle} />
            {translation.best_health} : {getBestHealthLocation(filteredOrgs)}
          </div>
        )}
      </div>

      <TableFilterHeader
        sortOptions={options}
        showOptions={[]}
        currentSort={orgSortOrder}
        onSortChange={(term: string) => {
          NavState.setOrgSortOrder(term)
        }}
        onSearchChange={(term: string) => setSearchTerm(term.toLowerCase())}
      />
      {sortOrganizations(orgSortOrder, filteredOrgs, options).map(org => (
        <OrgGlobalCard key={org.id} organization={org} />
      ))}
    </>
  )
}

export default GlobalDashboard
