/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import styled from '@emotion/styled/macro'
import { InputLabel, Input } from '@material-ui/core'
import React from 'react'
import { OrgType, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { ActionButton, StyledDialogActions } from 'components/widgets'
import { Orgs, PopUpNotifications } from 'models'
import { nullEmptyString } from 'lib/utils/common'
import { GRAY3_COLOR, TEXTFIELD_LABEL_COLOR } from 'lib/constants'

const UserWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '20px',
})

const StyledFormControl = styled.div({
  display: 'flex',
  width: '30%',
})

const StyledDoubleFormControl = styled.div({
  width: '48%',
})

const StyledFullFormControl = styled.div({
  width: '100%',
})

const StyledInputLabel = styled(InputLabel)({
  color: TEXTFIELD_LABEL_COLOR,
  fontFamily: 'Montserrat',
  fontSize: '1.2rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.5rem',
  letterSpacing: '0.1rem',
  textAlign: 'left',
})

const StyledFullInputLabel = styled(InputLabel)({
  color: TEXTFIELD_LABEL_COLOR,
  fontFamily: 'Montserrat',
  fontSize: '1.2rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.5rem',
  letterSpacing: '0.1rem',
  textAlign: 'left',
})

const StyledInput = styled(Input)({
  marginTop: '1px',
  background: GRAY3_COLOR,
  borderRadius: '10px',
  padding: '5px 10px',
  fontFamily: 'Montserrat',
})

type Props = {
  groupMode?: boolean
  closeModal: () => void
}

export const CreateProperty = ({ closeModal, groupMode = false }: Props) => {
  const translation: TranslationGroup = trans.merge(TranslationKey.ADMIN)
  const [input, setInput] = React.useState({
    name: '',
    phone: '',
    image: '',
    proximity: '',
    street1: '',
    street2: '',
    zipcode: '',
    city: '',
    state: '',
  })

  const handleInputChange = function (e: any) {
    setInput({
      ...input,
      [e.target.name]: nullEmptyString(`${e.target.value}`),
    })
  }

  const confirmCreate = () => {
    closeModal()
    PopUpNotifications.tryWithErrorNotifications(
      Orgs.createOrUpdate({
        name: input.name,
        orgType: groupMode ? OrgType.Group : OrgType.Property,
        props: {
          address: {
            city: input.city,
            state: input.state,
            street1: input.street1,
            street2: input.street2,
            zipcode: input.zipcode,
          },
          proximityUuid: {
            name: '', // TODO: add friendly name input
            value: input.proximity,
          },
          phone: input.phone,
          image: input.image,
        },
      }),
    ).then(() => PopUpNotifications.fireSuccess({ content: translation.success }))
  }

  return (
    <UserWrapper>
      <StyledContainer>
        <StyledFormControl>
          <StyledInputLabel htmlFor="name">
            {translation.name}
            <StyledInput
              id="name"
              name="name"
              disableUnderline
              fullWidth
              value={input.name}
              onChange={handleInputChange}
            />
          </StyledInputLabel>
        </StyledFormControl>
        {!groupMode && (
          <>
            <StyledFormControl>
              <StyledInputLabel htmlFor="phone">
                {translation.phone}
                <StyledInput
                  id="phone"
                  name="phone"
                  type="tel"
                  disableUnderline
                  fullWidth
                  value={input.phone}
                  onChange={handleInputChange}
                />
              </StyledInputLabel>
            </StyledFormControl>
            <StyledFormControl>
              <StyledInputLabel htmlFor="image">
                {translation.image_url}
                <StyledInput
                  id="image"
                  name="image"
                  disableUnderline
                  fullWidth
                  value={input.image}
                  onChange={handleInputChange}
                />
              </StyledInputLabel>
            </StyledFormControl>
          </>
        )}
      </StyledContainer>
      {!groupMode && (
        <>
          <StyledContainer>
            <StyledFullFormControl>
              <StyledFullInputLabel htmlFor="proximity">
                {translation.proximity_id}
                <StyledInput
                  id="proximity"
                  name="proximity"
                  disableUnderline
                  fullWidth
                  value={input.proximity}
                  onChange={handleInputChange}
                />
              </StyledFullInputLabel>
            </StyledFullFormControl>
          </StyledContainer>

          <StyledContainer>
            <StyledFullFormControl>
              <StyledFullInputLabel htmlFor="street1">
                {translation.street_one}
                <StyledInput
                  id="street1"
                  name="street1"
                  disableUnderline
                  fullWidth
                  value={input.street1}
                  onChange={handleInputChange}
                />
              </StyledFullInputLabel>
            </StyledFullFormControl>
          </StyledContainer>

          <StyledContainer>
            <StyledDoubleFormControl>
              <StyledInputLabel htmlFor="street2">
                {translation.street_two}
                <StyledInput
                  id="street2"
                  name="street2"
                  disableUnderline
                  fullWidth
                  value={input.street2}
                  onChange={handleInputChange}
                />
              </StyledInputLabel>
            </StyledDoubleFormControl>
            <StyledDoubleFormControl>
              <StyledInputLabel htmlFor="zipcode">
                {translation.zipcode}
                <StyledInput
                  id="zipcode"
                  name="zipcode"
                  disableUnderline
                  fullWidth
                  value={input.zipcode}
                  onChange={handleInputChange}
                />
              </StyledInputLabel>
            </StyledDoubleFormControl>
          </StyledContainer>

          <StyledContainer>
            <StyledDoubleFormControl>
              <StyledInputLabel htmlFor="city">
                {translation.city}
                <StyledInput
                  id="city"
                  name="city"
                  disableUnderline
                  fullWidth
                  value={input.city}
                  onChange={handleInputChange}
                />
              </StyledInputLabel>
            </StyledDoubleFormControl>
            <StyledDoubleFormControl>
              <StyledInputLabel htmlFor="state">
                {translation.state}
                <StyledInput
                  id="state"
                  name="state"
                  disableUnderline
                  fullWidth
                  value={input.state}
                  onChange={handleInputChange}
                />
              </StyledInputLabel>
            </StyledDoubleFormControl>
          </StyledContainer>
        </>
      )}

      <StyledDialogActions>
        <ActionButton width="110px" height="50px" onClick={() => closeModal()}>
          {translation.Cancel}
        </ActionButton>
        <ActionButton width="110px" height="50px" onClick={() => confirmCreate()}>
          {translation.Ok}
        </ActionButton>
      </StyledDialogActions>
    </UserWrapper>
  )
}
