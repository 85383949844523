import { createReduxModule } from 'hooks-for-redux'
import { getAllChildOrgs, createChildOrgRelationship, deleteChildOrgRelationship } from './api'
import { ChildOrg, IdToIdList } from 'lib/types'
import { invertIdToIdList } from 'lib/utils/common'
const { each } = require('art-comprehensions')
import * as OrgsState from './Orgs'

export interface ChildOrgsMapState {
  childOrgMap: IdToIdList
  parentOrgMap: IdToIdList
  loading: boolean
  error?: any
}

const initialState: ChildOrgsMapState = {
  childOrgMap: {},
  parentOrgMap: {},
  loading: false,
}

export const [use, { setChildOrgMap, setLoading, clearLoading, setError }, store] = createReduxModule(
  'childOrgMap',
  initialState,
  {
    setChildOrgMap: (state: ChildOrgsMapState, childOrgMap: IdToIdList) => ({
      ...state,
      childOrgMap,
      parentOrgMap: invertIdToIdList(childOrgMap),
    }),

    setLoading: (state: ChildOrgsMapState) => ({
      ...state,
      loading: true,
      error: null,
    }),
    clearLoading: (state: ChildOrgsMapState) => ({ ...state, loading: false }),
    setError: (state: ChildOrgsMapState, error: any) => ({ ...state, error }),
  },
)

export const useChildOrgMap = () => {
  return use(({ childOrgMap }) => childOrgMap)
}
export const useParentOrgMap = () => {
  return use(({ parentOrgMap }) => parentOrgMap)
}

// @ts-ignore
const convertChildOrgsToMap = (allChildOrgs: ChildOrg[]) => {
  const childOrgsMap: IdToIdList = {}
  // @ts-ignore
  each(allChildOrgs, childOrg => {
    const { parentOrgId, childOrgId } = childOrg
    // @ts-ignore
    if (!childOrgsMap[parentOrgId]) childOrgsMap[parentOrgId] = []
    childOrgsMap[parentOrgId].push(childOrgId)
  })
  return childOrgsMap
}

export const create = (parentOrgId: string, childOrgId: string) =>
  createChildOrgRelationship(parentOrgId, childOrgId).then(reload, setError)

export const deleteRelationship = (parentOrgId: string, childOrgId: string) =>
  deleteChildOrgRelationship(parentOrgId, childOrgId).then(reload, setError)

export const reload = () =>
  Promise.resolve()
    .then(setLoading)
    .then(getAllChildOrgs)
    .then(convertChildOrgsToMap)
    .then(setChildOrgMap)
    .then(clearLoading, setError)

setTimeout(() => {
  OrgsState.store.subscribe(() => {
    reload()
  })
}, 1)
