/** @jsxImportSource @emotion/react */
import { AuthProvider, TITLE_COLOR } from 'lib/constants'
import { ConditionalRender } from 'components/widgets'
import { trans, TranslationGroup, TranslationKey } from 'lib/types'

const headerStyles = {
  fontWeight: 700,
  fontSize: '2.4rem',
  color: TITLE_COLOR,
  margin: '0 0 21px 0',
}

export function SignInHeader({
  children,
  provider,
}: {
  children?: string | React.ReactNode
  provider?: AuthProvider | null
}) {
  const translation: TranslationGroup = trans.group(TranslationKey.AUTH)
  let childElement = children
  switch (provider) {
    case AuthProvider.UNKNOWN:
      childElement = translation.enter_email
      break
    case null:
      childElement = translation.choose_provider
  }
  return (
    <ConditionalRender condition={!!childElement}>
      <h1 data-testid={`signInHeader`} css={headerStyles}>
        {childElement}
      </h1>
    </ConditionalRender>
  )
}
