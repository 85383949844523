/** @jsxImportSource @emotion/react */
import { useEffect } from 'react'
import { isAndroid, isIOS } from 'react-device-detect'
import { Button } from '@material-ui/core'
import {
  APP_STORE_ANDROID,
  APP_STORE_ANDROID_DEV,
  APP_STORE_IOS,
  APP_STORE_IOS_DEV,
  AuthTokenParam,
  EMAIL_PARAM,
  JWT_TOKEN,
  RESET_TOKEN_PARAM,
  SEGMENT_CONFIRM_EMAIL,
  SEGMENT_RESET_PASSWORD,
} from 'lib/constants'
import AppleAppStore from 'static/images/app_store/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import GooglePlayStore from 'static/images/app_store/google-play-badge.svg'
import { authWrapperStyles, justifyContent } from 'styles'
import { ConditionalRender } from 'components/widgets'
import { Redirect, useParams } from 'react-router-dom'
import { trans, TranslationGroup, TranslationKey } from 'lib/types'

const wrapperStyles = {
  ...authWrapperStyles,
  ...{ alignItems: 'center', display: 'flex', ...justifyContent('center'), padding: `0 5vw`, width: `45vw` },
}

const buttonWrapperStyles = {
  display: 'flex',
  ...justifyContent('center'),
  height: '60px',
}

const appStoreButtonStyles = {
  background: `url(${AppleAppStore}) no-repeat center center / cover`,
  display: 'inline-block',
  width: '200px',
  height: '58px',
  padding: '0 12px',
}

function getResetToken() {
  const search = window.location.search
  const params = new URLSearchParams(search)
  return params.get(RESET_TOKEN_PARAM) || undefined
}

function getEmailAddress() {
  const params = new URLSearchParams(window.location.search)
  return params.get(EMAIL_PARAM) || ''
}

interface MobileParams {
  section: string
}

function getAppLink(platform: string) {
  const env = window._env_ ? window._env_ : { REACT_APP_ENV: 'prod' }
  const server = env.REACT_APP_ENV ? env.REACT_APP_ENV.toLowerCase() : 'prod'
  const isDev = ['local', 'dev', 'stage', 'staging'].includes(server)
  if (platform === 'apple') return isDev ? APP_STORE_IOS_DEV : APP_STORE_IOS
  return isDev ? APP_STORE_ANDROID_DEV : APP_STORE_ANDROID
}

function MobileApp() {
  const translation: TranslationGroup = trans.group(TranslationKey.MOBILE_APP)
  const resetToken = getResetToken()
  const email = getEmailAddress()
  const { section } = useParams<MobileParams>()
  const appleAppStoreURL = getAppLink('apple')
  const googlePlayStoreURL = getAppLink('google')

  let param
  switch (section) {
    case SEGMENT_RESET_PASSWORD:
      param = AuthTokenParam.RESET_TOKEN
      break
    case SEGMENT_CONFIRM_EMAIL:
      param = AuthTokenParam.CONFIRM_TOKEN
      break
  }
  let resetURL = `/?${param}=${resetToken}`
  if (email) resetURL += `&email=${encodeURIComponent(email)}`

  useEffect(() => {
    if (isAndroid) {
      window.location.href = googlePlayStoreURL
    } else if (isIOS) {
      window.location.href = appleAppStoreURL
    }
  }, [])

  const doLoginFlow = !!section && !!resetToken && !!param
  if (doLoginFlow) localStorage.removeItem(JWT_TOKEN)
  return (
    <>
      <ConditionalRender condition={doLoginFlow}>
        <Redirect to={resetURL} />
      </ConditionalRender>
      <ConditionalRender condition={!resetToken}>
        <div css={wrapperStyles}>
          <div css={buttonWrapperStyles}>
            <Button
              aria-label={translation.apple_app_store}
              variant={`contained`}
              css={appStoreButtonStyles}
              onClick={() => (window.location.href = appleAppStoreURL)}
            ></Button>
            <Button
              aria-label={translation.google_play_store}
              variant={`contained`}
              css={{ ...appStoreButtonStyles, ...{ backgroundImage: `url(${GooglePlayStore})`, marginLeft: '12px' } }}
              onClick={() => (window.location.href = googlePlayStoreURL)}
            ></Button>
          </div>
        </div>
      </ConditionalRender>
    </>
  )
}

export default MobileApp
