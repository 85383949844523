/** @jsxImportSource @emotion/react */
import { shallowEqual } from 'react-redux'
import { Button, Chip, FormControl, MenuItem, Select } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { StyledSubHeader } from 'components/widgets'
import { BORDER1_COLOR, getAppColors, GRAY3_COLOR } from 'lib/constants'
import { Organization, trans, TranslationGroup, TranslationKey } from 'lib/types'
import _ from 'lodash'
import { ChildOrgMap, Orgs } from 'models'
import { useState } from 'react'

const managePropertiesStyles = {
  borderRadius: '10px',
  backgroundColor: GRAY3_COLOR,
}
const tagsContainerStyles = {
  padding: '15px',
  borderBottom: `1px solid ${BORDER1_COLOR}`,
  display: 'flex',
  flexFlow: 'row wrap',
}
const addPropertyContainerStyles = {
  padding: '15px',
  display: 'flex',
}

export const GroupPropertyAssignment = ({ selectedGroup }: { selectedGroup: Organization }) => {
  const translation: TranslationGroup = trans.group(TranslationKey.ADMIN)

  const childOrgMap = ChildOrgMap.use(({ childOrgMap }) => childOrgMap)
  const { orgs, orgsById } = Orgs.use(({ orgs, orgsById }) => {
    return { orgs, orgsById }
  }, shallowEqual)
  const [dropdownSelectedPropertyId, setDropdownSelectedPropertyId] = useState<string | null>(null)
  const allProperties = orgs.filter(Orgs.isProperty)
  const assignedPropertyIds = childOrgMap[selectedGroup.id]
  const assignedProperties = !!assignedPropertyIds
    ? assignedPropertyIds?.map(id => orgsById[id]).filter(Orgs.isProperty)
    : []

  const assignableProperties = _.xorBy(allProperties, assignedProperties, org => org.id)

  function handleAddProperty() {
    !!dropdownSelectedPropertyId && ChildOrgMap.create(selectedGroup.id, dropdownSelectedPropertyId).then(Orgs.reload)
  }

  function removeProperty(propertyId: string) {
    ChildOrgMap.deleteRelationship(selectedGroup.id, propertyId)
  }

  return (
    <>
      <StyledSubHeader css={{ label: 'PropertyPropertyAssignment-title' }}>
        {translation.associated_properties}
      </StyledSubHeader>
      <div css={managePropertiesStyles}>
        <div css={tagsContainerStyles}>
          {assignedProperties?.length > 0 ? (
            assignedProperties.map(property => (
              <Chip
                key={property.id}
                css={{
                  marginRight: '10px',
                }}
                label={property.name}
                onDelete={() => removeProperty(property.id)}
                deleteIcon={<Delete />}
                variant="outlined"
              />
            ))
          ) : (
            <div css={{ margin: 'auto' }}>{translation.no_properties_assigned}</div>
          )}
        </div>
        <div css={addPropertyContainerStyles}>
          <FormControl css={{ width: '200px' }}>
            <Select label="Select property" onChange={e => setDropdownSelectedPropertyId(`${e.target.value}`)}>
              {assignableProperties.map(property => (
                <MenuItem key={property.id} value={property.id}>
                  {property.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            css={{
              color: 'white',
              backgroundColor: getAppColors('--color-brand'),
              marginLeft: '12px',
              fontFamily: 'Montserrat',
            }}
            onClick={handleAddProperty} // TODO: add group to property
          >
            {translation.add_this_property_to_group}
          </Button>
        </div>
      </div>
    </>
  )
}
