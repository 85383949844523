/** @jsxImportSource @emotion/react */
import { useState, ChangeEvent } from 'react'
import styled from '@emotion/styled'
import { Button, FormControlLabel, IconButton, Switch, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { ConfirmationDialog } from 'components/partials'
import { ConditionalRender, StyledFormLabel, StyledInput } from 'components/widgets'
import { INTEGRATIONS, INTEGRATION_MAP, PRIMARY_COLOR } from 'lib/constants'
import { ExternalIntegration, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { ExternalIntegrations, NavState } from 'models'
import { rowContainerStyles, titleTextStyles } from 'styles'

const DeleteButton = styled(IconButton)({
  marginTop: '0 !important',
  paddingTop: 0,
  paddingBottom: 0,
})

const FormContainer = styled.div({
  position: 'relative',
  display: 'flex',
  padding: '0 2.4rem',
  marginBottom: '5.0rem',
  flexDirection: 'column',
  flex: '0 0 33.3333%',
  borderRight: '1px solid #E3E3E3',
  boxSizing: 'border-box',
})

const SpaceBetweenContainer = styled.div({
  justifyContent: 'space-between',
  display: 'flex',
  flex: 1,
})

const StyledButton = styled(Button)({
  color: PRIMARY_COLOR,
  padding: '8px 20px',
  border: `2px solid ${PRIMARY_COLOR}`,
  boxSizing: 'border-box',
  fontFamily: 'Montserrat',
  boxShadow: '0px 4px 8px rgba(20, 48, 72, 0.1)',
  fontSize: '1.0rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.2rem',
  letterSpacing: '0.1rem',
  textAlign: 'left',
  marginRight: '20px',
  cursor: 'pointer',
  borderRadius: '2px',
})

const SubHeader = styled.h3({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  letterSpacing: '0.1rem',
  lineHeight: '1.5rem',
  color: PRIMARY_COLOR,
  textTransform: 'uppercase',
})

const SwitchLabel = styled.div({
  fontSize: '1.0rem',
})

const TitleRowContainer = styled.div({
  marginBottom: '5.0rem',
})

const Toggle = styled.div({
  flex: 1,
})

const Toggles = styled.div({
  display: 'flex',
})

const ToggleSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: 'rgba(48, 188, 176, 1)',
    },
    '&$checked + $track': {
      backgroundColor: 'rgba(48, 188, 176, 0.33)',
    },
  },
  checked: {},
  track: {},
})(Switch)

export function ExternalIntegrationForm({ model, index }: { model: ExternalIntegration; index: number }) {
  const translation: TranslationGroup = trans.merge(TranslationKey.SETTINGS)
  const selectedOrgId = NavState.use(({ selectedOrgId }) => selectedOrgId)
  const { name, props } = model
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [integration, setIntegration] = useState<ExternalIntegration>({
    ...model,
    props: {
      ...props,
      isEnabled: props.isEnabled || false,
    },
  })

  function handleDelete(id: string | undefined) {
    if (id) {
      ExternalIntegrations.deleteIntegration(id)
    }
  }

  const handleEnabledChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIntegration({ ...integration, name, props: { ...integration.props, isEnabled: event.target.checked } })
  }

  const handleFormSubmit = (data: ExternalIntegration) => {
    ExternalIntegrations.doUpsertExternalIntegration({
      ...data,
      orgId: selectedOrgId,
    })
  }

  const delTip = `${translation.remove_integration} ${INTEGRATION_MAP[integration.name as INTEGRATIONS]}`

  return (
    <>
      <ConfirmationDialog
        title={translation.delete_integration}
        description={translation.confirm_delete_integration}
        open={openConfirmModal}
        onConfirm={() => {
          setOpenConfirmModal(false)
          handleDelete(integration.id)
        }}
        handleClose={() => setOpenConfirmModal(false)}
      />
      <FormContainer>
        <TitleRowContainer>
          <SpaceBetweenContainer>
            <h3 css={titleTextStyles}>{INTEGRATION_MAP[integration.name as INTEGRATIONS]}</h3>
            <ConditionalRender condition={integration.id}>
              <Tooltip title={delTip}>
                <DeleteButton
                  edge={`end`}
                  style={{ backgroundColor: 'transparent' }}
                  name={delTip}
                  aria-label={delTip}
                  onClick={() => setOpenConfirmModal(true)}
                >
                  <CloseIcon />
                </DeleteButton>
              </Tooltip>
            </ConditionalRender>
          </SpaceBetweenContainer>
        </TitleRowContainer>
        <div css={rowContainerStyles}>
          <Toggles>
            <Toggle>
              <SubHeader>{translation.activate}</SubHeader>
              <FormControlLabel
                control={
                  <ToggleSwitch checked={integration.props.isEnabled} onChange={handleEnabledChange} name="activate" />
                }
                label={
                  <SwitchLabel>{integration.props.isEnabled ? translation.enabled : translation.disabled}</SwitchLabel>
                }
              />
            </Toggle>
          </Toggles>
        </div>
        <div css={rowContainerStyles}>
          <StyledFormLabel>
            {translation.apiurl}
            <StyledInput
              id={`apiUrl_${index}`}
              required
              disableUnderline
              fullWidth
              placeholder={translation.enter_url}
              value={integration.props.apiUrl || ''}
              onChange={e =>
                setIntegration({
                  ...integration,
                  props: { ...integration.props, apiUrl: e.target.value },
                })
              }
              inputProps={{ maxLength: 255 }}
            />
          </StyledFormLabel>
        </div>
        <ConditionalRender condition={integration.name === INTEGRATIONS.KNOWCROSS}>
          <div css={rowContainerStyles}>
            <StyledFormLabel>
              {translation.apikey}
              <StyledInput
                id={`apiKey_${index}`}
                required
                disableUnderline
                fullWidth
                placeholder={translation.enter_apikey}
                value={integration.props.apiKey || ''}
                onChange={e =>
                  setIntegration({
                    ...integration,
                    props: { ...integration.props, apiKey: e.target.value },
                  })
                }
                inputProps={{ maxLength: 255 }}
              />
            </StyledFormLabel>
          </div>
          <div css={rowContainerStyles}>
            <StyledFormLabel>
              {translation.property_code}
              <StyledInput
                id={`propertyCode_${index}`}
                disableUnderline
                fullWidth
                placeholder={translation.enter_property_code}
                value={integration.props.propertyCode || ''}
                onChange={e =>
                  setIntegration({
                    ...integration,
                    props: { ...integration.props, propertyCode: e.target.value },
                  })
                }
                inputProps={{ maxLength: 255 }}
              />
            </StyledFormLabel>
          </div>
        </ConditionalRender>
        <ConditionalRender
          condition={integration.name === INTEGRATIONS.HOTSOS || integration.name === INTEGRATIONS.PINEAPPLE}
        >
          <div css={rowContainerStyles}>
            <StyledFormLabel>
              {translation.username}
              <StyledInput
                id={`username_${index}`}
                disableUnderline
                fullWidth
                placeholder={translation.enter_username}
                value={integration.props.username || ''}
                onChange={e =>
                  setIntegration({
                    ...integration,
                    props: { ...integration.props, username: e.target.value },
                  })
                }
                inputProps={{ maxLength: 255 }}
              />
            </StyledFormLabel>
          </div>
          <div css={rowContainerStyles}>
            <StyledFormLabel>
              {translation.password}
              <StyledInput
                id={`password_${index}`}
                disableUnderline
                fullWidth
                type="password"
                placeholder={translation.enter_password}
                value={integration.props.password || ''}
                onChange={e =>
                  setIntegration({
                    ...integration,
                    props: { ...integration.props, password: e.target.value },
                  })
                }
                inputProps={{ maxLength: 255 }}
              />
            </StyledFormLabel>
          </div>
        </ConditionalRender>
        <div css={rowContainerStyles}>
          <StyledButton onClick={() => setIntegration(model)}>{translation.cancel}</StyledButton>
          <StyledButton onClick={() => handleFormSubmit(integration)}>{translation.apply_changes}</StyledButton>
        </div>
      </FormContainer>
    </>
  )
}
