import { PageWrapper, ViewWrapper } from 'components/widgets'
import { trans, TranslationGroup } from 'lib/types'
import { isAdminAnywhere, isSuperAdmin } from 'lib/utils/auth'

export const AdminAnywhereRequired = (props: any) => {
  const translation: TranslationGroup = trans.admin()
  return isSuperAdmin() || isAdminAnywhere() ? (
    props.children
  ) : (
    <PageWrapper>
      <ViewWrapper>
        <>{translation.admin_required}</>
      </ViewWrapper>
    </PageWrapper>
  )
}
