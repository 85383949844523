/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import * as CSS from 'csstype'
import _ from 'lodash'
import { BATTERY_LEVELS } from 'lib/constants'
import { UpdateFirmwareProps } from 'lib/types'
import { ReactComponent as CriticalBattery } from 'static/images/beacons/Critical_Battery.svg'
import { ReactComponent as FullBattery } from 'static/images/beacons/Full_Battery.svg'
import { ReactComponent as LowBattery } from 'static/images/beacons/Low_Battery.svg'
import { ReactComponent as MediumBattery } from 'static/images/beacons/Medium_Battery.svg'
import { ReactComponent as Firmware } from 'static/images/Firmware.svg'
import { ReactComponent as FirmwareUpdate } from 'static/images/Firmware_Update.svg'

export const batteryWrapper = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-evenly',
}

export function getBatteryIcon(batteryLevel: number) {
  if (_.isNil(batteryLevel)) return
  if (batteryLevel <= BATTERY_LEVELS.CRITICAL)
    return <CriticalBattery style={{ color: '#F44336' }} />
  if (batteryLevel <= BATTERY_LEVELS.LOW) return <LowBattery />
  if (batteryLevel < BATTERY_LEVELS.MEDIUM) return <MediumBattery />
  return <FullBattery />
}

export function FirmwareIcon({
  iconStyle,
  update,
}: {
  iconStyle: CSS.Properties
  update?: UpdateFirmwareProps
}) {
  return _.isEmpty(update) ? (
    // @ts-ignore
    <Firmware css={iconStyle} />
  ) : (
    // @ts-ignore
    <FirmwareUpdate css={iconStyle} />
  )
}
