import { TimeStamps } from './common'

export interface Address {
  city: string
  state: string
  street1: string
  street2: string
  zipcode: string
}

interface Firmware {
  [key: string]: string
}

interface OrgProps {
  address: Address
  image: string
  legacy?: any
  logoUrl: string
  phone: string
  proximityUuid: {
    name: string
    value: string
  } | null
  firmware?: Firmware
}

interface OrgSummary {
  alertSources: {
    critical: number
    disabled: number
    healthy: number
    high_battery: number
    late: number
    low_battery: number
    med_battery: number
    need_update: number
    total: number
  }
  alerts: {
    critical: number
    moderate: number
    resolved: number
    responseTime: number
    lastTest: number
  }
  beacons: {
    critical: number
    disabled: number
    healthy: number
    high_battery: number
    late: number
    low_battery: number
    med_battery: number
    need_update: number
    total: number
  }
  responders: {
    disabled: number
    push_disabled: number
    push_enabled: number
    total: number
  }
}

export enum OrgType {
  Group = 'group',
  Property = 'property',
}

export interface Organization extends TimeStamps {
  id: string
  name: string
  lat?: number
  long?: number
  geofencingEnabled?: boolean
  responderRadiusInMiles?: number
  orgType: OrgType
  parents?: string[]
  props: OrgProps
  summary?: OrgSummary
}

// export interface OrganizationResponse {
//   data?: Organization
//   included?: IdTypePair[]
//   links?: Links
// }

// export interface OrganizationListResponse {
//   data?: Organization[]
//   links?: Links
//   meta?: Meta
// }

export type ChildOrg = {
  childOrgId: string
  parentOrgId: string
} & TimeStamps
