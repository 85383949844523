/** @jsxImportSource @emotion/react */
import { Device, RegisterStatus, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { useState } from 'react'
import { DetailSliderContent, EditElementModal, ConfirmationDialog } from 'components/partials'
import { getBatteryDisplay, getHealthIcon } from 'components/partial-pages/ButtonsDevicesPage'
import useModal from 'lib/hooks/useModal'
import { ActionButton } from 'components/widgets'
import { ButtonDetail, DetailRow, FirmwareDetail, WanDetail } from './utils'
import { getHealth, isWan, toDate } from 'lib/utils'
import { format } from 'date-fns'
import { Devices, Orgs } from 'models'
import { isAdmin, isInstaller } from 'lib/utils/auth'
import { actionRowStyles, detailStyles } from 'styles'

export const DeviceDetailDrawer = ({ device, close }: { device: Device; close: Function }) => {
  const translation: TranslationGroup = trans.merge(TranslationKey.DEVICES_DRAWER)
  const healthLabels: TranslationGroup = trans.merge(TranslationKey.HEALTH_LABELS)

  const { Modal: ModalEdit, openModal: openModalEdit, closeModal: closeModalEdit } = useModal(translation.Edit_Device)
  const [openUnregisterModal, setOpenUnregisterModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const deviceOrgId = device.orgId
  const orgsById = Orgs.use(({ orgsById }) => orgsById)
  const org = orgsById[deviceOrgId]

  function handleUnregister() {
    Devices.unregisterDevice(device)
    close()
  }

  function handleDelete() {
    Devices.disableDevice(device)
    close()
  }

  const showUnregister =
    (isAdmin(deviceOrgId) || isInstaller(deviceOrgId)) && device.registration === RegisterStatus.REGISTERED

  const rightStyles = {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.0rem',
  }

  const padRightStyles = {
    paddingRight: '10px',
  }

  const batteryWrapperStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: '64px',
  }
  const health = getHealth(device)
  const healthRow = (
    <div css={rightStyles}>
      <div css={padRightStyles}>{getHealthIcon(health)}</div>
      <div>{healthLabels[health]}</div>
    </div>
  )

  const showFirmware = device.props?.version && device.deviceType !== 'android' && device.deviceType !== 'ios'
  const preciseDate = device.updatedAt ? format(toDate(device.updatedAt), 'M/d/yyyy pp') : ''
  const pushEnabled = device.pushEnabled ? translation.yes : translation.no
  return (
    <>
      <DetailSliderContent
        title={(device.responder ? translation.Responder : translation.Device) + ' ' + translation.Details}
        close={close}
      >
        <ul css={detailStyles}>
          {org && <DetailRow title={translation.Property} content={org.name} />}
          <DetailRow title={translation.Property_ID} content={device.orgId} />
          {/* **REDUNDANT** <DetailRow title={translation.Health} content={healthRow} /> */}
          <DetailRow title={translation.Registration_Status} content={translation[device.registration]} />
          <DetailRow title={translation.Type} content={device.deviceType} />
          <DetailRow title={translation.Name} content={device.name} />
          {isWan(device.deviceType) && (
            <>
              {Number.isInteger(device.batteryPercent) && (
                <DetailRow
                  title={translation.Battery}
                  content={
                    Number.isInteger(device.batteryPercent) ? (
                      <div css={batteryWrapperStyles}>{getBatteryDisplay(device)}</div>
                    ) : (
                      ''
                    )
                  }
                />
              )}
              <WanDetail data={device.props} />
            </>
          )}
          {showFirmware && (
            <FirmwareDetail
              fallback={''}
              title={translation.Firmware}
              update={device.updateFirmware}
              version={device.props?.version}
            />
          )}
          {device.responder && <DetailRow title={translation.Push_Enabled} content={pushEnabled} />}
          {device.props?.button && <ButtonDetail data={device.props?.button} update={device.updateFirmware} />}
          <DetailRow title={translation.Last_Check_In} content={preciseDate} />
          <DetailRow title={isWan(device.deviceType) ? translation.IMEI : translation.Device_ID} content={device.id} />
        </ul>
        <div css={actionRowStyles}>
          <ActionButton onClick={openModalEdit}>{translation.Edit_Device_Name}</ActionButton>
          {showUnregister && (
            <ActionButton onClick={() => setOpenUnregisterModal(true)}>{translation.Unregister_Device}</ActionButton>
          )}
        </div>
        <ModalEdit>
          <EditElementModal
            title={translation.Edit_Device_Name}
            element={device}
            fieldName={'name'}
            close={closeModalEdit}
            action={Devices.update}
          />
        </ModalEdit>
        <ConfirmationDialog
          title={translation.Unregister_Device}
          description={translation.confirm_unregister}
          open={openUnregisterModal}
          onConfirm={() => {
            setOpenUnregisterModal(false)
            handleUnregister()
          }}
          handleClose={() => setOpenUnregisterModal(false)}
        />
        <ConfirmationDialog
          title={translation.Delete_Device}
          description={translation.confirm_delete}
          open={openDeleteModal}
          onConfirm={() => {
            setOpenDeleteModal(false)
            handleDelete()
          }}
          handleClose={() => setOpenDeleteModal(false)}
        />
      </DetailSliderContent>
    </>
  )
}
