import styled from '@emotion/styled/macro'
import { PRIMARY_COLOR } from 'lib/constants'
import { Oval, RotatingLines } from 'react-loader-spinner'

const FullContainer = styled.section({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const StyledBox = styled.div({
  padding: '12px',
  display: 'inline-block',
})

export enum LoaderType {
  OVAL = 'oval',
  ROTATING_LINES = 'rotating_lines',
}
export interface LoaderProps {
  width?: string
  height?: string
  type?: LoaderType
}

export const Loader = ({ width, height, type }: LoaderProps) => {
  return (
    <>
      {type !== LoaderType.ROTATING_LINES && (
        <FullContainer>
          <StyledBox>
            <Oval color="#BCF8F2" secondaryColor="#BCF8F2" width={width ? width : 100} height={height ? height : 100} />
          </StyledBox>
        </FullContainer>
      )}
      {type === LoaderType.ROTATING_LINES && <RotatingLines width={width} strokeColor={PRIMARY_COLOR} />}
    </>
  )
}
