import { createReduxModule } from 'hooks-for-redux'
import { AlertSession } from 'lib/types'
import { uniqueId } from 'lodash'
import axios, { AxiosError } from 'axios'

const uniquePopUpNotificationId = (): string => `pop-up-notification-${uniqueId()}`

export interface PopUpNotification {
  id?: string // auto assigned; don't assign manually
  type?: string // "info", "error", "success", "warning"
  content: string // text message to display
  onClick?: any // action to take when on click
  alertSession?: AlertSession // provide an alertSession as an alternative to onClick; will include actions appropriate for alertSessions alerts
}
export type PopUpNotificationsState = PopUpNotification[]

const DELAY = 10000
const initialState: PopUpNotificationsState = []

export const [use, { reset, close, fire }, store] = createReduxModule('pop-up-notifications', initialState, {
  reset: () => initialState,
  close: (popupNotifications: PopUpNotificationsState, id: string) =>
    popupNotifications.filter(({ id: _id }) => id !== _id),

  fire: (popupNotifications: PopUpNotificationsState, popupNotification: PopUpNotification) => {
    const id = uniquePopUpNotificationId()
    setTimeout(() => close(id), DELAY)
    return [...popupNotifications, { id, ...popupNotification }]
  },
})

export const tryWithSuccessAndErrorNotifications = (
  promiseOrFunction: any,
  successMessage: string,
  optionalErrorMessage?: string,
) =>
  tryWithErrorNotifications(promiseOrFunction, optionalErrorMessage).then(() =>
    fireSuccess({ content: successMessage }),
  )

export const tryWithErrorNotifications = (promiseOrFunction: any, optionalErrorMessage?: string) =>
  Promise.resolve()
    .then(typeof promiseOrFunction === 'function' ? promiseOrFunction : () => promiseOrFunction)
    .catch(error => {
      const errors = error.response?.data?.errors
      const content =
        optionalErrorMessage || (errors && errors[0]?.title) || error.message || 'Request did not succeed.'
      fireError({ content })
      throw error
    })

export const fireInfo = (popupNotification: PopUpNotification) => fire({ ...popupNotification, type: 'info' })
export const fireSuccess = (popupNotification: PopUpNotification) => fire({ ...popupNotification, type: 'success' })
export const fireWarning = (popupNotification: PopUpNotification) => fire({ ...popupNotification, type: 'warning' })
export const fireError = (popupNotification: PopUpNotification) => fire({ ...popupNotification, type: 'error' })

export const fireErrorObject = (error: Error | AxiosError) => {
  let errorDetail = undefined
  if (axios.isAxiosError(error)) {
    const errors = error.response?.data?.errors
    errorDetail = errors ? errors[0] : undefined
  }
  fire({ content: errorDetail?.detail || errorDetail?.title || error.message, type: 'error' })
}
