import styled from '@emotion/styled'
import { Input, InputLabel } from '@material-ui/core'
import { ERROR_COLOR, GRAY3_COLOR } from 'lib/constants'

export const StyledInput = styled(Input)({
  font: '1.2rem/1 Montserrat',
  background: GRAY3_COLOR,
  borderRadius: '10px',
  marginTop: '12px',
  padding: '21px 20px',
  '.MuiInputBase-input::placeholder': {
    color: 'black !important',
    fontSize: '1.2rem',
  },
})

export const StyledInputErrorLabel = styled(InputLabel)({
  color: ERROR_COLOR,
  font: 'bold 1.2rem/1.5 Montserrat',
  minHeight: '15px',
  letterSpacing: '0.1rem',
  textAlign: 'left',
  margin: '5px',
})
