import { AlertTargetAttributes } from './alertTarget'
import { HealthCode } from './common'
import { Device } from './devices'
import { User } from './user'

export interface LiveResponder extends User, Device {}

export enum ResponderType {
  ALERT_SOURCE = 'alert_source',
  USER = 'user',
}

export interface ResponderAttributes {
  batteryPercent?: number
  email?: string
  health: HealthCode
  healthWarnings: string[]
  lastCheckedInAt?: string
  name: string
  orgIds: string[]
  pushEnabled?: boolean
  responderType: ResponderType
  targets: AlertTargetAttributes[]
  updatedAt: string
}

export interface Responder {
  id: string
  type: string
  attributes: ResponderAttributes
}
