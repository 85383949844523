/** @jsxImportSource @emotion/react */
import { Check, Close } from '@material-ui/icons'
import { BRAND_COLOR, COLOR_INVALID, SECONDARY_COLOR } from 'lib/constants'

const containerStyles = {
  fontSize: '1.2rem',
  display: 'flex',
  lineHeight: '2em',
  color: SECONDARY_COLOR,
}

const validStyle = {
  paddingRight: '0.4rem',
  color: BRAND_COLOR,
}

const invalidStyle = {
  paddingRight: '0.4rem',
  color: COLOR_INVALID,
}

interface ValidationListItemProps {
  valid: boolean
  label: string
}

export function PasswordValidationListItem(props: ValidationListItemProps) {
  const icon = props.valid ? <Check css={validStyle} /> : <Close css={invalidStyle} />
  return (
    <div css={containerStyles}>
      {icon}
      {props.label}
    </div>
  )
}
