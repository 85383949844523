import { createReduxModule } from 'hooks-for-redux'
import { useEffect, ReactElement } from 'react'

export interface ModalState {
  title?: string
  modalComponent?: ReactElement
  modalProps?: any,
  isOpen: boolean
}

const initialState: ModalState = {
  isOpen: false
}

export const [use, { open, close, setContent, setTitle}, store] = createReduxModule(
  'modal',
  initialState,
  {
    open: (state: ModalState) => ({...state, isOpen: true}),
    close: (state: ModalState) => ({...state, isOpen: false}),
    setContent: (state: ModalState, content: ReactElement) => ({ ...state, modalComponent: content }),
    setTitle: (state: ModalState, title: string) => ({...state, title})
  },
)

export const useStaticModal = (modalName: string) => {
  const { isOpen, title, modalComponent, modalProps } = use()

  useEffect(() => {
    setTitle(modalName)
  }, [modalName])
  
  return {
    setModalContent: setContent,
    modalComponent, 
    modalProps,
    title, 
    isOpen,
    closeModal: close,
    openModal: open
  }
}

export const setStaticModalContent = (content: React.ReactElement) => {
  setContent(content)
}