import {
  format,
  formatDistance as formatDistanceHelper,
  formatDistanceToNowStrict as formatDistanceToNowHelper,
  fromUnixTime,
  parseISO,
} from 'date-fns'
import { LOCAL_TIME, ISO_8601 } from 'lib/constants/dateFormat'
import enLocale from 'date-fns/locale/en-US'
import esLocale from 'date-fns/locale/es'
import frLocale from 'date-fns/locale/fr'

export function getDateLocale() {
  const langCode = localStorage.getItem('language')
  switch (langCode) {
    case 'fr-CA':
      return frLocale
    case 'es-419':
      return esLocale
    default:
      return enLocale
  }
}

type FormatTime = (time?: string | number | Date, type?: string, placeholder?: string) => undefined | string

/**
 * Converts string to Date if not already a Date (required by date-fns)
 *
 * @param strOrDate ISO date string or Date
 * @returns Date
 */
export const toDate = (strOrDate: string | Date) => (typeof strOrDate === 'string' ? parseISO(strOrDate) : strOrDate)

/**
 * Formats time as a string
 *
 * @param time string date, number unix time, or Date object
 * @param type format type for date-fns
 * @param placeholder if time is null, return placeholder
 * @returns formatted time as a string or undefined if no time or place holder was provided
 */
export const formatTime: FormatTime = (time, type = LOCAL_TIME, placeholder) => {
  // End times are often undefined
  if (!time) return placeholder ? placeholder : undefined
  const dateTime = parseTime(time, false)
  return dateTime ? format(dateTime, type, { locale: getDateLocale() }) : undefined
}

// shorter function for the often used LocalTime

export function formatLocalTime(time: string | number | Date, placeholder?: string): string
export function formatLocalTime(time: undefined | null, placeholder: string): string
export function formatLocalTime(time?: string | number | Date, placeholder?: string): string | undefined
export function formatLocalTime(time?: any, placeholder?: any): any {
  return formatTime(time, LOCAL_TIME, placeholder)
}

/**
 * Parses time to a Date object
 *
 * @param time string date, number unix time, or Date object
 * @returns Date or undefined
 */
export function parseTime(time: undefined, fallback: false | undefined): Date
export function parseTime(time?: string | number | Date, fallback?: true): Date
export function parseTime(time?: string | number | Date, fallback?: false): Date | undefined
export function parseTime(time?: any, fallback?: boolean): any {
  if (!time && fallback !== false) return new Date()
  return typeof time === 'number' ? fromUnixTime(time) : typeof time === 'string' ? new Date(time) : time
}

/**
 * Wrapper around date-fns word formatDistanceFromNow
 * @param time
 */

export const formatDistanceToNow = (time?: string | number | Date, addSuffix?: boolean): string => {
  return formatDistanceToNowHelper(parseTime(time), { addSuffix: addSuffix || true, locale: getDateLocale() })
}

/**
 * Wrapper around date-fns word formatDistance
 * @param time
 */
export const formatDistance = (time: number, addSuffix?: boolean): string => {
  const now = new Date().getTime()
  return formatDistanceHelper(now - time, now, { addSuffix: addSuffix || true, locale: getDateLocale() })
}

export const formatToISO = (time?: string | number | Date): string => {
  return format(parseTime(time), ISO_8601, {
    locale: frLocale,
  })
}
