import { shallowEqual } from 'react-redux'
import { memo, useEffect, useState } from 'react'
import _ from 'lodash'
import { ALL_ORGS_SELECTED, DevicesColumnKeys } from 'lib/constants'
import {
  ActionButton,
  AddDeviceQRC,
  ViewWrapper,
  PageWrapper,
  ViewSpacerWrapper,
  StyledHeader,
} from 'components/widgets'
import { Devices, NavState, DetailsDrawer, Orgs, Modal, PopUpNotifications } from 'models'
import { isAdmin, isInstaller, isSuperAdmin } from 'lib/utils/auth'
import * as utils from './utils'
import { PreConfiguredTableView } from 'components/partials/TableView'
import { SortOrder, TableState, TableToolEvent, Device, TranslationGroup, trans, TranslationKey } from 'lib/types'
import { getDeviceTooltip, invertBinaryEnum } from 'lib/utils/common'
import { DeviceDetailDrawer } from 'components/partials/DrawerDetails/DeviceDetailDrawer'
import { useHistory, useParams } from 'react-router-dom'
import { DEVICES_PATH, RESPONDERS_PATH } from 'lib/constants'
import { useQuery } from 'models/modelUtils'
import { RenderCounter } from 'components/widgets/RenderCounter'

interface AlertSourcesViewProps {
  responders?: boolean
}
interface RouteParams {
  id: string
}

export const AlertSourcesView = memo(({ responders = false }: AlertSourcesViewProps) => {
  const history = useHistory()
  const { id } = useParams<RouteParams>()
  const query = useQuery()
  const showPresence = false //isTruthy(useSearchParams('presence') as string)
  const selectedOrgId = NavState.use(({ selectedOrgId }) => selectedOrgId)
  const selectedOrg = Orgs.useSelectedOrg()
  const common: TranslationGroup = trans.common()

  const {
    setModalContent,
    openModal: openQrcModal,
    closeModal,
  } = Modal.useStaticModal(`${common.register_qr} ${selectedOrg && selectedOrg.name}`)
  const baseRoute = responders ? RESPONDERS_PATH : DEVICES_PATH
  const [filteredItems, setfilteredItems] = useState<Device[]>([])
  const { devices, devicesById, devicesByOrgId, initialLoading, loading } = Devices.use(
    ({ devices, devicesById, devicesByOrgId, initialLoading, loading }) => {
      return { devices, devicesById, devicesByOrgId, initialLoading, loading }
    },
    shallowEqual,
  )
  const [tableState, setTableState] = useState<TableState<string, DevicesColumnKeys>>({
    filterBy: utils.getFilterOptions()[0],
    sortBy: utils.getSortOptions()[0].sortBy,
    searchBy: '',
  })

  useEffect(() => {
    if (id && (!loading || !initialLoading)) {
      const item = devicesById[id]
      if (item) {
        handleRowClick(item)
      } else if (!loading) {
        PopUpNotifications.fireWarning({
          content: `${responders ? common.Responder : common.Device} ${id} ${common.was_not_found}`,
        })
        history.replace(baseRoute)
      }
    }
  }, [id, initialLoading, loading])

  useEffect(() => {
    const page = query.get('page')
    if (page === '1' || loading) return
    query.set('page', '1')
    history.push({
      search: query.toString(),
    })
  }, [tableState])

  useEffect(() => {
    if (!loading || !initialLoading) {
      processItems()
    }
  }, [initialLoading, loading, devices, tableState, query])

  useEffect(() => {
    setModalContent(<AddDeviceQRC responders={responders} closeModal={closeModal} />)
  }, [responders, closeModal, setModalContent])

  function processItems() {
    let selectedDevices = (
      selectedOrgId === ALL_ORGS_SELECTED ? devices : devicesByOrgId[selectedOrgId] || []
    ).filter(device => !!device.responder === !!responders)

    // remove duplicates
    let uniqueIds = new Set(selectedDevices.map(device => device.id))

    selectedDevices = selectedDevices.filter(device => {
      if (uniqueIds.has(device.id)) {
        uniqueIds.delete(device.id)
        return true
      } else {
        return false
      }
    })
    //////////////////

    if (!selectedDevices.length && !filteredItems.length) return
    const opts = utils.getFilterOptions()
    const idx = opts.indexOf(tableState.filterBy)
    const filtered = utils
      .searchDevices(selectedDevices, tableState.searchBy)
      .filter(device => (idx === 0 ? true : utils.getFilterDeviceComparison(device, tableState.filterBy)))
      .sort(utils.getSortDeviceCompareFn(tableState.sortBy))
    setfilteredItems(filtered)
  }

  function handleDrawerClose() {
    history.push({
      pathname: `${baseRoute}`,
      search: query.toString(),
    })
    DetailsDrawer.close()
  }

  function handleRowClick(row: Device) {
    if (row.id != id) {
      history.push({
        pathname: `${baseRoute}/${row.id}`,
        search: query.toString(),
      })
    }
    DetailsDrawer.show({
      drawerComponent: DeviceDetailDrawer,
      drawerProps: {
        device: row,
        close: handleDrawerClose,
      },
    })
  }

  function handleHeaderClick(header: string) {
    setTableState(state => {
      return Object.assign({}, state, {
        sortBy: {
          field: header,
          order: state.sortBy.field === header ? invertBinaryEnum(state.sortBy.order) : SortOrder.Ascending,
        },
      })
    })
  }

  function handleToolEvent(toolEvent: TableToolEvent<string, DevicesColumnKeys>) {
    setTableState(state => ({
      filterBy: toolEvent.filterByRequest || state.filterBy,
      sortBy: toolEvent.sortByRequest || state.sortBy,
      searchBy: _.isNil(toolEvent.searchByRequest) ? state.searchBy : toolEvent.searchByRequest,
    }))
  }

  const isAdminOrInstaller = isAdmin(selectedOrgId) || isInstaller(selectedOrgId) || isSuperAdmin()
  const translation: TranslationGroup = trans.group(TranslationKey.DEVICES_TABLE_VIEW)

  return (
    <>
      <PageWrapper>
        <ViewWrapper>
          <RenderCounter name={'AlertSourcesView'} />
          <ViewSpacerWrapper>
            {responders && <StyledHeader>{common.responder_devices}</StyledHeader>}
            <PreConfiguredTableView
              sortedData={filteredItems}
              tableState={tableState}
              columnConfigs={utils.getColumnConfigs()}
              filterOptions={utils.getFilterOptions()}
              sortOptions={utils.getSortOptions()}
              getRowToolTip={getDeviceTooltip}
              onHeaderClick={handleHeaderClick}
              onRowClick={handleRowClick}
              onToolEvent={handleToolEvent}
              selectedRowId={id}
              loading={loading}
              hiddenColumns={
                responders
                  ? [
                      DevicesColumnKeys.Type,
                      DevicesColumnKeys.Battery,
                      DevicesColumnKeys.Firmware,
                      DevicesColumnKeys.RSSI,
                    ]
                  : undefined
              }
            >
              <ActionButton onClick={() => utils.downloadCsv(filteredItems, responders)}>
                {common.download_csv}
              </ActionButton>
              {isAdminOrInstaller && (
                <ActionButton
                  onClick={() => {
                    if (selectedOrgId === ALL_ORGS_SELECTED) alert(translation.register_select_org)
                    else openQrcModal()
                  }}
                >
                  {common.register} {responders ? common.responders : common.devices}
                </ActionButton>
              )}
            </PreConfiguredTableView>
          </ViewSpacerWrapper>
        </ViewWrapper>
      </PageWrapper>
    </>
  )
})
AlertSourcesView.displayName = 'AlertSourcesView'
