import Pusher from 'pusher-js/with-encryption'
import { JWT_TOKEN, PUSHER_APP_AUTH } from 'lib/constants'
import { reloadAll } from 'models'
import { addResponder, removeResponder, Responder, setRespondersByOrgId } from 'models/LiveResponders'

export const PUSHER_APP_KEY = window._env_?.PUSHER_APP_KEY || process.env?.PUSHER_APP_KEY || ''
export const PUSHER_APP_CLUSTER = window._env_?.PUSHER_APP_CLUSTER || process.env?.PUSHER_APP_CLUSTER || ''

export type UnsubscribeFunction = () => void
export type HandlerFunction = (data: any) => void

enum ConnectionStates {
  INITIALIZED = 'initialized',
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
  UNAVAILABLE = 'unavailable',
  FAILED = 'failed',
  DISCONNECTED = 'disconnected',
}
let hasError: boolean = false

if (!(PUSHER_APP_KEY && PUSHER_APP_CLUSTER))
  console.error('Pusher credentials are not defined. Cannot subscribe to updates.')

const PUSHER_SUB_SUCCESS = 'pusher:subscription_succeeded'
let presenceSuccess: Function
let handleAddPusherMember: Function
let handleRemovePusherMember: Function
export let pusher: Pusher | undefined

const getPusherInstance = () => {
  if (pusher) return pusher
  const token = localStorage.getItem(JWT_TOKEN)
  const p = new Pusher(PUSHER_APP_KEY, {
    cluster: PUSHER_APP_CLUSTER,
    authEndpoint: PUSHER_APP_AUTH,
    auth: {
      headers: { Authorization: `Bearer ${token}` },
    },
  })
  p.connection.bind('error', () => {
    hasError = true
  })
  p.connection.bind('state_change', (states: { previous: any; current: any }) => {
    switch (states.current) {
      case ConnectionStates.FAILED:
      case ConnectionStates.UNAVAILABLE:
        hasError = true
        break
      case ConnectionStates.CONNECTED:
        if (hasError && states.current === ConnectionStates.CONNECTED) {
          hasError = false
          reloadAll()
        }
        break
      case ConnectionStates.DISCONNECTED:
        pusher?.unbind_all()
        pusher = undefined
        break
    }
  })
  pusher = p
  return p
}

interface PusherMember extends Responder {}

interface PusherMembers {
  members: PusherMember[]
}

export const pusherSubscribeAndBind = (
  channelName: string,
  eventName: string,
  handler: HandlerFunction,
  historyHandler?: HandlerFunction,
) => {
  const p = getPusherInstance()
  const channel = p.subscribe(channelName)

  if (channelName.indexOf('presence-org_') === 0) {
    const orgId = channelName.split('presence-org_')[1]
    handleAddPusherMember = (member: PusherMember) => {
      if (member.id) addResponder({ orgId, responder: member })
    }

    handleRemovePusherMember = (member: PusherMember) => {
      if (member.id) removeResponder({ orgId, responder: member })
    }
    presenceSuccess = (members: PusherMembers) => {
      const responders = Object.values(members.members)
      setRespondersByOrgId({ orgId, responders })
    }
    channel.bind('pusher:member_added', handleAddPusherMember)
    channel.bind('pusher:member_removed', handleRemovePusherMember)
    channel.bind(PUSHER_SUB_SUCCESS, presenceSuccess)
  }

  channel.bind(eventName, handler)
  if (historyHandler) channel.bind(PUSHER_SUB_SUCCESS, historyHandler)

  const unsubscribe = () => {
    if (channelName.indexOf('presence-org_') === 0) {
      channel.unbind('pusher:member_added')
      channel.unbind('pusher:member_removed')
      channel.unbind(PUSHER_SUB_SUCCESS, presenceSuccess)
    }
    channel.unbind(eventName, handler)
    if (historyHandler) channel.unbind(PUSHER_SUB_SUCCESS, historyHandler)
    p.unsubscribe(channelName)
  }
  return unsubscribe
}
