/** @jsxImportSource @emotion/react */
import { NumberBubble } from 'components/widgets'
import {
  IdToIdList,
  Organization,
  SortBy,
  SortOrder,
  TableColumnConfig,
  trans,
  TranslationGroup,
  TranslationKey,
  User,
} from 'lib/types'
import { getNameFromUser } from 'lib/utils'
import { createSortOption, invertIfDescending, simpleCompare } from 'lib/utils/common'
import _ from 'lodash'
import { ChildOrgMap } from 'models'
import { fuzzySearch } from '@art-suite/art-fuzzy-search'
import { EditUserRoleButton } from 'components/partials'
import {
  AdminOrgOptionButton,
  ViewGroupsButton as ViewAssociatedOrgsButton,
  UsersCount,
  RemoveUserButton,
} from './Partials'

export enum EmptyColumn {
  Empty = '',
}

export enum UsersColumn {
  Name = 'Name',
  Email = 'Email',
  Roles = 'Roles',
}

export enum PropertiesColumn {
  Name = 'Name',
  Groups = 'Groups',
  Properties = 'Properties',
  Users = 'Users',
}

export function getFilterOptions() {
  const translation: TranslationGroup = trans.merge(TranslationKey.ADMIN)
  return [translation.all_properties, translation.no_groups, translation.no_users]
}

export function searchOrgs(orgs: Organization[], searchBy: string): Organization[] {
  const searchArray = _.flatten(orgs.map(org => [[org.name, org.id]]))

  const searchResults = fuzzySearch(searchBy, searchArray)
  const searchResultsIds = searchResults.map(result => result[1] || null).filter(result => result !== null)

  return orgs.filter(org => searchResultsIds.includes(org.id))
}

export function searchUsers(users: User[], searchBy: string): User[] {
  const searchArray = _.flatten(
    users.map(user => [
      [user.attributes.email, user.id],
      [user.attributes.firstName, user.id],
      [user.attributes.lastName, user.id],
    ]),
  ) as string[][]

  const searchResults = fuzzySearch(searchBy, searchArray)

  const searchResultsIds = searchResults.map(result => result[1] || null).filter(result => result !== null)
  return users.filter(user => searchResultsIds.includes(user.id))
}

//TODO reuse teh functions from users view once it's merged

function simpleSort(a: any, b: any) {
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

function normalizeCompareResult(r: number) {
  return simpleSort(r, 0)
}

export function getSortUserCompareFn(sortBy: SortBy<UsersColumn>): (a: User, b: User) => number {
  switch (sortBy.field) {
    case UsersColumn.Name:
      return (a: User, b: User) =>
        normalizeCompareResult(invertIfDescending(simpleSort(getNameFromUser(a), getNameFromUser(b)), sortBy.order))

    case UsersColumn.Email:
      return (a: User, b: User) =>
        normalizeCompareResult(invertIfDescending(simpleSort(a.attributes.email, b.attributes.email), sortBy.order))

    default:
      console.warn(`Unhandled sort column ${sortBy.field}`)
      return () => 0
  }
}

export function getPropertiesSortOptions() {
  const translation: TranslationGroup = trans.group(TranslationKey.COMMON)
  return _.flatten(
    _.map(PropertiesColumn, c => [
      createSortOption(c, SortOrder.Descending, `${translation[c]} ↓`),
      createSortOption(c, SortOrder.Ascending, `${translation[c]} ↑`),
    ]),
  )
}

export function getColumnConfigs(): TableColumnConfig<PropertiesColumn | EmptyColumn, Organization>[] {
  const common: TranslationGroup = trans.common()
  return [
    {
      header: PropertiesColumn.Name,
      label: common[PropertiesColumn.Name],
      renderFn: org => org.name,
    },
    {
      header: PropertiesColumn.Groups,
      label: common[PropertiesColumn.Groups],
      renderFn: org => (
        <div
          css={{
            whiteSpace: 'nowrap',
            display: 'flex',
          }}
        >
          <NumberBubble value={ChildOrgMap.store.getState().parentOrgMap[org.id]?.length || 0} />
          <ViewAssociatedOrgsButton groupIds={ChildOrgMap.store.getState().parentOrgMap[org.id] || []} />
        </div>
      ),
    },
    {
      header: PropertiesColumn.Properties,
      label: common[PropertiesColumn.Properties],
      renderFn: org => (
        <div
          css={{
            whiteSpace: 'nowrap',
            display: 'flex',
          }}
        >
          <NumberBubble value={ChildOrgMap.store.getState().childOrgMap[org.id]?.length || 0} />
          <ViewAssociatedOrgsButton groupIds={ChildOrgMap.store.getState().childOrgMap[org.id] || []} />
        </div>
      ),
    },
    {
      header: PropertiesColumn.Users,
      label: common[PropertiesColumn.Users],
      renderFn: org => (
        <div
          css={{
            whiteSpace: 'nowrap',
            display: 'flex',
          }}
        >
          <UsersCount org={org} />
        </div>
      ),
    },
    {
      header: EmptyColumn.Empty,
      renderFn: org => <AdminOrgOptionButton org={org} />,
    },
  ]
}

export function filterProperties(
  org: Organization,
  filterBy: string,
  parentOrgMap: IdToIdList,
  orgUsersMap: IdToIdList,
) {
  const opts = getFilterOptions()
  switch (filterBy) {
    case opts[1]: // no groups
      return !parentOrgMap[org.id] || parentOrgMap[org.id]?.length < 1

    case opts[2]: // no users
      return !orgUsersMap[org.id] || orgUsersMap[org.id]?.length < 1

    default:
      return true
  }
}

export function getSortPropertiesCompareFn(
  sortBy: SortBy<PropertiesColumn>,
  orgUsersMap: IdToIdList,
  childOrgMap: IdToIdList,
  parentOrgMap: IdToIdList,
): (a: Organization, b: Organization) => number {
  switch (sortBy.field) {
    case PropertiesColumn.Name:
      return (a, b) => invertIfDescending(simpleCompare(a.name, b.name), sortBy.order)

    case PropertiesColumn.Groups:
      return (a, b) => {
        const groupsA = _.uniq(parentOrgMap[a.id] || [])?.length || 0
        const groupsB = _.uniq(parentOrgMap[b.id] || [])?.length || 0
        const diff = groupsA - groupsB
        return normalizeCompareResult(invertIfDescending(diff, sortBy.order))
      }

    case PropertiesColumn.Properties:
      return (a, b) => {
        const propertiesA = _.uniq(childOrgMap[a.id] || [])?.length || 0
        const propertiesB = _.uniq(childOrgMap[b.id] || [])?.length || 0
        const diff = propertiesA - propertiesB
        return normalizeCompareResult(invertIfDescending(diff, sortBy.order))
      }

    case PropertiesColumn.Users:
      return (a, b) => {
        const usersA = _.uniq(orgUsersMap[a.id] || [])?.length || 0
        const usersB = _.uniq(orgUsersMap[b.id] || [])?.length || 0
        const diff = usersA - usersB
        return normalizeCompareResult(invertIfDescending(diff, sortBy.order))
      }

    default:
      return () => 0
  }
}

export function getAdminUsersColumnConfig(orgId: string): TableColumnConfig<UsersColumn | EmptyColumn, User>[] {
  const common: TranslationGroup = trans.common()

  return [
    {
      header: UsersColumn.Name,
      label: common[UsersColumn.Name],
      renderFn: getNameFromUser,
    },
    {
      header: UsersColumn.Email,
      label: common[UsersColumn.Email],
      renderFn: (user: User) => user.attributes.email,
    },
    {
      header: UsersColumn.Roles,
      label: common[UsersColumn.Roles],
      renderFn: (user: User) => (
        <>
          <EditUserRoleButton userId={user.id} orgId={orgId} />
        </>
      ),
    },
    {
      header: EmptyColumn.Empty,
      // TODO: wire up
      renderFn: (user: User) => <RemoveUserButton user={user} orgId={orgId} />,
    },
  ]
}

export function userFilterOptions() {
  const translation: TranslationGroup = trans.group(TranslationKey.USER_TABLE_VIEW)
  return [translation.all_users]
}

export function usersSortOptions() {
  const common: TranslationGroup = trans.common()
  return _.flatten(
    _.map(UsersColumn, c => [
      createSortOption(c, SortOrder.Descending, `${common[c]} ↓`),
      createSortOption(c, SortOrder.Ascending, `${common[c]} ↑`),
    ]),
  )
}
