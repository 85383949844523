import { shallowEqual } from 'react-redux'
import { useEffect, useState } from 'react'
import styled from '@emotion/styled/macro'
import { Button, Icon, Tooltip, Box, setRef } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useHistory } from 'react-router-dom'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import DashboardIcon from 'static/images/Dashboard_Icon.svg'
import AlertsIcon from 'static/images/Alerts_Icon.svg'
import BeaconsIcon from 'static/images/Beacons_Icon.svg'
import ButtonsDevicesIcon from 'static/images/Buttons_Devices_Icon.svg'
import RespondersIcon from 'static/images/Responders_icon.svg'
import UsersIcon from 'static/images/Users_Icon.svg'
import { RoundedTextField } from 'components/legacy-poorly-defined/Common'
import {
  DASHBOARD_MAIN_PATH,
  BEACONS_PATH,
  DEVICES_PATH,
  GRAY3_COLOR,
  USERS_PATH,
  RESPONDERS_PATH,
  ALERTS_PATH,
  ALL_ORGS_SELECTED,
  BRAND_COLOR,
  BORDER1_COLOR,
} from 'lib/constants'
import { AlertSessions, Orgs, NavState, Beacons, Devices, Users, Responders } from 'models'
import { ChartsSection } from './ChartsSection'
import { trans, TranslationGroup, TranslationKey } from 'lib/types/translation'
import { RenderCounter } from 'components/widgets/RenderCounter'
import ClearIcon from '@material-ui/icons/Clear'
import { reloadAll } from 'models'
import Refresh from '../MainHeader/Refresh'

const borderRadius = 10

const MainSidebarContainer = styled.div({
  width: '350px',
  position: 'fixed',
  overflowY: 'auto',

  '@media (max-width: 936px)': {
    justifyContent: 'space-evenly',
  },

  '@media (min-width: 1300px)': {
    height: '100%',
  },

  '@media (max-width: 1300px)': {
    background: 'white',
    zIndex: 200,
    width: '100vw',
    position: 'fixed',
    top: '65px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
  },
})

const MainSidebarPaddingContainer = styled.div({
  padding: '25px 0 25px 25px',
  '@media (max-width: 1300px)': {
    padding: '0px 0 20px 20px',
  },
})

const RightCount = styled.div`
  margin-left: auto;
  @media (max-width: 1300px) {
    display: none;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  &:first-child {
    border-radius: 10px;
  }
  button {
    font-family: Montserrat;
    background: ${GRAY3_COLOR};
    height: 56px;
    width: 100%;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    justify-content: start;
    &:hover {
      box-shadow: none;
      background-color: #eaeaea;
    }
    &:first-child {
      border-radius: 0;
    }
    &:last-child {
      border-radius: 0 0 ${borderRadius}px ${borderRadius}px;
    }
    @media (max-width: 1100px) {
      font-size: 0;
      .MuiButton-startIcon {
        margin: 0;
      }
      min-width: 0;
    }
    @media (max-width: 1300px) {
      &:last-child {
        border-radius: 0 ${borderRadius}px ${borderRadius}px 0;
      }
    }
    &:only-child {
      border-radius: ${borderRadius}px;
    }
  }
  @media (max-width: 1300px) {
    flex-direction: row;
    width: 85vw;
    & > button {
      width: unset;
    }
  }
`

const StyledRoundedTextField = styled(RoundedTextField)`
  .MuiInputBase-root fieldset {
    border: 0;
  }
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
    background: ${GRAY3_COLOR};
    border-radius: ${borderRadius}px ${borderRadius}px 0 0;
    border-bottom: 1px solid #ddd;
    @media (max-width: 1300px) {
      border-radius: ${borderRadius}px 0 0 ${borderRadius}px;
      border-bottom: 0px solid #ddd;
      border-right: 1px solid #ddd;
      width: 260px;
    }
  }
`

const SidebarSubElementContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',

  '@media (max-width: 1300px)': {
    width: '40vw',
    flexDirection: 'row',
  },
})

const Spacer = styled.div`
  display: inline-block;
  min-width: 15px;
  min-height: 15px;
`

const ChartsContainer = styled.div`
  @media (max-width: 1300px) {
    display: none;
  }
`

const StyledOrgName = styled.div`
  background: ${GRAY3_COLOR};
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid #ddd;
  padding: 19px 16px;
`

const getIcon = (iconUrl: string, alt: string) => (
  <Icon>
    <img alt={alt} src={iconUrl} style={{ marginBottom: '5px', height: '100%' }} />
  </Icon>
)

export const MainSidebar = () => {
  const history = useHistory()
  const orgs = Orgs.use(({ orgs }) => orgs)

  // const { pagination: alertSessionsPagination } = AlertSessions.use(({ pagination }) => {
  //   return { pagination }
  // }, shallowEqual)
  // const { pagination: beaconsPagination } = Beacons.use(({ pagination }) => {
  //   return { pagination }
  // }, shallowEqual)
  const selectedOrg = Orgs.useSelectedOrg()

  const selectedOrgId = selectedOrg ? selectedOrg.id : ALL_ORGS_SELECTED

  const selectedOrgsBeacons = Beacons.useSelectedOrgsBeacons()
  const selectedOrgsAlertSessions = AlertSessions.useSelectedOrgsAlertSessions()
  const selectedOrgsDevices = Devices.useSelectedOrgsDevices(false)
  const selectedOrgsResponders = Responders.useSelectedOrgsResponders(selectedOrgId)
  const selectedOrgsUsers = Users.useAssociatedUsers(selectedOrgId)

  // const handleMouseEnter = () => {
  //   setIconColor('#bababa')
  // }

  // const handleMouseLeave = () => {
  //   setIconColor(BORDER1_COLOR)
  // }

  const getSelectedStyle = (routePath: any): any =>
    history.location.pathname.match(routePath) ? { backgroundColor: '#eaeaea', fontSize: '1.4rem' } : undefined

  const renderButton = ([key, name, icon, path, tip, count]: [string, string, string, string, string, number?]) => {
    return (
      <Tooltip key={key} title={tip}>
        <Button
          aria-label={name}
          variant="contained"
          startIcon={getIcon(icon, name)}
          data-testid="sidebarDashboard"
          onClick={() => history.push(path)}
          style={getSelectedStyle(path)}
        >
          {name}
          <RightCount>{count}</RightCount>
        </Button>
      </Tooltip>
    )
  }
  const translation: TranslationGroup = trans.merge(TranslationKey.MAIN_SIDEBAR)
  const DEFAULT_LOCATION = { name: translation.all_properties, id: translation.all_properties }
  return (
    <MainSidebarContainer>
      <MainSidebarPaddingContainer>
        <SidebarSubElementContainer>
          <RenderCounter name={'MainSidebar'} />
          <ButtonsContainer>
            {' '}
            {renderButton([
              'Dashboard',
              translation.dashboard,
              DashboardIcon,
              DASHBOARD_MAIN_PATH,
              translation.dashboard_tooltip,
            ])}
          </ButtonsContainer>
          <Spacer>&nbsp;</Spacer>
          {orgs.length === 1 ? (
            <StyledOrgName>{selectedOrg?.name || ''}</StyledOrgName>
          ) : (
            <Autocomplete
              id="organization"
              value={selectedOrg || DEFAULT_LOCATION} //
              onChange={(event, newValue) => {
                NavState.selectOrganization(newValue?.id || translation.all_properties)
              }}
              // onMouseLeave={() => {
              //   !selectedOrg && setSelectedOrg(DEFAULT_LOCATION as Organization)
              // }}
              options={[...orgs, DEFAULT_LOCATION]}
              getOptionSelected={(option, value) => option?.id === value?.id}
              defaultValue={DEFAULT_LOCATION}
              getOptionLabel={option => option?.name || translation.all_properties}
              popupIcon={<KeyboardArrowDownIcon />}
              renderInput={params => <StyledRoundedTextField {...params} variant="outlined" />}
            />
          )}

          <ButtonsContainer>
            {[
              [
                'Alerts',
                translation.alerts,
                AlertsIcon,
                ALERTS_PATH,
                translation.alerts_tooltip,
                selectedOrg ? selectedOrgsAlertSessions.length : '-', //alertSessionsPagination.totalCount,
              ],
              [
                'Devices',
                translation.devices,
                ButtonsDevicesIcon,
                DEVICES_PATH,
                translation.devices_tooltip,
                selectedOrg ? selectedOrgsDevices.length : '-',
              ],
              [
                'Responders',
                translation.responders,
                RespondersIcon,
                RESPONDERS_PATH,
                translation.responders_tooltip,
                selectedOrg ? selectedOrgsResponders.length : '-',
              ],
              [
                'Users',
                translation.users,
                UsersIcon,
                USERS_PATH,
                translation.users_tooltip,
                selectedOrg ? selectedOrgsUsers.length : '-',
              ],
              [
                'Beacons',
                translation.beacons,
                BeaconsIcon,
                BEACONS_PATH,
                translation.beacons_tooltip,
                selectedOrg ? selectedOrgsBeacons.length : '-',
              ],
              // @ts-ignore
            ].map(renderButton)}
          </ButtonsContainer>
        </SidebarSubElementContainer>
        <ChartsContainer>
          <ChartsSection currentPath={history.location.pathname} />
        </ChartsContainer>
      </MainSidebarPaddingContainer>
    </MainSidebarContainer>
  )
}
