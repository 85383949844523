import { createReduxModule } from 'hooks-for-redux'
import {
  ALL_ORGS_SELECTED,
  SELECTED_ORG_ID_LOCAL_STORAGE_KEY,
  ORG_SORT_ORDER_LOCAL_STORAGE_KEY,
  SELECTED_ALERT_SESSION_ID_LOCAL_STORAGE_KEY,
  DASHBOARD,
} from 'lib/constants'
import { getUserJWT } from 'lib/utils/auth'
import { reload } from 'models/Responders'
export interface NavStateType {
  selectedOrgId: string
  selectedAdminOrgId: string | undefined
  orgSortOrder: string
  selectedAlertSessionId?: string
}

const initialState: NavStateType = {
  selectedOrgId: localStorage.getItem(SELECTED_ORG_ID_LOCAL_STORAGE_KEY) || ALL_ORGS_SELECTED,
  selectedAdminOrgId: undefined,
  orgSortOrder: localStorage.getItem(ORG_SORT_ORDER_LOCAL_STORAGE_KEY) || DASHBOARD.SORT_OPTIONS[0],
  selectedAlertSessionId: localStorage.getItem(SELECTED_ALERT_SESSION_ID_LOCAL_STORAGE_KEY) || undefined,
}

export const [use, { selectOrganization, setSelectedAdminOrgId, setOrgSortOrder, setSelectedAlertSessionId }, store] =
  createReduxModule('navState', initialState, {
    selectOrganization: (state: NavStateType, selectedOrgId?: string) => {
      selectedOrgId = selectedOrgId || ALL_ORGS_SELECTED
      if (state.selectedOrgId === selectedOrgId) return state
      localStorage.setItem(SELECTED_ORG_ID_LOCAL_STORAGE_KEY, selectedOrgId)
      return { ...state, selectedOrgId }
    },
    setSelectedAdminOrgId: (state: NavStateType, selectedAdminOrgId?: string) => {
      return { ...state, selectedAdminOrgId }
    },
    setOrgSortOrder: (state: NavStateType, orgSortOrder: string) => {
      localStorage.setItem(ORG_SORT_ORDER_LOCAL_STORAGE_KEY, orgSortOrder)
      return { ...state, orgSortOrder }
    },
    setSelectedAlertSessionId: (state: NavStateType, selectedAlertSessionId: string) => {
      localStorage.setItem(SELECTED_ALERT_SESSION_ID_LOCAL_STORAGE_KEY, selectedAlertSessionId)
      return { ...state, selectedAlertSessionId }
    },
  })

export const getRegisterDeviceQRCode = (forResponder?: boolean): any =>
  JSON.stringify({
    token: getUserJWT() || '',
    orgId: store.getState().selectedOrgId,
    responder: !!forResponder,
  })
