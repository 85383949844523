import { jsx } from '@emotion/react'
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import RefreshIcon from '@material-ui/icons/Refresh'
import ClearIcon from '@material-ui/icons/Clear'
import Box from '@material-ui/core/Box'
import { BORDER1_COLOR } from 'lib/constants'
import { reloadAll } from 'models'
import { Loader, LoaderType, StyledInput } from 'components/widgets'

const Refresh = () => {
  const [loading, setLoading] = useState(false)
  const [iconColor, setIconColor] = useState(BORDER1_COLOR)
  const [refreshVisible, setRefreshVisible] = useState(true)
  const [spinning, setSpinning] = useState(false)

  const useStyles = makeStyles(theme => ({
    icon: {
      width: '35px',
      height: '35px',
      color: iconColor,
      transition: 'transform 1s ease-in-out',
    },
    spinning: {
      transform: 'rotate(360deg)', //'rotate(360deg) scale(0)'
    },
    disabled: {
      opacity: '50%',
    },
  }))
  const classes = useStyles()

  const handleMouseEnter = () => {
    setIconColor('#bababa')
  }

  const handleMouseLeave = () => {
    setIconColor(BORDER1_COLOR)
  }

  const handleRefreshClick = async () => {
    setLoading(true)
    setSpinning(true)

    await reloadAll()
    setTimeout(() => {
      setSpinning(false)
      setRefreshVisible(true)
    }, 1100)
    setTimeout(() => setLoading(false), 5000)
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {!loading ? (
        <RefreshIcon
          className={`${classes.icon}  `}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleRefreshClick}
        />
      ) : spinning ? (
        <RefreshIcon
          className={`${classes.icon} ${spinning ? classes.spinning : ''}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleRefreshClick}
        />
      ) : (
        <Loader type={LoaderType.ROTATING_LINES} width="25px" height="25px" />
      )}
    </Box>
  )
}

export default Refresh
