import { ALERTS_PATH, BEACONS_PATH, DEVICES_PATH, RESPONDERS_PATH } from 'lib/constants'
import { lc, trans, TranslationGroup, TranslationKey } from 'lib/types/translation'
import { Organization } from 'lib/types'
import { NavState } from 'models'

// @ts-ignore
export const viewSelectedOrgDetail = (history: any, org: Organization, path: string) => {
  NavState.selectOrganization(org.id)
  history.push(path)
}

export const getConfigFromSummary = (title: string) => {
  const translation: TranslationGroup = trans.group(TranslationKey.ORG_GLOBAL_CARD)
  const common: TranslationGroup = trans.common()

  switch (title) {
    case common.Alerts:
      return {
        goPath: ALERTS_PATH,
        messageGreen: ` ${lc(common.resolved)}`,
        messageYellow: ` ${translation.alerts_message_yellow}`,
        messageRed: ` ${translation.alerts_message_red}`,
        keyGreen: 'resolved',
        keyYellow: 'moderate',
        keyRed: 'critical',
      }
    case common.Devices:
      return {
        goPath: DEVICES_PATH,
        messageGreen: ` ${common.message_green}`,
        messageYellow: ` ${translation.devices_message_yellow}`,
        messageRed: ` ${common.offline}`,
        keyGreen: 'healthy',
        keyYellow: 'moderate',
        keyRed: 'critical',
      }
    case common.Responders:
      return {
        goPath: RESPONDERS_PATH,
        messageGreen: ` ${translation.responders_message_green}`,
        messageRed: ` ${translation.responders_message_red}`,
        keyGreen: 'healthy',
        keyRed: 'critical',
      }
    case common.Beacons:
      return {
        goPath: BEACONS_PATH,
        messageGreen: ` ${common.message_green}`,
        messageYellow: ` ${translation.beacons_message_yellow}`,
        messageRed: ` ${translation.beacons_message_red}`,
        keyGreen: 'healthy',
        keyYellow: 'moderate',
        keyRed: 'critical',
      }
    default:
      return {
        goPath: '',
        messageGreen: '',
        messageYellow: '',
        messageRed: '',
        keyGreen: '',
        keyYellow: '',
        keyRed: '',
      }
  }
}
