/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'

import React from 'react'
import styled from '@emotion/styled/macro'
import { Close } from '@material-ui/icons'
import { getAppColors } from 'lib/constants'

const DrawerWrapper = styled.div({
  backgroundColor: getAppColors('--color-gray-3'),
  minWidth: '420px',
  minHeight: 'fit-content',
  '@media (max-width: 600px)': {
    width: '90vw',
  },
  top: '67px',
  paddingLeft: '18px',
  paddingBottom: '15px',
})

const DrawerHeaderWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontFamily: 'Montserrat',
})

const DrawerContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  // zIndex: 1350,
})

export const DetailSliderContent = ({
  title,
  close,
  children,
}: {
  title: string
  close: Function
  children: React.ReactNode
}) => {
  const headerStyles = {
    fontWeight: 600,
    fontSize: '1.8rem',
    lineHeight: '2.9rem',
    color: getAppColors('--color-primary'),
    margin: 'auto 0',
  }

  const closeButtonStyles = {
    color: getAppColors('--color-primary'),
    padding: '15px',
    cursor: 'pointer',
  }

  return (
    <DrawerWrapper id="DetailDrawerWrapper">
      <DrawerHeaderWrapper>
        <h2 css={headerStyles}>{title}</h2>
        <Close css={closeButtonStyles} onClick={() => close()} />
      </DrawerHeaderWrapper>
      <DrawerContentWrapper>{children}</DrawerContentWrapper>
    </DrawerWrapper>
  )
}
