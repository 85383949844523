/** @jsxImportSource @emotion/react */
import { ConditionalRender } from 'components/widgets'
import { trans, TranslationGroup, TranslationKey } from 'lib/types'
import { PasswordValidationListItem } from './PasswordValidationListItem'

const validationStyles = {
  padding: '1rem 0',
}

interface Props {
  validation: PasswordValidation
  isConfirm?: boolean
}

export interface PasswordValidation {
  VALID: boolean
  LENGTH: boolean
  LOWER: boolean
  UPPER: boolean
  NUMBER_SYMBOL: boolean
  MATCH: boolean
}

export function PasswordValidationList({ validation, isConfirm }: Props) {
  const translation: TranslationGroup = trans.group(TranslationKey.AUTH)
  return (
    <div css={validationStyles}>
      <ConditionalRender condition={!!isConfirm}>
        <PasswordValidationListItem label={translation.password_validation_match} valid={validation.MATCH} />
      </ConditionalRender>
      <ConditionalRender condition={!isConfirm}>
        <PasswordValidationListItem label={translation.password_validation_length} valid={validation.LENGTH} />
        <PasswordValidationListItem
          label={translation.password_validation_upper_lower}
          valid={validation.LOWER && validation.UPPER}
        />
        <PasswordValidationListItem
          label={translation.password_validation_number_symbol}
          valid={validation.NUMBER_SYMBOL}
        />
      </ConditionalRender>
    </div>
  )
}
