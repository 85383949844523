export enum BeaconsColumnKeys {
  Health = 'Health',
  Battery = 'Battery',
  Firmware = 'Firmware',
  Type = 'Type',
  Location = 'Location',
  Floor = 'Floor',
  Room = 'Room',
  Name = 'Name',
  LastCheckIn = 'Last_Check_In',
}
