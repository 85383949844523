/** @jsxImportSource @emotion/react */
import { useEffect, useRef } from 'react'
import { Interpolation, jsx, Theme } from '@emotion/react/macro'
import { getAppColors, FULL_LOCAL_TIME } from 'lib/constants'
import { formatTime, parseTime, getAlertDisplayMessage } from 'lib/utils'
import { AlertEvent } from 'lib/types'
import { alignContent, flexFlow, overflowWrap, textAlign, whiteSpace } from 'styles/cssProps'

const alertCardContainer = {
  display: 'flex',
  ...flexFlow('column wrap'),
  ...alignContent('flex-end'),
  margin: '30px 0',
  fontFamily: 'Montserrat',

  "&[data-role='initiator']": {
    ...alignContent('flex-start'),
  },
}

const transcriptContainer = {
  display: 'flex',
  ...flexFlow('column wrap'),
  ...alignContent('flex-end'),
  maxWidth: '66%',
  ...overflowWrap('anywhere'),
  borderTop: `1px solid ${getAppColors('--color-gray-2')}`,
  maxHeight: '100%',
  overflow: 'auto',
  borderRadius: '4px',
  boxShadow: '0px 2px 4px rgba(75, 75, 75, 0.2)',
  margin: '10px 0 10px auto',
  padding: '0 10px',
  backgroundColor: `${getAppColors('--color-white')}`,
  width: 'fit-content',
  fontSize: '1.4rem',
  ...textAlign('end'),

  "&[data-role='initiator']": {
    ...alignContent('flex-start'),
    backgroundColor: `${getAppColors('--color-white')}`,
    color: `${getAppColors('--color-black')}`,
    ...textAlign('left'),
    margin: '10px auto 10px 0',

    "&[data-action-type='open']": {
      color: `${getAppColors('--color-white')}`,
      backgroundColor: `${getAppColors('--color-error')}`,
    },
  },

  "&[data-action-type='close']": {
    color: `${getAppColors('--color-white')}`,
    backgroundColor: `${getAppColors('--color-textfield-focus')}`,
  },
}

const eventWrapper = {
  display: 'inline-block',
  ...whiteSpace('pre-wrap'),
  margin: '0 0 15px',
  ...textAlign('end'),

  "&[data-role='initiator']": {
    ...textAlign('left'),
  },
}

const nameWrapper = {
  fontWeight: 700,
  margin: '15px 0',
}

const subText = {
  fontSize: '1.2rem',
  color: `${getAppColors('--color-secondary')}`,
  margin: 'initial',
}

export function AlertChatEvent({
  event,
  last,
  movingThroughHistory,
  scrolledToBottom,
}: {
  event: AlertEvent
  last: boolean
  movingThroughHistory: boolean
  scrolledToBottom: boolean
}) {
  const {
    attributes: { action, alertSourceName, role, timestamp },
  } = event

  return (
    <div
      css={alertCardContainer}
      data-role={role}
      ref={useScrollToBottomOnNewEvents(last, movingThroughHistory, scrolledToBottom)}
    >
      <EventContent css={subText} content={formatTime(parseTime(timestamp), FULL_LOCAL_TIME)} data-role={role} />
      <div css={transcriptContainer} key={event.id} data-role={role} data-action-type={action}>
        <EventContent content={alertSourceName} css={nameWrapper} data-role={role} />
        <EventContent content={getAlertDisplayMessage(event)} data-role={role} />
      </div>
    </div>
  )
}

function EventContent(props: {
  content: string | undefined
  css?: Interpolation<Theme>
  'data-role'?: string | undefined
}) {
  const { content, ...new_props } = props
  return (
    <span css={eventWrapper} {...new_props}>
      {content}
    </span>
  )
}

function useScrollToBottomOnNewEvents(last: boolean, movingThroughHistory: boolean, scrolledToBottom: boolean) {
  const alertEventsBottom = useRef<HTMLDivElement>(null)
  // Scroll to bottom of chat on mount and when new events are added if not already at the bottom and if the user hasn't already started scrolling up through the history.
  useEffect(() => {
    last && !movingThroughHistory && !scrolledToBottom && alertEventsBottom.current?.scrollIntoView()
  }, [last, movingThroughHistory, scrolledToBottom])
  return alertEventsBottom
}
