import styled from '@emotion/styled/macro'
import { Oval } from 'react-loader-spinner'

const StyledContainer = styled.section({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(255,255,255,.5)',
  zIndex: 1000,
})

const StyledBox = styled.div({
  padding: '12px',
  display: 'inline-block',
})

interface LoaderOverlayProps {
  size: number
}

export const LoaderOverlay = (props: LoaderOverlayProps) => {
  const { size } = props
  return (
    <StyledContainer>
      <StyledBox>
        <Oval color="#BCF8F2" secondaryColor="#BCF8F2" height={size} width={size} />
      </StyledBox>
    </StyledContainer>
  )
}
