/** @jsxImportSource @emotion/react */
import { Menu, MenuItem } from '@material-ui/core'
import React, { useState } from 'react'

export const HoverBox = ({
  content,
  children,
}: {
  content: string[]
  children: React.ReactNode
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleHover = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <div onMouseEnter={handleHover}>{children}</div>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'left' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div onMouseLeave={handleClose}>
          {!!content && content.length > 0 ? (
            content.map((c, i) => (
              <MenuItem key={`MenuItem-${i}`} css={{ textTransform: 'none' }}>{c}</MenuItem>
            ))
          ) : (
            <MenuItem disabled>None</MenuItem>
          )}
        </div>
      </Menu>
    </>
  )
}
