/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import { FormControl, IconButton, TextField } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { onChangeValueCallback } from 'lib/types'
import { formControlStyles, roundedTextFieldStyles } from 'styles'

export const SearchBar = ({
  prefix,
  onChange,
  className,
  initialValue
}: {
  prefix?: string
  onChange: onChangeValueCallback
  className?: string,
  initialValue?: string
}) => {
  return (
    <div css={{ className }}>
      <FormControl css={formControlStyles}>
        <TextField
          value={initialValue}
          css={roundedTextFieldStyles}
          placeholder={prefix}
          onChange={e => onChange(e.target.value)}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <IconButton name="Filter table" aria-label="Search">
                <Search />
              </IconButton>
            ),
          }}
        />
      </FormControl>
    </div>
  )
}
