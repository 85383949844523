import styled from '@emotion/styled/macro'
import _ from 'lodash'
import { Checkbox, CheckboxProps, DialogTitle, InputLabel } from '@material-ui/core'
import { GrantCheckedMap, trans, TranslationGroup } from 'lib/types'
import { BORDER1_COLOR, GRANTS, getHumanGrantName, TEXTFIELD_LABEL_COLOR, USER_ASSIGNABLE_GRANTS } from 'lib/constants'
import { memo, useState } from 'react'
import { getCheckedStatus, getCheckboxColor, isDisabled } from './utils'
import { ActionButton, StyledDialogActions } from 'components/widgets'
import { Grants } from 'models'
import { createOrUpdate } from 'models/Users'
import { User } from 'lib/types'
interface StyledCheckboxProps extends CheckboxProps {
  checkboxcolor: string
}

const StyledFormSectionTitle = styled(InputLabel)({
  color: TEXTFIELD_LABEL_COLOR,
  fontFamily: 'Montserrat',
  fontSize: '1.4rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.5rem',
  letterSpacing: '0.1rem',
  textAlign: 'left',
  margin: '3.2rem 0 1.6rem',
})

const StyledMainWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const StyledListContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '25px',
})

const StyledSeparator = styled.div({
  border: `1px solid ${BORDER1_COLOR}`,
  marginBottom: '14px',
})

const StyledRow = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

const StyledText = styled.span({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '1.6rem',
})

export const StyledCheckbox = styled(Checkbox)<StyledCheckboxProps>(props => ({
  border: '1px solid #FFFFFF',
  padding: '0',
  '&.Mui-checked': {
    color: props.checkboxcolor,
  },
}))

const StyledActionButton = styled(ActionButton)({
  width: '97.6px',
  height: '28px',
})

export const StyledDialogTitle = styled(DialogTitle)({
  fontFamily: 'Montserrat',
  padding: '1.6rem 0',
  h2: {
    fontFamily: 'Montserrat',
    fontSize: '1.8rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '2.9rem',
    letterSpacing: '0',
    textAlign: 'left',
  },
})

export interface AssignModalProps {
  userId?: string
  orgId: string
  close?: () => void
  title?: string
  setGrants?: Function
  firstName?: string | undefined
  lastName?: string | undefined
  user?: User
}

export const updateGrants = async (
  userId: string,
  orgId: string,
  oldGrantCheckMap: GrantCheckedMap,
  newGrantCheckMap: GrantCheckedMap,
) => {
  const wantsAdmin = !!newGrantCheckMap[GRANTS.ADMIN]
  // Do not set or delete `ASSOCIATE` grant here.
  delete oldGrantCheckMap[GRANTS.ASSOCIATE]
  delete newGrantCheckMap[GRANTS.ASSOCIATE]
  // The `DISPATCHER` grant is just a combination of `PORTAL_USER` and `RESPONDER`, not an actual grant.
  delete newGrantCheckMap[GRANTS.DISPATCHER]
  if (wantsAdmin) {
    delete newGrantCheckMap[GRANTS.INSTALLER]
    delete newGrantCheckMap[GRANTS.PORTAL_USER]
    delete newGrantCheckMap[GRANTS.RESPONDER]
  }

  // Do Admin first
  if (!!oldGrantCheckMap[GRANTS.ADMIN] !== wantsAdmin) {
    await updateGrant(oldGrantCheckMap, wantsAdmin, GRANTS.ADMIN, userId, orgId)
    if (wantsAdmin) return
  }

  Object.keys(newGrantCheckMap).forEach(name => {
    const checked = !!newGrantCheckMap[name]
    updateGrant(oldGrantCheckMap, checked, name, userId, orgId)
  })
}

async function updateGrant(
  oldGrantCheckMap: GrantCheckedMap,
  checked: boolean,
  name: string,
  userId: string,
  orgId: string,
) {
  if (!!oldGrantCheckMap[name] !== checked) {
    return checked ? await Grants.createGrant(name, userId, orgId) : Grants.deleteGrant(name, userId, orgId)
  }
}

export const AssignModal = memo(
  ({ user, userId, orgId, close, title, setGrants, firstName, lastName }: AssignModalProps) => {
    const common: TranslationGroup = trans.common()

    const usersGrants = Grants.useUserOrgGrants(userId || '', orgId)
    const [oldGrantCheckMap] = useState((): GrantCheckedMap => {
      const out: GrantCheckedMap = {}
      usersGrants.forEach(grant => (out[grant.attributes.name] = !!grant))
      return out
    })
    const [newGrantsMap, setGrantsByName] = useState(_.clone(oldGrantCheckMap))
    return (
      <>
        {title && <StyledFormSectionTitle>{title.toUpperCase()}</StyledFormSectionTitle>}
        <StyledMainWrapper>
          <StyledSeparator />
          <StyledListContainer>
            <GrantsList grants={newGrantsMap} />
          </StyledListContainer>
          <StyledSeparator />
          {userId && close && (
            <StyledDialogActions>
              <StyledActionButton onClick={() => close()}>{common.Cancel}</StyledActionButton>
              <StyledActionButton
                onClick={() => {
                  createOrUpdate({ ...user, firstName, lastName })
                  updateGrants(userId, orgId, oldGrantCheckMap, newGrantsMap)
                  close()
                }}
              >
                {common.Apply}
              </StyledActionButton>
            </StyledDialogActions>
          )}
        </StyledMainWrapper>
      </>
    )

    function GrantsList({ grants }: { grants: GrantCheckedMap }) {
      return (
        <>
          {USER_ASSIGNABLE_GRANTS.map(grantName => (
            <StyledRow key={grantName}>
              <StyledText>{getHumanGrantName(grantName)}</StyledText>
              <StyledCheckbox
                checkboxcolor={getCheckboxColor(grants, grantName)}
                checked={getCheckedStatus(grants, grantName)}
                onChange={e => setGrant(grants, grantName, e.target.checked)}
                disabled={isDisabled(grants, grantName)}
              />
            </StyledRow>
          ))}
        </>
      )
    }

    function setGrant(grants: GrantCheckedMap, grantName: string, checked: boolean) {
      switch (grantName) {
        case GRANTS.ADMIN:
          for (const key in grants) {
            if (key !== GRANTS.ADMIN) {
              grants[key] = false
            }
          }
          break
        case GRANTS.DISPATCHER:
          grants[GRANTS.PORTAL_USER] = checked
          grants[GRANTS.RESPONDER] = checked
          break
        default:
          break
      }
      setGrantsByName({ ...grants, [grantName]: checked })
      if (setGrants) setGrants({ ...grants, [grantName]: checked })
    }
  },
)
AssignModal.displayName = 'AssignModal'
