import ImageError from 'static/images/image-error.svg'
import UploadImage from 'static/images/upload-image.svg'
import styles from './OrgGlobalCard.module.css'

export const getImageUrl = (url: any) => (url ? url : ImageError)

export const getErrorImage = (e: React.SyntheticEvent<HTMLImageElement, Event>, setImgSrc: any) => {
  setImgSrc(ImageError)
  e.currentTarget.className = styles.orgImage
}

export const updatePlaceholderImage = (e: React.MouseEvent<HTMLImageElement>, setImgSrc: any) => {
  setImgSrc(UploadImage)
}

export const updateToOriginalImage = (e: React.MouseEvent<HTMLImageElement>, urlImg: string, setImgSrc: any) => {
  setImgSrc(urlImg)
}

export const getAddress = (orgProps: any) => {
  const { street1 = '', street2 = '', city = '', state = '', zipcode = '' } = orgProps.address || {}
  const formattedAddress = `${street1} ${street2}, ${city} ${state}, ${zipcode}`

  return formattedAddress.length > 7 ? formattedAddress : ''
}
