/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'

import { CheckCircleOutline, Highlight, HighlightOff, RemoveCircleOutline } from '@material-ui/icons'

export enum HealthStatus {
  HEALTHY = 'healthy',
  MODERATE = 'moderate',
  CRITICAL = 'critical',
  UNKNOWN = 'unknown',
  ACTIVE_NO_RESPONSE = 'active_no_response',
}

interface Props {
  label?: string
  status: HealthStatus
}

export const HealthIcon = ({ label, status }: Props) => {
  let icon = null

  switch (status) {
    case HealthStatus.CRITICAL:
      icon = <HighlightOff css={{ color: '#F44336' }} />
      break
    case HealthStatus.ACTIVE_NO_RESPONSE:
      icon = <Highlight css={{ color: '#F44336' }} />
      break
    case HealthStatus.MODERATE:
      icon = <RemoveCircleOutline css={{ color: '#E89F0B' }} />
      break
    case HealthStatus.HEALTHY:
      icon = <CheckCircleOutline css={{ color: '#5FD078' }} />
      break
    default:
      icon = ''
      break
  }

  return (
    <div css={{ display: 'flex', alignItems: 'center' }}>
      <div
        css={{
          paddingRight: '10px',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {icon}
      </div>
      <div css={{ lineHeight: '1.0rem' }}>{label}</div>
    </div>
  )
}
