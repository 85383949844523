import { textAlign } from 'styles'
import { getAppColors } from 'lib/constants'

export const headerCellStyles = {
  fontFamily: 'Montserrat',
  fontSize: '1.4rem',
  fontStyle: 'normal',
  fontWeight: 700,
  ...textAlign('left'),
  cursor: 'pointer',
  lineHeight: '1.5rem',
  letterSpacing: '0.1rem',
}

export const headerCellNoSortStyles = {
  ...headerCellStyles,
  cursor: 'default',
}

export const headerContentStyles = {
  display: 'flex',
  alignItems: 'center',

  svg: {
    width: '15px',
  },
}

export const tableRowStyles = {
  fontFamily: 'Montserrat',
  fontSize: '1.2rem',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '1.5rem',
  letterSpacing: '0.1rem',
  ...textAlign('left'),

  '&:global(.MuiTableRow-root)': {
    '&:hover': {
      backgroundColor: getAppColors('--color-table-row-hover'),
    },
  },
  '&.selected': {
    backgroundColor: getAppColors('--color-table-row-selected'),
  },
}

export const tableCellStyles = {
  fontFamily: 'Montserrat',
  '&:global(.MuiTableCell-root)': {
    fontFamily: 'Montserrat',
    fontSize: '1.4rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '1.9rem',
    letterSpacing: '0',
    ...textAlign('left'),
  },
}