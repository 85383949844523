/** @jsxImportSource @emotion/react */

import { jsx } from '@emotion/react/macro'
import { HealthLabelsKeys } from 'lib/constants'
import {
  HighlightOff as HighlightOffIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@material-ui/icons'

function getIcon(flag: string) {
  switch (flag) {
    case HealthLabelsKeys.OFFLINE:
      return <CheckCircleOutlineIcon style={{ color: '#5FD078' }} />
    case HealthLabelsKeys.CRITICAL:
      return <CheckCircleOutlineIcon style={{ color: '#5FD078' }} />
    case HealthLabelsKeys.MODERATE:
      return <RemoveCircleOutlineIcon style={{ color: '#E89F0B' }} />
    case HealthLabelsKeys.HEALTHY:
      return <HighlightOffIcon style={{ color: '#F44336' }} />
    default:
      return null
  }
}

export const IconWithLabel = ({ flag, label }: { flag: string; label: string }) => (
  <div
    css={{
      display: 'flex',
    }}
  >
    <div css={{ marginRight: '10px' }}>{getIcon(flag)}</div>
    <div css={{ label: 'Label' }}>{label}</div>
  </div>
)
