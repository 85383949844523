import { Button, Menu, MenuItem } from '@material-ui/core'
import { StyledMoreHorizIcon } from 'components/widgets'
import { omit } from 'lodash'
import { Organization, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { useState } from 'react'
import { Orgs, NavState } from 'models'
import { ConfirmationDialog } from 'components/partials'
import { isSuperAdmin } from 'lib/utils/auth'
import { OrganizationDetailsForm } from './OrganizationDetailsForm'

export const AdminOrgOptionButton = ({ org }: { org: Organization }) => {
  const translation: TranslationGroup = trans.merge(TranslationKey.ORGS)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  function handleEditClick() {
    NavState.setSelectedAdminOrgId(org.id)
    handleClose()
  }

  function handleDuplicate() {
    const selectedOrg = omit(Orgs.getOrg(org.id), 'id')
    Orgs.createOrUpdate(selectedOrg)
    handleClose()
  }

  function handleDelete(id: string) {
    Orgs.deleteOrg(id)
    handleClose()
  }

  return (
    <>
      <ConfirmationDialog
        title={`${translation.delete} ${translation.organization}`}
        description={translation.confirm_delete + ' ' + org.name}
        open={openConfirmModal}
        onConfirm={() => {
          setOpenConfirmModal(false)
          handleDelete(org.id)
        }}
        handleClose={() => setOpenConfirmModal(false)}
      />
      <Button onClick={handleClick}>
        <StyledMoreHorizIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div>
          <MenuItem onClick={handleEditClick}>
            {`${translation.edit} ${Orgs.isGroup(org) ? translation.group : translation.property} ${translation.and}
             ${translation.users}`}
          </MenuItem>
          {isSuperAdmin() && (
            <>
              <MenuItem onClick={handleDuplicate}>{translation.duplicate}</MenuItem>
              <MenuItem onClick={() => setOpenConfirmModal(true)}>{translation.delete}</MenuItem>
            </>
          )}
        </div>
      </Menu>
    </>
  )
}
