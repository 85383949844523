import { createReduxModule } from 'hooks-for-redux'
import { FirmwareLTEDataRequest, SubTypeOfHardware, Firmware, TranslationGroup, trans, TranslationKey } from 'lib/types'
import { compareSemanticVersions } from 'lib/utils'
import { Orgs } from 'models'
import {
  updateSingleOrGlobalFirmware,
  getAllSubHardwareTypesAPI,
  getAllFirmwareOptionList,
  uploadFirmwareFile,
  deleteFirmwareAPI,
} from './api'
import * as PopUpNotifications from './PopUpNotifications'

export interface FirmwareGroup {
  [key: string]: Firmware[]
}
export interface FirmwareState {
  uploading: boolean
  uploadSuccess: boolean | null
  loading: boolean
  error?: any
  subHardwareTypes: SubTypeOfHardware[]
  firmwares: Firmware[]
  firmwaresByType: FirmwareGroup
  firmwaresByTypeAuto: FirmwareGroup
}

const initialState: FirmwareState = {
  uploading: false,
  uploadSuccess: null,
  subHardwareTypes: [],
  firmwares: [],
  firmwaresByType: {},
  firmwaresByTypeAuto: {},
  loading: false,
}

const sortFirmwaresByVersion = (firmwares: Firmware[]) => {
  return firmwares.sort((a, b) => {
    return compareSemanticVersions(b.version, a.version)
  })
}

const groupFirmwaresByType = (firmwares: Firmware[], includeAuto: boolean = false) => {
  const firmwareType: { [key: string]: Firmware[] } = {}
  const auto = firmwares.filter(firmware => firmware.version === 'auto')
  firmwares.forEach((firmware: Firmware) => {
    const ft = firmware.firmwareType
    if (!firmwareType[ft]) firmwareType[ft] = includeAuto ? [...auto] : []
    firmwareType[ft].push(firmware)
  })
  return firmwareType
}

export const [
  use,
  { setUploading, setUploadSuccess, setFirmwares, setSubHardwareTypes, setLoading, clearLoading, setError },
  store,
] = createReduxModule('firmware', initialState, {
  setSubHardwareTypes: (state: FirmwareState, subHardwareTypes: SubTypeOfHardware[]) => ({
    ...state,
    subHardwareTypes,
  }),
  setFirmwares: (state: FirmwareState, firmwares: Firmware[]) => {
    const sorted = sortFirmwaresByVersion(firmwares)
    return {
      ...state,
      firmwares: sorted,
      firmwaresByType: groupFirmwaresByType(sorted),
      firmwaresByTypeAuto: groupFirmwaresByType(sorted, true),
    }
  },
  setLoading: (state: FirmwareState) => ({ ...state, loading: true }),
  clearLoading: (state: FirmwareState) => ({ ...state, loading: false }),
  setError: (state: FirmwareState, error: any) => ({ ...state, error }),
  setUploading: (state: FirmwareState, uploading: boolean) => ({ ...state, uploading }),
  setUploadSuccess: (state: FirmwareState, uploadSuccess: boolean | null) => ({ ...state, uploadSuccess }),
})

export const getAllFirmwaresList = () =>
  Promise.resolve().then(setLoading).then(getAllFirmwareOptionList).then(setFirmwares).then(clearLoading, setError)

export const getAllSubHardwareTypes = () =>
  Promise.resolve()
    .then(setLoading)
    .then(getAllSubHardwareTypesAPI)
    .then(setSubHardwareTypes)
    .then(clearLoading, setError)

export const reload = () =>
  Promise.resolve()
    .then(setLoading)
    .then(getAllSubHardwareTypesAPI)
    .then(setSubHardwareTypes)
    .then(getAllFirmwareOptionList)
    .then(setFirmwares)
    .then(clearLoading, setError)

const doUpdateAction = (f: any) => {
  setLoading()
  return Promise.resolve()
    .then(f)
    .then(result => {
      reload()
      return result
    }, setError)
    .then(clearLoading)
}

const doUploadAction = (f: any) => {
  setUploading(true)
  setUploadSuccess(null)
  return Promise.resolve()
    .then(f)
    .then(
      result => {
        setUploadSuccess(true)
        reload()
        return result
      },
      err => {
        setUploadSuccess(false)
        setError(err)
      },
    )
    .then(() => setUploading(false))
    .then(() => setUploadSuccess(null))
}

export const updateSingleOrGlobal = (data: FirmwareLTEDataRequest, orgId: string, alertSourceId?: string) =>
  updateSingleOrGlobalFirmware(data, orgId, alertSourceId).then(() => {
    Orgs.reloadOrg(orgId)
    return
  })

export const uploadNewFirmware = (data: FormData) => doUploadAction(() => uploadFirmwareFile(data))

export const deleteFirmware = (firmwareId: string) => doUpdateAction(() => deleteFirmwareAPI(firmwareId))

export const deleteFirmwareWithNotifications = (firmwareId: string) => {
  const translation: TranslationGroup = trans.group(TranslationKey.ADMIN_FIRMWARE)
  try {
    deleteFirmware(firmwareId)
    PopUpNotifications.fireInfo({ content: translation.delete_successful })
  } catch (ex) {
    PopUpNotifications.fireError({
      content: translation.delete_failed,
    })
  }
}
