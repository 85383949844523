/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled/macro'
import { ActionButton, StyledDialogActions } from 'components/widgets'
import { trans, TranslationGroup, TranslationKey, User } from 'lib/types'
import { Grants, PopUpNotifications } from 'models'

const UserWrapper = styled.div({
  width: '100%',
  alignContent: 'center',
})

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '25px',
})

const StyledText = styled.div({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '1.4rem',
  marginBottom: '20px',
})

export const DeleteUserModal = ({ user, orgId, close }: { user: User; orgId: string; close: Function }) => {
  const translation: TranslationGroup = trans.group(TranslationKey.USER_TABLE_VIEW)
  const common: TranslationGroup = trans.common()

  return (
    <UserWrapper>
      <StyledContainer>
        <div>
          <StyledText>
            {translation.confirm_remove_user}{' '}
            {(user.attributes.firstName &&
              user.attributes.lastName &&
              `${user.attributes.firstName} ${user.attributes.lastName}`) ||
              user.attributes.email}
            ?
          </StyledText>
        </div>
      </StyledContainer>
      <StyledDialogActions>
        <ActionButton width="120px" height="33px" onClick={() => close()}>
          {common.cancel}
        </ActionButton>
        <ActionButton
          width="120px"
          height="33px"
          onClick={() => {
            PopUpNotifications.tryWithErrorNotifications(Grants.removeUserFromOrg(user.id, orgId))
            close()
          }}
        >
          {translation.remove_user}
        </ActionButton>
      </StyledDialogActions>
    </UserWrapper>
  )
}
