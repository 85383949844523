/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled/macro'
import { TitleFormat, SloganFormat, LoginImageContainer } from 'components/legacy-poorly-defined/Common'
import ReactMobileLogo from 'static/images/Reactmobile_Logo.svg'
import LoginImage from 'static/images/auth/Signin-Image.png'
import { Link } from 'react-router-dom'
import { LINK_COLOR } from 'lib/constants'
import { sideImageContainerStyles } from 'styles'
import { trans, TranslationGroup, TranslationKey, uc } from 'lib/types'
import packageJson from '../../../package.json'
const version = packageJson.version

const Wrapper = styled.div`
  margin-top: 5vh;
  margin-left: 69px;
`

function LandingPageSideImage() {
  const translation: TranslationGroup = trans.group(TranslationKey.LANDING)
  return (
    <div css={sideImageContainerStyles}>
      <Wrapper>
        <Link to="/" css={{ textDecoration: 'none', color: LINK_COLOR }}>
          <img data-testid="logo" src={ReactMobileLogo} alt="Reactmobile Logo"></img>
          <TitleFormat>{uc(translation.management_console)}</TitleFormat>
        </Link>
      </Wrapper>
      <SloganFormat>{translation.slogan}</SloganFormat>
      <LoginImageContainer>
        <img src={LoginImage} data-testid="loginImage" style={{ maxHeight: 'auto', width: '100%' }} alt="Login" />
        {`v${version}`}
      </LoginImageContainer>
    </div>
  )
}

export default LandingPageSideImage
