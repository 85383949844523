import { trans, TranslationGroup, TranslationKey } from 'lib/types'
import { GRANTS } from './keys'

export const getHumanGrantName = (name: string): string => {
  const translation: TranslationGroup = trans.merge(TranslationKey.HUMAN_GRANTS_NAME)
  return translation[name] || name
}

export const USER_ASSIGNABLE_GRANTS = [
  GRANTS.PORTAL_USER,
  GRANTS.RESPONDER,
  GRANTS.DISPATCHER,
  GRANTS.INSTALLER,
  GRANTS.ADMIN,
]

// export const ROLE_NAMES = [
//   {
//     name: 'Portal User',
//     value: 'portal_user',
//   },
//   {
//     name: 'Responder',
//     value: 'responder',
//   },
//   {
//     name: 'Installer',
//     value: 'installer',
//   },
//   {
//     name: 'Alert Source',
//     value: 'alert_source',
//   },
//   {
//     name: 'Admin',
//     value: 'admin',
//   },
// ]
