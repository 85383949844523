import { useEffect, useState } from 'react'
import { shallowEqual } from 'react-redux'
import { PreConfiguredTableView } from 'components/partials/TableView'
import { ChildOrgMap, Orgs, Grants, NavState } from 'models'
import { SortBy, SortOrder, TableState, TableToolEvent, trans, TranslationGroup, TranslationKey } from 'lib/types'
import _ from 'lodash'
import * as utils from '../utils'
import { invertBinaryEnum } from 'lib/utils/common'
import { ActionButton } from 'components/widgets'
import { CreateProperty } from 'components/partials/CreateProperty'
import useModal from 'lib/hooks/useModal'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'models/modelUtils'
import { PATH_ADMIN } from 'lib/constants'
import i18n from 'i18n.js'
import { isSuperAdmin } from 'lib/utils/auth'

interface AdminOrgsTableProps {
  groupsMode?: boolean
}

export const AdminOrgsTable = ({ groupsMode = false }: AdminOrgsTableProps) => {
  const history = useHistory()
  const query = useQuery()
  const selectedOrgId = NavState.use(({ selectedOrgId }) => selectedOrgId)
  const [inited, setInited] = useState<boolean>(false)
  const { orgs, orgsById, loading } = Orgs.use(({ orgs, orgsById, loading }) => {
    return { orgs, orgsById, loading }
  }, shallowEqual)
  const selectedOrgName = orgsById[selectedOrgId] ? orgsById[selectedOrgId].name : ''
  const translation: TranslationGroup = trans.merge(TranslationKey.ADMIN)

  const {
    Modal: CreateOrgModal,
    openModal: openCreateOrgModel,
    closeModal,
  } = useModal(`${translation.create} ${groupsMode ? translation.group : translation.property}`)

  const basePath = groupsMode ? PATH_ADMIN.GROUPS : PATH_ADMIN.PROPERTIES

  const { childOrgMap, parentOrgMap } = ChildOrgMap.use(({ childOrgMap, parentOrgMap }) => {
    return { childOrgMap, parentOrgMap }
  }, shallowEqual)
  const orgUserMap = Grants.useOrgUserMap()

  const [tableState, setTableState] = useState<TableState<string, utils.PropertiesColumn>>({
    filterBy: utils.getFilterOptions()[0],
    sortBy: utils.getPropertiesSortOptions()[0].sortBy,
    searchBy: '',
  })

  i18n.on('languageChanged', lng => {
    setTableState({
      ...tableState,
      filterBy: utils.getFilterOptions()[0],
      sortBy: utils.getPropertiesSortOptions()[0].sortBy,
      searchBy: selectedOrgName,
    })
  })

  const filteredOrgs = utils
    .searchOrgs(orgs, tableState.searchBy)
    .filter(groupsMode ? Orgs.isGroup : Orgs.isProperty)
    .filter(org => utils.filterProperties(org, tableState.filterBy, parentOrgMap, orgUserMap))
    .sort(utils.getSortPropertiesCompareFn(tableState.sortBy, orgUserMap, childOrgMap, parentOrgMap))

  useEffect(() => {
    if (inited) {
      history.push({
        pathname: basePath,
        search: 'page=1',
      })
    } else {
      setInited(true)
    }
  }, [groupsMode, inited])

  useEffect(() => {
    const page = query.get('page')
    if (page === '1' || loading) return
    query.set('page', '1')
    history.push({
      search: query.toString(),
    })
  }, [tableState])

  function handleHeaderClick(header: utils.PropertiesColumn | utils.EmptyColumn) {
    if (header === utils.EmptyColumn.Empty) return

    setTableState(state => ({
      ...state,
      sortBy: {
        field: header,
        order: state.sortBy.field === header ? invertBinaryEnum(state.sortBy.order) : SortOrder.Ascending,
      },
    }))
  }

  function handleToolEvent(toolEvent: TableToolEvent<string, utils.PropertiesColumn | utils.EmptyColumn>) {
    if (toolEvent.sortByRequest?.field === utils.EmptyColumn.Empty) return

    setTableState(state => ({
      filterBy: toolEvent.filterByRequest || state.filterBy,
      sortBy: (toolEvent.sortByRequest as SortBy<utils.PropertiesColumn>) || state.sortBy,
      searchBy: _.isNil(toolEvent.searchByRequest) ? state.searchBy : toolEvent.searchByRequest,
    }))
  }

  return (
    <>
      <PreConfiguredTableView
        sortedData={filteredOrgs}
        tableState={tableState}
        columnConfigs={utils.getColumnConfigs()}
        filterOptions={utils.getFilterOptions()}
        sortOptions={utils.getPropertiesSortOptions()}
        onHeaderClick={handleHeaderClick}
        onToolEvent={handleToolEvent}
        loading={loading}
        hiddenColumns={[groupsMode ? utils.PropertiesColumn.Groups : utils.PropertiesColumn.Properties]}
      >
        {isSuperAdmin() && (
          <ActionButton onClick={() => openCreateOrgModel()}>
            {translation.add} {groupsMode ? translation.group : translation.property}
          </ActionButton>
        )}
      </PreConfiguredTableView>
      <CreateOrgModal>
        <CreateProperty closeModal={closeModal} groupMode={groupsMode} />
      </CreateOrgModal>
    </>
  )
}
