import { getRequest, putRequest, postRequest, deleteRequest } from "./rest";
import {
    Device,
    Firmware,
    FirmwareLTEDataRequest,
    Organization,
    SubTypeOfHardware,
} from "lib/types";
import { isUndefined } from "lodash";
import { API_FIRMWARE } from "lib/constants";
import { decodeJsonApiObject } from "./ApiLib";

const getAllSubHardwareTypesRaw = (): Promise<SubTypeOfHardware[]> => getRequest({ endpoint: `${API_FIRMWARE}/list/types` }).then(({ data }) => data.data)

const getAllFirmwareListRaw = (): Promise<Firmware[]> => getRequest({ endpoint: `${API_FIRMWARE}` }).then(({ data }) => data.data)

export const getAllSubHardwareTypesAPI = (): Promise<SubTypeOfHardware[]> =>
    Promise.resolve()
        .then(getAllSubHardwareTypesRaw)
        .then(rawSubType => rawSubType.map(rST => decodeJsonApiObject<SubTypeOfHardware>(rST)))

export const getAllFirmwareOptionList = (): Promise<Firmware[]> =>
    Promise.resolve()
        .then(getAllFirmwareListRaw)
        .then((rawFirmware => rawFirmware.map(rF => decodeJsonApiObject<Firmware>(rF))))

export const uploadFirmwareFile = (formData: FormData): Promise<Firmware> => postRequest({
    endpoint: `${API_FIRMWARE}`,
    data: formData
}).then(({ data }) => decodeJsonApiObject<Firmware>(data.data))


export const updateSingleOrGlobalFirmware = (
    newSettings: FirmwareLTEDataRequest,
    orgId: string,
    alertSourceId?: string
): Promise<Device | Organization> => {
    return (
        !isUndefined(alertSourceId) ?
            putRequest({
                endpoint: `${API_FIRMWARE}/alert_source_id/${alertSourceId}`,
                data: { firmware: newSettings }
            })
            : putRequest({
                endpoint: `${API_FIRMWARE}/global`,
                header: { org_id: orgId },
                data: { firmware: newSettings }
            })
    ).then(({ data }) => decodeJsonApiObject<Device | Organization>(data.data))
}

export const deleteFirmwareAPI = (firmwareId: string) => deleteRequest({
    endpoint: `${API_FIRMWARE}/${firmwareId}`,
})