import { flexWrap, overflowY, textAlign, textTransform } from 'styles'
import { getAppColors } from 'lib/constants'

export const linearProgressStyles = {
  backgroundColor: getAppColors('--color-linear-progress-1'),
  margin: '0 -15px',
  ':global(.MuiLinearProgress-bar1Indeterminate)': {
    backgroundColor: getAppColors('--color-linear-progress-2'),
  },
  ':global(.MuiLinearProgress-bar2Indeterminate)': {
    backgroundColor: getAppColors('--color-linear-progress-1'),
  },
}

export const actionRowStyles = {
  display: 'flex',
  justifyContent: 'flex-start',
  ...flexWrap('wrap'),
  fontFamily: 'Montserrat',
  gap: '10px 14px',
  marginBottom: '15px',
}

export const alertHistoryTitleStyles = {
  fontFamily: 'Montserrat',
  fontSize: '1.6rem',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: '1',
  letterSpacing: '0.1rem',
  ...textAlign('left'),
  alignItems: 'center',
  color: getAppColors('--color-primary'),
  padding: '0 20px',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: getAppColors('--color-gray-4'),
}

export const alertEventCountStyles = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '1.2rem',
  color: getAppColors('--colors-text-link'),
  padding: '20px 10px',
}

export const alertEventsWrapperStyles = {
  backgroundColor: getAppColors('--color-gray-3'),
  padding: '0 15px',
  ...overflowY('auto'),

  'div:first-child': {
    borderTop: 'none',
  },
}

export const detailHeadingStyles = {
  fontSize: '1.6rem',
  fontStyle: 'normal',
  fontWeight: 900,
  padding: '20px 0',
  margin: '0',
  ...textTransform('uppercase'),
}

export const detailStyles = { listStyle: 'none', margin: '0 0 15px 0', padding: 0 }
