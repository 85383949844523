import { trans, TranslationGroup, TranslationKey } from 'lib/types'
import { PopUpNotifications, Orgs } from 'models'

export const updateImgSrc = (id: string, setNewImgSrc: any, image: string, handleClose: any) => {
  const translation: TranslationGroup = trans.group(TranslationKey.ORGS)
  return Orgs.createOrUpdate({ id, props: { image } }).then(
    () => {
      setNewImgSrc('')
      handleClose()
    },
    () =>
      PopUpNotifications.fireError({
        content: translation.image_error,
      }),
  )
}
