import { createReduxModule } from 'hooks-for-redux'
import { Device, trans, TranslationGroup } from 'lib/types'
import {
  getAlertSourceById,
  getAllAlertSourcesWithPagination,
  deleteAlertSource,
  AlertSourcesWithPagination,
  Pagination,
  EMPTY_PAGINATION,
  getAlertSourcePage,
  AlertSourceParams,
  EMPTY_ALERT_SOURCES,
} from './api'
import * as NavState from './NavState'
import { ALL_ORGS_SELECTED, API_DEFAULT_LIMIT, ROWS_PER_PAGE } from 'lib/constants'
import { updateAlertSource, unregisterAlertSource, disableAlertSource } from './api/alertSources'
import { getRecordsById, getRecordsByOrgId } from './modelUtils'
import { PopUpNotifications } from 'models'
import * as RespondersState from './LiveResponders'

export interface DevicesState {
  devices: Device[]
  devicesById: { [key: string]: Device }
  devicesByOrgId: { [key: string]: Device[] }
  loading: boolean
  initialLoading: boolean
  pagination: Pagination
  error?: any
}

const initialState: DevicesState = {
  devices: [],
  devicesById: {},
  devicesByOrgId: {},
  loading: false,
  initialLoading: false,
  pagination: EMPTY_PAGINATION,
}

const setDevicesState = (state: DevicesState, devicesWithPagination: AlertSourcesWithPagination): DevicesState => {
  const devices = devicesWithPagination.alertSources
  return {
    ...state,
    devices: devices,
    devicesById: getRecordsById<Device>(devices),
    devicesByOrgId: getRecordsByOrgId<Device>(devices),
    pagination: devicesWithPagination.pagination,
  }
}

export const [
  use,
  { setDevice, setDevicesWithPagination, updateDevicesWithPagination, setError, setInitialLoading, setLoading },
  store,
] = createReduxModule('devices', initialState, {
  setDevice: (state: DevicesState, device: Device) => {
    let newDevices = [...state.devices]
    if (!state.devicesById[device.id]) {
      newDevices.push(device)
    } else {
      const index = state.devices.findIndex(u => u.id === device.id)
      newDevices[index] = device
    }
    return {
      ...state,
      devices: newDevices,
      devicesById: {
        ...state.devicesById,
        [device.id]: device,
      },
      devicesByOrgId: {
        ...state.devicesByOrgId,
        [device.orgId]: [...(state.devicesByOrgId[device.orgId] || []), device],
      },
    }
  },
  setDevicesWithPagination: (state: DevicesState, devicesWithPagination: AlertSourcesWithPagination) => {
    return setDevicesState(state, devicesWithPagination)
  },
  updateDevicesWithPagination: (
    state: DevicesState,
    devicesWithPagination: AlertSourcesWithPagination,
  ): DevicesState => {
    const updatedDevices = Array.from(new Set([...state.devices, ...devicesWithPagination.alertSources]))
    const updatedDevicesWithPagination: AlertSourcesWithPagination = {
      alertSources: updatedDevices,
      pagination: devicesWithPagination.pagination,
    }
    return setDevicesState(state, updatedDevicesWithPagination)
  },
  setError: (state: DevicesState, error: any) => ({ ...state, error }),
  setInitialLoading: (state: DevicesState, initialLoading: boolean) => ({ ...state, initialLoading }),
  setLoading: (state: DevicesState, loading: boolean) => ({ ...state, loading }),
})

const handleError = (errorObj: any) => {
  const translation: TranslationGroup = trans.common()
  const msg = errorObj instanceof Error ? errorObj.message : errorObj.toString()
  const content = `${translation.devices} ${msg.toLowerCase()}`
  PopUpNotifications.fireError({ content })
  setError(errorObj)
}

export const reloadDevice = (id: string) => {
  const state = store.getState()
  const device = state.devicesById[id]
  const now = new Date().getTime()
  if (device && now - device.lastFetched! < 60000) {
    return Promise.resolve().then(() => device)
  }
  if (device) setDevice({ ...device, lastFetched: now })
  Promise.resolve()
    .then(() => getAlertSourceById(id))
    .then(setDevice)
}

export const reload = async () => {
  const selectedOrgId = localStorage.getItem('selected_org_id')
  // Don't send request to server?
  if (!selectedOrgId || selectedOrgId === ALL_ORGS_SELECTED) {
    setDevicesWithPagination(EMPTY_ALERT_SOURCES)
    return
  }
  const params: AlertSourceParams = {
    limit: ROWS_PER_PAGE * 2,
    afterCursor: null,
    orgId: selectedOrgId,
  }
  setInitialLoading(true)
  setLoading(true)
  let pageOne = EMPTY_ALERT_SOURCES
  let errorObj
  try {
    pageOne = await getAlertSourcePage(params)
  } catch (err: any) {
    errorObj = err
  }
  setInitialLoading(false)
  if (errorObj) {
    if (errorObj.response.status >= 500) handleError(errorObj)
    setLoading(false)
    return
  }
  params.limit = API_DEFAULT_LIMIT
  params.afterCursor = pageOne.pagination.after
  params.orgId = selectedOrgId
  setDevicesWithPagination(pageOne)

  let devices = EMPTY_ALERT_SOURCES
  try {
    devices = await getAllAlertSourcesWithPagination(params)
  } catch (err: any) {
    errorObj = err
  }
  if (errorObj) {
    if (errorObj.response.status >= 500) handleError(errorObj)
    setLoading(false)
    return
  }
  updateDevicesWithPagination(devices)
  setLoading(false)
}

const doUpdateAction = (f: any) => {
  setLoading(true)
  return Promise.resolve()
    .then(f)
    .then(result => {
      reload()
      return result
    }, setError)
}

export const useSelectedOrgsDevices = (responders: boolean) => {
  const selectedOrgId = NavState.use(({ selectedOrgId }) => selectedOrgId)
  const devices = use(({ devices }) => devices)
  // return (selectedOrgId === ALL_ORGS_SELECTED ? devices : devices.filter(a => a.orgId === selectedOrgId)).filter(
  //   device => !!device.responder === !!responders,
  // )

  /* Avoid duplicates - */
  if (selectedOrgId === ALL_ORGS_SELECTED) {
    let uniqueIds = new Set(devices.map(device => device.id))

    let filteredDevices = devices.filter(device => {
      if (uniqueIds.has(device.id)) {
        uniqueIds.delete(device.id)
        return true
      } else {
        return false
      }
    })

    filteredDevices = filteredDevices.filter(device => !!device.responder === !!responders)
    return filteredDevices
  } else {
    let filteredDevices = devices
      .filter(a => a.orgId === selectedOrgId)
      .filter(device => !!device.responder === !!responders)

    let uniqueIds = new Set(filteredDevices.map(device => device.id))
    filteredDevices = filteredDevices.filter(device => {
      if (uniqueIds.has(device.id)) {
        uniqueIds.delete(device.id)
        return true
      } else {
        return false
      }
    })

    return filteredDevices
  }
}

export const del = (deviceId: string) => doUpdateAction(() => deleteAlertSource(deviceId))

/** Update an specific device, them refresh the current H4R state. */
export const update = (device: any): Promise<Device> =>
  // @ts-ignore
  doUpdateAction(() => updateAlertSource(device))

export const unregisterDevice = (device: any): Promise<Device> =>
  // @ts-ignore
  doUpdateAction(() => unregisterAlertSource(device.id, device.orgId))

export const disableDevice = (device: any): Promise<Device> =>
  // @ts-ignore
  doUpdateAction(() => disableAlertSource(device.id))

RespondersState.store.subscribe(({ responderAdded }) => {
  if (responderAdded && responderAdded.responder.info!.resourceType === RespondersState.ResourceType.DEVICE) {
    reloadDevice(responderAdded?.responder.id)
  }
})
