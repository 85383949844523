import styled from '@emotion/styled'
import { FormLabel } from '@material-ui/core'
import { PRIMARY_COLOR } from 'lib/constants'

export const StyledFormLabel = styled(FormLabel)({
  fontFamily: 'Montserrat',
  fontSize: '1.2rem',
  fontWeight: 'bold',
  letterSpacing: '0.1rem',
  lineHeight: '1.5rem',
  color: PRIMARY_COLOR,
  textTransform: 'uppercase',
})
