import { Dialog, DialogContent } from '@material-ui/core'
import { StyledDialogTitle } from 'components/legacy-poorly-defined/Common'
import { ActionButton, StyledDialogActions } from 'components/widgets'
import { trans, TranslationGroup } from 'lib/types'

export const ConfirmationDialog = (props: any) => {
  const { title, description, open, onConfirm, handleClose } = props
  const common: TranslationGroup = trans.common()

  return (
    <Dialog open={!!open} onClose={handleClose} maxWidth="sm" fullWidth>
      <StyledDialogTitle>{title}</StyledDialogTitle>
      <DialogContent>{description}</DialogContent>
      <StyledDialogActions>
        <ActionButton width="110px" height="28px" onClick={handleClose}>
          {common.cancel}
        </ActionButton>
        <ActionButton width="110px" height="28px" onClick={onConfirm}>
          {common.confirm}
        </ActionButton>
      </StyledDialogActions>
    </Dialog>
  )
}
