import styled from '@emotion/styled'
import { Button } from '@material-ui/core'
import { BORDER1_COLOR, getAppColors, PRIMARY_COLOR } from 'lib/constants'

const primary = getAppColors('--color-primary')
const primaryHover = getAppColors('--color-primary-highlight')
const gray1 = getAppColors('--color-gray-1')

export const SectionWrapper = styled.div({
  margin: '10px',
})

export const StyledFirmwareContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '40px',
  marginBottom: '10px',
  span: {
    flex: 1,
  },
})

export const StyledAlternatingTableContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  padding: 10px;
  > span {
    flex: 1;
  }
  &:nth-child(even) {
    background: white;
  }
  &:nth-child(odd) {
    background: #fcfcfc;
  }
`

export const StyledColumnContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
})

export const StyledTitleText = styled.span({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '1.4rem',
  letterSpacing: '0.1rem',
  lineHeight: '1.5rem',
  textTransform: 'uppercase',
  color: PRIMARY_COLOR,
})

export const StyledRegularText = styled.div({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  lineHeight: '1.9rem',
  color: '#242424',
})

export const StyledSubText = styled.span({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  lineHeight: '1.9rem',
  color: '#242424',
})

export const StyledSeparator = styled.div({
  border: `1px solid ${BORDER1_COLOR}`,
  marginTop: '18px',
  marginBottom: '20px',
})

export const SmallMarginStyledSeparator = styled.div({
  border: `1px solid ${BORDER1_COLOR}`,
  marginTop: '8px',
  marginBottom: '10px',
})

export const StyledTableContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

export const ActionsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '1.6rem',
  margin: '1.6rem 0',
  span: {
    flex: 1,
  },
})

export const StyledButton = styled(Button)({
  color: primary,
  padding: '8px 20px',
  border: `2px solid ${primary}`,
  boxSizing: 'border-box',
  fontFamily: 'Montserrat',
  boxShadow: '0px 4px 8px rgba(20, 48, 72, 0.1)',
  fontSize: '1.0rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.2rem',
  letterSpacing: '0.1rem',
  textAlign: 'left',
  minWidth: '130px',
  cursor: 'pointer',
  borderRadius: '2px',
  height: '32px',
})

export const ConfirmButton = styled(StyledButton)`
  color: white;
  background: ${primary};
  &:hover {
    background: ${primaryHover};
  }
  &:disabled {
    color: ${gray1};
    opacity: 0.5;
  }
`

export const StyledLinkableText = styled.span({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  color: '#29a096',
  texTransform: 'none',
})
