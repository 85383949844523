import { getAppColors } from './ui'

export const BACKGROUND2_COLOR = `#f7f7f7`
export const BORDER1_COLOR = getAppColors(`--color-gray-1`)
export const BRAND_COLOR = getAppColors(`--color-brand`)
export const DISABLED_COLOR = `#808080`
export const ERROR_COLOR = getAppColors(`--color-error`)
export const TEXTFIELD_LABEL_COLOR = getAppColors(`--color-textfield-label`)
export const TITLE_COLOR = `#4e5d78`
export const LINK_COLOR = `#323b4b`
export const PRIMARY_COLOR = getAppColors(`--color-primary`)
export const SECONDARY_COLOR = getAppColors(`--color-secondary`)
export const GRAY3_COLOR = getAppColors(`--color-gray-3`)
export const COLOR_INVALID = getAppColors(`--color-invalid`)
