export const BASE_URL = (window._env_?.MAIN_API_URL || 'https://api.dev.reactmobile-infra.net') + '/'
export const BASE_ALERTS_URL = (window._env_?.ALERT_API_URL || 'https://alert-api.dev.reactmobile-infra.net') + '/'

export const API_AUTH = `auth`
export const API_RESEND_INVITE = `${API_AUTH}/resend/confirm`

export const API_DEFAULT_LIMIT = 2500
export const API_VERSION = 'api/v4'

export const API_BEACONS = `${API_VERSION}/beacons`
export const API_ALERT_SOURCES = `${API_VERSION}/alert_sources`
export const API_DEVICE_TYPES = `${API_VERSION}/device_types`
export const API_EXTERNAL_INTEGRATIONS = `${API_VERSION}/external_integrations`
export const API_MICRO_JWT = `${API_ALERT_SOURCES}/installer_micro_jwt`
export const API_ORGANIZATIONS = `${API_VERSION}/orgs`
export const API_ALERT_SESSIONS = `${API_VERSION}/alert_sessions`
export const API_ALERT_EVENTS = `${API_VERSION}/alert_events`
export const ALERT_API_ALERT_EVENT = `${API_VERSION}/alert_event`
export const API_ALERT_TARGETS = `${API_VERSION}/alert_targets`
export const API_USER = `${API_VERSION}/user`
export const API_USERS = `${API_VERSION}/users`
export const API_CHILD_ORGS = `${API_VERSION}/child_orgs`
export const API_RESPONDERS = `${API_VERSION}/responders`
export const API_RESPONDER_LIST = `${API_VERSION}/responders/list`
export const API_GRANTS = `${API_VERSION}/grants`
export const API_FIRMWARE = `${API_VERSION}/firmware`
export const API_UNREGISTER_ALERT_SOURCE = `${API_ALERT_SOURCES}/unregister`
export const API_UNREGISTER_BEACON = `${API_BEACONS}/unregister`
export const ADD_USER_PATH = `${API_GRANTS}/associate`
export const PUSHER_APP_AUTH = `${BASE_URL}pusher/auth`

export const API_LEGACY_APP = `${BASE_URL}/qr/legacy_app`
export const API_NEW_APP = `${BASE_URL}/qr/new_app`

export const AUTH_OAUTH = `${BASE_URL}/${API_AUTH}/`
export const AUTH_LOGIN = `${API_AUTH}/identity`
export const AUTH_PROVIDER = `${API_AUTH}/provider/`
export const AUTH_REGISTER = `${API_AUTH}/register`
export const AUTH_RESEND_PASSWORD = `${API_AUTH}/resend/password`
export const AUTH_RESEND_CONFIRM = `${API_AUTH}/resend/confirm`
export const AUTH_CONFIRM_EMAIL = `${API_AUTH}/confirm`
export const AUTH_UPDATE_PASSWORD = `${API_AUTH}/reset_password`
