/** @jsxImportSource @emotion/react */
import { useState } from 'react'
import { jsx } from '@emotion/react/macro'
import styled from '@emotion/styled/macro'
import QRCode from 'qrcode.react'
import { NavState } from 'models'
import { ActionButton, StyledDialogActions } from 'components/widgets'
import { createMicroJWT } from 'models/api/alertSources'
import { useEffect } from 'react'
import { JWT_TOKEN, MOBILE_APP_PATH } from 'lib/constants'
import { trans, TranslationGroup, TranslationKey } from 'lib/types'
import { Duration, intervalToDuration } from 'date-fns'

const QRCCWrapper = styled.div({
  width: '100%',
  alignContent: 'center',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
})

const QRCItemContainer = styled.div({
  textAlign: 'center',
  marginBottom: '10px',
})

const LoadingWrapper = styled.div({
  width: '300px',
  height: '300px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const QRCItem = styled(QRCode)({
  padding: '1%',
  border: '1px solid black',
  borderRadius: '4px',
})
interface AddDeviceQRCProps {
  subtitle?: string
  value?: string
  responders?: boolean
  closeModal: () => void
}

const zeroPad = (num: number | undefined) => String(num).padStart(2, '0')

export const AddDeviceQRC = ({ responders, closeModal, value, subtitle }: AddDeviceQRCProps) => {
  const [QRVal, setQRVal] = useState<string | undefined>()
  const selectedOrgId = NavState.use(({ selectedOrgId }) => selectedOrgId)
  const [token, setToken] = useState<string | undefined>()
  const totalSeconds = 600
  const [seconds, setSeconds] = useState(totalSeconds)
  const [duration, setDuration] = useState<Duration>(intervalToDuration({ start: 0, end: totalSeconds * 1000 }))
  const [resetCount, setResetCount] = useState(0)

  const retrieveMicroJWT = (f: any) => {
    return Promise.resolve()
      .then(f)
      .then((result: any) => {
        setToken(result.token)
        return result
      })
  }

  useEffect(() => {
    if (value) {
      setQRVal(value)
    } else {
      setSeconds(totalSeconds)
      retrieveMicroJWT(() => createMicroJWT(responders || false, selectedOrgId))
    }
  }, [resetCount])

  useEffect(() => {
    if (value) return
    if (seconds === 0) {
      setResetCount(resetCount + 1)
      return
    }
    let interval: ReturnType<typeof setInterval>
    interval = setInterval(() => {
      const tmp = seconds - 1
      setSeconds(tmp)
      setDuration(intervalToDuration({ start: 0, end: tmp * 1000 }))
    }, 1000)
    return () => clearInterval(interval)
  }, [seconds])

  useEffect(() => {
    if (token) {
      const url = new URL(window.location.href)
      const base = `${url.protocol}//${url.hostname}`
      const port = url.port ? `:${url.port}` : ''
      setQRVal(`${base}${port}${MOBILE_APP_PATH}?${JWT_TOKEN}=${token}`)
    }
  }, [token])
  const translation: TranslationGroup = trans.merge(TranslationKey.MODAL_QR)
  return (
    <QRCCWrapper>
      {!value ? (
        <QRCItemContainer>
          {translation.register} {responders ? translation.responder : translation.device_alert_source}
        </QRCItemContainer>
      ) : (
        <QRCItemContainer>{subtitle}</QRCItemContainer>
      )}
      {!value && (
        <QRCItemContainer>{`${translation.expires}: ${zeroPad(duration.minutes)}:${zeroPad(
          duration.seconds,
        )}`}</QRCItemContainer>
      )}
      <QRCItemContainer>
        {QRVal && <QRCItem includeMargin={false} renderAs={`svg`} size={300} level={`L`} value={QRVal} />}
        {!QRVal && <LoadingWrapper>{translation.loading}</LoadingWrapper>}
      </QRCItemContainer>
      <StyledDialogActions>
        <ActionButton onClick={() => closeModal()}>{translation.close}</ActionButton>
      </StyledDialogActions>
    </QRCCWrapper>
  )
}
