import { Dialog, DialogContent } from '@material-ui/core'
import { StyledDialogTitle } from 'components/widgets'
import { Modal } from 'models'
import React from 'react'

export const StaticModal = () => {
  const { modalComponent: ModalComponent, modalProps, isOpen, title } = Modal.use()

  return (
    <React.Fragment key={'center'}>
      <Dialog open={isOpen} onClose={Modal.close} maxWidth="sm" fullWidth>
        <StyledDialogTitle>{title}</StyledDialogTitle>
        <DialogContent>{ModalComponent && React.cloneElement(ModalComponent, modalProps)}</DialogContent>
      </Dialog>
    </React.Fragment>
  )
}
