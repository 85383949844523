// import React, { useState, useEffect } from 'react'
// import { Howler } from 'howler'
// import AudioDisconnectWarning from 'static/images/audio-disconnect-warning.png'
// import { Button, Typography } from '@material-ui/core'
// import { BRAND_COLOR, PRIMARY_COLOR } from 'lib/constants'

// type Props = {}

// const AudioContextOverlay = (props: Props) => {
//   const [audioContextStarted, setAudioContextStarted] = useState(false)
//   const [headerHeight, setHeaderHeight] = useState(0)

//   useEffect(() => {
//     const checkAudioContextState = () => {
//       setAudioContextStarted(Howler.ctx.state === 'running')
//     }
//     checkAudioContextState()

//     const isRunning = Howler.ctx.state === 'running'
//     if (!isRunning) {
//       document.title = 'ATTENTION REQUIRED!'
//       document.addEventListener(
//         'click',
//         () => {
//           setAudioContextStarted(true)
//           document.title = 'Customer Portal'
//         },
//         { once: true },
//       )
//     }

//     const handleResize = () => {
//       const headerElement = document.getElementById('main-header')
//       if (headerElement) {
//         setHeaderHeight(headerElement.offsetHeight)
//       }
//     }

//     handleResize()
//     window.addEventListener('resize', handleResize)
//     return () => {
//       window.removeEventListener('resize', handleResize)
//     }
//   }, [])

//   const handleOverlayClick = () => {
//     setAudioContextStarted(true)
//   }

//   return (
//     <>
//       {!audioContextStarted && (
//         <div
//           id="audioContext-banner"
//           style={{
//             marginTop: headerHeight,
//             zIndex: 9999,
//             position: 'fixed',
//             top: 0,
//             left: 0,
//             backgroundColor: 'white',
//             width: '100vw',
//             height: '100vh',
//             display: 'flex',
//             flexDirection: 'column',
//             justifyContent: 'center',
//             alignItems: 'center',
//             color: 'black',
//             fontSize: '1.5em',
//             overflow: 'hidden',
//             opacity: '0.7',
//           }}
//           onClick={handleOverlayClick}
//         >
//           <img style={{ opacity: '1', width: '40%', height: 'auto' }} src={AudioDisconnectWarning} />
//           {/* <Typography variant="h4">Audio was interrupted by refreshing the web browser</Typography>
//           <Typography variant="h2" style={{ color: BRAND_COLOR, fontWeight: 2 }}>
//             Click anywhere to reconnect
//           </Typography> */}
//         </div>
//       )}
//     </>
//   )
// }

// export default AudioContextOverlay
import React, { useState, useEffect } from 'react'
import { Howler } from 'howler'
import AudioDisconnectWarning from 'static/images/audio-disconnect-warning.png'
import { Button, Typography } from '@material-ui/core'
import { BRAND_COLOR, PRIMARY_COLOR } from 'lib/constants'

type Props = {}

const AudioContextOverlay = (props: Props) => {
  const [audioContextStarted, setAudioContextStarted] = useState(false)
  const [headerHeight, setHeaderHeight] = useState(0)

  useEffect(() => {
    const checkAudioContextState = () => {
      setAudioContextStarted(Howler.ctx.state === 'running')
    }
    checkAudioContextState()

    const isRunning = Howler.ctx.state === 'running'
    if (!isRunning) {
      document.title = 'ATTENTION REQUIRED!'
      document.addEventListener(
        'click',
        () => {
          setAudioContextStarted(true)
          document.title = 'Customer Portal'
        },
        { once: true },
      )
    }

    const handleResize = () => {
      const headerElement = document.getElementById('main-header')
      if (headerElement) {
        setHeaderHeight(headerElement.offsetHeight)
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleOverlayClick = () => {
    setAudioContextStarted(true)
  }

  return (
    <>
      {!audioContextStarted && (
        <div
          id="audioContext-banner"
          style={{
            marginTop: headerHeight,
            zIndex: 9999,
            position: 'fixed',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.85)',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: 'black',
            fontSize: '1.5em',
            overflow: 'hidden',
          }}
          onClick={handleOverlayClick}
        >
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', opacity: 1 }}>
            <img style={{ width: '40%', height: 'auto' }} src={AudioDisconnectWarning} />
          </div>
          {/* <Typography variant="h4">Audio was interrupted by refreshing the web browser</Typography>
          <Typography variant="h2" style={{ color: BRAND_COLOR, fontWeight: 2 }}>
            Click anywhere to reconnect
          </Typography> */}
        </div>
      )}
    </>
  )
}

export default AudioContextOverlay
