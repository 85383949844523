import * as CSS from 'csstype'

export function alignContent(style: CSS.Property.AlignContent): CSS.Properties {
  return {
    alignContent: style,
  }
}

export function boxSizing(style: CSS.Property.BoxSizing): CSS.Properties {
  return {
    boxSizing: style,
  }
}

export function flexDirection(style: CSS.Property.FlexDirection): CSS.Properties {
  return {
    flexDirection: style,
  }
}

export function flexFlow(style: CSS.Property.FlexFlow): CSS.Properties {
  return {
    flexFlow: style,
  }
}

export function flexWrap(style: CSS.Property.FlexWrap): CSS.Properties {
  return {
    flexWrap: style,
  }
}

export function fontWeight(style: CSS.Property.FontWeight): CSS.Properties {
  return {
    fontWeight: style,
  }
}

export function justifyContent(style: CSS.Property.JustifyContent): CSS.Properties {
  return {
    justifyContent: style,
  }
}

export function overflowWrap(style: CSS.Property.OverflowWrap): CSS.Properties {
  return {
    overflowWrap: style,
  }
}

export function overflowX(style: CSS.Property.OverflowX): CSS.Properties {
  return {
    overflowX: style,
  }
}

export function overflowY(style: CSS.Property.OverflowY): CSS.Properties {
  return {
    overflowY: style,
  }
}

export function position(style: CSS.Property.Position): CSS.Properties {
  return {
    position: style,
  }
}

export function textAlign(style: CSS.Property.TextAlign): CSS.Properties {
  return {
    textAlign: style,
  }
}

export function textTransform(style: CSS.Property.TextTransform): CSS.Properties {
  return {
    textTransform: style,
  }
}

export function whiteSpace(style: CSS.Property.WhiteSpace): CSS.Properties {
  return {
    whiteSpace: style,
  }
}
