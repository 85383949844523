export enum AuthTokenParam {
  RESET_TOKEN = 'rt',
  CONFIRM_TOKEN = 'ct',
}

export enum AuthProvider {
  UNKNOWN = 'unknown',
  APPLE = 'apple',
  GITHUB = 'github',
  GOOGLE = 'google',
  IDENTITY = 'identity',
  MICROSOFT = 'microsoft',
}

export const PASSWORD_REGEX = {
  // VALID: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#!?^*])[A-Za-z\d#!?^*]{12,}$/,
  LOWER: /[a-z]+/,
  UPPER: /[A-Z]+/,
  NUMBER_SYMBOL: /[\d`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/,
}
