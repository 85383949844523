/* eslint-disable react/jsx-curly-newline */
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { JWT_TOKEN } from 'lib/constants'

interface Props {
  component: React.ComponentType
  path: string
  exact?: boolean
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.

function PrivateRoute({ component, path, exact }: Props) {
  const isAuthenticated = !!localStorage.getItem(JWT_TOKEN)
  return isAuthenticated ? (
    <Route path={path} exact={exact} component={component} />
  ) : (
    <Redirect to="/" />
  )
}

export default PrivateRoute
