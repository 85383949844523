import { shallowEqual } from 'react-redux'
import { memo, useEffect, useState } from 'react'
import isNil from 'lodash/isNil'
import { ActionButton, ViewWrapper, PageWrapper, ViewSpacerWrapper } from 'components/widgets'
import { ALL_ORGS_SELECTED, BeaconsColumnKeys, BEACONS_PATH } from 'lib/constants'
import { Beacons, NavState, DetailsDrawer, PopUpNotifications } from 'models'
import * as utils from './utils'
import { PreConfiguredTableView } from 'components/partials/TableView'
import { SortOrder, TableState, TableToolEvent, Beacon, RouteParams, trans, TranslationGroup } from 'lib/types'
import { invertBinaryEnum } from 'lib/utils/common'
import { BeaconDetailDrawer } from 'components/partials/DrawerDetails/BeaconDetailDrawer'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'models/modelUtils'
import { RenderCounter } from 'components/widgets/RenderCounter'

function BeaconsPage() {
  const common: TranslationGroup = trans.common()
  const history = useHistory()
  const { id } = useParams<RouteParams>()
  const query = useQuery()
  const selectedOrgId = NavState.use(({ selectedOrgId }) => selectedOrgId)
  const { beacons, beaconsById, beaconsByOrgId, initialLoading, loading } = Beacons.use(
    ({ beacons, beaconsById, beaconsByOrgId, initialLoading, loading }) => {
      return { beacons, beaconsById, beaconsByOrgId, initialLoading, loading }
    },
    shallowEqual,
  )
  const [filteredItems, setfilteredItems] = useState<Beacon[]>([])
  const [tableState, setTableState] = useState<TableState<string, BeaconsColumnKeys>>({
    filterBy: utils.getFilterOptions()[0],
    sortBy: utils.getSortOptions()[0].sortBy,
    searchBy: '',
  })

  useEffect(() => {
    if (id && (!loading || !initialLoading)) {
      const item = beaconsById[id]
      if (item) {
        handleRowClick(item)
      } else if (!loading) {
        PopUpNotifications.fireWarning({ content: `${common.beacon} ${id} ${common.was_not_found}` })
        history.replace(BEACONS_PATH)
      }
    }
  }, [id, initialLoading, loading])

  useEffect(() => {
    const page = query.get('page')
    if (page === '1' || loading) return
    query.set('page', '1')
    history.push({
      search: query.toString(),
    })
  }, [tableState])

  useEffect(() => {
    if (!loading || !initialLoading) {
      processItems()
    }
  }, [loading, initialLoading, beacons, tableState, query])

  function processItems() {
    const selectedBeacons = selectedOrgId === ALL_ORGS_SELECTED ? beacons : beaconsByOrgId[selectedOrgId] || []
    if (!selectedBeacons.length && !filteredItems.length) return
    const opts = utils.getFilterOptions()
    const idx = opts.indexOf(tableState.filterBy)
    const filtered = utils
      .searchBeacons(selectedBeacons, tableState.searchBy)
      .filter(beacon => (idx === 0 ? true : utils.getFilterBeaconComparison(beacon, tableState.filterBy)))
      .sort(utils.getSortBeaconCompareFn(tableState.sortBy))
    setfilteredItems(filtered)
  }

  function handleDrawerClose() {
    history.push({
      pathname: `${BEACONS_PATH}`,
      search: query.toString(),
    })
    DetailsDrawer.close()
  }

  function handleRowClick(row: Beacon) {
    if (row.id != id) {
      history.push({
        pathname: `${BEACONS_PATH}/${row.id}`,
        search: query.toString(),
      })
    }
    DetailsDrawer.show({
      drawerComponent: BeaconDetailDrawer,
      drawerProps: {
        beacon: row,
        close: handleDrawerClose,
      },
    })
  }

  function handleHeaderClick(header: string) {
    setTableState(state => {
      return Object.assign({}, state, {
        sortBy: {
          field: header,
          order: state.sortBy.field === header ? invertBinaryEnum(state.sortBy.order) : SortOrder.Ascending,
        },
      })
    })
  }

  function handleToolEvent(toolEvent: TableToolEvent<string, BeaconsColumnKeys>) {
    setTableState(state => ({
      filterBy: toolEvent.filterByRequest || state.filterBy,
      sortBy: toolEvent.sortByRequest || state.sortBy,
      searchBy: isNil(toolEvent.searchByRequest) ? state.searchBy : toolEvent.searchByRequest,
    }))
  }

  return (
    <PageWrapper>
      <ViewWrapper>
        <ViewSpacerWrapper>
          <RenderCounter name={'BeaconsPage'} />
          <PreConfiguredTableView
            sortedData={filteredItems}
            tableState={tableState}
            columnConfigs={utils.getColumnConfigs()}
            filterOptions={utils.getFilterOptions()}
            sortOptions={utils.getSortOptions()}
            getRowToolTip={utils.getBeaconTooltip}
            onHeaderClick={handleHeaderClick}
            onRowClick={handleRowClick}
            onToolEvent={handleToolEvent}
            selectedRowId={id}
            loading={loading}
          >
            <ActionButton onClick={() => utils.downloadCsv(filteredItems)}>{common.download_csv}</ActionButton>
          </PreConfiguredTableView>
        </ViewSpacerWrapper>
      </ViewWrapper>
    </PageWrapper>
  )
}

export default memo(BeaconsPage)
