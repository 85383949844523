import axios from 'axios'
import { BASE_URL, BASE_ALERTS_URL, JWT_TOKEN, RELOGIN_PARAM } from 'lib/constants'
import { Error } from 'lib/types'

declare module 'axios' {
  export interface AxiosResponse<T = any> extends Promise<T> {}
}

const API = axios.create({
  baseURL: BASE_URL,
})

export const AxiosInstanceForMocking = API

API.interceptors.request.use((config: any) => {
  const token = localStorage.getItem(JWT_TOKEN)

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
})

/***  function for retrying failed api calls - revisit ***/
// const sleepRequest = (milliseconds: any, originalRequest: any) => {
//   return new Promise((resolve, reject) => {
//     setTimeout(() => resolve(API(originalRequest)), milliseconds)
//   })
// }
// useful link: https://gist.github.com/edmondburnett/38ed3451de659dc43fa3f24befc0073b

API.interceptors.response.use(
  res => res,
  err => {
    if (err?.response?.status === 401) {
      logout(err.response.data?.errors)
      console.log('Unauthorized Call')
    } else {
      throw err
    }
    console.log('logging out')
  },
)

function logout(errors: Array<Error>) {
  const code = errors[0]?.code || 40100
  localStorage.removeItem(JWT_TOKEN)
  window.location.href = `/?${RELOGIN_PARAM}=${code}`
}

export const putRequest = ({ endpoint, data = {}, header }: { endpoint: string; data?: any; header?: any }) =>
  API.put(`${BASE_URL}${endpoint}`, data, { headers: header })

export const postRequest = ({ endpoint, data = {}, header }: { endpoint: string; data: any; header?: any }) =>
  API.post(`${BASE_URL}${endpoint}`, data, { headers: header })

export const postAlertRequest = ({ endpoint, data = {}, header }: { endpoint: string; data: any; header?: any }) =>
  API.post(`${BASE_ALERTS_URL}${endpoint}`, data, { headers: header })

export const getRequest = ({ endpoint, header = {} }: { endpoint: string; header?: any }) =>
  API.get(`${BASE_URL}${endpoint}`, {
    headers: header,
  })

export const deleteRequest = ({ endpoint, header }: { endpoint: string; header?: any }) =>
  API.delete(`${BASE_URL}${endpoint}`, {
    headers: header,
  })
