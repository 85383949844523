import { HoverBox } from 'components/widgets'
import { Users } from 'models'
import { trans, TranslationGroup } from 'lib/types'

export const ViewUsers = ({ userIds }: { userIds: string[] }) => {
  const users = Users.use(({ users }) => users)
  const common: TranslationGroup = trans.common()
  const userNames = userIds.map(id => users.find(user => user.id === id)).map(user => user!?.attributes.email || '')
  return <HoverBox content={userNames}>{userNames.length ? common.view : ''}</HoverBox>
}
