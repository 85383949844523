import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { setOrgSortOrder } from 'models/NavState'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
import { BASE_URL } from 'lib/constants'
import { trans, TranslationKey } from 'lib/types'

export const availableLanguages = ['en-US', 'es-419', 'fr-CA']

let language = localStorage.getItem('language')

if (language === undefined) {
  language = 'en-US'
}

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage'],
}

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .use(LanguageDetector)

  .init({
    // resources,
    backend: {
      loadPath: `${BASE_URL}locales/{{ns}}/{{lng}}/portal`,
      requestOptions: {
        // used for fetch, can also be a function (payload) => ({ method: 'GET' })
        mode: 'cors',
        credentials: 'same-origin',
        cache: 'default',
      },
    },
    detection: DETECTION_OPTIONS,
    fallbackLng: 'en-US',
    lng: language,
    load: 'currentOnly',
    // debug only when not in production
    debug: process.env.NODE_ENV !== 'production',
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: true,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      useSuspense: true,
    },
  })
  .then(() => {
    resetSortOrder()
  })

i18n.on('languageChanged', lng => {
  localStorage.setItem('language', lng)
  resetSortOrder()
})

function resetSortOrder() {
  const translation = trans.group(TranslationKey.DASHBOARD)
  const sortOptions = JSON.parse(translation.sort_options)
  setOrgSortOrder(sortOptions[0])
}

export default i18n
