import {} from './cssProps'
import { TITLE_COLOR } from 'lib/constants'

export const rowContainerStyles = {
  width: '100%',
  marginBottom: '2.4rem',
}

export const titleRowContainerStyles = {
  marginBottom: '5.0rem',
}

export const titleTextStyles = {
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '1.8rem',
  lineHeight: '2.9rem',
  color: TITLE_COLOR,
  margin: 0,
}
