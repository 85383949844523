import { API_DEFAULT_LIMIT } from 'lib/constants'

export interface Pagination {
  after?: string | null
  before?: string | null
  limit: number
  totalCount: number
  totalCountCapExceeded: boolean
}

export const EMPTY_PAGINATION: Pagination = { limit: API_DEFAULT_LIMIT, totalCount: 0, totalCountCapExceeded: false }

// @ts-ignore
export const decodePagination = (rawMetaPagination, records: any[]): Pagination => {
  if (rawMetaPagination) {
    const {
      // @ts-ignore
      before,
      // @ts-ignore
      after,
      // @ts-ignore
      limit,
      // @ts-ignore
      total_count,
      // @ts-ignore
      totalCount,
      // @ts-ignore
      total_count_cap_exceeded,
      // @ts-ignore
      totalCountCapExceeded,
    } = rawMetaPagination
    return {
      before,
      after,
      limit,
      totalCount: totalCount || total_count,
      totalCountCapExceeded: totalCountCapExceeded != null ? totalCountCapExceeded : total_count_cap_exceeded,
    }
  } else {
    return {
      before: null,
      after: null,
      limit: records.length,
      totalCount: records.length,
      totalCountCapExceeded: false,
    }
  }
}
