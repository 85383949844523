import _ from 'lodash'
import { fuzzySearch } from '@art-suite/art-fuzzy-search'
import {
  SortBy,
  SortOption,
  SortOrder,
  TableColumnConfig,
  User,
  trans,
  TranslationGroup,
  TranslationKey,
} from 'lib/types'
import { createSortOption } from 'lib/utils/common'
import { generateCSV } from 'lib/utils/csv'
import { Orgs } from 'models'
import { formatDistanceToNow, parseTime, getNameFromUser } from 'lib/utils'
import { differenceInMilliseconds } from 'date-fns'
import { EditUserRoleButton } from 'components/partials'
import { Button } from '@material-ui/core'
import { RemoveUserButton } from 'components/partial-pages/AdminView/Partials'

// types
export enum UsersColumn {
  Name = 'Name',
  Email = 'Email',
  Provider = 'Provider',
  LastCheckIn = 'Last_check_in',
  Membership = 'Membership',
  Roles = 'Roles',
  Remove = 'Remove',
  View = 'View',
}

export function getFilterOptions() {
  const translation: TranslationGroup = trans.group(TranslationKey.USER_TABLE_VIEW)
  return [translation.all_users, translation.no_membership]
}

function invertIfDescending(diff: number, sortOrder: SortOrder) {
  return sortOrder === SortOrder.Descending ? diff * -1 : diff
}

export const getSortOptions: () => SortOption<UsersColumn>[] = () => {
  const translation: TranslationGroup = trans.merge(TranslationKey.USER_TABLE_VIEW)
  return _.flatten(
    _.map(UsersColumn, c => [
      createSortOption(c, SortOrder.Descending, `${translation[c]} ↓`),
      createSortOption(c, SortOrder.Ascending, `${translation[c]} ↑`),
    ]),
  )
}

export function searchUsers(users: User[], searchBy: string): User[] {
  // This function is very expensive, only execute if necessary
  // TODO: Look for ways to improve efficiency
  if (searchBy.length < 2) return users
  const searchArray = _.flatten(
    users.map(user => [
      [user.attributes.email, user.id],
      [user.attributes.firstName, user.id],
      [user.attributes.lastName, user.id],
      [user.attributes.provider, user.id],
      [getMembershipFromUser(user), user.id],
    ]),
  ) as string[][]

  const searchResults = fuzzySearch(searchBy, searchArray)

  const searchResultsIds = searchResults.map(result => result[1] || null).filter(result => result !== null)
  return users.filter(user => searchResultsIds.includes(user.id))
}

export function getFilterUserComparison(user: User, filterBy: string) {
  // if (!getFilterOptions().includes(filterBy)) throw new Error()
  if (filterBy === getFilterOptions()[0]) return true
  return _.isEmpty(user.attributes.membership)
}

export function getMembershipFromUser(user: User, joinChar?: string) {
  if (!user.attributes.membership) return ''
  return user.attributes.membership.map(o => o.name).join(joinChar || ', ')
}

export const getAdminUsersColumnConfig = (
  orgId: string,
  handleRowClick: any,
): TableColumnConfig<UsersColumn, User>[] => {
  const translation: TranslationGroup = trans.group(TranslationKey.USER_TABLE_VIEW)
  const common: TranslationGroup = trans.common()
  return [
    {
      header: UsersColumn.Name,
      label: common[UsersColumn.Name],
      renderFn: (user: User) => getNameFromUser(user),
    },
    {
      header: UsersColumn.Email,
      label: common[UsersColumn.Email],
      renderFn: (user: User) => user.attributes.email,
    },
    {
      header: UsersColumn.Provider,
      label: translation[UsersColumn.Provider],
      renderFn: (user: User) => (user.attributes.provider === 'identity' ? common.password : user.attributes.provider),
    },
    {
      header: UsersColumn.LastCheckIn,
      label: common[UsersColumn.LastCheckIn],
      renderFn: (user: User) => {
        return user.attributes.isOnline
          ? translation.online_now
          : user.attributes.lastCheckedInAt
          ? formatDistanceToNow(user.attributes.lastCheckedInAt)
          : ''
      },
    },
    {
      header: UsersColumn.Membership,
      label: common[UsersColumn.Membership],
      renderFn: (user: User) => getMembershipFromUser(user),
    },
    {
      header: UsersColumn.Roles,
      label: common[UsersColumn.Roles],
      renderFn: (user: User) => <EditUserRoleButton userId={user.id} orgId={orgId} />,
      sortable: false,
      requiresOrg: true,
    },
    {
      header: UsersColumn.Remove,
      label: '',
      renderFn: (user: User) => <RemoveUserButton user={user} orgId={orgId} />,
      sortable: false,
      requiresAdmin: true,
      requiresOrg: true,
    },
    {
      header: UsersColumn.View,
      label: '',
      renderFn: (user: User) => <Button onClick={() => handleRowClick(user)}>{common.View}</Button>,
      sortable: false,
    },
  ]
}

export function getSortUserCompareFn(sortBy: SortBy<UsersColumn>): (a: User, b: User) => number {
  switch (sortBy.field) {
    case UsersColumn.Name:
      return (a: User, b: User) =>
        normalizeCompareResult(invertIfDescending(simpleSort(getNameFromUser(a), getNameFromUser(b)), sortBy.order))

    case UsersColumn.Email:
      return (a: User, b: User) =>
        normalizeCompareResult(invertIfDescending(simpleSort(a.attributes.email, b.attributes.email), sortBy.order))

    case UsersColumn.Provider:
      return (a: User, b: User) => {
        const aprop = a.attributes.provider ? a.attributes.provider : ''
        const bprop = b.attributes.provider ? b.attributes.provider : ''
        return normalizeCompareResult(invertIfDescending(simpleSort(aprop, bprop), sortBy.order))
      }

    case UsersColumn.LastCheckIn:
      return (a: User, b: User) =>
        normalizeCompareResult(
          invertIfDescending(lastUpdatedSort(a.attributes.lastCheckedInAt, b.attributes.lastCheckedInAt), sortBy.order),
        )

    case UsersColumn.Membership:
      return (a: User, b: User) =>
        normalizeCompareResult(
          invertIfDescending(simpleSort(getMembershipFromUser(a), getMembershipFromUser(b)), sortBy.order),
        )

    default:
      console.warn(`Unhandled sort column ${sortBy.field}`)
      return () => 0
  }
}

function simpleSort(a: any, b: any) {
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

function lastUpdatedSort(a: any, b: any) {
  if (!a) return -1
  if (!b) return 1
  return differenceInMilliseconds(parseTime(a), parseTime(b))
}

function normalizeCompareResult(r: number) {
  return simpleSort(r, 0)
}

export function downloadCsv(users: User[]) {
  const common: TranslationGroup = trans.common()
  const fields = [common.name, common.email, common.last_check_in, common.membership]
  const data = users.map(user => [
    getNameFromUser(user),
    user.attributes.email,
    parseTime(user.attributes.lastCheckedInAt).toISOString(),
    getMembershipFromUser(user, '-'),
  ])

  const name = `${Orgs.getSelectedOrgName()} ${common.users}`.trim()

  generateCSV(data, fields, name)
}
