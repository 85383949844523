/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import styled from '@emotion/styled/macro'
import { useState } from 'react'
import useModal from 'lib/hooks/useModal'
import { Beacon, RegisterStatus, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { DetailSliderContent, ConfirmationDialog } from 'components/partials'
import { DetailRow, FirmwareDetail } from './utils'
import { actionRowStyles, detailStyles } from 'styles'
import { isAdmin, isInstaller } from 'lib/utils/auth'
import { ActionButton } from 'components/widgets'
import { Beacons, Orgs } from 'models'
import { formatDistanceToNow, getHealth } from 'lib/utils'
import { EditBeaconDetailsModal } from '../EditBeaconDetailsModal'
import { getHealthIcon } from 'components/partial-pages/ButtonsDevicesPage'

export const BeaconDetailDrawer = ({ beacon, close }: { beacon: Beacon; close: Function }) => {
  const translation: TranslationGroup = trans.merge(TranslationKey.BEACON_DRAWER)
  const healthLabels: TranslationGroup = trans.merge(TranslationKey.HEALTH_LABELS)

  const { Modal: ModalEdit, openModal: openModalEdit, closeModal: closeModalEdit } = useModal(translation.edit_beacon)
  const [openUnregisterModal, setOpenUnregisterModal] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const orgsById = Orgs.use(({ orgsById }) => orgsById)
  const beaconOrgId = beacon.orgId
  const org = orgsById[beaconOrgId]
  const showUnregister =
    (isAdmin(beaconOrgId) || isInstaller(beaconOrgId)) && beacon.registration === RegisterStatus.REGISTERED

  function handleUnregister() {
    Beacons.unregisterBeacon(beacon)
    close()
  }

  const handleDisablingBeacon = () => {
    close()
    setShowDialog(false)
    Beacons.disableBeacon(beacon)
  }

  const rightStyles = {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '1.0rem',
  }

  const padRightStyles = {
    paddingRight: '10px',
  }

  const health = getHealth(beacon, true)
  const healthRow = (
    <div css={rightStyles}>
      <div css={padRightStyles}>{getHealthIcon(health)}</div>
      <div>{healthLabels[health]}</div>
    </div>
  )

  return (
    <>
      <DetailSliderContent title={translation.beacon_details} close={close}>
        <ul css={detailStyles}>
          {org && <DetailRow title={translation.property} content={org.name} />}
          <DetailRow title={translation.property_id} content={beacon.orgId} />
          <DetailRow title={translation.health} content={healthRow} />
          {beacon.batteryPercent !== 0 && (
            <DetailRow
              title={translation.battery}
              content={Number.isInteger(beacon.batteryPercent) ? `${beacon.batteryPercent}%` : ''}
            />
          )}
          <DetailRow title={translation.Registration_Status} content={translation[beacon.registration]} />
          {beacon.props?.version && (
            <FirmwareDetail
              fallback={''}
              title={translation.Firmware}
              version={beacon.props?.version}
              update={beacon.updateFirmware}
            />
          )}
          <DetailRow title={translation.Type} content={beacon.beaconType} />
          <DetailRow title={translation.Name} content={beacon.name} />
          <DetailRow
            title={translation.Location}
            content={`${translation.Room}: ${beacon.room} - ${translation.Floor}: ${beacon.floor}`}
          />
          <DetailRow title={translation.last_check_in} content={formatDistanceToNow(beacon.updatedAt)} />
          <DetailRow title={translation.Beacon_ID} content={beacon.id} />
        </ul>
        <div css={actionRowStyles}>
          {showUnregister && <ActionButton onClick={openModalEdit}>{translation.edit_beacon_details}</ActionButton>}
          {showUnregister && (
            <ActionButton onClick={() => setOpenUnregisterModal(true)}>
              {translation.remove_beacon_from_loc}
            </ActionButton>
          )}
          {isAdmin(beaconOrgId) && (
            <ActionButton onClick={() => setShowDialog(true)}>{translation.remove_loc_from_property}</ActionButton>
          )}
        </div>
        <ModalEdit>
          <EditBeaconDetailsModal beacon={beacon} close={closeModalEdit} action={Beacons.update} />
        </ModalEdit>
        <ConfirmationDialog
          title={translation.unregister_beacon}
          description={translation.confirm_unregister}
          open={openUnregisterModal}
          onConfirm={() => {
            setOpenUnregisterModal(false)
            handleUnregister()
          }}
          handleClose={() => setOpenUnregisterModal(false)}
        />
        <ConfirmationDialog
          title={translation.please_confirm}
          description={translation.confirm_disable}
          open={showDialog}
          onConfirm={handleDisablingBeacon}
          handleClose={() => setShowDialog(false)}
        />
      </DetailSliderContent>
    </>
  )
}
