/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import { getAppColors } from 'lib/constants'
import { onChangeValueCallback, Primitive, SortBy, SortOption } from 'lib/types'
import { useEffect } from 'react'
import { formControlStyles, position } from 'styles'

const prefixStyles = {
  label: 'prefix',
  ...position('relative'),
  top: '17px',
  marginLeft: '10px',
  height: '2px',
}

const selectStyles = {
  label: 'select',
  fontFamily: 'Montserrat',
  'div[id="dropdown-widget"]': {
    padding: '16px 30px 15px 15px',
    color: getAppColors('--color-secondary'),
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
}

export const UncontrolledDropdown = ({
  prefix,
  options,
  onChange,
  className = '',
}: {
  prefix?: string
  options: string[]
  onChange: onChangeValueCallback
  className?: string
}) => {
  return (
    <div css={{ className }}>
      <FormControl css={formControlStyles}>
        <span css={prefixStyles}>{prefix || ''}</span>
        <Select
          css={selectStyles}
          id="dropdown-widget"
          defaultValue={options[0]}
          onChange={e => onChange(`${e.target.value}`)}
          disableUnderline
          autoWidth={false}
          IconComponent={KeyboardArrowDown}
        >
          {options.map(option => (
            <MenuItem value={option} key={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export const ControlledGenericPrimitiveDropdown = <T extends Primitive>({
  prefix,
  options,
  value,
  className = '',
  onChange,
}: {
  prefix?: string
  options: T[]
  value: T
  className?: string
  onChange: (value: T) => void
}) => {
  useEffect(() => {
    if (!options.includes(value)) {
      onChange(options[0])
    }
  }, [options, value])

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: T }>) => {
    onChange(event.target.value)
  }

  return (
    <div css={{ className }}>
      <FormControl css={formControlStyles} style={{ flexDirection: 'row' }}>
        <span css={prefixStyles}>{prefix || ''}</span>
        <Select
          css={selectStyles}
          id="dropdown-widget"
          disableUnderline
          autoWidth={false}
          value={value}
          // @ts-ignore
          onChange={handleChange}
          IconComponent={KeyboardArrowDown}
        >
          {options.map((option, i) => (
            // @ts-ignore
            <MenuItem value={option} key={i}>
              {/* @ts-ignore */}
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

/** Controlled String Dropdown */
export const ControlledStringDropdown = ({
  prefix,
  options,
  value,
  className = '',
  onChange,
}: {
  prefix?: string
  options: string[]
  value: string
  className?: string
  onChange: (value: string) => void
}) => {
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    onChange(event.target.value)
  }

  return (
    <div css={{ className }}>
      <FormControl css={formControlStyles}>
        <span css={prefixStyles}>{prefix || ''}</span>
        <Select
          css={selectStyles}
          id="dropdown-widget"
          disableUnderline
          autoWidth={false}
          value={value}
          // @ts-ignore
          onChange={handleChange}
          IconComponent={KeyboardArrowDown}
        >
          {options.map((option, i) => (
            <MenuItem value={option} key={i}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

/** Controlled SortOption Dropdown with SortBy field of type S */
export const ControlledSortOptionDropdown = <S extends unknown>({
  prefix,
  options,
  value,
  className = '',
  onChange,
}: {
  prefix?: string
  options: SortOption<S>[]
  value: SortBy<S>
  className?: string
  onChange: (value: SortBy<S>) => void
}) => {
  const handleChange = (event: React.ChangeEvent<{ name?: string; value: string }>) => {
    onChange(JSON.parse(event.target.value) as SortBy<S>)
  }

  return (
    <div css={{ className }}>
      <FormControl css={formControlStyles} style={{ flexDirection: 'row' }}>
        <span css={prefixStyles}>{prefix || ''}</span>
        <Select
          css={selectStyles}
          id="dropdown-widget"
          disableUnderline
          autoWidth={false}
          value={JSON.stringify(value)}
          // @ts-ignore
          onChange={handleChange}
          IconComponent={KeyboardArrowDown}
        >
          {options.map((option, i) => (
            // @ts-ignore
            <MenuItem value={JSON.stringify(option.sortBy)} key={i}>
              {option.display}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
