import { useState } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import { updateImgSrc } from './utils'
import {
  ActionButton,
  StyledDialogTitle,
  StyledFormControl,
  StyledInputLabel,
  StyledTextArea,
  StyledDialogActions,
} from 'components/widgets'

type Props = {
  handleClose: any
  open: boolean
  orgId: string
}

function ImgModal({ handleClose, open, orgId }: Props) {
  const [newImgSrc, setNewImgSrc] = useState('')

  return (
    <Dialog open={!!open} onClose={handleClose} maxWidth="sm" fullWidth>
      <StyledDialogTitle>Update Image URL</StyledDialogTitle>
      <DialogContent>
        <StyledFormControl>
          <StyledInputLabel htmlFor="updateImg">Image URL</StyledInputLabel>
          <StyledTextArea
            id="updateImg"
            onChange={e => setNewImgSrc(e.target.value)}
            value={newImgSrc}
            rowsMin={2}
          />
        </StyledFormControl>
      </DialogContent>
      <StyledDialogActions>
        <ActionButton onClick={handleClose}>Cancel</ActionButton>
        <ActionButton
          onClick={() =>
            updateImgSrc(orgId, setNewImgSrc, newImgSrc, handleClose)
          }
        >
          Update
        </ActionButton>
      </StyledDialogActions>
    </Dialog>
  )
}

export default ImgModal
