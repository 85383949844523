/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import { AlertEvent } from 'lib/types'
import { AlertChatEvent } from './AlertChatEvent'

export const AlertChatEvents = ({
  events,
  movingThroughHistory,
  scrolledToBottom,
}: {
  events: AlertEvent[]
  movingThroughHistory: boolean
  scrolledToBottom: boolean
}) => {
  return (
    <>
      {events.map((event: any, index: number) => (
        <AlertChatEvent
          key={`event-${index}`}
          event={event}
          last={index == events.length - 1}
          movingThroughHistory={movingThroughHistory}
          scrolledToBottom={scrolledToBottom}
        />
      ))}
    </>
  )
}
