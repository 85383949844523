import styled from '@emotion/styled/macro'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { PopUpNotifications } from 'models'
import { AlertSession } from 'lib/types'
import { AlertDetailDrawer } from 'components/partials/DrawerDetails'
import { DetailsDrawer } from 'models'
import { getAppColors } from 'lib/constants'

const typeColors = {
  info: getAppColors('--color-brand'),
  error: getAppColors('--color-error'),
  success: getAppColors('--color-brand'),
  warning: getAppColors('--color-warning'),
}

const ToastWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  min-width: 320px;
  padding: 16px;
  font-weight: 600;
  background: ${({ type }: { type: any }) => {
    // @ts-ignore
    return typeColors[type]
  }};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: white;
  font-family: Montserrat;
  cursor: pointer;
  &:before {
    content: '';
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 5px;
    position: absolute;
    border-radius: 4px 0 0 4px;
  }
  & + & {
    margin-top: 16px;
  }
  svg {
    cursor: pointer;
  }
  div {
    line-height: 3.2rem;
  }
`

const Toast = ({
  onClose,
  onClick,
  type,
  children,
}: {
  onClose: () => void
  onClick?: () => void
  type?: string
  children?: React.ReactElement | string
}) => (
  <ToastWrapper type={type || 'info'} onClick={onClick}>
    <div>{children}</div>
    <HighlightOffIcon
      onClick={e => {
        e.stopPropagation()
        onClose()
      }}
    />
  </ToastWrapper>
)

const NotificationsToastWrapper = styled.div`
  position: fixed;
  top: 64px;
  right: 16px;
  z-index: 5000;
`

export const Notification = () => {
  const popUpNotifications = PopUpNotifications.use()

  function showAlertDetail(row: AlertSession) {
    DetailsDrawer.show({
      drawerComponent: AlertDetailDrawer,
      drawerProps: {
        alertSession: row,
        close: DetailsDrawer.close,
      },
    })
  }

  return (
    <NotificationsToastWrapper>
      {popUpNotifications.map(({ type, id, onClick, content, alertSession }) => (
        <Toast
          key={id}
          type={type}
          onClick={alertSession ? () => showAlertDetail(alertSession) : onClick}
          onClose={() => id && PopUpNotifications.close(id)}
        >
          {content}
        </Toast>
      ))}
    </NotificationsToastWrapper>
  )
}
