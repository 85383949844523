export const getAppColors = (color: string) => {
  return window.getComputedStyle(document.body).getPropertyValue(color)
}

export const CHART_COLORS = [
  getAppColors('--color-error'),
  getAppColors('--color-warning'),
  getAppColors('--color-success'),
]

export const CHART_COLOR_PLACEHOLDER = getAppColors('--color-gray-1')

export const CHART_COLOR_BLUE = getAppColors('--color-primary')
