import { shallowEqual } from 'react-redux'
import styled from '@emotion/styled'
import { uniqueId, isUndefined } from 'lodash'
import { HardwareTable } from 'components/partials/HardwareTableView'
import { Orgs, Firmwares } from 'models'
import { PRIMARY_COLOR } from 'lib/constants'
import { trans, TranslationGroup } from 'lib/types'
import { RenderCounter } from 'components/widgets/RenderCounter'

const StyledFirmwareContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
})

const StyledTitleText = styled.div({
  color: PRIMARY_COLOR,
  fontFamily: 'Montserrat',
  fontSize: '1.8rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '2.9rem',
  letterSpacing: '0',
  textAlign: 'left',
})

const StyledDescriptionText = styled.div({
  fontFamily: 'Montserrat',
  fontSize: '1.4rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.9rem',
  letterSpacing: '0',
  textAlign: 'left',
})

const StyledLinkableText = styled.span({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  color: '#29a096',
  texTransform: 'none',
})

const FirmwareSettings = () => {
  const selectedOrg = Orgs.useSelectedOrg()
  const { subHardwareTypes, firmwaresByTypeAuto, firmwares } = Firmwares.use(
    ({ subHardwareTypes, firmwaresByTypeAuto, firmwares }) => {
      return { subHardwareTypes, firmwaresByTypeAuto, firmwares }
    },
    shallowEqual,
  )

  const settings: TranslationGroup = trans.settings()
  return (
    <StyledFirmwareContainer>
      <RenderCounter name={'FirmwareSettings'} />
      <StyledTitleText>{settings.firmware_settings}</StyledTitleText>
      <StyledDescriptionText>{settings.determine_update}</StyledDescriptionText>
      {!isUndefined(selectedOrg) ? (
        <HardwareTable
          key={uniqueId()}
          org={selectedOrg}
          subHardware={subHardwareTypes}
          firmwares={firmwares}
          firmwaresByTypeAuto={firmwaresByTypeAuto}
        />
      ) : (
        <StyledLinkableText>{settings.select_org_firmware}</StyledLinkableText>
      )}
    </StyledFirmwareContainer>
  )
}

export default FirmwareSettings
