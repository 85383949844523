/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import _ from 'lodash'
import { Tooltip } from '@material-ui/core'
import { FirmwareVersionProps, trans, TranslationGroup, UpdateFirmwareProps } from 'lib/types'
import { FirmwareIcon } from 'styles'

export function Firmware({
  fallback,
  update,
  version = {},
  appVersionOnly,
}: {
  fallback?: string | undefined
  update?: UpdateFirmwareProps
  version?: FirmwareVersionProps
  appVersionOnly?: boolean
}) {
  const translation: TranslationGroup = trans.common()
  if (_.isEmpty(version)) {
    return <span>{fallback}</span>
  }
  const versionTypes = Object.keys(version)
  console.log({ versionTypes })
  const getFirmwareVersions = () => {
    return versionTypes.map((firmwareType, index) => {
      if (appVersionOnly && ['wan_peerless_ble', 'wan_peerless_modem'].includes(firmwareType)) return null
      if (!version[firmwareType]) return null
      return (
        <Tooltip
          enterDelay={200}
          enterNextDelay={200}
          placement={`left`}
          title={`${translation.type}: ${firmwareType}`}
          key={firmwareType}
        >
          <div css={{ alignItems: 'center', display: 'flex' }} key={firmwareType + index}>
            <FirmwareIcon iconStyle={{ padding: '0 8px 0' }} update={update} />
            <p css={{ marginBottom: 0, marginTop: 0 }}>
              <span>{`${version[firmwareType]}`}</span>
            </p>
          </div>
        </Tooltip>
      )
    })
  }

  const firmware = getFirmwareVersions().filter(v => v !== null)
  const display = firmware.length ? firmware : fallback
  return <>{display}</>
}
