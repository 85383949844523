/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import { ChartsWrapper } from 'components/widgets'
import { Device, NameNumberValuePair } from 'lib/types'
import { HealthLabelsKeys } from 'lib/constants'
import * as utils from '../utils'
import StyledPieChartLegend from 'components/partials/StyledPieChartLegend'
import { chartPageContainer } from 'styles'
import StyledPieChart from 'components/partials/StyledPieChart'
import { HealthKey, LegendLabel } from 'components/partials/MainSidebar/ChartsSection'

export const Charts = ({
  devices,
  legendLabel,
}: {
  devices: Device[]
  width?: number
  height?: number
  legendLabel: LegendLabel
}) => {
  const green = legendLabel[HealthKey.GREEN]
  const yellow = legendLabel[HealthKey.YELLOW]
  const red = legendLabel[HealthKey.RED]
  const devicesData: NameNumberValuePair[] = [
    {
      name: red,
      value: utils.filterDeviceByHealthLabel(devices, HealthLabelsKeys.CRITICAL).length,
    },
    {
      name: yellow,
      value: utils.filterDeviceByHealthLabel(devices, HealthLabelsKeys.MODERATE).length,
    },
    {
      name: green,
      value: utils.filterDeviceByHealthLabel(devices, HealthLabelsKeys.HEALTHY).length,
    },
  ]
  return (
    <ChartsWrapper>
      <div css={chartPageContainer}>
        <StyledPieChart data={devicesData} legendLabel={legendLabel} />
        <StyledPieChartLegend
          green={`${devicesData[2].value} ${green}`}
          yellow={`${devicesData[1].value} ${yellow}`}
          red={`${devicesData[0].value} ${red}`}
          reverse
        />
      </div>
    </ChartsWrapper>
  )
}
