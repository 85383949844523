import { isEmpty } from 'lodash'
import { mapValues, groupBy } from 'lodash'

import { SubTypeOfHardware, Firmware, FirmwareLTEDataRequest, TranslationGroup, trans } from 'lib/types'
import { ALL_ORGS_SELECTED, HARDWARE_TYPE_OPTIONS, PEERLESS_APP, PEERLESS_APP_V2 } from 'lib/constants'
import { Beacons, Devices, Firmwares, NavState } from 'models'

// GENERAL FUNCTION THAT ARE BEING USED BY ALL THE FOTA

export const getSubHardwareTypeName = (subHardware: SubTypeOfHardware[], firmwareType: string) => {
  const translation: TranslationGroup = trans.settings()
  return subHardware.find(sH => sH.firmwareType === firmwareType)?.name || translation.all_options
}

export const getFirmwareUpdateOption = (version: string, environment: string) => {
  const translation: TranslationGroup = trans.settings()
  return version?.includes('auto') ? `${translation.auto_update} (${environment})` : version
}

export const getFirmwareFormattedName = (firmwares: Firmware[], option?: string | null) => {
  const translation: TranslationGroup = trans.settings()

  if (option?.includes('auto')) {
    const firmware = firmwares.find(f => f.version === option)
    return getFirmwareUpdateOption(firmware?.version!, firmware?.environment!)
  }

  if (option?.includes('notUpdate')) return translation.do_not_update
  return option
}

export const getFilteredFirmwareList = (firmwares: Firmware[], subHardware: string) =>
  subHardware !== 'N/A'
    ? firmwares.filter(f => f.firmwareType === subHardware || (f.firmwareType === null && subHardware !== 'N/A'))
    : firmwares

// Hardware Table functions

export const getCurrentFirmwareVersionName = (
  orgFirmware: FirmwareLTEDataRequest,
  subHardware: SubTypeOfHardware,
  firmwares: Firmware[],
) => {
  const translation: TranslationGroup = trans.settings()
  return getFirmwareFormattedName(firmwares, orgFirmware[subHardware?.firmwareType]) || translation.no_existing
}

export const getHardwareTypeName = (hardwareType: string) =>
  HARDWARE_TYPE_OPTIONS.find(hardware => hardware.value === hardwareType)?.name

export const getApplyChangesButtonStatus = (firmwareSettings: FirmwareLTEDataRequest) => isEmpty(firmwareSettings)

// Firmware Settings Confirmation Modal Functions

export const getFirmwareSettingsData = (firmwareSettings: FirmwareLTEDataRequest, subHardware: SubTypeOfHardware[]) =>
  Object.keys(firmwareSettings).map(firmware => subHardware.find(sH => sH.firmwareType === firmware))

export const getHardwareList = (subHardware: SubTypeOfHardware[]) => mapValues(groupBy(subHardware, 'hardwareType'))

export interface AllowedOrganizationHardware {
  beacons: string[]
  devices: string[]
}

export const useAllowedHardwareByOrg = (orgId: string = ALL_ORGS_SELECTED): AllowedOrganizationHardware => {
  const beaconsByOrgId = Beacons.use(({ beaconsByOrgId }) => beaconsByOrgId)
  const devicesByOrgId = Devices.use(({ devicesByOrgId }) => devicesByOrgId)
  const beacons = beaconsByOrgId[orgId] ?? []
  const devices = devicesByOrgId[orgId] ?? []

  const beaconWhitelist = ['carfit', 'react', 'react2']
  const beaconList = beacons.reduce((acc, item) => {
    const t = item.beaconType.toLowerCase()
    if (beaconWhitelist.includes(t)) acc.add(t)
    return acc
  }, new Set())

  const deviceList = devices.reduce((acc, item) => {
    const t = item.deviceType.toLowerCase()
    if (t === 'ios' || t === 'android') {
      if (item.props?.button) acc.add('sidekick')
    }
    if (t === 'wan_button') acc.add(t)
    return acc
  }, new Set())

  return {
    beacons: Array.from(beaconList) as string[],
    devices: Array.from(deviceList) as string[],
  }
}

export const isHardwareAllowed = (sub: SubTypeOfHardware, allowedHardware: AllowedOrganizationHardware) => {
  const name = sub.name.toLowerCase()
  if (sub.hardwareType.toLowerCase() === 'beacon') {
    return allowedHardware.beacons.includes(name)
  }
  const hardwareType = sub.hardwareType.toLowerCase()
  if (hardwareType === 'sidekick') {
    return allowedHardware.devices.includes(hardwareType)
  }
  if (hardwareType === 'wan_button') {
    return (
      (allowedHardware.devices.includes(hardwareType) && name === PEERLESS_APP) ||
      (allowedHardware.devices.includes(hardwareType) && name === PEERLESS_APP_V2)
    )
  }
  return false
}

export const useAllowedHardware = () => {
  const subHardwareTypes = Firmwares.use(({ subHardwareTypes }) => subHardwareTypes)
  const selectedOrgId = NavState.use(({ selectedOrgId }) => selectedOrgId)
  const allowedHardware: AllowedOrganizationHardware = useAllowedHardwareByOrg(selectedOrgId)
  return subHardwareTypes.filter(sub => isHardwareAllowed(sub, allowedHardware))
}
