import { getAppColors } from 'lib/constants'
import { textAlign } from 'styles/cssProps'

export const chartPageContainer = {
  border: `1px solid ${getAppColors('--color-gray-1')}`,
  borderRadius: '10px',
  padding: '10px',
  display: 'inline-block',
  marginBottom: '20px',
  width: '93%',
  fontSize: '1.2rem',
  color: getAppColors('--color-primary'),

  text: {
    fill: getAppColors('--color-primary'),
    fontWeight: '600',
  },
}

export const chartCardText = {
  fontFamily: 'Montserrat',
  fontSize: '1.2rem',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '2.5rem',
  alignItems: 'center',
  color: getAppColors('--color-primary'),
  ...textAlign('center'),
}
