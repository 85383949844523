export enum ResponderColumnKeys {
  Health = 'Health',
  Type = 'Type',
  Name = 'Name',
  Email = 'Email',
  Membership = 'Membership',
  Push = 'Push',
  SMS = 'SMS',
  LastSeen = 'last_seen',
}
