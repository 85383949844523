import { TextField, DialogTitle, Input, InputLabel, DialogActions, FormControl } from '@material-ui/core'
import { MoreHoriz as MoreHorizIcon } from '@material-ui/icons'
import styled from '@emotion/styled/macro'
import {
  BORDER1_COLOR,
  BRAND_COLOR,
  GRAY3_COLOR,
  LINK_COLOR,
  TEXTFIELD_LABEL_COLOR,
  PRIMARY_COLOR,
} from 'lib/constants'

export const RoundedTextField = styled(TextField)`
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
    border-radius: 15px;
    font-family: Montserrat;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${BRAND_COLOR};
  }
  input {
    padding: 15px;
  }
`

export const StyledH1 = styled.h1`
  font-family: Montserrat;
  color: ${PRIMARY_COLOR};
`

export const StyledH2 = styled.h2`
  color: ${PRIMARY_COLOR};
  font-family: Montserrat;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.9rem;
  letter-spacing: 0;
  text-align: left;
`

export const SloganFormat = styled.p({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '3.6rem',
  lineHeight: '5.6rem',
  color: LINK_COLOR,
  marginLeft: '70px',
  marginRight: '100px',
})

export const TitleFormat = styled.p({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '1.8rem',
  lineHeight: '2.9rem',
})

export const LoginImageContainer = styled.div`
  margin: 69px;
  @media (max-width: 767px) {
    display: none;
  }
`

export const StyledDialogTitle = styled(DialogTitle)`
  font-family: Montserrat;
  h2 {
    font-family: Montserrat;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.9rem;
    letter-spacing: 0;
    text-align: left;
    text-transform: capitalize;
  }
`

export const StyledMoreHorizIcon = styled(MoreHorizIcon)`
  color: #29a096;
`

export const ShowMoreContainer = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 2.5rem;
  padding: 5px;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #29a096;
  box-sizing: border-box;
  box-shadow: -4px 4px 6px rgb(0 0 0 / 12%);
  border-radius: 6px;
  z-index: 2000;
`
export const ShowMoreOption = styled.div`
  &:hover {
    background: #f7f7f7;
  }
`

export const StyledInput = styled(Input)`
  && {
    background: ${GRAY3_COLOR};
    border-radius: 10px;
    padding: 5px 10px;
    font-family: Montserrat;
  }
`

export const StyledInputLabel = styled(InputLabel)`
  && {
    color: ${TEXTFIELD_LABEL_COLOR};
    font-family: Montserrat;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0.1rem;
    text-align: left;
  }
`

export const StyledDialogActions = styled(DialogActions)`
  && {
    justify-content: center;
  }
`

export const StyledFormControl = styled(FormControl)`
  && {
    width: 100%;
    margin-bottom: 15px;
  }
`

export const LinkableText = styled.span`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  color: #29a096;
  text-transform: none;
`
export const Separator = styled.div`
  border: 1px solid ${BORDER1_COLOR};
`
