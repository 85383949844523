import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Input, Button, MenuItem, Select, FormControl, TextField } from '@material-ui/core'
import { uniqueId, isNull } from 'lodash'
import { PopUpNotifications } from 'models'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import semver from 'semver'
import {
  BRAND_COLOR,
  ENVIRONMENT_OPTIONS,
  ERROR_COLOR,
  GRAY3_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  TITLE_COLOR,
} from 'lib/constants'
import { Firmwares } from 'models'
import { ActionButton, StyledDialogActions } from 'components/widgets'
import { ManageFirmware, trans, TranslationGroup, TranslationKey } from 'lib/types'

interface ServerEnvironment {
  name: string
  value: string
  code: string
}

const onHandleApplyManageFirmware = (newFirmware: ManageFirmware, closeModal: () => void) => {
  try {
    const formData = new FormData()

    formData.append('file', newFirmware.file!)
    formData.append('canUpdateFrom', JSON.stringify(newFirmware.updateFrom))
    formData.append('cantUpdateFrom', JSON.stringify(newFirmware.notUpdateFrom))
    formData.append('firmwareType', newFirmware?.subHardwareType || '')
    formData.append('version', newFirmware.version)
    if (newFirmware?.environment) {
      formData.append('environment', newFirmware?.environment)
    }

    if (!semver.valid(newFirmware.version)) return

    Firmwares.uploadNewFirmware(formData)
    // PopUpNotifications.fireInfo({ content: 'Firmware added successfully' })
    closeModal()
  } catch (e) {
    PopUpNotifications.fireError({
      content: 'There was a problem uploading the new firmware.',
    })
  }
}

const StyledModalContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
})

const StyledSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
})

const StyledTitleText = styled.div({
  fontStyle: 'normal',
  fontFamily: 'Montserrat',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  lineHeight: '1.5rem',
  display: 'flex',
  alignItems: 'center',
  letterSpacing: '0.1rem',
  textTransform: 'uppercase',
  color: TITLE_COLOR,
})

const StyledSubText = styled.div({
  fontStyle: 'normal',
  fontFamily: 'Montserrat',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  display: 'flex',
  letterSpacing: '0.1rem',
  color: '#242424',
})

const StyledFileContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '15px,',
  background: GRAY3_COLOR,
  borderRadius: '10px',
})

const StyledInput = styled(Input)({
  display: 'none',
})

const StyledButton = styled(Button)({
  background: '#FFFFFF',
  border: `2px solid ${PRIMARY_COLOR}`,
  boxSizing: 'border-box',
  boxShadow: '0px 4px 8px rgba(20, 48, 72, 0.1)',
  borderRadius: '2px',
  marginRight: '20px',
  color: PRIMARY_COLOR,
})

const StyledLabel = styled.label({
  color: SECONDARY_COLOR,
  fontSize: '1.2rem',
})

const StyledFormControl = styled(FormControl)({
  background: GRAY3_COLOR,
  height: '40px',
  borderRadius: '10px',
  borderColor: BRAND_COLOR,
  div: {
    padding: '5px',
  },
})

const StyledTextField = styled(TextField)({
  background: GRAY3_COLOR,
  borderRadius: '10px',
})

const StyledErrorText = styled.div({
  fontStyle: 'normal',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontSize: '1.2rem',
  display: 'flex',
  letterSpacing: '0.1rem',
  color: ERROR_COLOR,
})

const getEnvironment = (): string => {
  const url = new URL(location.href)
  const hn = url.hostname === 'localhost' ? '.dev.' : url.hostname
  const curEnv = ENVIRONMENT_OPTIONS.find(env => {
    return hn.includes(`.${env.code}.`)
  })
  return curEnv?.value || 'prod'
}

type Props = {
  closeModal: () => void
  firmwareType: string
  hardwareType: string
  subType: string
}

const isValidProduct = (fileType: string, fileProduct: string, expectedType: string, expectedProduct: string) => {
  const ft = fileType.toLowerCase()
  const fp = fileProduct.toLowerCase()
  const et = expectedType.toLowerCase()
  const ep = expectedProduct.toLowerCase()
  if (ft === 'button' && fp.includes('peerless') && et === 'wan_button' && ep.includes('peerless')) return true
  if (ft === 'button' && fp === 'sidekick' && et === 'sidekick' && ep === 'react') return true
  return ft === et && fp === ep
}

function UploadFirmwareModalContent({ closeModal, firmwareType, hardwareType, subType }: Props) {
  const [file, setFile] = useState<File>()
  const [environment, setEnv] = useState<string>(getEnvironment())
  const [validProduct, setValidProduct] = useState<boolean>(false)
  const [product, setProduct] = useState<string>()
  const [productType, setProductType] = useState<string>()
  const [version, setVersion] = useState('')

  const uploadInputRef = useRef<HTMLInputElement>(null)
  const envList = ['prod', 'stage', 'dev', 'test']

  const validVersionString = semver.valid(version)

  useEffect(() => {
    if (file) {
      const name = (file.name || '').split('.').slice(0, -1).join('.')
      const parts = name.split('-')
      const version = parts.find(v => semver.valid(v)) || ''
      const env = parts.find(en => envList.includes(en)) || getEnvironment()
      const prodType = parts[0] ?? ''
      const prod = (parts[1] ??= '')
      semver.valid(version)
      setProductType(prodType)
      setProduct(prod)
      setVersion(version)
      setValidProduct(isValidProduct(prodType, prod, hardwareType, subType))
      setEnv(env === 'stage' ? 'staging' : env)
    } else {
      setProductType('')
      setProduct('')
      setVersion('')
      setValidProduct(false)
      setEnv(getEnvironment())
    }
  }, [file])
  const translation: TranslationGroup = trans.merge(TranslationKey.ADMIN_FIRMWARE)
  return (
    <StyledModalContainer>
      <StyledSection>
        <StyledTitleText>{translation.File} *</StyledTitleText>
        <StyledFileContainer>
          <label htmlFor="upload-file">
            <StyledInput
              ref={uploadInputRef}
              type="file"
              id="upload-file"
              name="upload-file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFile(e.target.files![0])}
            />
            <StyledButton onClick={() => uploadInputRef.current && uploadInputRef.current.click()} variant="contained">
              {translation.Select_File}
            </StyledButton>
            <StyledLabel>{file?.name ? file.name : translation.NoFile}</StyledLabel>
          </label>
        </StyledFileContainer>
        {product && !validProduct && (
          <StyledErrorText>
            {translation.was_expecting}
            {subType} {hardwareType} {translation.Firmware_File} {product} {productType}.
          </StyledErrorText>
        )}
      </StyledSection>

      <StyledSection>
        <StyledTitleText>{translation.Enviroment}</StyledTitleText>
        <StyledFormControl>
          <Select
            value={environment}
            onChange={e => {
              setEnv(e.target.value as string)
            }}
            IconComponent={KeyboardArrowDownIcon}
            disableUnderline
          >
            <MenuItem disabled selected value={'--'}>
              {translation.Select_Environment}
            </MenuItem>
            {ENVIRONMENT_OPTIONS.map((environment: ServerEnvironment) => (
              <MenuItem value={environment.value} key={uniqueId()}>
                {environment.name}
              </MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </StyledSection>

      <StyledSection>
        <StyledTitleText>
          {translation.Hardware}: {hardwareType}
        </StyledTitleText>
      </StyledSection>

      <StyledSection>
        <StyledTitleText>
          {translation.Sub_Type}: {subType}
        </StyledTitleText>
      </StyledSection>

      <StyledSection>
        <StyledTitleText>{translation.Version} * </StyledTitleText>
        <StyledSubText>{translation.semantic_version}</StyledSubText>
        <StyledTextField
          value={version}
          InputProps={{ disableUnderline: true }}
          onChange={e => setVersion(e.target.value as string)}
          error={isNull(validVersionString)}
          helperText={validVersionString ? '' : translation.semantic_version}
        />
      </StyledSection>

      <StyledDialogActions>
        <ActionButton onClick={() => closeModal()}>{translation.Close}</ActionButton>
        <ActionButton
          disabled={!validVersionString || !validProduct}
          onClick={() =>
            onHandleApplyManageFirmware(
              {
                file,
                environment,
                subHardwareType: firmwareType,
                updateFrom: [],
                notUpdateFrom: [],
                version,
              },
              closeModal,
            )
          }
        >
          {translation.Apply}
        </ActionButton>
      </StyledDialogActions>
    </StyledModalContainer>
  )
}

export default UploadFirmwareModalContent
