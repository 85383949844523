import { Button } from '@material-ui/core'
import { DeleteUserModal } from 'components/partials/DeleteUserModal'
import useModal from 'lib/hooks/useModal'
import { trans, TranslationGroup, TranslationKey, User } from 'lib/types'
import { Orgs } from 'models'

export const RemoveUserButton = ({ user, orgId }: { user: User; orgId: string }) => {
  const translation: TranslationGroup = trans.merge(TranslationKey.USER_TABLE_VIEW)
  const orgsById = Orgs.use(({ orgsById }) => orgsById)
  const { Modal, openModal, closeModal } = useModal(`${translation.remove_user_from} ${orgsById[orgId || '']?.name}`)

  return (
    <>
      <Button onClick={openModal}>{translation.remove}</Button>
      <Modal>
        <DeleteUserModal user={user} orgId={orgId} close={closeModal} />
      </Modal>
    </>
  )
}
