import { createReduxModule } from 'hooks-for-redux'

export interface DetailsDrawerState {
  drawerComponent?: any
  drawerProps?: any
}

const initialState: DetailsDrawerState = {}

export const [use, { show, close }, store] = createReduxModule(
  'detailsDrawer',
  initialState,
  {
    show: (state, newState: DetailsDrawerState) => newState,
    close: () => initialState,
  },
)
