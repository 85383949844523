/** @jsxImportSource @emotion/react */
import { AuthProvider, TITLE_COLOR } from 'lib/constants'
import { ConditionalRender } from 'components/widgets'
import { trans, TranslationGroup, TranslationKey } from 'lib/types'

const subTitleStyles = {
  fontWeight: 600,
  fontSize: '1.8rem',
  color: TITLE_COLOR,
  margin: '0 0 18px 0',
}

export function SignInSubtitle({
  children,
  provider,
}: {
  children?: string | React.ReactNode
  provider?: AuthProvider | null
}) {
  const translation: TranslationGroup = trans.group(TranslationKey.AUTH)
  let childElement = children
  switch (provider) {
    case AuthProvider.UNKNOWN:
      childElement = translation.enter_email
      break
    case AuthProvider.IDENTITY:
      childElement = translation.enter_password
      break
    case null:
      childElement = translation.choose_provider
      break
  }
  return (
    <ConditionalRender condition={!!childElement}>
      <h2 data-testid={`signInSubTitle`} css={subTitleStyles}>
        {childElement}
      </h2>
    </ConditionalRender>
  )
}
