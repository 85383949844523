import { createReduxModule } from 'hooks-for-redux'
import { AlertEvent } from 'lib/types'
import { getAlertEvents, pusherSubscribeAndBind, sendAlertEvent } from './api'
import { getAlertDisplayMessage, alertEventsAreDuplicates } from 'lib/utils/alertEvents'
import * as NavState from './NavState'
import { PUSHER_PRIVATE_PREFIX } from 'lib/constants'

const ALERT_EVENT_PUSHER_EVENT = 'alertEvent'
const ALERT_SESSION_SUBSCRIPTION_PREFIX = 'alertSession_'

export interface AlertEventsState {
  alertEvents: AlertEvent[]
  loading: boolean
  error?: any
}

const initialState: AlertEventsState = {
  alertEvents: [],
  loading: false,
}

const filterEvents = (events: AlertEvent[]) => {
  // Old function on this line -> consider refactoring current active func -> return events.filter((event, i) => getAlertDisplayMessage(event) && !alertEventsAreDuplicates(event, events[i - 1]))
  return events.filter((event, i) =>
    event.attributes?.map !== null &&
    !(
      event.attributes.location?.floor &&
      event.attributes.location?.integration &&
      event.attributes.location?.property &&
      event.attributes.location?.room
    )
      ? !alertEventsAreDuplicates(event, events[i - 1])
      : getAlertDisplayMessage(event) && !alertEventsAreDuplicates(event, events[i - 1]),
  )
}

export const [use, { setAlertEvents, appendAlertEvent, setLoading, clearLoading, setError }, store] = createReduxModule(
  'alertEvents',
  initialState,
  {
    setAlertEvents: (state: AlertEventsState, alertEvents: AlertEvent[]) => ({
      ...state,
      alertEvents: filterEvents(alertEvents),
    }),
    appendAlertEvent: (state: AlertEventsState, alertEvent: AlertEvent) => ({
      ...state,
      alertEvents: filterEvents([...state.alertEvents, alertEvent]),
    }),
    setLoading: (state: AlertEventsState) => ({ ...state, loading: true }),
    clearLoading: (state: AlertEventsState) => ({ ...state, loading: false }),
    setError: (state: AlertEventsState, error: any) => ({ ...state, error }),
  },
)

const sortAlertEvents = (a: AlertEvent, b: AlertEvent) => a.attributes.timestamp - b.attributes.timestamp

const loadAlertEvents = (alertSessionId: string) =>
  getAlertEvents(alertSessionId)
    .then(events => events.sort(sortAlertEvents))
    .then(setAlertEvents)

let oldSelectedAlertSessionId: string | undefined = undefined
let unsubscribePusher: any = () => null
let safeUnsubscribe: any = () => {
  if (unsubscribePusher) {
    unsubscribePusher()
    unsubscribePusher = null
  }
}

setTimeout(() => {
  NavState.store.subscribe(({ selectedAlertSessionId }) => {
    if (!selectedAlertSessionId) {
      safeUnsubscribe()
      setAlertEvents([])
    } else {
      const alertEventUpdateHandler = ({ data: alertEvent }: { data: AlertEvent }) => {
        // make sure we didn't miss any events
        loadAlertEvents(selectedAlertSessionId)

        // append the new event for immediate update
        appendAlertEvent({ ...{ action: null }, ...alertEvent })
      }
      const subscribeSuccessHandler = () => {
        // Runs after initial subscription
        // and on reconnect following an outage
        loadAlertEvents(selectedAlertSessionId).then(clearLoading, setError)
      }

      if (selectedAlertSessionId !== oldSelectedAlertSessionId) {
        safeUnsubscribe()
        oldSelectedAlertSessionId = selectedAlertSessionId
        unsubscribePusher = pusherSubscribeAndBind(
          `${PUSHER_PRIVATE_PREFIX}${ALERT_SESSION_SUBSCRIPTION_PREFIX}${selectedAlertSessionId}`,
          ALERT_EVENT_PUSHER_EVENT,
          alertEventUpdateHandler,
          subscribeSuccessHandler,
        )

        setLoading()
        setAlertEvents([])
      }
    }
  })
}, 1)

export const createAlertEvent = (ae: any) => sendAlertEvent(ae)
