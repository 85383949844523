import { shallowEqual } from 'react-redux'
import React from 'react'
import styled from '@emotion/styled/macro'
import Drawer from '@material-ui/core/Drawer'
import { DetailsDrawer } from 'models'
import { GRAY3_COLOR, PRIMARY_COLOR } from 'lib/constants'

export const SliderWrapper = styled.div`
  min-width: 420px;
  @media (max-width: 600px) {
    width: 90vw;
  }
  min-height: fit-content;
  background-color: ${GRAY3_COLOR};
  padding-left: 18px;
  top: 67px;
  padding-bottom: 15px;
`

export const AlertEventsWrapper = styled.div`
  background-color: ${GRAY3_COLOR};
  padding: 0 15px;
  overflow-y: auto;
  div:first-child {
    border-top: none;
  }
`

export const SliderHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const SliderTitle = styled.h2({
  fontFamily: 'Montserrat',
  fontWeight: 600,
  fontSize: '1.8rem',
  lineHeight: '2.9rem',
  color: PRIMARY_COLOR,
})

export const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
`

export const DetailLine = styled.p`
  font-family: Montserrat;
  justify-content: space-between;
  margin: 5px 0;
  display: flex;
`

export const DetailLineTitle = styled.span`
  color: ${PRIMARY_COLOR};
  font-weight: 600;
  font-style: normal;
  font-size: 1.4rem;
  line-height: 155.4%;
  width: 30%;
`

export const DetailLineContent = styled.span`
  padding: 0 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.9rem;
  text-align: end;
`

export const DetailButtonContainer = styled.div`
  button {
    margin: 0 15px;
    &:first-child {
      margin: 0 15px 0 0;
    }
  }
`

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    background-color: #fafafa;
    height: 100%;
    overflow: hidden;
    max-width: 90%;
  }
`

export const DetailSlider = () => {
  const { drawerComponent: DrawerComponent, drawerProps } = DetailsDrawer.use(({ drawerComponent, drawerProps }) => {
    return { drawerComponent, drawerProps }
  }, shallowEqual)

  return (
    <React.Fragment key={'right'}>
      <StyledDrawer
        anchor={'right'}
        open={!!DrawerComponent}
        onClose={() => {
          if (drawerProps.close) drawerProps.close()
          DetailsDrawer.close()
        }}
      >
        {DrawerComponent && <DrawerComponent {...drawerProps} />}
      </StyledDrawer>
    </React.Fragment>
  )
}
