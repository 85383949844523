/** @jsxImportSource @emotion/react */
import { authButtonWrapperStyles, authWrapperStyles, authButtonStyles } from 'styles'
import { SignInHeader, SignInSubtitle } from 'components/partials'
import { Button } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom'
import { confirmEmailAlerts, pusher } from 'models/api'
import { trans, TranslationGroup, TranslationKey } from 'lib/types'

type Props = {}

const EmailAlertsConfirmation = (props: Props) => {
  const { search } = useLocation() // "?token=abc123"
  const token = search.split('=')[1] || ''
  confirmEmailAlerts(token)
  const translation: TranslationGroup = trans.group(TranslationKey.AUTH)
  const history = useHistory()
  const logout = () => {
    localStorage.clear()
    pusher?.disconnect()
    history.push('/')
  }
  return (
    <div css={authWrapperStyles}>
      <SignInHeader>You have been successfully subscribed to email alerts.</SignInHeader>
      <div css={authButtonWrapperStyles}>
        <Button variant={`contained`} style={authButtonStyles} onClick={logout}>
          {translation.SIGN_IN}
        </Button>
      </div>
    </div>
  )
}

export default EmailAlertsConfirmation
