import _ from 'lodash'
import { Organization, trans, TranslationGroup } from 'lib/types'
import { getAddress } from './GlobalDashboard/OrgGlobalCard/utils'

export const sortOrganizations = (sortOption: string, orgs: Organization[], options: string[]): Organization[] => {
  if (sortOption === options[0]) {
    return _.orderBy(orgs, org => org.name)
  }

  if (sortOption === options[1]) {
    return _.orderBy(orgs, org => getAddress(org.props), 'desc')
  }

  if (sortOption === options[2]) {
    orgs = _.orderBy(orgs, org =>
      _.sum([
        org.summary?.alertSources?.critical,
        org.summary?.alerts?.critical,
        org.summary?.beacons?.critical,
        org.summary?.responders?.disabled,
      ]),
    )
    return orgs
  }

  if (sortOption === options[3]) {
    orgs = _.orderBy(
      orgs,
      org =>
        _.sum([
          org.summary?.alertSources?.critical,
          org.summary?.alerts?.critical,
          org.summary?.beacons?.critical,
          org.summary?.responders?.disabled,
        ]),
      'desc',
    )
    return orgs
  }

  return orgs
}

export const getBestHealthLocation = (orgs: Organization[]) => {
  let maxAlerts = Number.MAX_VALUE
  let healthiest = ''
  if (!orgs || orgs?.length === 0) return ''
  orgs.forEach(org => {
    const { alerts } = org.summary!
    if (alerts?.critical < maxAlerts) {
      maxAlerts = alerts.critical
      healthiest = org.name
    }
  })
  return healthiest
}

export const searchOrganization = (orgs: any, word: string) => {
  return orgs.filter(
    (org: any) =>
      org.name.toLowerCase().includes(word.toLowerCase()) ||
      getAddress(org.props).toLowerCase().includes(word.toLowerCase()),
  )
}

export const getLocationTitle = (orgs: any) => {
  const translation: TranslationGroup = trans.common()
  return orgs?.length !== 1 ? `${orgs?.length} ${translation.properties}` : `1 ${translation.property}`
}
