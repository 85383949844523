import { createReduxModule } from 'hooks-for-redux'
import { GrantPostData, Grant, IdToIdList, IdIdGrantsMap, TranslationGroup, trans, TranslationKey } from 'lib/types'
import {
  createGrant as apiCreateGrant,
  getAllGrants,
  deleteGrantByGrantId as apiDeleteGrant,
  associateUser as apiAssociateUser,
} from './api'
import * as PopUpNotifications from './PopUpNotifications'
import { createIdToIdList } from 'lib/utils/common'
import * as NavState from './NavState'
import { ALL_ORGS_SELECTED, GRANTS } from 'lib/constants'
import { Users } from 'models'

export interface GrantState {
  loading: boolean
  error?: any
  grants: Grant[]
  orgUserMap: IdToIdList
  userOrgMap: IdToIdList
  userOrgGrants: IdIdGrantsMap
  orgUserGrants: IdIdGrantsMap
}

const initialState: GrantState = {
  grants: [],
  orgUserMap: {},
  userOrgMap: {},
  userOrgGrants: {},
  orgUserGrants: {},
  loading: false,
}

const addGrantToIdGrantMap = (id1: string, id2: string, grant: Grant, map: IdIdGrantsMap) => {
  if (!map[id1]) map[id1] = {}
  if (!map[id1][id2]) map[id1][id2] = []
  map[id1][id2].push(grant)
}

// @ts-ignore
const believeMeItsAString = (obj, key): string => obj[key]

const createUserOrgGrants = (grants: Grant[], key1: string, key2: string): IdIdGrantsMap => {
  let out: IdIdGrantsMap = {}
  grants.forEach(grant => {
    addGrantToIdGrantMap(
      believeMeItsAString(grant.attributes, key1),
      believeMeItsAString(grant.attributes, key2),
      grant,
      out,
    )
  })
  return out
}

export const [use, { setGrants, setLoading, clearLoading, setError }, store] = createReduxModule(
  'grants',
  initialState,
  {
    setGrants: (state: GrantState, grants: Grant[]) => ({
      ...state,
      grants,
      userOrgMap: createIdToIdList(grants.map(grant => [grant.attributes.userId, grant.attributes.orgId])),
      orgUserMap: createIdToIdList(grants.map(grant => [grant.attributes.orgId, grant.attributes.userId])),
      userOrgGrants: createUserOrgGrants(grants, 'userId', 'orgId'),
      orgUserGrants: createUserOrgGrants(grants, 'orgId', 'userId'),
    }),
    setLoading: (state: GrantState) => ({ ...state, loading: true }),
    clearLoading: (state: GrantState) => ({ ...state, loading: false }),
    setError: (state: GrantState, error: any) => ({ ...state, error }),
  },
)

export const addGrants = (grants: GrantPostData[], orgId: string) => {
  const promise = Promise.all(grants.map(grant => apiCreateGrant(grant, orgId)))
  promise.then(reload)
  return promise
}

export const removeGrants = (grants: Grant[]) => {
  const promise = Promise.all(grants.map(grant => apiDeleteGrant(grant.id)))
  promise.then(reload)
  return promise
}

export const createGrant = async (name: string, userId: string, orgId: string) => {
  const translation: TranslationGroup = trans.group(TranslationKey.ERRORS)
  return await apiCreateGrant({ userId, name }, orgId).then(reload, () =>
    PopUpNotifications.fireError({
      content: translation.grants_update_failed,
    }),
  )
}

const getUserOrgGrants = (userId: string, orgId: string): Grant[] => {
  const { userOrgGrants } = store.getState()
  return (userOrgGrants[userId] && userOrgGrants[userId][orgId]) || []
}

const getGrant = (
  name: string,
  userId: string,
  orgId: string,
  // @ts-ignore
): Grant | undefined => getUserOrgGrants(userId, orgId).filter(grant => grant.attributes.name === name)[0]

export const hasDispatcherGrant = (grants: Grant[]): Boolean => {
  // User has both the portal_user and responder grants, and is not an admin
  return grants.filter(grant => [GRANTS.PORTAL_USER, GRANTS.RESPONDER].includes(grant.attributes.name)).length == 2
}

export const isDispatcher = (grants: string[]): Boolean => {
  // User has both the portal_user and responder grants, and is not an admin
  return grants.filter(grant => [GRANTS.PORTAL_USER, GRANTS.RESPONDER].includes(grant)).length == 2
}

export const deleteGrant = (name: string, userId: string, orgId: string) => {
  const grant: Grant | undefined = getGrant(name, userId, orgId)
  return grant ? deleteGrantByGrantId(grant.id) : Promise.resolve()
}

export const deleteGrantByGrantId = (grantId: string) => {
  const translation: TranslationGroup = trans.group(TranslationKey.ERRORS)
  return apiDeleteGrant(grantId).then(reload, () =>
    PopUpNotifications.fireError({
      content: translation.grants_delete_failed,
    }),
  )
}

export const removeUserFromOrg = (userId: string, orgId: string) => {
  const { userOrgGrants } = store.getState()
  return removeGrants((userOrgGrants[userId] && userOrgGrants[userId][orgId]) || [])
}

export const associateUser = (orgId: string, email: string) =>
  apiAssociateUser(orgId, email)
    .then(() => Users.reload())
    .then(reload)

export const useOrgUserMap = (): IdToIdList => use(({ orgUserMap }) => orgUserMap)
export const useUserOrgMap = (): IdToIdList => use(({ userOrgMap }) => userOrgMap)
export const useUserOrgGrants = (userId: string, orgId: string): Grant[] => {
  const userOrgGrants = use(({ userOrgGrants }) => userOrgGrants)
  const tmp = userOrgGrants[userId]
  return (tmp && tmp[orgId]) || []
}

export const useSelectedGrants = () => {
  const selectedOrgId = NavState.use(({ selectedOrgId }) => selectedOrgId)
  const grants = use(({ grants }) => grants)
  return selectedOrgId === ALL_ORGS_SELECTED ? grants : grants.filter(a => a.attributes.orgId === selectedOrgId)
}

export const useSelectedGrantsByUserId = () => {
  let res: { [userId: string]: Grant[] } = {}
  const selectedGrants = useSelectedGrants()
  selectedGrants.forEach(grant => {
    const grants = (res[grant.attributes.userId] = res[grant.attributes.userId] || [])
    grants.push(grant)
  })
  return res
}

export const reload = () =>
  Promise.resolve().then(setLoading).then(getAllGrants).then(setGrants).then(clearLoading, setError)
