import { shallowEqual } from 'react-redux'
import NotAuthorizedPage from 'components/pages/NotAuthorizedPage'
import { Orgs } from 'models'
import { Loader } from 'components/widgets'
import { memo } from 'react'

export const PortalRequired = memo(({ children }: { children: React.ReactChild }) => {
  const { orgs, loading } = Orgs.use(({ orgs, loading }) => {
    return { orgs, loading }
  }, shallowEqual)
  return loading ? <Loader /> : orgs.length === 0 ? <NotAuthorizedPage /> : <> {children}</>
})

PortalRequired.displayName = 'PortalRequired'
