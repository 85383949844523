import { createReduxModule } from 'hooks-for-redux'
import * as Orgs from './Orgs'
import { uniqueId } from 'lodash'

import * as AlertSessions from './AlertSessions'
import { AppNotification, AppNotificationsState, AlertSession } from 'lib/types'
import { Alert } from '@material-ui/lab'
const { array } = require('art-comprehensions')

const uniqueNotificationId = () => `notification-${uniqueId()}`

const initialState: AppNotificationsState = {
  notifications: Array<AppNotification>(0),
}

export const [use, { reset, setNotifications }, store] = createReduxModule('notifications', initialState, {
  reset: () => initialState,
  setNotifications: (state: AppNotificationsState, notifications: AppNotification[]) =>
    Object.assign({}, state, {
      notifications: array(notifications, (notification: AppNotification) =>
        notification.id
          ? notification
          : {
              ...notification,
              id: uniqueNotificationId(),
            },
      ),
    }),
})

// setTimeout(() => {
//   AlertSessions.store.subscribe(({ alertSessions }) => {
//     console.log('orgs', Orgs.store.getState())
//     // await Orgs.reload()
//     setNotifications(
//       array(alertSessions, {
//         when: (alertSession: AlertSession) => alertSession.attributes.status === 'open',
//         with: (alertSession: AlertSession) => ({
//           id: `notification-${alertSession.id}`,
//           title: 'Alert',
//           read: false,
//           orgName: Orgs.getOrg(alertSession.attributes.orgId)?.name || 'Unknown',
//           orgId: alertSession.attributes.orgId,
//           alertSessionId: alertSession.id,
//           onClick: () => {}, // TODO
//         }),
//       }),
//     )
//   })
// }, 1)

setTimeout(() => {
  AlertSessions.store.subscribe(({ alertSessions }) => {
    // await Orgs.reload()
    setNotifications(
      array(alertSessions, {
        when: (alertSession: AlertSession) => alertSession.attributes.status === 'open',
        with: (alertSession: AlertSession) => ({
          id: `notification-${alertSession.id}`,
          title: 'Alert',
          read: false,
          orgName: Orgs.getOrg(alertSession.attributes.orgId)?.name || 'Unknown',
          orgId: alertSession.attributes.orgId,
          alertSessionId: alertSession.id,
          onClick: () => {}, // TODO
        }),
      }),
    )
  })
}, 1)
