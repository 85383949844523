import React from 'react'
import Skeleton from 'react-loading-skeleton'
import styles from './GlobalDashboard.module.css'

const GlobalDashboardLoader = () => (
  <>
    <div className={styles.summaryContainer}>
      <div className={styles.summaryWrapper}>
        <Skeleton className={styles.hotelIconLoader} />
        <Skeleton className={styles.textLabelLoaders} />
      </div>
      <div className={styles.summaryWrapper}>
        <Skeleton className={styles.healthIconLoader} />
        <Skeleton className={styles.textLabelLoaders} />
      </div>
    </div>
    <div className={styles.headerWrapper}>
      <Skeleton className={styles.tableFilterLoaders} />
      <Skeleton className={styles.tableFilterLoaders} />
      <div>
        {Array(4)
          .fill(4)
          .map((item, index) => (
            <Skeleton className={styles.orgCardLoader} key={index} />
          ))}
      </div>
    </div>
  </>
)

export default GlobalDashboardLoader
