import { createReduxModule } from 'hooks-for-redux'
import { ALL_ORGS_SELECTED, GRANTS } from 'lib/constants'
import { Device, Grant, User } from 'lib/types'
import { flatten } from 'lodash'
import { Devices, NavState, Orgs, Users } from 'models'
import * as Grants from './Grants'
import { getRecordsById } from './modelUtils'

export enum ResourceType {
  USER = 'user',
  DEVICE = 'alert_source',
}

export interface Responder {
  id: string
  resourceType: ResourceType
  info?: Responder
}

export interface LiveResponder extends User, Device {}

export interface OrgResponder {
  orgId: string
  responder: Responder
}

export interface OrgResponders {
  orgId: string
  responders: Responder[]
}

export interface ResponderState {
  loading: boolean
  loadCount: number
  orgCount: number
  respondersById: { [key: string]: Responder }
  respondersByOrgId: { [key: string]: Responder[] }
  responderAdded?: OrgResponder
  responderRemoved?: OrgResponder
}

const initialState: ResponderState = {
  loading: true,
  loadCount: 0,
  orgCount: Number.POSITIVE_INFINITY,
  respondersById: {},
  respondersByOrgId: {},
  responderAdded: undefined,
  responderRemoved: undefined,
}

const setOrgResponders = (state: ResponderState, { orgId, responders }: OrgResponders) => {
  const respondersByOrgId = {
    ...state.respondersByOrgId,
    [orgId]: responders,
  }
  const all = flatten(Object.values(respondersByOrgId))
  const respondersById = getRecordsById<Responder>(all)
  const loadCount = state.loadCount + 1
  let loading = state.loading
  if (loadCount === state.orgCount) loading = false
  return {
    ...state,
    loading,
    loadCount,
    respondersById,
    respondersByOrgId,
  }
}

export const [use, { setRespondersByOrgId, addResponder, removeResponder, setOrgCount }, store] = createReduxModule(
  'liveRespondersState',
  initialState,
  {
    setOrgCount: (state: ResponderState, newCount: number) => {
      return {
        ...state,
        orgCount: newCount,
      }
    },
    setRespondersByOrgId: (state: ResponderState, orgResponders: OrgResponders) => {
      return setOrgResponders(state, orgResponders)
    },
    addResponder: (state: ResponderState, orgResponder: OrgResponder) => {
      // console.log('addResponder', orgResponder)
      const responders = new Set(state.respondersByOrgId[orgResponder.orgId])
      responders.add(orgResponder.responder.info!)
      return setOrgResponders(
        { ...state, responderAdded: orgResponder },
        {
          orgId: orgResponder.orgId,
          responders: Array.from(responders),
        },
      )
    },
    removeResponder: (state: ResponderState, orgResponder: OrgResponder) => {
      const responders = new Set(state.respondersByOrgId[orgResponder.orgId])
      responders.delete(orgResponder.responder.info!)
      return setOrgResponders(
        { ...state, responderRemoved: orgResponder },
        { orgId: orgResponder.orgId, responders: Array.from(responders) },
      )
    },
  },
)

export const isResponderOnline = (id: string): boolean => {
  const { respondersById } = store.getState()
  const member = respondersById[id]
  if (!member) return false
  const t = member.resourceType
  const { devicesById } = Devices.store.getState()

  if (t === ResourceType.DEVICE) {
    return devicesById[id]?.responder || false
  }
  const { grants } = Grants.store.getState()
  const responderGrant = grants.find(g => g.attributes.userId === id && g.attributes.name === GRANTS.RESPONDER)
  return !!responderGrant
}

const getOrgResponders = () => {
  let orgResponders: Responder[] = []
  const respondersByOrgId = use(({ respondersByOrgId }) => respondersByOrgId)
  const selectedOrgId = NavState.use(({ selectedOrgId }) => selectedOrgId)
  if (selectedOrgId === ALL_ORGS_SELECTED) {
    orgResponders = flatten(Object.values(respondersByOrgId)).filter((res, index, self) => {
      return index === self.findIndex(r => r.id === res.id)
    })
  } else {
    orgResponders = respondersByOrgId[selectedOrgId]
  }
  return orgResponders || []
}

export const useSelectedLiveRepondersById = (): { [key: string]: LiveResponder } => {
  const responders = useSelectedLiveResponders()
  return getRecordsById<LiveResponder>(responders)
}

export const useLiveRepondersByOrgId = (orgId: string): LiveResponder[] => {
  const respondersByOrgId = use(({ respondersByOrgId }) => respondersByOrgId)
  const usersById = Users.use(({ usersById }) => usersById)
  const devicesById = Devices.use(({ devicesById }) => devicesById)
  const orgResponders = respondersByOrgId[orgId] || []
  const grants = Grants.use(({ grants }) => grants)
  const orgResponderGrants = grants.filter(g => g.attributes.orgId === orgId && g.attributes.name === GRANTS.RESPONDER)
  const grantsByUserId: { [userId: string]: Grant } = {}
  orgResponderGrants.forEach(grant => {
    grantsByUserId[grant.attributes.userId] = grant
  })

  return orgResponders.reduce((acc: LiveResponder[], responder: Responder) => {
    if (!responder || ![ResourceType.DEVICE, ResourceType.USER].includes(responder.resourceType)) return acc
    if (responder.resourceType === ResourceType.DEVICE) {
      const deviceResponder = devicesById[responder.id] as LiveResponder
      if (deviceResponder) acc.push(deviceResponder)
      return acc
    }
    const isResponder = !!grantsByUserId[responder.id]
    if (isResponder) {
      const userResponder = usersById[responder.id] as LiveResponder
      if (userResponder) acc.push(userResponder)
    }
    return acc
  }, [])
}

export const useSelectedLiveResponders = (): LiveResponder[] => {
  const grantsByUserId = Grants.useSelectedGrantsByUserId()
  const usersById = Users.use(({ usersById }) => usersById)
  const devicesById = Devices.use(({ devicesById }) => devicesById)
  const orgResponders = getOrgResponders()
  return orgResponders.reduce((acc: LiveResponder[], responder: Responder) => {
    if (!responder || ![ResourceType.DEVICE, ResourceType.USER].includes(responder.resourceType)) return acc
    if (responder.resourceType === ResourceType.DEVICE) {
      const deviceResponder = devicesById[responder.id] as LiveResponder
      if (deviceResponder) acc.push(deviceResponder)
      return acc
    }
    const isResponder = !!(grantsByUserId[responder.id] || []).find(g => {
      return g.attributes.name === GRANTS.RESPONDER
    })
    if (isResponder) {
      const userResponder = usersById[responder.id] as LiveResponder
      if (userResponder) acc.push(userResponder)
    }
    return acc
  }, [])
}
setTimeout(() => {
  Orgs.store.subscribe(({ orgs }) => {
    if (orgs.length) setOrgCount(orgs.length)
  })
}, 1)
