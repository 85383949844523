import { shallowEqual } from 'react-redux'
import { createReduxModule } from 'hooks-for-redux'
import { ExternalIntegration } from 'lib/types'
import {
  getAllExternalIntegrations,
  getExternalIntegration,
  deleteExternalIntegration,
  upsertExternalIntegration,
} from './api'
import { RateLimitedTimer } from 'lib/utils'
import * as PopUpNotifications from './PopUpNotifications'
import { getRecordsById, getRecordsByOrgId } from './modelUtils'
import { NavState } from 'models'
import { ALL_ORGS_SELECTED } from 'lib/constants'
const deepEqual = require('fast-deep-equal')

interface ExternalIntegrationState {
  integrations: ExternalIntegration[]
  integrationsById: { [key: string]: ExternalIntegration }
  integrationsByOrgId: { [key: string]: ExternalIntegration[] }
  loading: boolean
  error?: any
}

const initialState: ExternalIntegrationState = {
  integrations: [],
  integrationsById: {},
  integrationsByOrgId: {},
  loading: true,
}

const setExternalIntegrationsReducer = (state: ExternalIntegrationState, integrations: ExternalIntegration[]) => ({
  ...state,
  integrations: (integrations || []).sort((a, b) => a.name.localeCompare(b.name)),
  integrationsById: getRecordsById<ExternalIntegration>(integrations),
  integrationsByOrgId: getRecordsByOrgId<ExternalIntegration>(integrations || []),
})

export const [use, { setExternalIntegrations, setLoading, clearLoading, setError, updateExternalIntegration }, store] =
  createReduxModule('externalIntegrations', initialState, {
    setExternalIntegrations: setExternalIntegrationsReducer,
    updateExternalIntegration: (
      state: ExternalIntegrationState,
      updatedItem: ExternalIntegration,
    ): ExternalIntegrationState => {
      if (!updatedItem.id) return state
      return deepEqual(updatedItem, state.integrationsById[updatedItem.id])
        ? state
        : setExternalIntegrationsReducer(
            state,
            state.integrations.find(item => item.id === updatedItem.id)
              ? state.integrations.map(item => (item.id === updatedItem.id ? updatedItem : item))
              : [...state.integrations, updatedItem],
          )
    },
    setLoading: (state: ExternalIntegrationState, loading: boolean) => ({ ...state, loading }),
    clearLoading: (state: ExternalIntegrationState) => ({ ...state, loading: false }),
    setError: (state: ExternalIntegrationState, error: any) => ({ ...state, error }),
  })

export const useSelectedOrgsDevices = () => {
  const selectedOrgId = NavState.use(({ selectedOrgId }) => selectedOrgId)
  const { integrations, integrationsByOrgId } = use(({ integrations, integrationsByOrgId }) => {
    return { integrations, integrationsByOrgId }
  }, shallowEqual)
  return selectedOrgId === ALL_ORGS_SELECTED ? integrations : integrationsByOrgId[selectedOrgId] || []
}

const reloadRateLimiter = new RateLimitedTimer()
/* reload the specified org to get current stats; Note: rate-limited to one call per org per 5 seconds. */
export const reloadOrg = (orgId: string) =>
  reloadRateLimiter.timeout(process.env.NODE_ENV === 'test' ? 100 : 5000, orgId, () =>
    getExternalIntegration(orgId).then(updateExternalIntegration),
  )

export const setErrorWithNotificationAndRethrow = (error: any) => {
  clearLoading()
  if (error.response.status >= 500) {
    PopUpNotifications.fireErrorObject(error)
    setError(error)
    throw error
  }
}

/** Fetches the list of ExternalIntegration visible to the current user and stores them in the ExternalIntegrations H4R state. Super Admins can see all ExternalIntegrations. */
export const reload = () => {
  setLoading(true)
  return Promise.resolve()
    .then(getAllExternalIntegrations)
    .then(setExternalIntegrations)
    .then(clearLoading, setErrorWithNotificationAndRethrow)
}

const doUpdateAction = (f: any) => {
  setLoading(true)
  return Promise.resolve()
    .then(f)
    .then(result => {
      reload()
      return result
    }, setErrorWithNotificationAndRethrow)
}

/** Update an specific integration, them refresh the current H4R state. Super Admins can see all ExternalIntegrations. */
export const doUpsertExternalIntegration = (integration: ExternalIntegration): Promise<ExternalIntegration> =>
  // @ts-ignore
  doUpdateAction(() => upsertExternalIntegration(integration))

export const deleteIntegration = (id: string) => doUpdateAction(() => deleteExternalIntegration(id))
