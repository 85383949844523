import { createReduxModule } from 'hooks-for-redux'
import { Organization, IdToIdList } from 'lib/types'
import * as NavState from './NavState'
import * as Orgs from './Orgs'
import * as ChildOrgMap from './ChildOrgMap'
import { ALL_ORGS_SELECTED } from 'lib/constants'
const { each } = require('art-comprehensions')

export interface ChildOrgsMapState {
  childOrgIds: string[]
}

const initialState: string[] = []

export const [use, set, store] = createReduxModule('selectedChildOrgIds', initialState)

const addChildOrgsRecursively = (
  orgId: string,
  childOrgMap: IdToIdList,
  childOrgsByIdToFill: { [key: string]: boolean },
) => {
  // @ts-ignore
  each(childOrgMap[orgId], childOrgId => {
    if (!childOrgsByIdToFill[childOrgId]) {
      childOrgsByIdToFill[childOrgId] = true
      addChildOrgsRecursively(childOrgId, childOrgMap, childOrgsByIdToFill)
    }
  })
  return childOrgsByIdToFill
}

// OUT: returns an array of childOrgIs
// NOTE: includes all childOrgIds, recursively, but this does NOT include rootOrgId
const getAllChildOrgIds = (rootOrgId: string, childOrgMap: IdToIdList): string[] =>
  rootOrgId === ALL_ORGS_SELECTED ? [] : Object.keys(addChildOrgsRecursively(rootOrgId, childOrgMap, {}))

setTimeout(() => {
  NavState.store.subscribe(({ selectedOrgId }) =>
    set(getAllChildOrgIds(selectedOrgId, ChildOrgMap.store.getState().childOrgMap)),
  )

  ChildOrgMap.store.subscribe(({ childOrgMap }) =>
    set(getAllChildOrgIds(NavState.store.getState().selectedOrgId, childOrgMap)),
  )
}, 100)

/*********************************************

    ADDITIONAL HOOKS

*********************************************/

export const useAllSelectedOrgIds = (): string[] => {
  const selectedOrgId = NavState.use(({ selectedOrgId }) => selectedOrgId)
  if (selectedOrgId) {
    return [selectedOrgId, ...use()]
  } else return []
}

export const useAllSelectedOrgs = (): Organization[] => useOrgsFromIds(useAllSelectedOrgIds())

export const useSelectedChildOrgs = (): Organization[] => useOrgsFromIds(use())

// useSelectedOrgIdsPath
// get an array of the current selectedOrgId and all its ancestors
// OUT: [rootAncestorId, ..., selectedOrgId]
export const useSelectedOrgIdsPath = (): string[] => {
  const selectedOrgId = NavState.use(({ selectedOrgId }) => selectedOrgId)
  const parentOrgMap = ChildOrgMap.use(({ parentOrgMap }) => parentOrgMap)
  if (!selectedOrgId) return []
  let currentOrgId = selectedOrgId
  const selectedOrgIdsPath = []
  while (currentOrgId) {
    selectedOrgIdsPath.push(currentOrgId)
    let parentOrgIds = parentOrgMap[currentOrgId]
    // @ts-ignore
    currentOrgId = parentOrgIds ? parentOrgIds[0] : undefined
  }
  return selectedOrgIdsPath.reverse()
}

// useAllChildOrgIdsFromOrgId
// given an orgId, return an updating list of all child-org-ids
export const useAllChildOrgIdsFromOrgId = (orgId: string): string[] => {
  const childOrgMap = ChildOrgMap.use(({ childOrgMap }) => childOrgMap)
  return getAllChildOrgIds(orgId, childOrgMap)
}

export const useAllChildOrgsFromOrgId = (orgId: string): Organization[] =>
  useOrgsFromIds(useAllChildOrgIdsFromOrgId(orgId))

export const useOrgsFromIds = (orgIds: string[]): Organization[] => {
  const orgsById = Orgs.use(({ orgsById }) => orgsById)
  return orgIds.filter(id => orgsById[id]).map(id => orgsById[id])
}
