import { getRequest, postRequest, putRequest } from './rest'
import { User } from 'lib/types'
import { API_USER, API_USERS, AUTH_PROVIDER, AuthProvider, API_RESEND_INVITE } from 'lib/constants'
import { decodeJsonApiObject } from 'models/api'
import { PopUpNotifications } from 'models'
import { trans, TranslationGroup, TranslationKey } from 'lib/types'

const max_user_limit = 25000

export function getUserById(id: string): Promise<User> {
  return getRequest({
    endpoint: `${API_USERS}/${id}`,
  }).then(({ data }) => {
    return { ...data.data, lastFetched: new Date().getTime() }
  })
}

// TODO: switch to using decodeJsonApiObject!!!
export const getCurrentUser = (): Promise<User> =>
  getRequest({
    endpoint: `${API_USER}/current`,
  }).then(({ data }) => {
    return { ...data.data, lastFetched: new Date().getTime() }
  })

export function getUsers(): Promise<User[]> {
  return getRequest({
    endpoint: `${API_USERS}?limit=${max_user_limit}`,
  }).then(({ data }) => data.data)
}

export function getUsersByOrgId(orgId: string): Promise<User[]> {
  return getRequest({
    endpoint: `${API_USERS}`,
    header: {
      org_id: orgId,
    },
  }).then(({ data }) => data.data)
}

export function getUserByEmail(email: string): Promise<{ provider: AuthProvider | null }> {
  const translation: TranslationGroup = trans.group(TranslationKey.AUTH)
  return getRequest({
    endpoint: `${AUTH_PROVIDER}${email}`,
  })
    .then(({ data }) => data)
    .catch(() => PopUpNotifications.fireError({ content: translation.unexpected_error }))
}

export function createOrUpdateUser(userWithId: User): Promise<User> {
  const { id, ...user } = userWithId

  return (
    id
      ? putRequest({
          endpoint: `${API_USERS}/${id}`,
          data: { user },
        })
      : postRequest({
          endpoint: `${API_USERS}`,
          data: { user },
        })
  ).then(({ data }) => decodeJsonApiObject<User>(data.data))
}

export function resendInviteEmail(email: string): Promise<any> {
  return postRequest({
    endpoint: API_RESEND_INVITE,
    data: { user: { email: email } },
  })
}
