import { Links, Meta } from './common'

interface AlertSessionLocation {
  floor: string
  integration: string
  lat: number
  long: number
  property: string
  room: string
}

export enum AlertType {
  FALSE_ALARM = 'false_alarm',
  GUEST = 'guest',
  STAFF = 'staff',
  TEST = 'test',
}

interface Beacon {
  beaconId: string
  rssi: number
}

export interface ResponderSummary {
  alertSourceId: string
  arrivedTime: number
  firstTime: number
  lastTime: number
  location: AlertSessionLocation
  userId: string
}

interface AlertSessionAttributes {
  alertSourceId: string
  alertSourceName: string
  alertType?: string
  eventCount: number
  firstArrivedTime?: number
  firstLocation?: AlertSessionLocation
  firstTime: number
  initiatorName: string
  lastBeacons?: Beacon[]
  lastLocation?: AlertSessionLocation
  lastTime: number
  legacyId?: string
  orgId: string
  activeRespondersCount: number
  resolverAlertSourceId?: string
  resolverName?: string
  resolverUserId?: string
  responders: ResponderSummary[]
  status: string
  resolveTime?: number
}

export interface AlertSession {
  attributes: AlertSessionAttributes
  id: string
  type: string
}

export interface AlertSessionListResponse {
  data: AlertSession[]
  links: Links
  meta: Meta
}

export interface AlertSessionNotificationMap {
  [id: string]: {
    locationNoteSent: boolean
  }
}
