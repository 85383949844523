import { useState, useRef, ChangeEvent } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import styled from '@emotion/styled'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Papa from 'papaparse'
import { StyledH2, LinkableText } from 'components/legacy-poorly-defined/Common'
import { API_BEACONS, ALL_ORGS_SELECTED, PRIMARY_COLOR } from 'lib/constants'
import { postRequest } from 'models/api/rest'
import { Orgs, PopUpNotifications } from 'models'
import { ActionButton } from 'components/widgets'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import { trans, TranslationGroup } from 'lib/types'

const ParentList = styled.ul({
  fontFamily: 'Montserrat',
  fontSize: '1.2rem',
  listStyle: 'none',
  paddingLeft: 0,
})

const ChildList = styled.ul({
  listStyle: 'none',
  paddingLeft: '2.0rem',
})

const RowContainer = styled.div({
  width: '100%',
  marginBottom: '2.4rem',
  flexDirection: 'column',
})

const StyledBoldText = styled.div({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  lineHeight: '1.9rem',
  color: '#242424',
})

const StyledText = styled.div({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontSize: '1.2rem',
  lineHeight: '1.9rem',
  color: '#242424',
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
    },
    heading: {
      fontSize: '1.5rem',
      color: PRIMARY_COLOR,
    },
  }),
)

export default function BulkBeaconInstall() {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const inputFile = useRef({} as any)
  const [expanded, setExpanded] = useState<string | false>(false)
  // @ts-ignore
  const location = Orgs.useSelectedOrg()

  const hasLocation = location && location?.name !== ALL_ORGS_SELECTED
  const settings: TranslationGroup = trans.settings()
  const common: TranslationGroup = trans.common()

  const handleChange = (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const afterFileParse = async (parsedFile: any) => {
    try {
      setIsLoading(true)
      let newBeacons = parsedFile.data.map((b: any) => ({
        beaconType: b[0],
        disabled: false,
        name: b[1],
        floor: b[2],
        room: b[3],
        id: b[4],
        versionCode: parseInt(b[5], 10),
      }))
      const data = { beacons: newBeacons }
      await postRequest({
        endpoint: `${API_BEACONS}/multi`,
        data,
        header: { org_id: location?.id },
      })
      PopUpNotifications.fireInfo({ content: settings.beacons_created })
    } catch (error) {
      PopUpNotifications.fireError({
        content: settings.beacons_create_fail,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeFile = (event: any) => {
    event.stopPropagation()
    event.preventDefault()
    try {
      const file = event.target.files[0]
      Papa.parse(file, {
        complete: afterFileParse,
      })
    } catch (error) {
      PopUpNotifications.fireError({
        content: settings.csv_parse_error,
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <StyledH2>{settings.beacons_bulk_installation}</StyledH2>
      <StyledBoldText>{settings.upload_csv}</StyledBoldText>
      <br />
      <Accordion expanded={expanded === 'details'} onChange={handleChange('details')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="'details-content" id="'details-header">
          <Typography className={classes.heading}>{settings.more_details}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RowContainer>
            <StyledBoldText>{settings.csv_file_format}</StyledBoldText>
            <ParentList>
              <li>
                <b>{settings.beacon_type} </b> {settings.required_values} <br />
                <ChildList>
                  <li>iBeacon, Estimote, Carfit, REACT1, REACT2</li>
                </ChildList>
              </li>
              <li>
                <b>{common.name} </b>
                {settings.required}
                <ChildList>
                  <li>{settings.ex1}</li>
                  <li>{settings.ex2}</li>
                </ChildList>
              </li>
              <li>
                <b>{common.floor}: </b>
                {settings.required}
                <ChildList>
                  <li>{settings.ex3}</li>
                  <li>{settings.ex4}</li>
                  <li>{settings.ex5}</li>
                </ChildList>
              </li>
              <li>
                <b>{common.room}: </b>
                {settings.required}
                <ChildList>
                  <li>{settings.ex6}</li>
                  <li>{settings.ex7}</li>
                </ChildList>
              </li>
              <li>
                <b>{common.id}: </b>
                {settings.may_be_empty}
                <ChildList>
                  <li>{settings.ex8}</li>
                  <li>{settings.ex9}</li>
                  <li>{settings.ex10}</li>
                  <li>{settings.ex11}</li>
                </ChildList>
              </li>
              <li>
                <b>{settings.version_code}</b>
              </li>
            </ParentList>

            <StyledText>
              <b>{settings.example_file}</b>
              <br />
              {settings.ex12}
              <br />
              {settings.ex13}
              <br />
              {settings.ex14}
            </StyledText>
          </RowContainer>
        </AccordionDetails>
      </Accordion>
      <br />
      <input
        type="file"
        id="csvupload"
        style={{ display: 'none' }}
        ref={inputFile}
        onChange={onChangeFile}
        accept=".csv"
      />
      <ActionButton onClick={() => inputFile.current.click()} disabled={!hasLocation || isLoading}>
        {settings.browse_file}
      </ActionButton>
      {!hasLocation && (
        <div>
          <LinkableText>{settings.select_org_beacons}</LinkableText>
        </div>
      )}
    </div>
  )
}
