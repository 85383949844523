/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled/macro'
import { InputLabel, Input } from '@material-ui/core'
import { ActionButton, StyledDialogActions } from 'components/widgets'
import { trans, TranslationGroup } from 'lib/types'
import { PopUpNotifications } from 'models'
import { useState } from 'react'
import { GRAY3_COLOR, TEXTFIELD_LABEL_COLOR } from 'lib/constants'

const ElementWrapper = styled.div({
  width: '100%',
  alignContent: 'center',
})

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '25px',
})

const StyledFormControl = styled.div({
  width: '100%',
  marginBottom: '15px',
})

const StyledInputLabel = styled(InputLabel)({
  color: TEXTFIELD_LABEL_COLOR,
  fontFamily: 'Montserrat',
  fontSize: '1.4rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.5rem',
  letterSpacing: '0.1rem',
  textAlign: 'left',
  marginBottom: '20px',
})

const StyledInput = styled(Input)({
  background: GRAY3_COLOR,
  borderRadius: '10px',
  padding: '5px 10px',
  fontFamily: 'Montserrat',
})

export const EditElementModal = ({
  title,
  element,
  fieldName,
  close,
  action,
}: {
  title: string
  element: any
  fieldName: string
  close: Function
  action: Function
}) => {
  const [fieldValue, setFieldValue] = useState(element[fieldName])
  const common: TranslationGroup = trans.common()

  return (
    <ElementWrapper>
      <StyledContainer>
        <div>
          <form>
            <StyledFormControl>
              <StyledInputLabel htmlFor="field-value">{title}</StyledInputLabel>
              <StyledInput
                id="field-value"
                disableUnderline
                fullWidth
                placeholder={fieldName}
                value={fieldValue}
                onChange={e => {
                  setFieldValue(e.target.value)
                }}
              />
            </StyledFormControl>
          </form>
        </div>
      </StyledContainer>
      <StyledDialogActions>
        <ActionButton width="110px" height="28px" onClick={() => close()}>
          {common.cancel}
        </ActionButton>
        <ActionButton
          width="110px"
          height="28px"
          onClick={() => {
            close()
            Object.defineProperty(element, fieldName, {
              value: fieldValue,
            })
            return PopUpNotifications.tryWithErrorNotifications(action(element))
          }}
        >
          {common.confirm}
        </ActionButton>
      </StyledDialogActions>
    </ElementWrapper>
  )
}
