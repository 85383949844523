import { TimeStamps } from './common'
/*
{
    "attributes": {
      "alertSourceId": "a59687e5-3892-4e38-aad0-202963044142",
      "forward": "jasen1940@example.org",
      "forwardType": "email",
      "insertedAt": "1970-12-09T12:01:00",
      "orgId": "f2b2d4e7-5164-478c-b219-a7323d5aada8",
      "originalForward": "JASEN1940@EXAMPLE.ORG",
      "status": "pending",
      "updatedAt": "2020-12-09T12:01:00",
      "userId": "af28a609-9887-42a4-a378-33a1c7756b02"
    },
    "id": "6c44cd31-667c-4848-8cba-7904a972b848",
    "type": "alertTarget"
}
*/

// @forward_type_const [:email, :legacy, :push_android, :push_ios, :sms]

export enum AlertTargetStatus {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  REFUSED = 'refused',
}

export enum AlertTargetForwardType {
  EMAIL = 'email',
  LEGACY = 'legacy',
  PUSH_ANDROID = 'push_android',
  PUSH_IOS = 'push_ios',
  SMS = 'sms',
}

export interface AlertTargetAttributes {
  alertSourceId?: string
  forward: string
  forwardType: AlertTargetForwardType
  orgId: string
  originalForward?: string
  status?: AlertTargetStatus
  userId: string
}

export interface AlertTarget {
  attributes: AlertTargetAttributes & TimeStamps
  id: string
  type: string
}

export interface AlertTargetRequest extends AlertTargetAttributes {
  id?: string
}
