export enum AlertsColumnKeys {
  Name = 'Name',
  Health = 'Health',
  Responders = 'Responders',
  Resolution = 'Resolution',
  Time = 'Time',
  Date = 'Date',
  StartLocation = 'start_loc',
  EndLocation = 'end_loc',
}
