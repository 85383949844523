import { getRequest, postAlertRequest } from './rest'
import { AlertEvent } from 'lib/types'
import { API_ALERT_EVENTS, ALERT_API_ALERT_EVENT } from 'lib/constants'

export const getAlertEvents = (alertSessionId: string): Promise<AlertEvent[]> =>
  getRequest({
    endpoint: `${API_ALERT_EVENTS}?alertSessionId=${alertSessionId}`,
  }).then(({ data }) => data.data)

export const sendAlertEvent = (alertEvent: any): Promise<AlertEvent[]> =>
  postAlertRequest({
    endpoint: ALERT_API_ALERT_EVENT,
    data: alertEvent,
    header: { org_id: alertEvent.alertEvent.orgId },
  }).then(({ data }) => data.data)
