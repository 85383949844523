/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import styled from '@emotion/styled/macro'
import { TableRow } from '@material-ui/core'
import { MoreHoriz } from '@material-ui/icons'

export const TableToolsWrapper = styled.div({
  display: 'flex',
  marginBottom: '20px',
  flexWrap: 'wrap',
  '@media (max-width: 1300px)': {
    display: 'flex',
  },
})

export const StyledTableRow = styled(TableRow)({
  cursor: 'pointer',
  fontFamily: 'Montserrat',
  fontSize: '1.2rem',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '1.5rem',
  letterSpacing: '0.1rem',
  textAlign: 'left',
})

export const PaginationWrapper = styled(TableRow)({
  'p.MuiTypography-root.MuiTablePagination-caption.MuiTypography-body2.MuiTypography-colorInherit': {
    display: 'none',
  },
  '.MuiTablePagination-spacer': {
    flex: '1 1 auto',
  },
  '.MuiToolbar-gutters': {
    paddingLeft: '0',
  },
  '.MuiTablePagination-root': {
    overflow: 'initial',
    fontFamily: 'Montserrat',
    fontSize: '1.2rem',
    fontStyle: 'normal',
    fontWight: '500',
    lineHeight: '1.9rem',
    letterSpacing: '0',
    textAlign: 'left',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
})

export const StyledMoreHorizIcon = styled(MoreHoriz)({
  color: '#29a096',
})

export const ShowMoreContainer = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 2.5rem;
  padding: 5px;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #29a096;
  box-sizing: border-box;
  box-shadow: -4px 4px 6px rgb(0 0 0 / 12%);
  border-radius: 6px;
  z-index: 2000;
`
export const ShowMoreOption = styled.div`
  &:hover {
    background: #f7f7f7;
  }
`
