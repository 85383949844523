import { shallowEqual } from 'react-redux'
import { createReduxModule } from 'hooks-for-redux'
import {
  AlertSession,
  AlertSessionNotificationMap,
  Organization,
  trans,
  TranslationGroup,
  TranslationKey,
} from 'lib/types'
import {
  pusherSubscribeAndBind,
  UnsubscribeFunction,
  Pagination,
  EMPTY_PAGINATION,
  AlertSessionsWithPagination,
  getAlertSessionsWithPagination,
  AlertSessionParams,
  EMPTY_ALERT_SESSIONS,
  getAlertSessionPage,
} from './api'
import * as Orgs from './Orgs'
import * as PopUpNotifications from './PopUpNotifications'
import * as NavState from './NavState'
import { ALL_ORGS_SELECTED, API_DEFAULT_LIMIT, PUSHER_PRESENCE_PREFIX, ROWS_PER_PAGE } from 'lib/constants'
import { playAlertSound } from './Sound'
import { getRecordsById, getRecordsByOrgId } from './modelUtils'
import { displayDesktopNotification } from './DesktopNotifications'
import { isAdmin, isSuperAdmin } from 'lib/utils/auth'

const { object, each } = require('art-comprehensions')
const ALERT_SESSION_PUSHER_EVENT = 'alertSession'

export interface AlertSessionsState {
  alertSessions: AlertSession[]
  alertSessionsById: { [key: string]: AlertSession }
  alertSessionsByOrgId: { [key: string]: AlertSession[] }
  loading: boolean
  initialLoading: boolean
  pagination: Pagination
  error?: any
}

const initialState: AlertSessionsState = {
  alertSessions: [],
  alertSessionsById: {},
  alertSessionsByOrgId: {},
  loading: false,
  initialLoading: false,
  pagination: EMPTY_PAGINATION,
}

const withUpdatedAlertSessionsById = (state: AlertSessionsState) => ({
  ...state,
  alertSessionsById: getRecordsById(state.alertSessions),
  alertSessionsByOrgId: getRecordsByOrgId(state.alertSessions),
})

const setAlertSessionState = (
  state: AlertSessionsState,
  alertSessionsWithPagination: AlertSessionsWithPagination,
): AlertSessionsState => {
  const alertSessions = alertSessionsWithPagination.alertSessions
  return {
    ...state,
    alertSessions,
    alertSessionsById: getRecordsById(alertSessions),
    alertSessionsByOrgId: getRecordsByOrgId(state.alertSessions),
    pagination: alertSessionsWithPagination.pagination,
  }
}

export const [
  use,
  {
    setAlertSessionsWithPagination,
    updateAlertSessionsWithPagination,
    updateAlertSession: _updateAlertSession,
    setInitialLoading,
    setLoading,
    setError,
  },
  store,
] = createReduxModule('alertSessions', initialState, {
  setAlertSessionsWithPagination: (
    state: AlertSessionsState,
    alertSessionsWithPagination: AlertSessionsWithPagination,
  ) => {
    return setAlertSessionState(state, alertSessionsWithPagination)
  },
  updateAlertSessionsWithPagination: (
    state: AlertSessionsState,
    alertSessionsWithPagination: AlertSessionsWithPagination,
  ): AlertSessionsState => {
    const updatedAlertSessions = alertSessionsWithPagination.alertSessions.reduce(
      (acc: AlertSession[], alertSession: AlertSession) => {
        if (!state.alertSessionsById[alertSession.id]) acc.push(alertSession)
        return acc
      },
      state.alertSessions,
    )
    const updatedAlertSessionsWithPagination: AlertSessionsWithPagination = {
      alertSessions: updatedAlertSessions,
      pagination: alertSessionsWithPagination.pagination,
    }
    return setAlertSessionState(state, updatedAlertSessionsWithPagination)
  },
  updateAlertSession: (state: AlertSessionsState, alertSession: AlertSession) =>
    withUpdatedAlertSessionsById({
      ...state,
      alertSessions: state.alertSessions.find(oldAlertSession => oldAlertSession.id === alertSession.id)
        ? state.alertSessions.map(oldAlertSession =>
            oldAlertSession.id === alertSession.id ? alertSession : oldAlertSession,
          )
        : [...state.alertSessions, alertSession],
    }),
  setInitialLoading: (state: AlertSessionsState, initialLoading: boolean) => ({ ...state, initialLoading }),
  setLoading: (state: AlertSessionsState, loading: boolean) => ({ ...state, loading }),
  setError: (state: AlertSessionsState, error: any) => ({ ...state, error }),
})

const handleError = (errorObj: any) => {
  const msg = errorObj instanceof Error ? errorObj.message : errorObj.toString()
  const content = `AlertSessions ${msg.toLowerCase()}`
  PopUpNotifications.fireError({ content })
  setError(errorObj)
}

export const reload = async () => {
  const selectedOrgId = localStorage.getItem('selected_org_id')
  const params: AlertSessionParams = {
    limit: ROWS_PER_PAGE,
    afterCursor: null,
    orgId: selectedOrgId,
  }

  if (selectedOrgId === 'All Properties') {
    params.orgId = undefined
    params.open = true
  }

  setInitialLoading(true)
  setLoading(true)
  let pageOne = EMPTY_ALERT_SESSIONS
  let errorObj
  try {
    pageOne = await getAlertSessionPage(params)
  } catch (err: any) {
    errorObj = err
  }
  setInitialLoading(false)
  if (errorObj) {
    if (errorObj.response.status >= 500) handleError(errorObj)
    setLoading(false)
    return
  }

  setAlertSessionsWithPagination(pageOne)

  let alertSessions = EMPTY_ALERT_SESSIONS

  if (isAdmin() || isSuperAdmin()) {
    params.open = true
    params.orgId = undefined
    const openAlerts = await getAlertSessionPage(params)
    updateAlertSessionsWithPagination(openAlerts)
  }

  if (selectedOrgId !== 'All Properties') {
    params.open = undefined
    params.limit = API_DEFAULT_LIMIT
    params.afterCursor = pageOne.pagination.after
    params.orgId = selectedOrgId

    try {
      alertSessions = await getAlertSessionsWithPagination(params)
    } catch (err: any) {
      errorObj = err
    }
    if (errorObj) {
      if (errorObj.response.status >= 500) handleError(errorObj)
      setLoading(false)
      return
    }

    updateAlertSessionsWithPagination(alertSessions)
  }
  setLoading(false)
}

type ActiveSubscriptions = { [orgId: string]: UnsubscribeFunction }
let activeSubscriptions: ActiveSubscriptions = {}
const updateActiveSubscriptions = (newActiveSubscriptions: ActiveSubscriptions) => {
  each(activeSubscriptions, {
    when: (unsubscribe: UnsubscribeFunction, orgId: string) => !newActiveSubscriptions[orgId],
    with: (unsubscribe: UnsubscribeFunction) => unsubscribe(),
  })
  return (activeSubscriptions = newActiveSubscriptions)
}

const showPopUpNotificationsForNewAlertSession = (alertSession: AlertSession) => {
  const translation: TranslationGroup = trans.group(TranslationKey.NOTIFICATIONS)
  const currentState = store.getState()
  const previousStatus = currentState.alertSessions.find(({ id }) => id === alertSession.id)?.attributes?.status
  let locationKnown = false
  const locationAvailable = alertSession.attributes.firstLocation?.floor || alertSession.attributes.firstLocation?.room

  if (alertSession.attributes.status !== previousStatus) {
    switch (alertSession.attributes.status) {
      case 'open':
        if (locationAvailable) {
          locationKnown = true
        }
        playAlertSound()
        displayDesktopNotification(alertSession, locationKnown)

        PopUpNotifications.fireError({
          content: translation.new_alert_started,
          alertSession,
        })
        break
      case 'resolved':
        removeLocNoteMapSession(alertSession.id)
        PopUpNotifications.fireInfo({
          content: translation.alert_resolved,
          alertSession,
        })
        break
    }
  }
}

export const useSelectedOrgsAlertSessions = () => {
  const selectedOrgId = NavState.use(({ selectedOrgId }) => selectedOrgId)
  const { alertSessions, alertSessionsByOrgId } = use(({ alertSessions, alertSessionsByOrgId }) => {
    return { alertSessions, alertSessionsByOrgId }
  }, shallowEqual)
  return selectedOrgId === ALL_ORGS_SELECTED ? alertSessions : alertSessionsByOrgId[selectedOrgId] || []
}

export const updateAlertSession = (alertSession: AlertSession) => {
  showPopUpNotificationsForNewAlertSession(alertSession)
  _updateAlertSession(alertSession)

  const locationAvailable = alertSession.attributes.firstLocation?.floor || alertSession.attributes.firstLocation?.room

  if (
    locationAvailable &&
    !locationNoteMap[alertSession.id]?.locationNoteSent && // Doesn't exist in map data struct
    alertSession.attributes.status !== 'resolved' // Needed because the session was removed from map when the alert was resolved
  ) {
    displayDesktopNotification(alertSession, true)
    updateLocNoteMap(alertSession.id, true)
  }

  return Orgs.reloadOrg(alertSession.attributes.orgId)
}

const subscribeToOrgs = (orgs: Organization[]) => {
  return object(
    orgs,
    (org: Organization) =>
      activeSubscriptions[org.id] ||
      pusherSubscribeAndBind(
        `${PUSHER_PRESENCE_PREFIX}org_${org.id}`,
        ALERT_SESSION_PUSHER_EVENT,
        (data: { data: AlertSession }) => updateAlertSession(data.data),
      ),
  )
}

// Store to keep track of desktop notifications - We want to only send location once
let locationNoteMap: AlertSessionNotificationMap = {}

const removeLocNoteMapSession = (id: string) => {
  delete locationNoteMap[id]
}

const updateLocNoteMap = (id: string, status: boolean) => {
  locationNoteMap[id] = { locationNoteSent: status }
}

let inited = false

setTimeout(() => {
  Orgs.store.subscribe(({ orgs }) => {
    if (inited) return
    updateActiveSubscriptions(subscribeToOrgs(orgs))
    inited = true
  })
}, 1)
