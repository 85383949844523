import i18n from 'i18n'
import { getEnv } from 'lib/constants'
import { enUS, tkTK } from 'locales'

export enum TranslationKey {
  ACTION_MESSAGES = 'actionmessages',
  ADMIN = 'admin',
  ADMIN_FIRMWARE = 'adminfirmware',
  ALERT_DRAWER = 'alertdrawer',
  ALERT_HISTORY = 'alerthistory',
  ALERT_VIEW = 'alertview',
  AUTH = 'auth',
  BEACON_DRAWER = 'beacondrawer',
  BEACONS_TABLE_VIEW = 'beaconstableview',
  COMMON = 'common',
  DASHBOARD = 'dashboard',
  DEVICES_DRAWER = 'devicesdrawer',
  DEVICES_TABLE_VIEW = 'devicestableview',
  ENVIRONMENT_OPTIONS = 'enviroment_options',
  ERRORS = 'errors',
  HEADER = 'header',
  HEALTH_LABELS = 'healthlabels',
  HEALTH_TOOLTIPS = 'healthtooltips',
  HEALTH_WARNINGS = 'healthwarnings',
  HUMAN_GRANTS_NAME = 'humangrantsname',
  LANDING = 'landing',
  MAIN_SIDEBAR = 'mainsidebar',
  MOBILE_APP = 'mobileapp',
  MODAL_QR = 'modalqr',
  NOTIFICATIONS = 'notifications',
  ORG_GLOBAL_CARD = 'orgglobalcard',
  ORGS = 'orgs',
  RESPONDER_TABLE_VIEW = 'respondertableview',
  SETTINGS = 'settings',
  TABLE_VIEW = 'tableview',
  USER_TABLE_VIEW = 'usertableview',
}

const proxyHandler = {
  get: function (obj: TranslationGroup, key: string) {
    let trans = obj[key.toLowerCase()]
    if (trans === undefined) {
      const env = getEnv()
      trans = env === 'test' ? '' : `*** MISSING TRANSLATION FOR KEY "${key}" ***`
    }
    return trans
  },
}

function getDefaultTranslationGroups(): Record<string, TranslationGroup> {
  const defaultGroups = enUS
  return defaultGroups as Record<string, TranslationGroup>
}

export interface TranslationGroup extends Record<string, string> {}
export interface TranslationState {
  language: string
  defaultGroups: { [key: string]: TranslationGroup }
  groups: { [key: string]: TranslationGroup }
  merged: { [key: string]: TranslationGroup }
}

const transCache: TranslationState = {
  language: i18n.language,
  defaultGroups: getDefaultTranslationGroups(),
  groups: {},
  merged: {},
}

i18n.on('languageChanged', lng => {
  transCache.groups = {}
  transCache.merged = {}
})

export function getTranslationGroup(key: TranslationKey, merge: boolean = false): TranslationGroup {
  const k = key.toLowerCase()
  if (merge) {
    if (transCache.merged[k]) {
      return transCache.merged[k]
    }
  } else {
    if (transCache.groups[k]) {
      return transCache.groups[k]
    }
  }
  let defaultValue = {}
  try {
    defaultValue = transCache.defaultGroups[k]
  } catch (e) {
    console.warn(`Default value not found for key ${k}`)
  }
  try {
    if (merge) {
      const common = getTranslationGroup(TranslationKey.COMMON)
      const merged = { ...common, ...i18n.t(k, { returnObjects: true }) }
      const tmp = { ...defaultValue, ...merged }
      const p = new Proxy(tmp, proxyHandler)
      transCache.merged[k] = p as TranslationGroup
      return transCache.merged[k]
    } else {
      const tmp = { ...defaultValue, ...i18n.t(k, { returnObjects: true }) }
      const p = new Proxy(tmp, proxyHandler)
      transCache.groups[k] = p as TranslationGroup
      return transCache.groups[k]
    }
  } catch (e) {
    console.warn(`A localization error occurred`, e)
  }
  return defaultValue as TranslationGroup
}

export const trans = {
  admin: () => getTranslationGroup(TranslationKey.ADMIN),
  common: () => getTranslationGroup(TranslationKey.COMMON),
  health: () => getTranslationGroup(TranslationKey.HEALTH_LABELS),
  group: getTranslationGroup,
  merge: (key: TranslationKey) => getTranslationGroup(key, true),
  parse: (key: TranslationKey) => {
    try {
      return JSON.parse(i18n.t(key.toLowerCase()))
    } catch (e) {
      return getTranslationGroup(key)
    }
  },
  settings: () => getTranslationGroup(TranslationKey.SETTINGS),
}

export function lc(str: string) {
  return str.toLowerCase()
}

export function uc(str: string) {
  return str.toUpperCase()
}

export function ucfirst(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function tc(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}
