/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled/macro'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Avatar, Button, IconButton, Menu, MenuItem, Tooltip } from '@material-ui/core'
import { GENERAL_SETTINGS_PATH, getAppColors, PATH_ADMIN } from 'lib/constants'
import NotificationCenter from './NotificationCenter'
import ReactMobileLogo from 'static/images/Reactmobile_Logo.svg'
import { flexDirection, position, fontWeight } from 'styles'
import { getDataFromJWT, isAdminAnywhere } from 'lib/utils/auth'
import { CountrySelect } from './CountrySelect'
import { pusher } from 'models/api'
import { trans, TranslationGroup, TranslationKey } from 'lib/types'
import Refresh from './Refresh'
import packageJson from '../../../../package.json'
const version = packageJson.version

const MainHeaderWrapper = styled.div({
  lineHeight: '6.3rem',
  backgroundColor: getAppColors('--color-white'),
  zIndex: 200,
  ...position('sticky'),
  top: 0,
})

const RefreshContainer = styled.div({
  width: '60px',
  display: 'grid',
  placeContent: 'center',
})

const ButtonWrapper = styled.div({})

export const LinearGradientWrapper = styled.div({
  height: 0,
})

export const LinearGradient = styled.div({
  background: 'linear-gradient(180deg, rgba(0,0,0, 0.05) 0%, rgba(0,0,0, 0) 100%)',
  height: '15px',
})

const HeaderContent = styled.div({
  height: '67px',
  display: 'flex',
  ...flexDirection('row'),
  justifyContent: 'flex-start',
})

const leftSection = {
  flex: '1 1 auto',
  display: 'flex',
}

const rightSection = {
  flex: '0 1 auto',
  display: 'flex',
}

const logo = {
  height: '26px',
  margin: '17px 25px',
  cursor: 'pointer',
}

const slogan = {
  fontFamily: 'Montserrat',
  fontSize: '1.2rem',
  ...fontWeight(500),
  letterSpacing: '0',
  color: getAppColors('--color-secondary'),
}

const headerButton = {
  color: getAppColors('--color-secondary'),
  marginRight: '10px',
  marginLeft: '10px',
  fontFamily: 'Montserrat',
  ...fontWeight(500),
  fontSize: '1.2rem',
  lineHeight: '1.865rem',
  '&:hover': {
    borderRadius: '15px',
    background: getAppColors('--color-gray-4'),
  },
}

const highlighted = {
  borderRadius: '15px',
  background: getAppColors('--color-gray-4'),
}

const nonHighlighed = {
  borderRadius: '15px',
}

const avatarContainer = {
  cursor: 'pointer',
}

const userInfo = {
  padding: '0px 10px 5px',
  margin: '0',
  borderBottom: '2px solid lightgray',
  fontSize: '1.4rem',
  fontFamily: 'Montserrat',
}

const userName = {
  fontSize: '1.6rem',
  ...fontWeight(800),
}

const logoutStyle = {
  fontSize: '1.4rem',
  fontFamily: 'Montserrat',
  ...fontWeight(800),
}

export const MainHeader = () => {
  const history = useHistory()
  const isLanguageSelectVisible = true //isTruthy(useSearchParams('i18n') as string)

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const translation: TranslationGroup = trans.group(TranslationKey.HEADER)
  const common: TranslationGroup = trans.common()

  const handleAvatarClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logout = () => {
    localStorage.clear()
    pusher?.disconnect()
    history.push('/')
    location.reload()
  }

  const jwtData = getDataFromJWT()

  return (
    <MainHeaderWrapper>
      <HeaderContent>
        <div id="main-header" css={leftSection}>
          <img
            css={logo}
            src={ReactMobileLogo}
            alt="React Mobile"
            data-testid="headerLogo"
            onClick={() => history.push('/')}
          />
          <div data-testid="headerSlogan" css={slogan}>
            {`${translation.header_slogan} - v${version}`}
          </div>
        </div>
        <div css={rightSection}>
          {/* <AudioContextIcon /> */}
          <NotificationCenter />
          {[
            isAdminAnywhere() && ['Admin', common.admin, PATH_ADMIN.PROPERTIES, translation.admin_tooltip],
            ['Settings', translation.settings, GENERAL_SETTINGS_PATH, translation.settings_tooltip],
          ]
            .filter(entry => !!entry)
            // @ts-ignore
            .map(([key, name, path, tooltip]) => {
              const buttonCSS = history.location.pathname.match(path) ? highlighted : nonHighlighed
              return (
                <ButtonWrapper key={key}>
                  <Tooltip title={tooltip}>
                    <Button css={{ ...headerButton, ...buttonCSS }} onClick={() => history.push(path)}>
                      {name}
                    </Button>
                  </Tooltip>
                </ButtonWrapper>
              )
            })}
          <RefreshContainer>
            <Refresh />
          </RefreshContainer>
          {!!isLanguageSelectVisible && <CountrySelect />}

          <div css={avatarContainer}>
            <IconButton name="Open user options" aria-label="User Avatar" onClick={handleAvatarClick}>
              <Avatar imgProps={{ referrerPolicy: `no-referrer` }} src={jwtData?.avatar} />
            </IconButton>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              <div css={userInfo}>
                <div css={userName}>{`${jwtData?.first_name} ${jwtData?.last_name ? jwtData.last_name : ''}`}</div>
                <div>{jwtData?.email}</div>
              </div>
              <MenuItem css={logoutStyle} data-testid="headerLogout" onClick={logout}>
                {translation.logout}
              </MenuItem>
            </Menu>
          </div>
        </div>
      </HeaderContent>
      <LinearGradientWrapper>
        <LinearGradient />
      </LinearGradientWrapper>
    </MainHeaderWrapper>
  )
}
