/** @jsxImportSource @emotion/react */
/* eslint-disable no-useless-escape */
import styled from '@emotion/styled/macro'
import { BRAND_COLOR, DISABLED_COLOR } from 'lib/constants'
import { shallowEqual } from 'react-redux'
import {
  InputLabel,
  Input,
  Tooltip,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  CheckboxProps,
} from '@material-ui/core'
import { ActionButton } from 'components/widgets'
import { AuthProvider, ERROR_COLOR, GRAY3_COLOR, TEXTFIELD_LABEL_COLOR } from 'lib/constants'
import { AlertTargetRequest, AlertTargetStatus, trans, TranslationGroup, TranslationKey, User } from 'lib/types'
import { AlertTargets, PopUpNotifications, Users } from 'models'
import { resendAuthEmail, resendInviteEmail, sendConfirmEmailAlerts } from 'models/api'
import { useState, memo, useEffect } from 'react'
import { AssignModal } from '../AssignModal'
import { isAdmin, isMe } from 'lib/utils/auth'
import { createOrUpdate } from 'models/Users'
import { AlertTarget, AlertTargetForwardType } from 'lib/types'
import { subscribe, unsubscribe } from 'models/AlertTargets'
import 'react-phone-number-input/style.css'
import PhoneInput, { Value, isPossiblePhoneNumber } from 'react-phone-number-input'
import { HealthIcon, HealthStatus } from 'components/widgets/HealthIcon'
import { selectOrganization } from 'models/NavState'

const UserWrapper = styled.div({
  width: '100%',
  alignContent: 'center',
})

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  // gap: '25px',
})

const ActionContainer = styled.div({
  display: 'flex',
  height: '28px',
})

const StyledFormControl = styled.div({
  width: '100%',
  marginBottom: '15px',
})
interface StyledCheckboxProps extends CheckboxProps {
  checkboxcolor: string
}

const StyledCheckbox = styled(Checkbox)<StyledCheckboxProps>(props => ({
  border: '1px solid #FFFFFF',
  padding: '0',
  '&.Mui-checked': {
    color: props.checkboxcolor,
  },
}))

const StyledLabel = styled.div({
  color: TEXTFIELD_LABEL_COLOR,
  fontFamily: 'Montserrat',
  fontSize: '1.4rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.5rem',
  letterSpacing: '0.1rem',
  textAlign: 'left',
  marginBottom: '10px',
})

const StyledInputLabel = styled(InputLabel)({
  color: TEXTFIELD_LABEL_COLOR,
  fontFamily: 'Montserrat',
  fontSize: '1.4rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.5rem',
  letterSpacing: '0.1rem',
  textAlign: 'left',
  marginBottom: '10px',
})

const StyledInput = styled(Input)({
  background: GRAY3_COLOR,
  borderRadius: '10px',
  padding: '5px 10px',
  fontFamily: 'Montserrat',
  width: '100%',
})

const StyledInputErrorLabel = styled(InputLabel)({
  color: ERROR_COLOR,
  fontFamily: 'Montserrat',
  fontSize: '1.2rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.5rem',
  letterSpacing: '0.1rem',
  textAlign: 'left',
  margin: '5px',
})

const StyledPhoneInput = styled(PhoneInput)({
  height: '28px',
  width: '100%',
  '.PhoneInputInput': {
    backgroundColor: 'blue',
    height: '100%',
    border: 'none',
    background: GRAY3_COLOR,
    borderRadius: '10px',
    padding: '5px 10px',
    fontFamily: 'Montserrat',
    width: '100%',
  },
  '.PhoneInputCountryIcon': {
    height: '20px',
    width: '30px',
    border: 'none',
  },
})

interface Props {
  orgId: string
  userId: string
  close: Function
}

export function EditUserModal({ orgId, userId, close }: Props) {
  const translation: TranslationGroup = trans.group(TranslationKey.USER_TABLE_VIEW)
  const common: TranslationGroup = trans.common()
  const auth: TranslationGroup = trans.group(TranslationKey.AUTH)
  const usersById = Users.use(({ usersById }) => usersById)
  const [user] = useState<User>(usersById[userId])
  const [resetDisabled, setResetDisabled] = useState(false)
  const [resendInviteLoading, setResendInviteLoading] = useState(false)
  const { loading, alertTargetsByUserId } = AlertTargets.use(({ loading, alertTargetsByUserId }) => {
    return { loading, alertTargetsByUserId }
  }, shallowEqual)

  const [emailTarget, setEmailTarget] = useState<AlertTarget>()
  const [hasEmailTarget, setHasEmailTarget] = useState(false)

  /** SMS Logic */
  const [smsTarget, setSMSTarget] = useState<AlertTarget>()
  const [hasSmsTarget, setHasSmsTarget] = useState(false)
  const [phone, setPhone] = useState<string>('')
  const [smsError, setSmsError] = useState<string>('')
  const [phoneValid, setPhoneValid] = useState<boolean>(false)
  const [smsConsentChecked, setSmsConsentChecked] = useState<boolean>(false)

  const [firstName, setFirstName] = useState<string | undefined>('')
  const [lastName, setLastName] = useState<string | undefined>('')

  const REDIRECT_URL = `${window.location.protocol}//${window.location.host}`

  function handleAPIResponse(message: any) {
    if (typeof message === 'number' && message > 399 && message < 500) {
      PopUpNotifications.fireError({ content: translation.unauthorized })
    } else if (typeof message !== 'number') {
      PopUpNotifications.fireInfo({ content: message })
    } else {
      PopUpNotifications.fireInfo({ content: translation.unexpected_error })
    }
  }

  useEffect(() => {
    AlertTargets.reload(userId)
    setFirstName(user.attributes.firstName)
    setLastName(user.attributes.lastName)
  }, [])

  useEffect(() => {
    // reset consent state
    setSmsConsentChecked(false)
  }, [phone])

  useEffect(() => {
    if (!loading) {
      let het = false
      let hst = false
      const targets = alertTargetsByUserId[userId] || []
      targets.forEach((target: AlertTarget) => {
        if (target.attributes.forwardType === AlertTargetForwardType.EMAIL && target.attributes.orgId === orgId) {
          setEmailTarget(target)
          if (target.attributes.status !== AlertTargetStatus.REFUSED) het = true
        }
        if (target.attributes.forwardType === AlertTargetForwardType.SMS) {
          let num = target.attributes.forward
          setPhone(num)
          setSMSTarget(target)
          setPhoneValid(true)
          if (target.attributes.status !== AlertTargetStatus.REFUSED) hst = true
        }
      })

      setHasEmailTarget(het)
      setHasSmsTarget(hst)
    }
  }, [loading, alertTargetsByUserId])

  function sendResetEmail() {
    resendAuthEmail(user.attributes.email, REDIRECT_URL, true).then(message => handleAPIResponse(message))
  }

  function handleResendSignUp() {
    // function to resend the original sign-up email
    setResendInviteLoading(true)
    resendInviteEmail(user?.attributes.email).then(d => setResendInviteLoading(false))
    return
  }

  function clearProvider() {
    createOrUpdate({ ...user, provider: null })
  }

  const handleReset = () => {
    setResetDisabled(true)
    if (user.attributes.provider === AuthProvider.IDENTITY) {
      sendResetEmail()
    } else {
      clearProvider()
    }
  }

  function handlePhoneValueChange(num: Value) {
    const isValid = isPossiblePhoneNumber(num || '')
    if (isValid) {
      setSmsError('')
    }
    setPhoneValid(isValid)
    setPhone(num)
  }

  async function handleSubscribeAlerts(forwardType: AlertTargetForwardType, target: AlertTarget | undefined) {
    if (target && (target.attributes.status === 'accepted' || target.attributes.status === 'pending')) {
      console.log('unsubscribing')
      if (forwardType === AlertTargetForwardType.EMAIL) setHasEmailTarget(false)
      if (forwardType === AlertTargetForwardType.SMS) setHasSmsTarget(false)
      await unsubscribe(target)
      setSMSTarget(undefined)
      setPhone('')
    } else {
      // refresh sms error
      setSmsError('')
      console.log('subscribing')
      const request: AlertTargetRequest = { id: undefined, userId, orgId, forwardType, forward: '' }
      if (target) request.id = target.id
      if (forwardType === AlertTargetForwardType.EMAIL) {
        const forward = user.attributes.email || ''
        subscribe({ ...request, forward, status: AlertTargetStatus.PENDING }, () =>
          sendConfirmEmailAlerts(userId, user.attributes.email, orgId),
        )
      } else if (forwardType === AlertTargetForwardType.SMS) {
        if (!smsConsentChecked) {
          setSmsError('Please consent')
          return
        }
        const forward = phone
        const result: any = await subscribe({ ...request, forward, status: AlertTargetStatus.ACCEPTED }, () => null)
        if (result?.payload?.response?.status === 422) {
          setSmsError('The phone number entered already exists')
          return
        }
        setHasSmsTarget(true)
        // reset consent state
        smsConsentChecked ? setSmsConsentChecked(false) : null
      }
    }
  }

  const handleClose = () => {
    if (close) close()
  }
  const provider = user.attributes.provider
  let cta = auth.reset_password
  let msg = auth.reset_sent
  if (provider !== AuthProvider.IDENTITY) {
    cta = auth.reset_provider
    msg = auth.reset_provider_success
  }

  function StatusIcon({ target }: { target?: AlertTarget }) {
    if (!target) return null
    let status
    switch (target.attributes.status) {
      case AlertTargetStatus.ACCEPTED:
        status = HealthStatus.HEALTHY
        break
      case AlertTargetStatus.PENDING:
        status = HealthStatus.MODERATE
        break
      default:
        status = HealthStatus.CRITICAL
    }
    const title = translation[`subscription_${target.attributes.status}`]
    return (
      <Tooltip title={title} enterDelay={100} placement={'top'}>
        <div>
          <HealthIcon status={status} />
        </div>
      </Tooltip>
    )
  }

  return (
    <UserWrapper>
      <StyledContainer>
        <div>
          {(isAdmin(orgId) || isMe(userId)) && (
            <div>
              <StyledFormControl>
                <StyledInputLabel htmlFor="user-email">{common.email}</StyledInputLabel>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <StyledInput
                    id="user-email"
                    disableUnderline
                    placeholder={translation.enter_email}
                    value={user.attributes.email}
                    type="email"
                    disabled={true}
                  />
                  <StatusIcon target={emailTarget} />
                </div>
              </StyledFormControl>
              <>
                {provider && provider !== AuthProvider.IDENTITY && (
                  <StyledLabel>
                    {translation.provider}: {provider}
                  </StyledLabel>
                )}
                <ActionContainer>
                  {provider && (
                    <>
                      <ActionButton height="28px" onClick={() => handleReset()}>
                        {cta}
                      </ActionButton>
                      <ActionButton
                        height="28px"
                        onClick={() => handleSubscribeAlerts(AlertTargetForwardType.EMAIL, emailTarget)}
                        loading={loading}
                        disabled={loading}
                      >
                        {hasEmailTarget ? translation.unsubscribe_email : translation.subscribe_email}
                      </ActionButton>
                    </>
                  )}
                  {!provider && (
                    <>
                      <ActionButton
                        disabled={resendInviteLoading}
                        height="28px"
                        loading={resendInviteLoading}
                        onClick={handleResendSignUp}
                      >
                        {'Resend Sign-up Email'}
                      </ActionButton>
                    </>
                  )}
                </ActionContainer>
                <StyledInputErrorLabel style={{ visibility: resetDisabled ? 'visible' : 'hidden' }}>
                  {msg}
                </StyledInputErrorLabel>
              </>
              <StyledFormControl>
                <StyledInputLabel>{common.name}</StyledInputLabel>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <StyledInput
                    css={{ width: '48%' }}
                    placeholder="First Name"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    disableUnderline
                  />
                  <StyledInput
                    css={{ width: '48%' }}
                    placeholder={'Last Name'}
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    disableUnderline
                  />
                </Box>
              </StyledFormControl>

              <StyledFormControl>
                <StyledInputLabel htmlFor="user-phone">{common.phone}</StyledInputLabel>
                <div style={{ display: 'flex' }}>
                  <StyledPhoneInput
                    defaultCountry="US"
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={handlePhoneValueChange}
                    international={false}
                  />
                  <StatusIcon target={smsTarget} />
                </div>
              </StyledFormControl>
              <StyledInputErrorLabel style={{ visibility: smsError ? 'visible' : 'hidden' }}>
                {smsError}
              </StyledInputErrorLabel>
              {!loading && phoneValid && !hasSmsTarget && (
                <>
                  <Typography variant="body1">
                    Please confirm you would like to receive SMS notifications through React Mobile. Please note,
                    message and data rates may apply, message frequency varies. Please visit reactmobile.com for terms
                    and conditions and privacy policy. You can reply STOP to 25378 at any time to cancel. Reply HELP for
                    help
                  </Typography>
                  <Box css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5px' }}>
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checkboxcolor={BRAND_COLOR}
                          checked={smsConsentChecked}
                          onChange={() => setSmsConsentChecked(!smsConsentChecked)}
                          name="sms-consent-checkbox"
                        />
                      }
                      label="I consent"
                    />
                  </Box>
                </>
              )}

              <ActionContainer>
                <ActionButton
                  height="28px"
                  onClick={() => handleSubscribeAlerts(AlertTargetForwardType.SMS, smsTarget)}
                  loading={loading}
                  disabled={loading || !phoneValid}
                >
                  {hasSmsTarget ? translation.unsubscribe_sms : translation.subscribe_sms}
                </ActionButton>
              </ActionContainer>
            </div>
          )}
        </div>
        {isAdmin(orgId) && (
          <AssignModal
            title={translation.assign_roles}
            firstName={firstName}
            lastName={lastName}
            orgId={orgId}
            user={user}
            userId={userId}
            close={() => handleClose()}
          />
        )}
      </StyledContainer>
    </UserWrapper>
  )
}

export default memo(EditUserModal)
