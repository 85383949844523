import { getRequest, putRequest, postRequest, deleteRequest } from './rest'
import { ExternalIntegration } from 'lib/types'
import { API_EXTERNAL_INTEGRATIONS} from 'lib/constants'
import { decodeJsonApiObject } from './ApiLib'

export const getExternalIntegration = (id: string): Promise<ExternalIntegration> =>
  getRequest({ endpoint: `${API_EXTERNAL_INTEGRATIONS}/${id}` }).then(
    ({ data }) => decodeJsonApiObject(data.data),
  )

export const getAllExternalIntegrationsRaw = (): Promise<any[]> =>
  getRequest({
    endpoint: API_EXTERNAL_INTEGRATIONS,
  }).then(
    ({ data }) => data.data,
  )

export const getAllExternalIntegrations = (): Promise<ExternalIntegration[]> =>
  getAllExternalIntegrationsRaw().then(items =>
    items.map(a => decodeJsonApiObject<ExternalIntegration>(a)),
  )

export const getExternalIntegrationsByOrgIDRaw = (orgId: string): Promise<any[]> =>
  getRequest({
    endpoint: API_EXTERNAL_INTEGRATIONS,
    header: { org_id: orgId }
  }).then(
    ({ data }) => data.data,
  )

export const getAllExternalIntegrationsByOrgID = (orgId: string): Promise<ExternalIntegration[]> =>
  getExternalIntegrationsByOrgIDRaw(orgId).then(items =>
    items.map(a => decodeJsonApiObject<ExternalIntegration>(a)),
  )

export const upsertExternalIntegration = (input: ExternalIntegration): Promise<ExternalIntegration> => {
  const { id, orgId } = input
  return (
    id
      ? putRequest({
        endpoint: `${API_EXTERNAL_INTEGRATIONS}/${id}`,
        data: { external_integration: input },
        header: { org_id: orgId }
      })
      : postRequest({
        endpoint: `${API_EXTERNAL_INTEGRATIONS}`,
        data: { external_integration: input },
        header: { org_id: orgId }
      })
  ).then(({ data })=> decodeJsonApiObject<ExternalIntegration>(data.data))
}

export const deleteExternalIntegration = (id: string) =>
  deleteRequest({
    endpoint: `${API_EXTERNAL_INTEGRATIONS}/${id}`,
  })
