/** @jsxImportSource @emotion/react */
import { shallowEqual } from 'react-redux'
import { memo, useEffect, useState } from 'react'
import _ from 'lodash'
import { ViewWrapper, PageWrapper, ViewSpacerWrapper, ActionButton } from 'components/widgets'
import { AlertSessions, DetailsDrawer, PopUpNotifications } from 'models'
import { AlertSession, RouteParams, SortOrder, TableState, TableToolEvent, trans, TranslationGroup } from 'lib/types'
import { OpenAlertCards } from 'components/partial-pages/AlertsView'
import * as utils from './utils'
import { invertBinaryEnum } from 'lib/utils/common'
import { PreConfiguredTableView } from 'components/partials/TableView'
import { AlertDetailDrawer } from 'components/partials/DrawerDetails'
import { useHistory, useParams } from 'react-router-dom'
import { AlertsColumnKeys, ALERTS_PATH } from 'lib/constants'
import { useQuery } from 'models/modelUtils'
import { RenderCounter } from 'components/widgets/RenderCounter'

function AlertsView() {
  const common: TranslationGroup = trans.common()
  const history = useHistory()
  const { id } = useParams<RouteParams>()
  const query = useQuery()
  const [filteredItems, setFilteredItems] = useState<AlertSession[]>([])
  const [tableState, setTableState] = useState<TableState<string, AlertsColumnKeys>>({
    filterBy: utils.getFilterOptions()[0],
    sortBy: { field: AlertsColumnKeys.Health, order: SortOrder.Ascending },
    searchBy: '',
  })

  const alertSessions: AlertSession[] = AlertSessions.useSelectedOrgsAlertSessions()
  const { alertSessionsById, initialLoading, loading } = AlertSessions.use(
    ({ alertSessionsById, initialLoading, loading }) => {
      return { alertSessionsById, initialLoading, loading }
    },
    shallowEqual,
  )

  useEffect(() => {
    if (id && (!loading || !initialLoading)) {
      const item = alertSessionsById[id]
      if (item) {
        handleRowClick(item)
      } else if (!loading) {
        PopUpNotifications.fireWarning({ content: `${common.Alert} ${id} ${common.was_not_found}` })
        history.replace(ALERTS_PATH)
      }
    }
  }, [id, initialLoading, loading])

  useEffect(() => {
    const page = query.get('page')
    if (page === '1' || loading) return
    query.set('page', '1')
    history.push({
      search: query.toString(),
    })
  }, [tableState])

  useEffect(() => {
    if (!loading || !initialLoading) {
      processItems()
    }
  }, [loading, initialLoading, alertSessions, tableState, query])

  function processItems() {
    if (!alertSessions.length && !filteredItems.length) return
    const opts = utils.getFilterOptions()
    const idx = opts.indexOf(tableState.filterBy)
    const filtered = utils
      .searchAlerts(alertSessions, tableState.searchBy)
      .filter(alert => (idx === 0 ? true : utils.getFilterAlertComparison(alert, tableState.filterBy)))
      .sort(utils.getSortAlertCompareFn(tableState.sortBy))
    setFilteredItems(filtered)
  }

  function handleToolEvent(toolEvent: TableToolEvent<string, AlertsColumnKeys>) {
    setTableState(state => ({
      filterBy: toolEvent.filterByRequest || state.filterBy,
      sortBy: toolEvent.sortByRequest || state.sortBy,
      searchBy: _.isNil(toolEvent.searchByRequest) ? state.searchBy : toolEvent.searchByRequest,
    }))
  }

  function handleHeaderClick(header: AlertsColumnKeys) {
    setTableState(state => {
      return Object.assign({}, state, {
        sortBy: {
          field: header,
          order: state.sortBy.field === header ? invertBinaryEnum(state.sortBy.order) : SortOrder.Ascending,
        },
      })
    })
  }

  function handleDrawerClose() {
    history.push({
      pathname: `${ALERTS_PATH}`,
      search: query.toString(),
    })
    DetailsDrawer.close()
  }

  function handleRowClick(row: AlertSession) {
    if (row.id != id) {
      history.push({
        pathname: `${ALERTS_PATH}/${row.id}`,
        search: query.toString(),
      })
    }

    DetailsDrawer.show({
      drawerComponent: AlertDetailDrawer,
      drawerProps: {
        alertSession: row,
        close: handleDrawerClose,
      },
    })
  }

  return (
    <PageWrapper>
      <ViewWrapper>
        <ViewSpacerWrapper>
          <RenderCounter name={'AlertsView'} />
          <OpenAlertCards alertSessions={alertSessions} />
          <PreConfiguredTableView
            sortedData={filteredItems}
            tableState={tableState}
            columnConfigs={utils.getColumnConfigs()}
            filterOptions={utils.getFilterOptions()}
            sortOptions={utils.getSortOptions()}
            getRowToolTip={utils.getAlertTooltip}
            onHeaderClick={handleHeaderClick}
            onRowClick={handleRowClick}
            onToolEvent={handleToolEvent}
            selectedRowId={id}
            loading={loading}
          >
            <ActionButton onClick={() => utils.downloadCsv(filteredItems)}>{common.download_csv}</ActionButton>
          </PreConfiguredTableView>
        </ViewSpacerWrapper>
      </ViewWrapper>
    </PageWrapper>
  )
}

export default memo(AlertsView)
