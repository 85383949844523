import { BACKGROUND2_COLOR, BORDER1_COLOR, BRAND_COLOR, GRAY3_COLOR, SECONDARY_COLOR } from 'lib/constants'
import { flexDirection, fontWeight, justifyContent, textAlign, textTransform, whiteSpace } from './cssProps'

export const authInputFieldStyles = {
  border: `1px solid ${BORDER1_COLOR}`,
  borderRadius: `15px`,
  lineHeight: 1,
  marginBottom: `2px`,
  '.MuiInputBase-input': {
    height: 'auto',
    padding: '0',
  },
  '&.Mui-focused': {
    border: `1px solid ${BRAND_COLOR}`,
  },
}

export const authButtonStyles = {
  background: BRAND_COLOR,
  border: `1px solid ${BRAND_COLOR}`,
  borderRadius: `15px`,
  color: `#fff`,
  font: `600 1.8rem/1 Montserrat`,
  marginTop: `2px`,
  padding: `19px 0`,
  ...textTransform(`none`),
  '&:hover': {
    color: SECONDARY_COLOR,
  },
}

export const authButtonWrapperStyles = {
  display: `flex`,
  ...flexDirection(`column`),
  ...justifyContent('center'),
  margin: `32px 0 0 0`,
  maxWidth: '540px',
  '@media (max-width: 769px)': {
    maxWidth: 'initial',
  },
}

export const authCheckEmailStyles = {
  fontSize: '1.8rem',
  fontWeight: '600',
  ...textAlign('center'),
  margin: '16px 0 30px',
}

export const authDividerTextStyles = {
  display: 'block',
  fontSize: '1.8rem',
  ...fontWeight(600),
  padding: '16px 0 18px',
  ...textTransform('uppercase'),
  ...textAlign('center'),
}

export const authWrapperStyles = {
  zIndex: 100,
  display: 'inline-block',
  padding: `10vw 15vw 0 10vw`,
  width: `30vw`,
  '@media (max-width: 769px)': {
    padding: '5vh 10vh',
    width: 'initial',
    maxWidth: 'initial',
  },
}

export const forgotPasswordStyles = {
  borderRadius: '15px',
  font: '500 1.2rem/1.2 Montserrat',
  ...justifyContent('flex-end'),
  margin: '28px 0 30px auto',
  ...textTransform('capitalize'),
}

export const oauthButtonStyles = {
  ...authButtonStyles,
  ...{
    background: GRAY3_COLOR,
    border: 0,
    color: SECONDARY_COLOR,
    fontSize: '1.2rem',
    marginBottom: '18px',
    flex: '1',
  },
}

export const oauthButtonLabelStyles = {
  '@media (max-width: 769px)': {
    ...whiteSpace(`nowrap`),
  },
}

export const sideImageContainerStyles = {
  background: BACKGROUND2_COLOR,
  display: 'inline-block',
  width: '45vw',
  height: '100vh',
  '@media(max-width: 767px)': {
    width: '100vw',
    '&.img': { display: 'none' },
    height: 'auto',
  },
}

export const responsiveLoginWrapperStyles = {
  display: 'flex',
  '@media (max-width: 767px)': {
    ...flexDirection('column'),
  },
}
