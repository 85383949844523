export const HealthLabelsKeys = {
  CRITICAL: 'critical',
  MODERATE: 'moderate',
  HEALTHY: 'healthy',
  LOW_BATTERY: 'low_battery',
  OFFLINE: 'offline',
  ONLINE: 'online_now',
  NO_RESPONSE_CRITICAL: 'no_response_critical',
  NO_RESPONSE: 'no_response',
  RESOLVED: 'resolved',
  ACTIVE_IN_PROGRESS: 'active_in_Progress',
  ACTIVE_NO_RESPONSE: 'active_no_response',
  UNKNOWN: 'unknown',
}
export const HealthTypesKeys = {
  CRITICAL: 'critical',
  MODERATE: 'moderate',
  HEALTHY: 'healthy',
  LOW_BATTERY: 'low_battery',
  NO_RESPONSE_CRITICAL: 'no_response_critical',
  NO_RESPONSE: 'no_response',
  RESOLVED: 'resolved',
  OPEN: 'open',
  OFFLINE: 'offline',
}
