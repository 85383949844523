/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import { Button, ButtonProps } from '@material-ui/core'
import { getAppColors } from 'lib/constants'
import { textTransform } from 'styles'
import { Loader, LoaderType } from '.'

interface AllProps extends ButtonProps {
  error?: boolean
  height?: string
  loading?: boolean
  warn?: boolean
  warning?: boolean
  width?: string
}

export const ActionButton = (props: AllProps) => {
  const { error, height, loading, warn, warning, width } = props
  let color = getAppColors('--color-primary')
  let backgroundColor = 'white'
  let border = color
  if (error) {
    color = 'white'
    backgroundColor = getAppColors('--color-error')
    border = '#990000'
  }
  if (warn || warning) {
    color = 'white'
    backgroundColor = getAppColors('--color-warning')
    border = '#CC9900'
  }
  return (
    <>
      <Button
        css={{
          color,
          backgroundColor,
          padding: '8px 20px',
          border: `2px solid ${border}`,
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontFamily: 'Montserrat',
          fontSize: '1.0rem',
          lineHeight: '1.2rem',
          textAlign: 'center',
          ...textTransform('uppercase'),
          marginRight: '5px',
          cursor: 'pointer',
          height: height ? height : '50px',
          width: width,
        }}
        {...props}
      ></Button>
      {!!loading && (
        <Loader type={LoaderType.ROTATING_LINES} width={height ? height : '50px'} height={height ? height : '50px'} />
      )}
    </>
  )
}
