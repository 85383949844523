import * as AlertSessions from './AlertSessions'
import * as Devices from './Devices'
import * as ExternalIntegrations from './ExternalIntegrations'
import * as Firmwares from './Firmware'
import * as Orgs from './Orgs'
import * as ChildOrgMap from './ChildOrgMap'
import * as Beacons from './Beacons'
import * as Grants from './Grants'
import * as Users from './Users'
import * as Responders from './Responders'
import { ALL_ORGS_SELECTED } from 'lib/constants'

export * as AlertEvents from './AlertEvents'
export * as AlertSessions from './AlertSessions'
export * as AlertTargets from './AlertTargets'
export * as ChildOrgMap from './ChildOrgMap'
export * as DetailsDrawer from './DetailsDrawer'
export * as ExternalIntegrations from './ExternalIntegrations'
export * as Devices from './Devices'
export * as Grants from './Grants'
export * as NavState from './NavState'
export * as Notifications from './Notifications'
export * as PopUpNotifications from './PopUpNotifications'
export * as Orgs from './Orgs'
export * as SelectedChildOrgs from './SelectedChildOrgs'
export * as Users from './Users'
export * as Firmwares from './Firmware'
export * as Beacons from './Beacons'
export * as Modal from './Modal'
export * as Responders from './Responders'
export * as LiveResponders from './LiveResponders'

export const reloadAll = () => {
  let selectedOrgId = localStorage.getItem('selected_org_id')
  // validate all properties not selected
  if (selectedOrgId === ALL_ORGS_SELECTED) selectedOrgId = null
  Promise.all([
    Orgs.reload(),
    selectedOrgId && AlertSessions.reload(),
    selectedOrgId && Devices.reload(),
    selectedOrgId && Responders.reload(),
    Users.reload(),
    selectedOrgId && Beacons.reload(),
    ChildOrgMap.reload(),
    Firmwares.reload(),
    Grants.reload(),
    ExternalIntegrations.reload(),
    // Notifications.reload(),
  ])
}

// export const reloadOne = (orgId: string) => {
//   console.log('reloadOne', orgId)
//   Promise.all([
//     Orgs.reload(),
//     ChildOrgMap.reload(),
//     AlertSessions.reload(),
//     Devices.reload(),
//     Responders.reload(localStorage.getItem('selected_org_id') || ALL_ORGS_SELECTED),
//     ExternalIntegrations.reload(),
//     Firmwares.reload(),
//     Beacons.reload(),
//     Grants.reload(),
//     Users.reload(),
//   ])
// }
