import { API_GRANTS } from 'lib/constants'
import { FlattenedGrant, Grant, GrantPostData } from 'lib/types'
import { decodeJsonApiObject } from 'models/api/ApiLib'
import { deleteRequest, getRequest, postRequest } from 'models/api/rest'

export const getAllGrants = (): Promise<Grant[]> =>
  getRequest({
    endpoint: API_GRANTS,
  }).then(({ data }) => data.data)

export const createGrant = (newGrant: GrantPostData, orgId: string) =>
  postRequest({
    endpoint: API_GRANTS,
    data: { grant: newGrant },
    header: { org_id: orgId },
  })

export const deleteGrantByGrantId = (grantId: string) =>
  deleteRequest({
    endpoint: `${API_GRANTS}/${grantId}`,
  })

// export const createOrUpdateGrant = (grantWithId: Grant): Promise<Grant> => {
//   const { id, ...grant } = grantWithId
//   return (
//     id
//       ? putRequest({
//           endpoint: `${API_GRANTS}/${id}`,
//           data: { grant },
//         })
//       : postRequest({
//           endpoint: `${API_GRANTS}`,
//           data: { grant },
//           header: {
//             orgId: grant.attributes.orgId,
//           },
//         })
//   ).then(({ data }) => decodeJsonApiObject<Grant>(data.data))
// }

export const associateUser = (orgId: string, email: string): Promise<FlattenedGrant> =>
  postRequest({
    endpoint: `${API_GRANTS}/associate`,
    data: { email },
    header: { org_id: orgId },
  }).then(({ data }) => decodeJsonApiObject<FlattenedGrant>(data.data))
