export const timeout = (delayMs: number) => new Promise(resolve => setTimeout(resolve, delayMs))

/*
timeout executes f after delayMs UNLESS there is already a pending f for the same key. Otherwise,
f will not be executed.

Returns Promise.then => f's result
*/
export class RateLimitedTimer {
  activeLimiters: { [key: string]: Promise<any> }

  constructor() {
    this.activeLimiters = {}
  }

  timeout(delayMs: number, key: string, action: any) {
    const { activeLimiters } = this
    const alreadyActive = activeLimiters[key]
    if (!alreadyActive) {
      return (activeLimiters[key] = timeout(delayMs).then(() => {
        delete activeLimiters[key]
        return Promise.resolve().then(action)
      }))
    }
    return activeLimiters[key]
  }
}

/* ReschedulableTimer works like the classic setTimeout except
that if you call setTimeout on the same timer again BEFORE the previous
one fires, the previous, pending action is canceled and will never fire.
*/
export class ReschedulableTimer {
  _actionCount: number

  constructor() {
    this._actionCount = 0
  }

  setTimeout(action: any, ms: number) {
    let actionCount = (this._actionCount += 1)
    setTimeout(() => {
      if (this._actionCount === actionCount) {
        this._actionCount++
        action()
      }
    }, ms)
  }
}
