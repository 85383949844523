import { useEffect, useRef } from 'react'

export const RenderCounter = ({ name }: { name: string }) => {
  const render = useRef(0)

  useEffect(() => {
    render.current++
    const msg = `Render: ${name} ${render.current}`
    console.log(msg)
  })

  return null
}
