/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import { boxSizing, textAlign } from 'styles'

export const NumberBubble = ({ value }: { value: number | string }) => {
  const bubbleStyles = {
    label: 'NumberBubble',
    flex: '0 0 auto',
    border: '1px solid #e6e6e6',
    borderRadius: '11.5px',
    width: '32px',
    height: '23px',
    marginRight: '5px',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '1.2rem',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    ...textAlign('center'),
    ...boxSizing('border-box'),
  }

  return <span css={bubbleStyles}>{value}</span>
}
