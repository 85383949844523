/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled/macro'
import { PRIMARY_COLOR } from 'lib/constants'

export const PageWrapper = styled.div({
  width: '100vw-350px',
  marginLeft: '350px',
  '@media (max-width: 1300px)': {
    marginLeft: '0',
    width: '100%',
  },
})

export const ViewWrapper = styled.div({
  overflow: 'auto',
  zIndex: 100,
  padding: '25px',
  paddingTop: '0px',
  marginTop: '25px',
  '@media (max-width: 1300px)': {
    marginTop: '100px',
  },
})

export const ViewSpacerWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  '> div:first-child': {
    marginBottom: '10px',
  },
  '> div:not(:first-child)': {
    marginBottom: '30px',
  },
})

export const ChartsWrapper = styled.div({
  margin: '20px 0 35px',
})

export const StyledHeader = styled.h1({
  fontFamily: 'Montserrat',
  color: PRIMARY_COLOR,
  fontSize: '2.4rem',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '2.1rem',
  letterSpacing: '0',
})

export const StyledSubHeader = styled.h2({
  fontFamily: 'Montserrat',
  color: PRIMARY_COLOR,
  fontSize: '1.8rem',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '2.1rem',
  letterSpacing: '0',
})

export const StyledMetaData = styled.div({
  fontFamily: 'Montserrat',
  color: PRIMARY_COLOR,
  fontSize: '1.6rem',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '2.1rem',
  letterSpacing: '0',
})
