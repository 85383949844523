import { StyledComponents } from 'components/StyledComponents'
import { PageWrapper, ViewWrapper } from 'components/widgets'
import DashboardContentLoader from './components/DashboardContentLoader'

const MainDashboardPage = () => (
  <PageWrapper>
    <ViewWrapper>
      <StyledComponents.StyledH1 id="styledH1"></StyledComponents.StyledH1>
      <DashboardContentLoader />
    </ViewWrapper>
  </PageWrapper>
)

export default MainDashboardPage
