/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import { useEffect, useState } from 'react'
import { uniqueId } from 'lodash'
import { Button, Menu, MenuItem, Tooltip, styled } from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons'
// import { Notifications } from 'models'
import { AlertSessions } from 'models'
import { AlertSession } from 'lib/types'
import { Orgs } from 'models'
import { AppNotification, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { onAlertNotificationClick } from './utils'
import { useHistory } from 'react-router'
import { getAppColors } from 'lib/constants'

const notificationIconContent = {
  color: getAppColors('--color-secondary'),
  marginRight: '20px',
}

const activeAlertStyle = {
  backgroundColor: getAppColors('--color-error'),
  color: getAppColors('--color-white'),
  animation: 'blinker 2s linear infinite',
  '@keyframes blinker': {
    '50%': {
      opacity: '0.2',
    },
  },
  '&:hover': {
    backgroundColor: getAppColors('--color-primary'),
  },
}

const menuTitle = {
  padding: '0px 10px 5px 18px',
  width: '300px',
  fontFamily: 'Montserrat',
  fontWeight: 800,
}

const notificationIcon = {
  color: 'inherit',
  marginRight: '5px',
}

const notificationMenuItem = {
  cursor: 'pointer',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  display: 'flex',
  color: 'white',
  width: '100%',
  background: getAppColors('--color-error'),
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  '&:hover': {
    filter: 'grayscale(50%)',
  },
}

const notificationInfo = {
  flex: '1 1 auto',
  padding: '10px 0px 10px 20px',
}

const notificationTitle = {
  fontSize: '1.8rem',
}

const notificationOrg = {
  fontSize: '1.2rem',
}

const NotificationCenter = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  // const notifications = Notifications.use(({ notifications }) => notifications)
  // const count = notifications.length

  const history = useHistory()
  const translation: TranslationGroup = trans.merge(TranslationKey.HEADER)

  const alertSessions = AlertSessions.use().alertSessions
  const filtered = alertSessions.filter(as => as.attributes.status === 'open')
  const count = filtered.length

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const colorModStyle = count > 0 ? activeAlertStyle : ''

  // this was the only way to simply achieve the intended behavior
  const StyledMenuItem = styled(MenuItem)(() => ({
    cursor: 'default',
    '&:hover': {
      background: 'none',
    },
  }))

  const AlertMenuItem = ({ alertSession }: { alertSession: AlertSession }) => {
    return (
      <StyledMenuItem>
        <div
          css={notificationMenuItem}
          onClick={() => {
            handleClose()
            onAlertNotificationClick(alertSession, history)
          }}
        >
          <span css={notificationInfo}>
            <div css={notificationTitle}>{translation.alert}</div>
            <div css={notificationOrg}>{Orgs.getOrg(alertSession.attributes.orgId)?.name || '[name not found]'}</div>
          </span>
        </div>
      </StyledMenuItem>
    )
  }

  return (
    <>
      <Tooltip title={translation.notifications}>
        <div css={notificationIconContent}>
          <Button
            name="Open Notifications"
            aria-label="Notification Icon"
            onClick={handleClick}
            css={{
              ...notificationIconContent,
              ...colorModStyle,
            }}
          >
            <ErrorOutline css={notificationIcon} />
            {count}
          </Button>
        </div>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div css={menuTitle}>{`${translation.notifications.toUpperCase()} (${count})`}</div>
        {filtered?.map(as => (
          <AlertMenuItem key={uniqueId()} alertSession={as} />
        ))}
      </Menu>
    </>
  )
}

export default NotificationCenter
