import { useState, useEffect, useRef } from 'react'
import useInterval from '../../lib/hooks/useInterval'
import { PUSHER_APP_CLUSTER, PUSHER_APP_KEY } from 'lib/constants'
import { NavState, SelectedChildOrgs } from 'models'

const INTERVAL = 1000

const MyNotification = () => {
  return {
    permission: 'default',
    requestPermission: () => {
      return new Promise<string>(resolve => {
        resolve('denied')
      })
    },
  }
}

const NotificationAPI =
  typeof Notification !== 'undefined' ? Notification : MyNotification()

const UseDesktopNotifications = () => {
  let [userConsent, setUserConsent] = useState<string>(
    NotificationAPI.permission,
  )
  const selectedChildOrgs = SelectedChildOrgs.useSelectedChildOrgs()
  let [swRegistered, setSwRegistered] = useState<boolean>(false)
  let [continueInterval, setContinueInterval] = useState<boolean>(true)
  let [areLocationsUploaded, setAreLocationsUploaded] = useState<boolean>(false)
  let [attempts, setAttempts] = useState<number>(0)

  const attemptsRef = useRef(attempts)
  attemptsRef.current = attempts

  const addEventListener = () => {
    navigator.serviceWorker.addEventListener('message', event => {
      switch (event.data.type) {
        case 'RECIEVED_MESSAGE':
          setAreLocationsUploaded(true)
          break
        case 'CHANGE_LOCATION':
          NavState.selectOrganization(event.data.data.value)
      }
    })
  }

  // get permission for desktop notifications
  useEffect(() => {
    NotificationAPI.requestPermission()
      .then((consent: string) => {
        setUserConsent(consent)
      })
      .catch(err => {
        console.error('APP: Unable to get permission.', err)
      })
  }, [userConsent])

  // register the service worker
  useEffect(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        if (registrations.length > 0) {
          addEventListener()
          setSwRegistered(true)
        } else {
          navigator.serviceWorker
            .register('/sw.js')
            .then(() => navigator.serviceWorker.ready)
            .then(() => {
              addEventListener()
              setSwRegistered(true)
            })
            .catch(err => {
              console.error('APP: Unable to register the service worker.', err)
            })
        }
      })
    }
    // eslint-disable-next-line
  }, [navigator.serviceWorker, addEventListener])

  // attempt sending locations to the service worker
  useInterval(() => {
    if (continueInterval && swRegistered) {
      if (areLocationsUploaded) {
        setAttempts(0)
        setContinueInterval(false)
      } else {
        if (navigator.serviceWorker?.controller) {
          setAttempts(attempts + 1)

          navigator.serviceWorker.controller?.postMessage({
            locations: selectedChildOrgs,
            key: PUSHER_APP_KEY,
            cluster: PUSHER_APP_CLUSTER,
          })
        }
      }
    }
  }, INTERVAL)

  return (
    <div style={{ display: 'none' }}>
      {selectedChildOrgs?.map(org => (
        <div key={org.id}>{org.name}</div>
      ))}
    </div>
  )
}

export default UseDesktopNotifications
