export enum DevicesColumnKeys {
  Health = 'Health',
  Type = 'Type',
  Name = 'Name',
  Battery = 'Battery',
  RSSI = 'RSSI',
  Firmware = 'Firmware',
  LastCheckIn = 'Last_check_in',
  ButtonConnected = 'Button_Paired',
}
